import './ModalAlerte.scss';

export type ModalProps = {
  src?: string;
  alt?: string;
  url?: string ;
  ariaInfoUrl?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export default function ModalAlerte({
  url, src, alt, onClick, ariaInfoUrl, children,
}: ModalProps) {
  function handleClickUrl() {
    window.location.href = url || '#';
  }

  const icon = src;
  const img = icon ? <img src={src} alt={alt} /> : '';

  return (
    <div
      className="container_Modal_Alerte"
      role="dialog"
      aria-modal="true"
    >
      <button
        type="button"
        onClick={onClick ?? handleClickUrl}
        aria-labelledby="modal-heading"
        aria-controls={ariaInfoUrl}
        className="modal_Alerte"
      >
        {img}
        <h1 id="modal-heading">{children}</h1>
      </button>
    </div>
  );
}
