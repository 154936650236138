import { DecouverteModel } from '../Decouvertes/Decouvertes.types';
import { Note } from '../Notes/Notes.types';

export enum Variant {
  achatvente = 'achatvente',
  location = 'location',
}
export enum VisibleStatuses {
  contreVisiteTerminée = 'Contre visite terminée',
  visitée = 'Visitée',
  mandatActif = 'Mandat actif',
  bienPublié = 'Bien publié'
}

export type AchatModel = {
  id: string;
  achatId: string;
  titre:string|null;
  variant:string;
  rapprochements:number;
  activitesEffectuees: string[];
  clotureAt: null | string;
  collaborateur: null | string;
  contacts: string[];
  contreVisiteVentesToDisplay?: string[];
  createdAt: string;
  decouverte: DecouverteModel;
  demandesRenseignements: string[];
  documents: Document[];
  lastActiviteEffectueeDate?: string;
  notes: Note[];
  offres?: string[];
  offreVentesToDisplay?: string[];
  raisonCloture: string | null;
  shareableId: string | null;
  statut: string|null;
  ventesFavorites: string[];
  ventesIgnorees: string[];
  ventesIgnoreesToDisplay?: string[];
  ventesSelectionnees: string[];
  ventesSelectionneesToDisplay?: string[];
  visites: string[];
  visitesIntentionAnnuleesIds?: string[];
  visiteVentesToDisplay: string[];
  mandatsRechercheIds: string[];
  apporteurAffairesId?: string | null;
  apporteurAffairesType?: string;
  }
