import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useShowAchat from '../../Pages/ShowAchatLayout/useShowAchat';
import useShowAchatStore from '../../Pages/ShowAchatLayout/useShowAchatStore';
import { createVenteIgnoree, removeVenteIgnoree } from '../../lib/papirisClient';

function useIgnoreeAction(venteId, achatId) {
  useShowAchat(achatId);

  const { achat } = useShowAchatStore();

  const ignorees = useMemo(() => achat?.ventesIgnorees || [], [achat?.ventesIgnorees]);
  const queryClient = useQueryClient();

  const isIgnoree = useMemo(() => ignorees.some((favoriVente) => favoriVente.id === venteId), [ignorees, venteId]);

  const addMutationIgnorees = useMutation(createVenteIgnoree);
  const removeMutationIgnorees = useMutation(removeVenteIgnoree);

  const onIgnoree = useCallback(async (venteId, achatId) => {
    const isIgnoree = ignorees.some((ignore) => ignore.id === venteId);
    const mutation = isIgnoree ? removeMutationIgnorees : addMutationIgnorees;
    await mutation.mutateAsync({ achatId, venteId });
    await queryClient.invalidateQueries({ queryKey: 'achat' });
    await queryClient.invalidateQueries({ queryKey: 'vente' });
  }, [ignorees, removeMutationIgnorees, addMutationIgnorees, queryClient]);

  return { isIgnoree, onIgnoree };
}

export default useIgnoreeAction;
