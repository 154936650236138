import './IconRendezVousConfirmation.scss';

import classNames from 'classnames';

import { IconProps } from './Icon.types';

function IconRendezVousConfirmation({ className }: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <span className={classNames('Icon IconRendezVousConfirmation', className)}>
        <title id="icon-house-title">Icon Confirmation de rendez-vous</title>
        <svg className="IconRendezVousConfirmation_calendar" id="Groupe_561" data-name="Groupe 561" xmlns="http://www.w3.org/2000/svg" width="71.544" height="74.887" viewBox="0 0 71.544 74.887">
          <rect id="Rectangle_112" data-name="Rectangle 112" width="68" height="43" transform="translate(2.772 25.443)" fill="#fff" />
          <g id="Icon_ionic-ios-calendar" data-name="Icon ionic-ios-calendar">
            <path id="Tracé_3007" data-name="Tracé 3007" d="M68.04,6.75H61.16v4.857a1.517,1.517,0,0,1-1.376,1.619H57.033a1.517,1.517,0,0,1-1.376-1.619V6.75H22.637v4.857a1.517,1.517,0,0,1-1.376,1.619H18.509a1.517,1.517,0,0,1-1.376-1.619V6.75H10.254c-3.784,0-6.879,3.642-6.879,8.094V69.885c0,4.452,3.1,8.094,6.879,8.094H68.04c3.784,0,6.879-3.642,6.879-8.094V14.844C74.919,10.392,71.823,6.75,68.04,6.75Zm1.376,60.707c0,2.226-1.548,4.047-3.44,4.047H12.318c-1.892,0-3.44-1.821-3.44-4.047V31.033a1.517,1.517,0,0,1,1.376-1.619H68.04a1.517,1.517,0,0,1,1.376,1.619Z" transform="translate(-3.375 -3.093)" fill="#21341c" />
            <path id="Tracé_3008" data-name="Tracé 3008" d="M12.657,5.414a.917.917,0,0,0-.914-.914H9.914A.917.917,0,0,0,9,5.414V8.157h3.657Z" transform="translate(5.143 -4.5)" fill="#21341c" />
            <path id="Tracé_3009" data-name="Tracé 3009" d="M28.407,5.414a.917.917,0,0,0-.914-.914H25.664a.917.917,0,0,0-.914.914V8.157h3.657Z" transform="translate(28.994 -4.5)" fill="#21341c" />
          </g>
        </svg>
        <svg className="IconRendezVousConfirmation_stars" id="Icon_weather-stars" data-name="Icon weather-stars" xmlns="http://www.w3.org/2000/svg" width="47.57" height="47.15" viewBox="0 0 47.57 47.15">
          <path id="Icon_weather-stars-2" data-name="Icon weather-stars" d="M6.444,34.591a5.82,5.82,0,0,0,4.075-1.852,5.8,5.8,0,0,0,1.68-4.149,5.993,5.993,0,0,0,5.73,6,5.993,5.993,0,0,0-5.73,6,5.7,5.7,0,0,0-1.68-4.149A5.82,5.82,0,0,0,6.444,34.591ZM12.2,16.808a11.351,11.351,0,0,0,7.928-3.631,11.306,11.306,0,0,0,3.26-8.1,11.306,11.306,0,0,0,3.26,8.1A11.463,11.463,0,0,0,34.6,16.808a11.448,11.448,0,0,0-5.656,1.754,11.869,11.869,0,0,0-4.075,4.273,11.614,11.614,0,0,0-1.482,5.755,11.363,11.363,0,0,0-3.26-8.126A11.277,11.277,0,0,0,12.2,16.808Zm8.2,26.6a8.822,8.822,0,0,0,8.422-8.818,8.824,8.824,0,0,0,8.4,8.818,8.824,8.824,0,0,0-8.4,8.818,8.506,8.506,0,0,0-2.445-6.1A8.652,8.652,0,0,0,20.4,43.409ZM37.219,31.7a8.78,8.78,0,0,0,8.373-8.842,8.822,8.822,0,0,0,8.422,8.818,8.822,8.822,0,0,0-8.422,8.818A8.736,8.736,0,0,0,37.219,31.7Z" transform="translate(-6.444 -5.076)" fill="#ebbc02" />
        </svg>
        <svg className="IconRendezVousConfirmation_check" id="icone_check" data-name="icone check" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <circle id="Ellipse_58" data-name="Ellipse 58" cx="13" cy="13" r="13" transform="translate(1 1)" fill="#fff" />
          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M28.563,14.563a14,14,0,1,1-14-14A14,14,0,0,1,28.563,14.563ZM12.943,21.975,23.33,11.588a.9.9,0,0,0,0-1.277L22.053,9.034a.9.9,0,0,0-1.277,0L12.3,17.5,8.349,13.55a.9.9,0,0,0-1.277,0L5.795,14.827a.9.9,0,0,0,0,1.277l5.871,5.871A.9.9,0,0,0,12.943,21.975Z" transform="translate(-0.563 -0.563)" fill="#aec0b7" />
        </svg>
      </span>
    </div>
  );
}

export default IconRendezVousConfirmation;
