import { CircularProgress } from '@mui/material';
import './home_vente.scss';
import '../../Component/Icon/IconHouse.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';

// Mise en place des COMPOSANT
import AllInfoData from '../../Component/AllInfoData/AllInfoData';
import Button from '../../Component/Button/Button';
import ButtonDragonfly from '../../Component/Button/ButtonDragonfly/ButtonDragonfly';
// Mise en place des function
import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import IconAddWhiteAndYellow from '../../Component/Icon/IconAddWhiteAndYellow';
import ICON_HOUSE from '../../Component/Icon/IconHouse';
import InfoPastilleNumber from '../../Component/InfoPastilleNumber/InfoPastilleNumber';
import TitleGlow from '../../Component/Title_Glow/title_glow';
import { URL_PROJET_LIST } from '../../Function/URL_path';
import { showVente } from '../../lib/papirisClient';
import { makeRoutePath } from '../../store/route/route.api';
import useShowVente from '../ShowVenteLayout/useShowVente';
import useShowVenteStore from '../ShowVenteLayout/useShowVenteStore';

export default function HomeVente() {
  const { vente, isLoading, isError } = useShowVenteStore();
  const { formatMessage } = useIntl();

  const location = useLocation();
  const titleForLocation = vente?.variant === 'location';

  const visitesDataVente = vente?.visiteCount ?? 0;
  const favorisDataVente = vente?.favoriteCount ?? 0;
  const demandesDataVente = vente?.demandeRenseignementsCount ?? 0;
  const contreVisitesDataVente = vente?.contreVisiteCount ?? 0;
  const partagesDataVente = vente?.selectionneeCount ?? 0;

  const FullDate = vente?.bien?.createdAt ?? '';
  const underTitleOfProjet = vente?.titre ?? FullDate?.split(' ')[0] ?? FullDate;

  if (isLoading) {
    <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />;
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  return (
    <>
      <div className="container_Home_Modal">
        {isError || isLoading
        || (
          <TitleGlow
            icon={(
              <ICON_HOUSE
                className="icon_house_HomeVente img-w4xl img-h4xl"
                classColor={titleForLocation ? 'colorLocation' : 'colorVente'}
              />
            )}
            title={titleForLocation ? 'Location' : 'Vente'}
            underTitle={underTitleOfProjet ?? null}
            edit
          />
        )}
      </div>
      <HeaderRetour classBEM="_homeAchatVenteBienBack" to={makeRoutePath('projet.list')} transparent />
      {vente ? (
        <FlexRow
          gap="4"
          grow="1"
          justifyContent="space-evenly"
        >
          <div className="container_Home_Vente">
            <AllInfoData isLoading={isLoading} isError={isError} visitesData={visitesDataVente} favorisData={favorisDataVente} demandesData={demandesDataVente} contreVisitesData={contreVisitesDataVente} partagesData={partagesDataVente} />
            <div className="container_ButtonsDragonfly">
              <ButtonDragonfly className="button_home" topLeftRadius topRightRadius to={makeRoutePath('vente.projet.messagerie', { venteId: vente?.id })}>
                Messagerie
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
              <FlexRow
                direction="row"
                gap="4"
                grow="1"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonDragonfly className="button_home" topLeftRadius bottomLeftRadius to={makeRoutePath('bien.show', { bienId: vente?.bien?.id })}>
                  Mon bien
                  <InfoPastilleNumber infoPastilleNumber={0} />
                </ButtonDragonfly>
                <ButtonDragonfly className="button_home" topRightRadius bottomRightRadius to={makeRoutePath('vente.projet.documents', { venteId: vente?.id })}>
                  Documents
                  <InfoPastilleNumber infoPastilleNumber={0} />
                </ButtonDragonfly>
              </FlexRow>
              <ButtonDragonfly className="button_home" bottomLeftRadius bottomRightRadius to={makeRoutePath('vente.projet.agenda', { venteId: vente?.id })}>
                Agenda
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
            </div>
            <Button
              className="button standard button_home projet medium"
              icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri_Pro img-w3xl img-h3xl" />}
              to={makeRoutePath('vente.projet.professionnel', { venteId: vente?.id })}
            >
              Professionnel
            </Button>
          </div>
        </FlexRow>
      ) : null}
    </>
  );
}
