import {
  useState, useCallback, useRef, useLayoutEffect,
} from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import useShowAchatStore from '../../../Pages/ShowAchatLayout/useShowAchatStore';
import useFavoriteAction from '../../../hooks/ActionCard/useFavoriteAction';
import useIgnoreeAction from '../../../hooks/ActionCard/useIgnoreeAction';
import useActionToProject from '../../../hooks/ActionConnected/useActionToProject';
import './card_immo.scss';
// Mise en place des Icons
import useRapprochementsByAchatStore from '../../../hooks/Rapprochements/useRapprochementsByAchatStore';
import useShowRapprochement from '../../../hooks/Rapprochements/useShowRapprochementByAchat';
import ICON_CHECK from '../../../img/icon_Check.svg';
import ICON_DPE from '../../../img/icon_dpe_blanc.svg';
import ICON_NB_CHAMBRE from '../../../img/icon_porte_blanc.svg';
import ICON_POSITION from '../../../img/icon_position.svg';
import ICON_SUPERFICIE from '../../../img/icon_surface_habitable_blanc.svg';
import ICON_METRE_TERRAIN from '../../../img/icon_surface_terrain_blanc.svg';
import { Variant } from '../../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../../store/route/route.api';
import Button from '../../Button/Button';
import Share from '../../Button/Share/button_Share';
import CARROUSEL_IMMO from '../../Carrousel/carrousel.jsx';
import FlexRow from '../../FlexRow/FlexRow';
import FormFooter from '../../FormFooter/FormFooter';
import IconDelete from '../../Icon/IconDelete';
import ICON_COEUR from '../../Icon/IconeLike.jsx';
// Mise en place des Composant
// Mise en place du Carrousel
import AchatLocSelectionModal from '../../Modal/AchatLocSelectionModal';
import ModalAlerte from '../../Modal/ModalAlerte/ModalAlerte';
import TagNew from '../../TagNew/TagNew';

import { isNaN } from 'lodash';

/**
Component for created a CARD_IMMO.
@param {object} props - The props object for the component.
@param {string} id - The ID of the card
@param {string} title - The title of the card
@param {string} price - The price of the card
@param {string} ville - The city of the card
@param {string} cp - The postal code of the card
@param {Array} slides - The images of the card
@param {boolean} favori - Indicates if the card is marked as favorite

@returns {JSX.Element} - The rendered card component
*/

export default function CardImmo({
  bien, prixAgence, loyerChargesComprises, id: venteId, reference, listProjetsAchats, location, ...vente
}) {
  const { achat } = useShowAchatStore();
  const { formatMessage } = useIntl();
  const [saveActionType, setSaveActionType] = useState('');
  const [actionFinish, setActionFinish] = useState(false);
  const [achatRequiredOpen, setAchatRequiredOpen] = useState(false);
  const [selectedAchatID, setSelectedAchatID] = useState(null);
  const { achatId: achatIdUrl } = useParams();
  useShowRapprochement(achatIdUrl);
  const { rapprochements } = useRapprochementsByAchatStore();

  // ici on passe les params venteId, achatIdUrl pour obtenir le projet que l'on souhaite et on récupérer 'isFavorite'
  const { isFavoriteForThisVente } = useFavoriteAction(venteId, achatIdUrl ?? achat?.id);
  useIgnoreeAction(venteId, achatIdUrl ?? achat?.id);

  const { executeAction } = useActionToProject(setActionFinish, setAchatRequiredOpen);

  const handleHideFinish = useCallback(() => {
    setActionFinish(false);
  }, []);

  const handleHideAchatRequired = useCallback(() => {
    setAchatRequiredOpen(false);
  }, []);

  const handleExecuteAction = useCallback((actType) => {
    setSaveActionType(actType);
    executeAction(actType, venteId, achatIdUrl, listProjetsAchats);
  }, [achatIdUrl, executeAction, listProjetsAchats, venteId]);
  const onSubmit = useCallback((data) => {
    setSelectedAchatID(data.id);
    executeAction(saveActionType, venteId, data.id, listProjetsAchats);
    setAchatRequiredOpen(false);
  }, [executeAction, saveActionType, venteId, listProjetsAchats]);

  const actionChoiceToFavori = useCallback(() => {
    handleExecuteAction('favori');
  }, [handleExecuteAction]);

  const actionChoiceToIgnore = useCallback(() => {
    handleExecuteAction('ignoree');
  }, [handleExecuteAction]);

  const slidesImages = bien?.photos?.map((photo) => photo.path) ?? [bien?.firstPhoto?.path];

  const infoSuperficie = Boolean(bien?.surface) === true ? (
    <>
      <img src={ICON_SUPERFICIE} alt="icon superficie" />
      <small>
        <FormattedNumber value={(bien.surface / 100)} />
        {formatMessage({ id: 'info.bien.squareMetre' })}
      </small>
    </>
  ) : (
    ''
  );

  const infoDpe = Boolean(bien?.classeEnergetique) === true ? (
    <>
      <img src={ICON_DPE} alt="icon dpe" />
      <small>
        DPE:
        {bien?.classeEnergetique}
      </small>
    </>
  ) : (
    ''
  );

  const infoChambre = Boolean(bien?.chambresCount) === true ? (
    <>
      <img src={ICON_NB_CHAMBRE} alt="icon chambre" />
      <small>
        <FormattedMessage id="ventes.search.bien.chambres" values={{ chambresCount: bien?.chambresCount }} />
      </small>
    </>
  ) : (
    ''
  );

  const infoTerrain = Boolean(bien?.surfaceTerrain) === true ? (
    <>
      <img src={ICON_METRE_TERRAIN} alt="icon terrain" />

      <small>
        <FormattedNumber value={(bien.surfaceTerrain / 100)} />
        {formatMessage({ id: 'info.bien.squareMetre' })}
      </small>
    </>
  ) : (
    ''
  );

  const ArrayBienRapprochements = rapprochements?.items?.map((item) => item?.venteId);

  const conditionForTag = ((rapprochements?.items?.length ?? 0) !== 0) && (ArrayBienRapprochements?.includes(venteId));

  // Replace current history entry to add the venteId into the state, which will be used to restore scroll when navigating through history.
  const rootRef = useRef();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if ('IntersectionObserver' in window && rootRef.current) {
      const options = {
        root: window.document.getElementById('root'),
        threshold: 0.75,
      };

      const observer = new IntersectionObserver((nodes) => {
        nodes.forEach((node) => {
          if (node.isIntersecting) {
            navigate(`${window.location.pathname}${window.location.search}`, { state: { venteId }, replace: true });
          }
        });
      }, options);

      observer.observe(rootRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [navigate, venteId]);

  /**
    function URLCUSTOM for redirects the user to the detail page of a card when the top of the card is clicked.
    @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event - The click event to remove the propagation
    @param {string} card_Id - The ID of the card
    @param {string} title - The title of the card
    @param {string} price - The price of the card
    @param {string} ville - The city of the card
    @param {string} cp - The postal code of the card
    @returns {JSX.Element} - The detail page component
    */

  const getFormattedPrice = (vente, prixAgence, loyerChargesComprises) => {
    if (vente?.variant === Variant.achatvente) {
      const value = prixAgence / 100;

      return isNaN(value) ? '' : value;
    }
    if (vente?.variant === Variant.location) {
      const value = loyerChargesComprises / 100;

      return isNaN(value) ? '' : value;
    }

    return '';
  };

  const formattedPrice = getFormattedPrice(vente, prixAgence, loyerChargesComprises);

  return (
    <div className="container_Card" ref={rootRef} id={venteId}>
      {conditionForTag ? <TagNew /> : null}
      <Button to={achatIdUrl ? makeRoutePath('search.achat.vente.show', { achatId: achatIdUrl, venteId }) : `/search/vente/${venteId}`} key={`button-card-immo-${venteId}`} className="card_Images">
        <CARROUSEL_IMMO slides={slidesImages} />
        <div className="pictogrammes">
          {infoSuperficie ? <div className="picto">{infoSuperficie}</div> : ''}
          {infoChambre ? <div className="picto">{infoChambre}</div> : ''}
          {infoTerrain ? <div className="picto">{infoTerrain}</div> : ''}
          {infoDpe ? <div className="picto">{infoDpe}</div> : ''}
        </div>
      </Button>
      {actionFinish === true ? (
        <FormFooter>
          <FlexRow alignItems="center">
            <ModalAlerte src={ICON_CHECK} alt={formatMessage({ id: 'alt.icon.check' })} onClick={() => handleHideFinish()}>
              {formatMessage({ id: 'search.action.like.delete' })}
            </ModalAlerte>
          </FlexRow>
        </FormFooter>
      ) : ''}
      {achatRequiredOpen === true
        ? <AchatLocSelectionModal onSubmit={onSubmit} open={achatRequiredOpen} action={saveActionType} onClose={() => handleHideAchatRequired()} />
        : ''}
      <div className="card_Bottom">
        <div className="container_Info_Card">
          <div className="container_Price_Action">
            <Button
              to={achatIdUrl ? makeRoutePath('search.achat.vente.show', { achatId: achatIdUrl, venteId }) : `/search/vente/${venteId}`}
              className="title_Price"
            >
              <FormattedNumber
                value={formattedPrice}
                style="currency"
                currency="EUR"
                minimumFractionDigits="0"
                maximumFractionDigits={vente?.variant === Variant.location ? '2' : '0'}
              />
              {' '}
              <small className="infoMontant">
                {vente?.variant === Variant.location ? 'CC/Mois' : ''}
              </small>
            </Button>
            <div className="icons_Action">
              <Button onClick={actionChoiceToFavori}>
                <ICON_COEUR className="img-wxl img-hxl" favori={isFavoriteForThisVente} />
              </Button>
              <Share title="Notification" text={bien?.titre} url={makeRoutePath('search.vente.show', { venteId }, true)} />
              <Button onClick={actionChoiceToIgnore}>
                <IconDelete className="img-wlg img-hlg" />
              </Button>
            </div>
          </div>
          <Button
            to={achatIdUrl ? makeRoutePath('search.achat.vente.show', { achatId: achatIdUrl, venteId }) : `/search/vente/${venteId}`}
            className="title_Card"
          >
            <div
              className="title"
            >
              {bien?.titre}
            </div>
          </Button>
          <Button
            to={achatIdUrl ? makeRoutePath('search.achat.vente.show', { achatId: achatIdUrl, venteId }) : `/search/vente/${venteId}`}
            className="container_Title_Coordonee"
          >
            <div className="container_Location">
              <img
                src={
                  ICON_POSITION
                }
                alt="icon localisation maison"
              />

              <span className="title_Ville">{bien?.ville}</span>
              <span className="title_Cp">{bien?.codePostal}</span>

            </div>
          </Button>

        </div>
      </div>
    </div>
  );
}
