import './style/index.scss';
import './style/fontSize.scss';
import './style/fontWeight.scss';

import { ReactNode } from 'react';

import AuthProvider from './provider/AuthProvider';
import NotificationProvider from './provider/NotificationProvider';
import PermissionProvider from './provider/PermissionProvider';
import QueryProvider from './provider/QueryProvider';
import RouteProvider from './provider/RouteProvider';
import ServiceWorkerProvider from './provider/ServiceWorkerProvider';
import TranslationProvider from './provider/TranslationProvider';
import UserProvider from './provider/UserProvider';

const providers = [
  ServiceWorkerProvider,
  NotificationProvider,
  QueryProvider,
  PermissionProvider,
  AuthProvider,
  UserProvider,
  TranslationProvider,
  RouteProvider,
].reverse();

export type AppProps = {
  children?: ReactNode;
}

function App({ children }: AppProps) {
  return providers.reduce((previousChildren, Provider) => (
    <>
      <Provider>{previousChildren}</Provider>
      <div id="modal-root" />
    </>
  ), children);
}

export default App;
