import './critere_souhaites_nonSouhaites.scss';

import { useState, useMemo } from 'react';

export default function WordSelection({
  value,
  onChange,
  options,
  tabIndexStart,
  classColors,
}) {
  const [customOptions, setCustomOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleWordClick = (word) => {
    const alreadyIn = (w) => JSON.stringify(w) === JSON.stringify(word);
    const isWordInValue = value.some(alreadyIn);

    if (!isWordInValue) {
      onChange([...value, word]);
      setCustomOptions(customOptions.filter((w) => JSON.stringify(w) !== JSON.stringify(word)));
    } else {
      onChange(value.filter((w) => JSON.stringify(w) !== JSON.stringify(word))); // "filter" crée un nouveau tableau avec les mots de "value" et n'ajoutera pas le "word" spécifié.
      setCustomOptions([...customOptions, word]); // on ajoute le mot "word" à la liste "customOptions"
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddWord = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (inputValue) {
      const isWordAlreadySelected = value.some(
        (word) => word.value === inputValue,
      );
      if (!isWordAlreadySelected) {
        onChange([
          ...value,
          {
            value: inputValue,
            label: inputValue,
          },
        ]);
      }
      setInputValue('');
    }
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddWord(event);
    }
  };

  const everyOptions = useMemo(() => [...options, ...customOptions], [customOptions, options]);

  return (
    <>
      <div className={`word-list ${classColors}`}>
        {options.map((word, index) => (
          <button
            type="button"
            tabIndex={tabIndexStart + index + 1} // Ajout du décalage tabIndexStart
            className="word-available"
            key={`word-available-${word.value}`}
            onClick={() => handleWordClick(word)}
          >
            {word.label}
          </button>
        ))}
      </div>
      <div className="add_More">
        <input
          className="adding_Word"
          tabIndex={tabIndexStart + everyOptions.length} // Ajout du décalage tabIndexStart
          role="button"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />
        <button
          type="button"
          tabIndex={tabIndexStart + everyOptions.length} // Ajout du décalage tabIndexStart
          onClick={handleAddWord}
        >
          <i className="fa-solid fa-plus adding_Word" />
        </button>
      </div>
      <div className={`selected-words border-${classColors}`}>
        {value.map((word, index) => (
          <button
            type="button"
            name="selectedWord"
            tabIndex={tabIndexStart + everyOptions.length}
            className={`word-selected ${classColors}`}
            key={`word-selected-${word.value}`}
            onClick={() => handleWordClick(word)}
          >
            {word.label}
            <i className="fa-solid fa-xmark" />
          </button>
        ))}
      </div>
    </>
  );
}
