import { useIntl } from 'react-intl';

import FlexRow from '../Component/FlexRow/FlexRow';
import PageHeader from '../Component/PageHeader/PageHeader';
import Title from '../Component/Title/Title';

function InfoPage(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader />
      <FlexRow
        direction="column"
        grow="1"
        alignItems="center"
        justifyContent="center"
        padding="1rem"
      >
        <Title
          title={formatMessage({ id: 'info.title' })}
        />
        <p>{formatMessage({ id: 'info.text' })}</p>
      </FlexRow>
    </>
  );
}

export default InfoPage;
