import COLLAPSE from '../../Component/Collapse/collapse';
import FlexRow from '../../Component/FlexRow/FlexRow';

import './ModificationDesCriteresDuProjet.scss';
import { CircularProgress } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

// Import des Composants
import DECOUVERTE_1 from '../../Component/Form/Decouverte_1/decouverte_1';
import DECOUVERTE_2 from '../../Component/Form/Decouverte_2/decouverte_2';
import DECOUVERTE_3 from '../../Component/Form/Decouverte_3/decouverte_3';
import DECOUVERTE_4 from '../../Component/Form/Decouverte_4/decouverte_4';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import ICON_HOUSE from '../../Component/Icon/IconHouse';
import TitleGlow from '../../Component/Title_Glow/title_glow';

// import des "icons descriptifs"
import { Variant } from '../../lib/Achats/Achats.types';
import useShowAchat from '../ShowAchatLayout/useShowAchat';
import useShowAchatStore from '../ShowAchatLayout/useShowAchatStore';

export default function ModificationDesCriteresDuProjet() {
  const { achatId } = useParams();
  useShowAchat(achatId);
  const { achat: projetAchatId, isLoading, isError } = useShowAchatStore();
  const { formatMessage } = useIntl();

  const underTitleOfProjet = projetAchatId?.titre ?? projetAchatId?.createdAt;

  const titleForLocation = useMemo(
    () => (
      projetAchatId?.variant === Variant.location ? Variant.location : ''
    ),
    [projetAchatId?.variant],
  );

  if (isLoading) {
    <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />;
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  return (
    <>
      <div className="container_Home_Modal">
        {isError || isLoading || (
          <TitleGlow
            icon={(
              <ICON_HOUSE
                className="icon_house_HomeAchat img-w4xl img-h4xl"
                classColor={
                  titleForLocation ? 'colorLocation' : 'colorAchat'
                }
              />
            )}
            title={titleForLocation ? 'Location' : 'Achat'}
            underTitle={underTitleOfProjet ?? null}
          />
        )}
      </div>
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        transparent
      />
      {isError || isLoading || (
        <FlexRow
          className="Container_Collapse_Decouverte_Update"
          direction="column"
          gap="1rem"
          grow="1"
          alignItems="center"
          justifyContent="center"
        >
          <COLLAPSE key="1" title="Les participants ">
            <DECOUVERTE_1 />
          </COLLAPSE>
          <COLLAPSE key="2" title="Les membres de la famille ">
            <DECOUVERTE_2 />
          </COLLAPSE>
          <COLLAPSE key="3" title="Critères de recherches ">
            <DECOUVERTE_3 />
          </COLLAPSE>
          <COLLAPSE key="4" title="Préférences et exigences ">
            <DECOUVERTE_4 />
          </COLLAPSE>
        </FlexRow>
      )}
    </>
  );
}
