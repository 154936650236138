import './decouverte_3.scss';

import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { geoGouvAdresseResultSchema } from '../../../API/Adresse_France/geoGouv.schema';
import useGeoGouvLoadOptions from '../../../API/Adresse_France/useGeoGouvLoadOptions';
import useCreateAchatStore from '../../../Pages/CreateAchatLayout/useCreateAchatStore';
import RedirectError from '../../../Pages/Error/RedirectError';
import useShowAchatStore from '../../../Pages/ShowAchatLayout/useShowAchatStore';
import useUuid from '../../../hooks/useUuid';
import useZodForm from '../../../hooks/useZodForm';
import { Variant } from '../../../lib/Achats/Achats.types';
import { BIEN_TYPES } from '../../../lib/Biens/bien.const';
import { updateDecouverteImmobilier } from '../../../lib/papirisClient';
import { makeRoutePath } from '../../../store/route/route.api';
import Button from '../../Button/Button';
import ButtonV2 from '../../Button/ButtonV2';
import Container from '../../Container/Container';
import FlexCell from '../../FlexCell/FlexCell';
import FlexRow from '../../FlexRow/FlexRow';
import FormAddon from '../../FormAddon/FormAddon';
import FormAddonWrapper from '../../FormAddonWrapper/FormAddonWrapper';
import FormControl from '../../FormControl/FormControl';
import FormCounter from '../../FormCounter/FormCounter';
import FormError from '../../FormError/FormError';
import FormFieldset from '../../FormFieldset/FormFieldset';
import FormFooter from '../../FormFooter/FormFooter';
import FormInput from '../../FormInput/FormInput';
import FormLabel from '../../FormLabel/FormLabel';
import FormSelect from '../../FormSelect/FormSelect';
import FormSelectAsync from '../../FormSelect/FormSelectAsync';
import FormTextarea from '../../FormTextarea/FormTextarea';
import Title from '../../Title/Title';
import Form from '../Form';
import RangeSlider from '../Range_Slider/range_slider';

export default function DECOUVERTE_3() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const locationUrl = useLocation();
  const { achat } = useCreateAchatStore();
  const { achat: projetAchatUpdate } = useShowAchatStore();
  const queryClient = useQueryClient();
  const mutation = useMutation(updateDecouverteImmobilier);
  const [papirisError, setPapirisError] = useState(null);

  const location = useLocation();

  const typeOfProjet = locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id }) ? projetAchatUpdate : achat;

  /** Redirects to contact if no achat was found in store. */
  useEffect(() => {
    if (!typeOfProjet) {
      throw new RedirectError('../contact');
    }
  }, [typeOfProjet]);

  const variant = typeOfProjet?.variant;

  const initialValuesCritereBien = useMemo(() => (
    {
      ville: null,
      villes: typeOfProjet?.decouverte?.villes
        ?.filter((ville) => typeof ville === 'string')
        ?.map((ville) => ({ ville })) ?? [],
      bienTypes: typeOfProjet?.decouverte?.bienTypes ?? [],
      surfaceHabitable: typeOfProjet?.decouverte?.surfaceHabitable ? typeOfProjet.decouverte.surfaceHabitable / 100 : null,
      surfaceTerrain: typeOfProjet?.decouverte?.surfaceTerrain ? typeOfProjet.decouverte.surfaceTerrain / 100 : null,
      surfaceDependance: typeOfProjet?.decouverte?.surfaceDependance ? typeOfProjet.decouverte.surfaceDependance / 100 : null,
      chambresCount: typeOfProjet?.decouverte?.chambresCount ?? 0,
      budgetMin: typeOfProjet?.decouverte?.budgetMin ? typeOfProjet.decouverte.budgetMin / 100 : null,
      budgetMax: typeOfProjet?.decouverte?.budgetMax ? typeOfProjet.decouverte.budgetMax / 100 : null,
      budgetCommentaire: typeOfProjet?.decouverte?.budgetCommentaire ?? '',
      demarchesBancaires: typeOfProjet?.decouverte?.demarchesBancaires ?? '',
      situationPro: typeOfProjet?.decouverte?.situationPro ?? '',
      dateDemenagement: typeOfProjet?.decouverte?.dateDemenagement ?? '',
    }

  ), [typeOfProjet.decouverte?.bienTypes, typeOfProjet.decouverte?.budgetCommentaire, typeOfProjet.decouverte.budgetMax, typeOfProjet.decouverte.budgetMin, typeOfProjet.decouverte?.chambresCount, typeOfProjet.decouverte?.dateDemenagement, typeOfProjet.decouverte?.demarchesBancaires, typeOfProjet.decouverte?.situationPro, typeOfProjet.decouverte.surfaceDependance, typeOfProjet.decouverte.surfaceHabitable, typeOfProjet.decouverte.surfaceTerrain, typeOfProjet.decouverte?.villes]);

  const [open, setOpen] = useState(false);
  const typeOptions = useMemo(() => BIEN_TYPES.map((type) => ({
    label: formatMessage({ id: `model.bien.type.${type}` }),
    value: type,
  })), [formatMessage]);
  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      ville: geoGouvAdresseResultSchema.nullish(),
      villes: geoGouvAdresseResultSchema.array(),
      bienTypes: z.string().array(),
      surfaceHabitable: z.coerce.number().nullish(),
      surfaceTerrain: z.coerce.number().nullish(),
      surfaceDependance: z.coerce.number().nullish(),
      chambresCount: z.coerce.number().nullish(),
      budgetMin: z.coerce.number().nullish(),
      budgetMax: z.coerce.number().nullish(),
      budgetCommentaire: z.string().nullish(),
      demarchesBancaires: z.string().nullish(),
      situationPro: z.string().nullish(),
      dateDemenagement: z.string().nullish(),
    }), []),

    initialValues: useMemo(() => (
      initialValuesCritereBien
    ), [initialValuesCritereBien]),

    onSubmit: async (values) => {
      const commonPayload = {
        id: typeOfProjet?.decouverte?.id,
        villes: values?.villes.map((v) => v.ville),
        bienTypes: values?.bienTypes,
        surfaceHabitable: values?.surfaceHabitable ? values.surfaceHabitable * 100 : null,
        surfaceTerrain: values?.surfaceTerrain ? values.surfaceTerrain * 100 : null,
        surfaceDependance: values?.surfaceDependance ? values.surfaceDependance * 100 : null,
        chambresCount: values?.chambresCount,
        budgetMin: values?.budgetMin ? values.budgetMin * 100 : null,
        budgetMax: values?.budgetMax ? values.budgetMax * 100 : null,
      };

      if (variant === String(Variant.location)) {
        await mutation.mutateAsync({
          ...commonPayload,
          situationPro: values?.situationPro,
          dateDemenagement: values?.dateDemenagement,
        });
      } else if (variant === String(Variant.achatvente)) {
        await mutation.mutateAsync({
          ...commonPayload,
          budgetCommentaire: values?.budgetCommentaire,
          demarchesBancaires: values?.demarchesBancaires,
        });
      }
      if (locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: typeOfProjet?.id })) {
        await queryClient.invalidateQueries({ queryKey: 'achat' });
      } else {
        await queryClient.invalidateQueries({ queryKey: 'achat' });
        navigate('../critere');
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  const {
    isSubmitting, isValid,
  } = form.formState;

  const ville = form.watch('ville');
  const villes = form.watch('villes');

  const handleAddSelectedCommuneChange = (value) => {
    const newVilles = [
      ...villes,
      ...value.filter((ville) => !villes.some((c) => c.ville === ville.ville)),
    ];

    form.setValue('villes', newVilles);
    form.setValue('ville', null);
    setOpen(false);
  };

  const rangeId = useUuid();

  const adresseSelectProps = useGeoGouvLoadOptions('municipality');

  return (
    <Container size="lg">
      <Title
        title={formatMessage({ id: 'achats.create.critere.title' })}
        positon="start"
        subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 3, total: 4 })}
      />
      <Form onSubmit={onSubmit} {...form}>
        <FlexRow gap="2em">
          <FormFieldset legend={formatMessage({ id: 'achats.create.immobilier.criteres.legend' })}>
            <FlexRow gap="1em">
              <FormFieldset>
                <FormControl name="villes">
                  <FormLabel>{formatMessage({ id: location.pathname.includes('location') ? 'location.achats.create.immobilier.communes.label' : 'achats.create.immobilier.communes.label' })}</FormLabel>
                  <FormSelectAsync
                    isMulti
                    noOptionsMessage={() => formatMessage({ id: 'address_lookup.no_option_message' })}
                    {...adresseSelectProps}
                  />
                  <FormError />
                </FormControl>
              </FormFieldset>
              <ButtonV2
                onClick={() => setOpen((prevOpen) => !prevOpen)}
                type="button"
                fontSize="sm"
                aria-expanded={open ? 'true' : 'false'}
                aria-controls={rangeId}
                icon={(
                  <i
                    className={`fa-solid ${open ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                    role="img"
                  />
                )}
              >
                {open
                  ? formatMessage({ id: 'achats.create.immobilier.communes.with_range.close' })
                  : formatMessage({ id: 'achats.create.immobilier.communes.with_range.open' })}
              </ButtonV2>
              {open ? (
                <FlexRow id={rangeId} hidden={!open} gap="1em">
                  <FormControl name="ville">
                    <FormLabel>{formatMessage({ id: 'achats.create.immobilier.commune.label' })}</FormLabel>
                    <FormSelectAsync
                      noOptionsMessage={() => formatMessage({ id: 'address_lookup.no_option_message' })}
                      {...adresseSelectProps}
                    />
                    <FormError />
                  </FormControl>
                  <RangeSlider
                    value={2}
                    selectedCommune={ville}
                    onSouhaitCommunesChange={handleAddSelectedCommuneChange}
                    valueType="municipality"
                  />
                </FlexRow>
              ) : null}
              <FormControl name="bienTypes">
                <FormLabel>{formatMessage({ id: 'achats.create.immobilier.bienTypes.label' })}</FormLabel>
                <FormSelect options={typeOptions} isMulti />
                <FormError />
              </FormControl>
            </FlexRow>
          </FormFieldset>
          <FormFieldset legend={formatMessage({ id: 'achats.create.immobilier.surface.legend' })}>
            <FlexCell>
              <FlexRow gap="1em" width="100%">
                <FormControl name="surfaceHabitable">
                  <FormLabel>{formatMessage({ id: 'achats.create.immobilier.surfaceHabitable.label' })}</FormLabel>
                  <FormAddonWrapper>
                    <FormInput type="number" min="0" max="2000000000" />
                    <FormAddon side="right">{formatMessage({ id: 'info.bien.squareMetre' })}</FormAddon>
                  </FormAddonWrapper>
                  <FormError />
                </FormControl>
                <FormControl name="surfaceTerrain">
                  <FormLabel>{formatMessage({ id: 'achats.create.immobilier.surfaceTerrain.label' })}</FormLabel>
                  <FormAddonWrapper>
                    <FormInput type="number" min="0" max="2000000000" />
                    <FormAddon side="right">{formatMessage({ id: 'info.bien.squareMetre' })}</FormAddon>
                  </FormAddonWrapper>
                  <FormError />
                </FormControl>
                <FormControl name="surfaceDependance">
                  <FormLabel>{formatMessage({ id: 'achats.create.immobilier.surfaceDependance.label' })}</FormLabel>
                  <FormAddonWrapper>
                    <FormInput type="number" min="0" max="2000000000" />
                    <FormAddon side="right">{formatMessage({ id: 'info.bien.squareMetre' })}</FormAddon>
                  </FormAddonWrapper>
                  <FormError />
                </FormControl>
                <FormControl name="chambresCount">
                  <FormLabel>{formatMessage({ id: 'achats.create.immobilier.chambresCount.label' })}</FormLabel>
                  <FormCounter min={0} />
                  <FormError />
                </FormControl>
              </FlexRow>
            </FlexCell>
          </FormFieldset>

          <FormFieldset legend={formatMessage({ id: 'achats.create.immobilier.budget.legend' })}>
            <FlexRow gap="1em">
              <FlexRow direction="row">
                <FormControl name="budgetMin">
                  <FormLabel>{formatMessage({ id: 'achats.create.immobilier.budgetMin.label' })}</FormLabel>
                  <FormAddonWrapper>
                    <FormInput type="number" min="0" max="2000000000" />
                    <FormAddon side="right">€</FormAddon>
                  </FormAddonWrapper>
                  <FormError />
                </FormControl>
                <FormControl name="budgetMax">
                  <FormLabel>{formatMessage({ id: 'achats.create.immobilier.budgetMax.label' })}</FormLabel>
                  <FormAddonWrapper>
                    <FormInput type="number" min="0" max="2000000000" />
                    <FormAddon side="right">€</FormAddon>
                  </FormAddonWrapper>
                  <FormError />
                </FormControl>
              </FlexRow>
              {variant === 'location'
                ? (
                  ''
                )
                : (
                  <>
                    <FormControl name="budgetCommentaire">
                      <FormLabel>{formatMessage({ id: 'achats.create.immobilier.budgetCommentaire.label' })}</FormLabel>
                      <FormTextarea
                        rows="5"
                        cols="33"
                        autoCapitalize="sentences"
                        spellCheck="true"
                      />
                      <FormError />
                    </FormControl>
                    <FormControl name="demarchesBancaires">
                      <FormLabel>{formatMessage({ id: 'achats.create.immobilier.demarchesBancaires.label' })}</FormLabel>
                      <FormTextarea
                        rows="5"
                        cols="33"
                        autoCapitalize="sentences"
                        spellCheck="true"
                      />
                      <FormError />
                    </FormControl>
                  </>
                )}

            </FlexRow>
          </FormFieldset>
          {variant === 'location'
            ? (
              <>
                <FormFieldset legend={formatMessage({ id: 'location.achats.create.immobilier.situationPro.legend' })}>
                  <FlexRow gap="1em">
                    <FlexRow direction="row">
                      <FormControl name="situationPro">
                        <FormTextarea
                          rows="5"
                          cols="33"
                          autoCapitalize="sentences"
                          spellCheck="true"
                        />
                        <FormError />
                      </FormControl>
                    </FlexRow>
                  </FlexRow>
                </FormFieldset>
                <FormFieldset legend={formatMessage({ id: 'location.achats.create.immobilier.dateDemenagement.legend' })}>
                  <FlexRow gap="1em">
                    <FlexRow direction="row">
                      <FormControl name="dateDemenagement">
                        <FormInput type="date" placeholder={formatMessage({ id: 'location.achats.create.immobilier.dateDemenagement.placeholder' })} />
                        <FormError />
                      </FormControl>
                    </FlexRow>
                  </FlexRow>
                </FormFieldset>
              </>
            ) : ('')}
        </FlexRow>
        <FormFooter>
          <FlexRow alignItems="center" justifyContent="space-around" direction="row">
            { locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id })
              ? ''
              : (
                <Button
                  icon={<i className="fa-solid fa-chevron-left" />}
                  to="../famille"
                >
                  <FormattedMessage id="label.back" />
                </Button>
              )}
            <Button
              className={`button submit ${isValid ? 'yellow' : 'not_Valid'}`}
              disabled={isSubmitting}
              type="submit"
            >
              <FormattedMessage id="label.continue" />
            </Button>
          </FlexRow>
        </FormFooter>
      </Form>
      {/* </div> */}
    </Container>
  );
}
