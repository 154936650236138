import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconUpload.scss';

function IconUpload({
  className, classColor = 'upload',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_upload', className)} id="Bouton_upload" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29">
        <title id="icon_upload">Icon Galerie</title>
        <circle id="Ellipse_57" data-name="Ellipse 57" className={`${classColor}-cls-1`} cx="14.5" cy="14.5" r="14.5" />
        <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(8.721 7.021)">
          <path id="Tracé_3022" data-name="Tracé 3022" className={`${classColor}-cls-2`} d="M15.752,22.557v-.038c0-.011-.56-.019-1.25-.019H5.75c-.69,0-1.25.009-1.25.019v.038" transform="translate(-4.194 -6.23)" />
          <path id="Tracé_3023" data-name="Tracé 3023" className={`${classColor}-cls-2`} d="M22.058,8.854,16.279,4.5,10.5,8.854" transform="translate(-10.5 -5.5)" />
          <path id="Tracé_3024" data-name="Tracé 3024" className={`${classColor}-cls-2`} d="M18,4.5V15.878" transform="translate(-12.221 -4.836)" />
        </g>
      </svg>
    </div>
  );
}

export default IconUpload;
