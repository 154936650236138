import './SearchBar.scss';

import { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import Filter from '../../../Pages/Filtre/filtre.jsx';
import { useFilter } from '../../../provider/FilterProvider';
import Button from '../../Button/Button';
import Form from '../../Form/Form';
import FormControl from '../../FormControl/FormControl';
import IconFiltre from '../../Icon/IconFiltre';
import IconSearch from '../../Icon/IconRecherche';

import FormSearch from './FormSearch';

export default function SearchBar() {
  const { formatMessage } = useIntl();
  const {
    modalState, toggleModal, addFilters, villes,
  } = useFilter();

  const form = useForm({
    mode: 'onTouched',
  });

  const queryClient = useQueryClient();

  const onChange = useCallback(async (changedValue) => {
    addFilters({
      page: 1,
      villes: changedValue?.map((v) => v.value) ?? [],
    });

    await queryClient.invalidateQueries({ queryKey: 'vente' });
  }, [addFilters, queryClient]);

  const value = useMemo(() => villes.map((commune) => ({
    value: commune,
    label: commune,
  })), [villes]);

  const handleSubmit = useCallback((v, e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <>
      <div className="SearchBar">
        <Form onSubmit={handleSubmit} {...form}>
          <div className="SearchBar_row">
            <div className="SearchBar_input">
              <FormControl name="villes">
                <FormSearch
                  isMulti
                  isClearable
                  placeholder={formatMessage({ id: 'placeholder.recherche' })}
                  value={value}
                  onChange={onChange}
                  valueType="municipality"
                />
              </FormControl>
            </div>
            <Button className="SearchBar_submitButton" type="submit" icon={<IconSearch className="img-wxl img-hxl" />} />
            <Button
              className="SearchBar_filterButton"
              icon={<IconFiltre className="img-wxl img-hxl" />}
              onClick={toggleModal}
            />
          </div>
        </Form>
      </div>
      <div className={`modal_filter ${modalState}`}>
        <Filter onClick={toggleModal} />
      </div>
    </>
  );
}
