import React from 'react';
import './modal_bottom.scss';
import PropTypes from 'prop-types';

import Button from '../../Button/Button';

/**
Component for created a MODAL with variable number of buttons and a title.
@param {Object} props - The props object containing the title and button information.
@param {string} props.title - The title of the modal.
@param {Array} props.buttons - An array of button objects with each button's information.
@param {Function} props.onBackClick - The function to call when the back button is clicked.
@returns {JSX.Element} The modal container component.
*/
export default function MODAL_BOTTOM(props) {
  const { title, buttons, onClick } = props;

  return (
    <div className="container_Modal_Bottom">
      <div className="modal_Bottom">
        <span className="container_Title">
          <button type="button" className="return" onClick={onClick}>
            <i className="fa-solid fa-arrow-left" />
          </button>
          <p className="title">{title}</p>
        </span>

        {buttons.map(({ src, alt, text }, index) => (
          <Button key={src} icon={alt ? <img src={src} alt={alt} /> : ''} className="bloc_Button">
            <div>{src}</div>
            {text}
          </Button>
        ))}
      </div>
    </div>
  );
}

MODAL_BOTTOM.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};
