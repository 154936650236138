import {
  ReactNode, createContext, useCallback, useEffect, useMemo,
  useState,
} from 'react';

import { getDeviceToken, subscribeMessage } from '../notification/setupNotification';

export type NotificationProviderProps = {
  children: ReactNode;
}

export type NotificationPayload = {
  title: string;
  body?: string;
  url?: string;
}

type OnNotificationCallback = (notification: NotificationPayload) => void;

export type NotificationContextValue = {
  deviceToken: string|null;
  refreshDeviceToken(): Promise<string|null>;
  getPermission(): NotificationPermission|undefined;
  onNotification(onNotificationCallback: OnNotificationCallback): () => void;
}

function getPermission(): NotificationPermission|undefined {
  return 'Notification' in window ? Notification.permission : undefined;
}

const defaultContextValue: NotificationContextValue = {
  deviceToken: null,
  refreshDeviceToken: async () => null,
  getPermission,
  onNotification: () => () => {},
};

export const NotificationContext = createContext<NotificationContextValue>(defaultContextValue);

export default function NotificationProvider({ children }: NotificationProviderProps): JSX.Element {
  const [deviceToken, setDeviceToken] = useState<NotificationContextValue['deviceToken']>(null);

  const refreshDeviceToken = useCallback(async () => {
    const token = await getDeviceToken();

    setDeviceToken(token);

    return token;
  }, []);

  useEffect(() => {
    if (getPermission() === 'granted') {
      refreshDeviceToken();
    }
  }, [refreshDeviceToken]);

  const onNotification = useCallback((onNotificationCallback: OnNotificationCallback) => {
    if (getPermission() === 'granted') {
      const unsubscribe = subscribeMessage((payload) => {
        const { title, body } = payload.notification ?? {};

        if (title) {
          onNotificationCallback({
            title,
            body,
          });
        }
      });

      return () => {
        unsubscribe();
      };
    }

    return () => {};
  }, []);

  const value = useMemo<NotificationContextValue>(() => ({
    ...defaultContextValue,
    deviceToken,
    refreshDeviceToken,
    onNotification,
  }), [deviceToken, onNotification, refreshDeviceToken]);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}
