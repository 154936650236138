import './loader.scss';
// Logo d'Ideeri
import LOGO_IDEERI from '../../img/logo_Ideeri.svg';

export default function LOADER() {
  return (
    <div className="container_Loader">
      <img className="logo_Ideeri" src={LOGO_IDEERI} alt="Logo Ideeri" />
    </div>
  );
}
