import type { AchatModel, Variant } from './Achats/Achats.types';
import type { DemandeRenseignementModel } from './Achats/DemandeRenseignement.types';
import { BienFull } from './Biens/biens.types';
import type { CollaborateurModel } from './Collaborateurs/collaborateurs.types';
import type { ContactModel } from './Contacts/Contacts.types';
import type { DecouverteCritereListModel } from './DecouverteCriteresLists/DecouverteCriteresLists.types';
import type { DecouverteModel } from './Decouvertes/Decouvertes.types';
import { NotificationModel } from './Notification/Notification.types';
import { RapprochementModel } from './Rapprochement/rapprochements.types';
import type { UserModel } from './Users/Users.types';
import { VenteModel } from './Ventes/Ventes.types';
import { getIdToken } from './firebaseClient';
import {
  type VenteListModel,
  type AchatListModel,
  type UpdateDecouverteCriterePayload,
  type UpdateDecouverteImmobilierPayload,
  type UpdateDecouverteFamillePayload,
  // DecouverteCritereListModel,
  type CreateInvitationPayload,
  // InviteAchatPayload,
  type DemandeRenseignementPayload,
  type CreateVenteFavoriPayload,
  type RemoveVenteFavoriPayload,
  type CreateVenteIgnorePayload,
  type RemoveVenteIgnorePayload,
  type ClotureAchatPayload,
  type RapprochementListModel,
  isPapirisErrorModel,
  PapirisError,
  UpdateContactTitleProjetAchat,
  BienListModel,
  NotificationListModel,
} from './papirisClient.types';

const PAPIRIS_ENDPOINT = `${process.env.REACT_APP_PAPIRIS_API_URL}/api/ideeri`;

type RequestInit = Parameters<(typeof fetch)>[1]

async function getToken(): Promise<string> {
  const idToken = await getIdToken();

  if (!idToken) {
    throw new Error('Missing Firebase ID token');
  }

  return idToken;
}

async function getConfig(initialConfig: RequestInit = {}): Promise<RequestInit> {
  return {
    ...initialConfig,
    headers: {
      ...initialConfig.headers,
      Accept: 'application/json',
      Authorization: `Bearer ${await getToken()}`,
    },
  };
}

export async function request(url: string, method?: string, payload?: object) {
  const response = await fetch(`${PAPIRIS_ENDPOINT}${url}`, await getConfig({
    method,
    body: payload ? JSON.stringify(payload) : undefined,
    headers: payload ? {
      'Content-Type': 'application/json',
    } : undefined,
  }));

  if (response.ok) {
    return response.json();
  }

  const error = await response.json();

  if (isPapirisErrorModel(error)) {
    throw new PapirisError(error.message, error.errors);
  } else {
    throw new Error(`status.${response.status}`);
  }
}

export function showUser(): Promise<UserModel> {
  return request('/user/me');
}

export function updateContact(contact: Partial<ContactModel>): Promise<UserModel> {
  return request('/contact/me', 'PUT', contact);
}

export type ListAchatPayload = {
  page: number;
  variant?: string|string[];
}

export function listAchat(payload: ListAchatPayload): Promise<AchatListModel> {
  if (payload) {
    const params = new URLSearchParams();
    Object.entries(payload).forEach(([name, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            params.append(`${name}`, v as any);
          });
        } else {
          params.set(name, value as any);
        }
      }
    });

    return request(`/achat?${params}`);
  }

  return request('/achat');
}

export type ListVentePayload = {
  page: number;
  achatId?: string;
  perPage?: number;
  mine?: boolean;
}

export function listVente(payload?: ListVentePayload): Promise<VenteListModel> {
  if (payload) {
    const params = new URLSearchParams();
    Object.entries(payload).forEach(([name, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            params.append(`${name}[]`, v as any);
          });
        } else {
          params.set(name, value as any);
        }
      }
    });

    return request(`/vente?${params}`);
  }

  return request('/vente');
}
export type ListBienPayload = {
  page: number;
  bienId?: string;
  perPage?: number;
  mine?: boolean;
}

export function listBien(payload?: ListBienPayload): Promise<BienListModel> {
  if (payload) {
    const params = new URLSearchParams();
    Object.entries(payload).forEach(([name, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            params.append(`${name}[]`, v as any);
          });
        } else {
          params.set(name, value as any);
        }
      }
    });

    return request(`/bien?${params}`);
  }

  return request('/bien');
}
export type ListNotificationPayload = {
  notificationId?: string;
  mine?: boolean;
}

export function addNotificationToken(token: string): Promise<AchatModel> {
  return request('/notification/token', 'POST', { token });
}
export function listNotification(payload?: ListNotificationPayload): Promise<NotificationListModel> {
  if (payload) {
    const params = new URLSearchParams();

    Object.entries(payload).forEach(([name, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            params.append(`${name}[]`, v as any);
          });
        } else {
          params.set(name, value as any);
        }
      }
    });

    return request(`/notification?${params}`);
  }

  return request('/notification');
}
export function readNotification(notificationId: string): Promise<NotificationModel> {
  return request(`/notification/${notificationId}/read`, 'POST', {});
}
export function readAllNotification(): Promise<ListNotificationPayload> {
  return request('/notification/read', 'POST', {});
}

export function showAchat(achatId: string): Promise<AchatModel> {
  return request(`/achat/${achatId}`);
}

export function showLocationAchat(achatId: string): Promise<AchatModel> {
  return request(`/location/${achatId}`);
}

export function terminerAchatDecouverte(achatId: string): Promise<AchatModel> {
  return request(`/achat/${achatId}/decouverte/terminer`, 'POST', {});
}

export function createDemandeRenseignement({ achatId, ...payload }: DemandeRenseignementPayload): Promise<DemandeRenseignementModel> {
  return request(`/achat/${achatId}/demande-renseignements`, 'POST', payload);
}

export function showVente(venteId: string): Promise<VenteModel> {
  return request(`/vente/${venteId}`);
}

export function showBien(bienId: string): Promise<BienFull> {
  return request(`/bien/${bienId}`);
}

export function showCollaborateur(collaborateurId: string): Promise<CollaborateurModel> {
  return request(`/collaborateur/${collaborateurId}`);
}

export function createAchat(achat: Pick<AchatModel, 'titre' |'variant' | 'contacts'>): Promise<AchatModel> {
  return request('/achat', 'POST', achat);
}
export function updateAchat({ achatId, ...payload }: UpdateContactTitleProjetAchat): Promise<AchatModel> {
  return request(`/achat/${achatId}`, 'PUT', payload);
}

export function clotureAchat({ achatId, ...payload }: ClotureAchatPayload): Promise<AchatModel> {
  return request(`/achat/${achatId}/cloture`, 'POST', payload);
}

export function createInvitation(payload: CreateInvitationPayload): Promise<AchatModel> {
  return request('/invitation', 'POST', payload);
}
export function createVenteFavori({ achatId, venteId, ...payload }: CreateVenteFavoriPayload): Promise<AchatModel> {
  return request(`/achat/${achatId}/vente/${venteId}/favorite`, 'POST', payload);
}
export function removeVenteFavori({ achatId, venteId }: RemoveVenteFavoriPayload): Promise<AchatModel> {
  return request(`/achat/${achatId}/vente/${venteId}/favorite`, 'DELETE');
}

export function createVenteIgnoree({ achatId, venteId, ...payload }: CreateVenteIgnorePayload): Promise<AchatModel> {
  return request(`/achat/${achatId}/vente/${venteId}/ignoree`, 'POST', payload);
}

export function removeVenteIgnoree({ achatId, venteId }: RemoveVenteIgnorePayload): Promise<AchatModel> {
  return request(`/achat/${achatId}/vente/${venteId}/ignoree`, 'DELETE');
}

export function updateDecouverteFamille({ id, ...payload }: UpdateDecouverteFamillePayload): Promise<DecouverteModel> {
  return request(`/decouverte/${id}/famille`, 'PUT', payload);
}

export function updateDecouverteImmobilier({ id, ...payload }: UpdateDecouverteImmobilierPayload): Promise<DecouverteModel> {
  return request(`/decouverte/${id}/immobilier`, 'PUT', payload);
}

export function updateDecouverteCritere({ id, ...payload }: UpdateDecouverteCriterePayload): Promise<DecouverteModel> {
  return request(`/decouverte/${id}/critere`, 'PUT', payload);
}

export function listDecouverteCritere(): Promise<DecouverteCritereListModel> {
  return request('/decouverte/critere');
}
export function listRapprochement(createdAfter:string): Promise<RapprochementListModel> {
  return request(`/rapprochement?withoutProcessed${createdAfter ? `&createdAfter=${createdAfter}` : ''}`);
}
export function listRapprochementByAchat(achatId: string): Promise<RapprochementModel> {
  return request(`/achat/${achatId}/rapprochement?withoutProcessed`);
}
