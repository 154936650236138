import './MesProjetsLocationPage.scss';

import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../Component/Button/Button';
import FlexRow from '../../Component/FlexRow/FlexRow';
import JUMELLES from '../../img/icon_Jumelle_Decouverte.svg';
import ICON_HOUSE from '../../Component/Icon/IconHouse';
import { Variant } from '../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../store/route/route.api';

export default function CreateProjetPage() {
  const { formatMessage } = useIntl();
  const variant = Variant.location;

  return (
    <FlexRow
      width="initial"
      direction="column"
      alignItems="center"
      justifyContent="start"
    >

      <div className="container_New_Projet_location">
        <div className="titre">
          <h1>
            <FormattedMessage
              id="new_projet.location.title"
              values={{
                title: (children) => <p className="p_First">{children}</p>,
                subtitle: (children) => <p className="p_Second">{children}</p>,
              }}
            />
          </h1>
        </div>
        <div className="buttons_New_Projets">

          <Button
            className="button standard projet big"
            icon={(
              <ICON_HOUSE
                className="icon_house img-w4xl img-h4xl"
                classColor="colorLocation"
              />
            )}
            to={makeRoutePath('vente.create', { variant })}
          >
            <FlexRow
              direction="column"
            >
              <FormattedMessage
                id="location.big.button.louer mon bien"
                values={{
                  top: (children) => <p className="fs-lg">{children}</p>,
                  bottom: (children) => <p className="fs-lg">{children}</p>,
                }}
              />
            </FlexRow>
          </Button>
          <Button
            className="button standard projet big fs-lg"
            icon={<img src={JUMELLES} alt={`icon ${formatMessage({ id: 'location.big.button.trouver un logement' })}`} />}
            to={makeRoutePath('achat.create', { variant })}
          >
            <FlexRow
              direction="column"
            >
              <FormattedMessage
                id="location.big.button.trouver un logement"
                values={{
                  top: (children) => <p className="fs-lg">{children}</p>,
                  bottom: (children) => <p className="fs-lg">{children}</p>,
                }}
              />
            </FlexRow>
          </Button>
        </div>
      </div>
    </FlexRow>
  );
}
