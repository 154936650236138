import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './button_projet_card.scss';

type ButtonProjetProps = {
  url?: string;
  className?: string;
  maison?: string | ReactElement;
  picture?: string | ReactElement;
  title_card?: string;
  firstName?: string;
  lastName?: string;
  info?: string;
  subtitle_card?: string;
  onClick?: ()=> unknown;
};

export default function ButtonProjet({
  url,
  className,
  maison,
  picture,
  title_card,
  firstName,
  lastName,
  info,
  subtitle_card,
  onClick,
}: ButtonProjetProps): ReactElement {
  return (
    maison
      ? (
        url ? (
          <Link to={url}>
            <button className={className} type="button">
              {maison}
              <h2 className="title_card title_Project">{title_card}</h2>
              <span className="editing_Projet editing" aria-label="Edit_Pen">
                <p className="subtitle_card editing_p">{subtitle_card}</p>
              </span>
            </button>
          </Link>
        ) : (
          <p>
            {'Il manque l\'url'}
          </p>
        )
      )
      : picture ? (
        url ? (
          <Link to={url}>
            <button className={className} type="button">
              {picture}
              <h2 className="title_card title_Profil">
                <p className="firstName">{firstName}</p>
                <p className="lastName">{lastName}</p>
              </h2>
              <span className="editing_Profil editing" aria-label="Edit_Pen">
                <p className="subtitle_card_profil editing_p_profil">
                  <i className="fa-solid fa-pen" />
                  {subtitle_card}
                </p>
              </span>
            </button>
          </Link>
        ) : (
          <div className="button_projet_card container_Button_Partenaire_And_Edit">
            <button className={className} type="button" onClick={onClick}>
              {picture}
              <h2 className="title_card title_Profil">
                {firstName ? <p className="firstName">{firstName}</p> : ''}
                {info ? <p className="addPartenaireName">{info}</p> : ''}
              </h2>
              <span className="editing_Profil editing" aria-label="Edit_Pen">
                <p className="subtitle_card_profil editing_p_profil">{subtitle_card}</p>
              </span>
            </button>
          </div>
        )

      ) : <p>card impossible de créer</p>
  );
}
