import './FormHelp.scss';

import classNames from 'classnames';

export type FormHelpProps = {
  children?: React.ReactNode;
}

function FormHelp({ children }: FormHelpProps): JSX.Element|null {
  const classes = classNames('FormHelp');

  if (children) {
    return (
      <div className={classes}>
        {children}
      </div>
    );
  }

  return null;
}

export default FormHelp;
