import './Form.scss';

import {
  FieldValues, FormProvider, SubmitHandler, UseFormReturn,
} from 'react-hook-form';

export type FormProps<V extends FieldValues> = {
    onSubmit?: SubmitHandler<any>;
    children?: React.ReactNode;
    className?: string;
} & UseFormReturn<V, any, any>

function Form<V extends FieldValues>({
  children, onSubmit, className, ...methods
}: FormProps<V>) {
  return (
    <form className={`Form ${className ?? ''}`} onSubmit={onSubmit ? methods.handleSubmit(onSubmit) : undefined}>
      <FormProvider {...methods}>
        {children}
      </FormProvider>
    </form>
  );
}

export default Form;
