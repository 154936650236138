import React from 'react';
import { Outlet } from 'react-router-dom';

function SearchLayout(): JSX.Element {
  return (
    <Outlet />
  );
}

export default SearchLayout;
