import { useIntl } from 'react-intl';
import { Navigate, Outlet } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import Modal from '../../Component/Modal/Modal';
import useQueryParams from '../../hooks/useQueryParams';
import { sendVerificationEmail } from '../../lib/firebaseClient';
import { useAuth } from '../../provider/AuthProvider';
import { usePermission } from '../../provider/PermissionProvider';
import { isRoutePath } from '../../store/route/route.api';

export default function AnonymousOutlet() {
  const { formatMessage } = useIntl();
  const query = useQueryParams();
  const to = query.get('to') ?? undefined;

  const { user } = useAuth();
  const { hasPermission } = usePermission();
  const isAnonymous = hasPermission('anonymous');

  if (isAnonymous) {
    return (
      <>
        <Outlet />
        <Modal
          open={user !== null && !user.emailVerified}
          title={formatMessage({ id: 'accueil.email_verification.title' })}
        >
          <p>{formatMessage({ id: 'accueil.email_verification.text' })}</p>
          <Button className="standard" onClick={() => sendVerificationEmail()}>
            {formatMessage({ id: 'accueil.email_verification.submit' })}
          </Button>
        </Modal>
      </>
    );
  }

  return <Navigate to={isRoutePath(to) ? to : '/'} replace />;
}
