import './FormCounter.scss';

import classNames from 'classnames';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ComponentProps } from '../../types/ComponentProps';
import useFormControl from '../FormControl/useFormControl';

export type FormCounterProps = ComponentProps<'div', {
  name?: string;
  invalid?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  value?: number;
  min?: number;
  max?: number;
  onChange?(value: number): void;
}>

function FormCounter({
  className,
  name,
  disabled,
  invalid,
  icon,
  value,
  onChange,
  min,
  max,
  ...props
}: FormCounterProps): JSX.Element {
  const { formatMessage } = useIntl();
  const formControl = useFormControl();
  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const _value = value ?? field.value;

  const handleChange = useCallback((newValue: number) => {
    field.onChange(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }, [field, onChange]);

  const handleIncrement = (increment: number) => {
    let newValue = (_value ?? 0) + increment;

    if (min !== undefined && newValue < min) {
      newValue = min;
    }

    if (max !== undefined && newValue > max) {
      newValue = max;
    }

    handleChange(newValue);
  };

  const classes = classNames('FormCounter', className, {
    'FormCounter-invalid': invalid ?? fieldState.invalid,
    'FormCounter-disabled': disabled,
    'FormCounter-icon': Boolean(icon),
  });

  return (
    <div className={classes}>
      {icon ? <div className="FormCounter_icon">{icon}</div> : null}
      <button className="FormCounter_minus" type="button" onClick={() => handleIncrement(-1)} aria-label={formatMessage({ id: 'form.counter.minus.label' })}>
        -
      </button>
      <div className="FormCounter_value">{_value}</div>
      <button className="FormCounter_plus" type="button" onClick={() => handleIncrement(1)} aria-label={formatMessage({ id: 'form.counter.plus.label' })}>
        +
      </button>
    </div>
  );
}

export default FormCounter;
