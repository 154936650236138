import './mes_partenaires.scss';

import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import useCreateAchatStore from '../../Pages/CreateAchatLayout/useCreateAchatStore';
import useCreateVenteStore from '../../Pages/vente/CreateVenteLayout/useCreateVenteStore';
import { useUser } from '../../provider/UserProvider';
import { makeRoutePath } from '../../store/route/route.api';
import ButtonProjet from '../Button/ButtonProjet/button_projet_card';
import FlexRow from '../FlexRow/FlexRow';
import INPUT from '../Form/Input/input';
import IconAnonyme from '../Icon/IconAnnonyme';
import IconPartenaire from '../Icon/IconPartenaire';
import IconShare from '../Icon/IconShare';
import MODAL_GLOW from '../Modal/ModalGlow/ModalGlow';

export default function MesPartenaires({ withInvitation = true }) {
  const { formatMessage } = useIntl();
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const { invitations, addInvitation, removeInvitation } = useCreateAchatStore();
  const { invitations: invitationsVente, addInvitation: addInvitationVente, removeInvitation: removeInvitationVente } = useCreateVenteStore();
  const location = useLocation();

  const {
    register, handleSubmit, formState,
  } = useForm({
    mode: 'onTouched',
  });
  const {
    errors,
  } = formState;

  const handleSubmitInvitation = useCallback((data) => {
    addInvitation(data.email);
    setShowModal(false);
  }, [addInvitation]);

  const handleSubmitInvitationVente = useCallback((data) => {
    addInvitationVente(data.email);
    setShowModal(false);
  }, [addInvitationVente]);

  const handleRemoveInvitation = useCallback((email) => {
    removeInvitation(email);
  }, [removeInvitation]);

  const handleRemoveInvitationVente = useCallback((email) => {
    removeInvitationVente(email);
  }, [removeInvitationVente]);

  const onSubmit = handleSubmit(handleSubmitInvitation);

  const onSubmitVente = handleSubmit(handleSubmitInvitationVente);

  const handleModalChange = useCallback(() => {
    setShowModal((previousShowModal) => !previousShowModal);
  }, []);

  const invitationsList = location.pathname === makeRoutePath('vente.create.contact') ? invitationsVente : invitations;

  const handleRemove = location.pathname === makeRoutePath('vente.create.contact') ? handleRemoveInvitationVente : handleRemoveInvitation;

  const submit = location.pathname === makeRoutePath('vente.create.contact') ? onSubmitVente : onSubmit;

  const modalRoot = document.getElementById('modal-root');

  return (
    <FlexRow
      className="screenSize container_Buttons_Projets"
      direction="row"
      grow="1"
      alignItems="center"
      justifyContent="start"
      gap="1rem"
    >
      {user
        ? (
          <ButtonProjet
            key={user.id}
            className="button_projet_card size_card_project"
            firstName={user?.contact?.prenom ?? formatMessage({ id: 'achats-ventes.create.contact.me' })}
            lastName={user?.contact?.nom}
            subtitle_card={user?.contact ? formatMessage({ id: 'achats-ventes.create.contact.update' }) : formatMessage({ id: 'achats.create.contact.create' })}
            picture={user?.contact?.avatar ? <img className="icon_Avatar avatar_Profil size-img-card-profil position-card-profil-annonyme" src={user?.contact?.avatar} alt={user?.contact?.prenom} /> : <IconAnonyme className="size-img-card-profil position-card-profil-annonyme" />}
            url={makeRoutePath('profile.contact', { backTo: location.pathname })}
          />
        ) : ''}
      {withInvitation
        ? (
          <>
            {invitationsList.map((email, index) => (
              <ButtonProjet
                key={email}
                className={`button_projet_card size_card_project ${index}`}
                picture={<IconAnonyme className="size-img-card-profil position-card-profil-annonyme" />}
                firstName={email}
                onClick={() => handleRemove(email)}
                subtitle_card={(
                  <>
                    <i className="fa-solid fa-trash" />
                    <p>{formatMessage({ id: 'achats-ventes.create.contact.delete' })}</p>
                  </>
                )}
              />
            ))}
            <ButtonProjet
              key="Sending-Invitation"
              onClick={handleModalChange}
              className="button_projet_card size_card_profil"
              info={formatMessage({ id: 'achats-ventes.create.contact.invite' })}
              picture={<IconPartenaire className="size-img-card-profil position-card-profil" />}
            />
          </>
        )
        : null}
      {showModal && modalRoot ? createPortal(
        <MODAL_GLOW
          open
          onClose={handleModalChange}
          onSubmit={submit}
          src1={<i className="fa-solid fa-xmark" />}
          src2={<IconShare className="img-wxl img-hxl" />}
          title={formatMessage({ id: 'achats-ventes.create.contact.invite_modal.title' })}
        >
          <form onSubmit={submit}>
            <div className="label_group">
              <INPUT
                type="email"
                className="label_group"
                register={register}
                errors={errors}
                register_name="email"
                exempleText={formatMessage({ id: 'achats-ventes.create.contact.invite_modal.email_field.help' })}
                placeholder={formatMessage({ id: 'achats-ventes.create.contact.invite_modal.email_field.placeholder' })}
              />
            </div>
          </form>
        </MODAL_GLOW>,
        modalRoot,
      ) : null }
    </FlexRow>
  );
}
