import './home_achat.scss';
import { CircularProgress } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Import des Composants
import AllInfoData from '../../Component/AllInfoData/AllInfoData';
import Button from '../../Component/Button/Button';
import ButtonDragonfly from '../../Component/Button/ButtonDragonfly/ButtonDragonfly';
import CardInfoData from '../../Component/Card/CardInfoData/cardInfoData';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormControl from '../../Component/FormControl/FormControl';
import FormSelect from '../../Component/FormSelect/FormSelect';
import HeaderRetour from '../../Component/Header_Retour/header_retour.jsx';
import IconAddWhiteAndYellow from '../../Component/Icon/IconAddWhiteAndYellow';
import ICON_HOUSE from '../../Component/Icon/IconHouse';
import InfoPastilleNumber from '../../Component/InfoPastilleNumber/InfoPastilleNumber';
import Modal from '../../Component/Modal/Modal';
import ModalGlow from '../../Component/Modal/ModalGlow/ModalGlow';
import TitleGlow from '../../Component/Title_Glow/title_glow.tsx';

// import des "icons descriptifs"
import useRapprochementsByAchatStore from '../../hooks/Rapprochements/useRapprochementsByAchatStore';
import useShowRapprochement from '../../hooks/Rapprochements/useShowRapprochementByAchat';
import { Variant } from '../../lib/Achats/Achats.types';
import { CLOSE_STATUSES } from '../../lib/Biens/bien.const';
import { clotureAchat } from '../../lib/papirisClient';
import { makeRoutePath } from '../../store/route/route.api';
import useShowAchatStore from '../ShowAchatLayout/useShowAchatStore';

export default function HomeAchat() {
  const [showModalCloture, setShowModalCloture] = useState(false);
  const { achatId } = useParams();
  const { achat: projetAchatId, isLoading, isError } = useShowAchatStore();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const visitesDataAchat = projetAchatId?.visites ?? [];
  const favorisDataAchat = projetAchatId?.ventesFavorites ?? [];
  const demandesDataAchat = projetAchatId?.demandeRenseignements ?? [];
  const ignoresDataAchat = projetAchatId?.ventesIgnorees ?? [];

  const underTitleOfProjet = projetAchatId?.titre ?? projetAchatId?.createdAt;

  const statusOptions = useMemo(
    () => CLOSE_STATUSES.map((status) => ({
      label: formatMessage({ id: `achats.close.modal.status.${status}` }),
      value: status,
    })),
    [formatMessage],
  );

  const isLocation = projetAchatId?.variant === Variant.location;

  const form = useForm({
    mode: 'onTouched',
  });

  const handleShowModalCloture = () => {
    if (showModalCloture === true) {
      setShowModalCloture(false);
    } else {
      setShowModalCloture(true);
    }
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(clotureAchat);

  const handleCloseProjetAchat = useCallback(
    async (data) => {
      if (achatId) {
        setShowModalCloture(false);
        await mutation.mutateAsync({
          achatId,
          raisonCloture: data.raisonCloture,
        });
        await queryClient.invalidateQueries({ queryKey: 'achat' });
      }
    },
    [achatId, mutation, queryClient],
  );

  useShowRapprochement(achatId);
  const {
    rapprochements,
    isLoading: isLoadingRapprochements,
    isError: isErrorRapprochements,
  } = useRapprochementsByAchatStore();

  if (isLoading && isLoadingRapprochements) {
    <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />;
  }

  if (isError && isErrorRapprochements) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  return (
    <>
      <div className="container_Home_Modal">
        {isError || isLoading || (
          <TitleGlow
            icon={(
              <ICON_HOUSE
                className="icon_house_HomeAchat img-w4xl img-h4xl"
                classColor={
                  isLocation ? 'colorLocation' : 'colorAchat'
                }
              />
            )}
            title={isLocation ? 'Location' : 'Achat'}
            underTitle={underTitleOfProjet}
            edit
          />
        )}
      </div>
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        to={makeRoutePath('projet.list')}
        transparent
      />
      <FlexRow
        gap="4"
        grow="1"
        justifyContent="space-evenly"
      >

        <div className="container_Home_Achat">
          {form.formState.isSubmitting === true ? (
            <CircularProgress
              aria-label={formatMessage({ id: 'label.loading' })}
            />
          ) : (
            <>
              {showModalCloture === true ? (
                <ModalGlow
                  open={showModalCloture}
                  src1={<i className="fa-solid fa-xmark" />}
                  onClose={setShowModalCloture}
                  title={formatMessage({
                    id: 'achats.close.modal.titleForThisAchat',
                  })}
                >
                  <Form
                    className="raisonCloture"
                    onSubmit={handleCloseProjetAchat}
                    {...form}
                  >
                    <FormControl name="raisonCloture">
                      <FormSelect
                        noOptionsMessage={() => formatMessage({
                          id: 'achats.close.modal.no_option_message',
                        })}
                        options={statusOptions}
                      />
                    </FormControl>
                    <Button type="submit" className="button yellow">
                      {formatMessage({ id: 'label.valider' })}
                    </Button>
                  </Form>
                </ModalGlow>
              ) : (
                ''
              )}
              <AllInfoData
                isLoading={isLoading}
                visitesData={visitesDataAchat}
                favorisData={favorisDataAchat}
                demandesData={demandesDataAchat}
                ignoresData={ignoresDataAchat}
              />
              <div className="container_ButtonsDragonfly">
                <FlexRow
                  direction="row"
                  gap="4"
                  grow="1"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonDragonfly
                    disabled={!!isLoading}
                    topLeftRadius
                    topRightRadius
                    bottomLeftRadius
                    to={makeRoutePath('search.achat.show', { achatId, variant: projetAchatId?.variant })}
                  >
                    {formatMessage({ id: 'achat.show.bien' })}
                    <InfoPastilleNumber
                      infoPastilleNumber={rapprochements?.items?.length ?? 0}
                    />
                  </ButtonDragonfly>
                  <ButtonDragonfly
                    disabled={!!isLoading}
                    topLeftRadius
                    topRightRadius
                    bottomRightRadius
                    to={makeRoutePath('achat.projet.messagerie', { achatId })}
                  >
                    {formatMessage({ id: 'achat.show.messagerie' })}
                    <InfoPastilleNumber infoPastilleNumber={0} />
                  </ButtonDragonfly>
                </FlexRow>
                <FlexRow
                  direction="row"
                  gap="4"
                  grow="1"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonDragonfly
                    disabled={!!isLoading}
                    topLeftRadius
                    bottomLeftRadius
                    to={makeRoutePath('achat.show.update', { achatId })}
                  >
                    {formatMessage({ id: 'achat.show.decouverte' })}
                    <InfoPastilleNumber infoPastilleNumber={0} />
                  </ButtonDragonfly>
                  <ButtonDragonfly
                    disabled={!!isLoading}
                    topRightRadius
                    bottomRightRadius
                    to={makeRoutePath('achat.projet.documents', { achatId })}
                  >
                    {formatMessage({ id: 'achat.show.documents' })}
                    <InfoPastilleNumber infoPastilleNumber={0} />
                  </ButtonDragonfly>
                </FlexRow>
                <ButtonDragonfly
                  disabled={!!isLoading}
                  bottomLeftRadius
                  bottomRightRadius
                  to={makeRoutePath('achat.projet.agenda', { achatId })}
                >
                  {formatMessage({ id: 'achat.show.agenda' })}
                  <InfoPastilleNumber infoPastilleNumber={0} />
                </ButtonDragonfly>
              </div>
              <Button
                className="button standard projet medium"
                icon={<IconAddWhiteAndYellow className=" container_icon_plus_ideeri_Pro img-w3xl img-h3xl" />}
                to={makeRoutePath('achat.projet.professionnel', { achatId })}
              >
                Professionnel
              </Button>
              <Button
                onClick={handleShowModalCloture}
                className="button small-light"
              >
                <small>Clôturer mon projet</small>
              </Button>
            </>
          )}
          {form.formState.isSubmitSuccessful === true ? (
            <Modal
              open={form.formState.isSubmitSuccessful}
              title={formatMessage({
                id: 'achat.delete.confirmation.dialog_title',
              })}
              onClose={() => navigate(makeRoutePath('home'))}
            >
              <p>
                {formatMessage({ id: 'achat.delete.confirmation.dialog_text' })}
              </p>
              <Button className="standard" to={makeRoutePath('home')}>
                {formatMessage({
                  id: 'achat.delete.confirmation.dialog_continue',
                })}
              </Button>
            </Modal>
          ) : (
            ''
          )}
        </div>
      </FlexRow>
    </>
  );
}
