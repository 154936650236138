import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import IconHouseWithIcon from '../../Component/Icon/IconHouseWithIcon';
import PageHeader from '../../Component/PageHeader/PageHeader';
import TitleGlow from '../../Component/Title_Glow/title_glow';
import { makeRoutePath } from '../../store/route/route.api';

import useListBien from './useListBien';
import useShowBien from './useShowBien';
import useShowBienStore from './useShowBienStore';

export type ShowBienLayoutProps = {
  children?: React.ReactNode;
}

function ShowBienLayout({ children }: ShowBienLayoutProps): JSX.Element {
  const locationUrl = useLocation();
  const { bienId } = useParams();
  useShowBien(bienId);
  useListBien();
  const { formatMessage } = useIntl();
  const { bien, isError, isLoading } = useShowBienStore();
  const { roomId } = useParams();

  const titleOfProjet = bien?.titre || bien?.type || 'Bien';

  if (isLoading) {
    <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />;
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  return (
    locationUrl.pathname === makeRoutePath('bien.show.details.roomId', { bienId, roomId }) || locationUrl.pathname === makeRoutePath('bien.list')
      ? (
        <>
          <PageHeader
            bcg="_bien"
          />

          {children}
          <Outlet />
        </>
      )
      : (
        <>
          <PageHeader
            bcg="_bien"
          />
          <div className="container_Home_Modal">
            {isError || isLoading || (
              <TitleGlow
                icon={<IconHouseWithIcon className="img-w4xl img-h4xl" />}
                title={titleOfProjet}
                underTitle={bien?.adressePostale}
              />
            )}
          </div>
          {children}
          <Outlet />
        </>
      )
  );
}

export default ShowBienLayout;
