import './FormChip.scss';

import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import classNames from 'classnames';
import { MouseEventHandler, useRef } from 'react';
import { useController } from 'react-hook-form';

import { ComponentProps } from '../../types/ComponentProps';

export type FormChipProps = ComponentProps<'button', {
  value: string;
  name?: string;
  invalid?: boolean;
}>

function FormChip({
  className,
  name = '',
  value,
  disabled,
  invalid,
  children,
  ...props
}: FormChipProps): JSX.Element {
  const {
    field,
    fieldState,
  } = useController({ name, ...props });

  const checked = Array.isArray(field.value) ? field.value.includes(value) : field.value === value;

  const rippleRef = useRef<TouchRippleActions>(null);

  const onRippleStart: MouseEventHandler = (e) => {
    rippleRef.current?.start(e);
  };

  const onRippleStop: MouseEventHandler = (e) => {
    rippleRef.current?.stop(e);
  };

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (Array.isArray(field.value)) {
      if (field.value.includes(value)) {
        field.onChange([...field.value].filter((v) => v !== value));
      } else {
        field.onChange([...field.value, value]);
      }
    } else {
      field.onChange(value);
    }

    // e.target.blur();
  };

  const classes = classNames('FormChip', className, {
    'FormChip-invalid': invalid ?? fieldState.invalid,
    'FormChip-disabled': disabled,
    'FormChip-checked': checked,
  });

  return (
    <button
      type="button"
      className={classes}
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      onClick={onClick}
      ref={field.ref}
      disabled={disabled}
      aria-pressed={checked}
      {...props}
    >
      {children}
      <TouchRipple ref={rippleRef} />
    </button>
  );
}

export default FormChip;
