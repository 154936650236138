import { Outlet, useParams } from 'react-router-dom';

import useShowAchat from './useShowAchat';

function ShowAchatLayout(): JSX.Element {
  const { achatId } = useParams();

  useShowAchat(achatId);

  return (
    <Outlet />
  );
}

export default ShowAchatLayout;
