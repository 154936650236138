import { AchatModel } from '../Achats/Achats.types';
import { Bien } from '../Biens/biens.types';
import { PagerModel } from '../Pagers/Pagers.types';
import { RendezVousModel } from '../RendezVous/rendezVous.types';
import { request } from '../papirisClient';
import { UpdateContactTitleProjetVente } from '../papirisClient.types';

import { CreateVenteRendezVousEstimationPayload, ListVenteRendezVousAvailablePayload, VenteModel } from './Ventes.types';

export function createVente(vente: Pick<AchatModel, 'titre' | 'variant' | 'contacts'> & Pick<Bien, 'type' | 'statut' | 'ville' | 'codePostal' | 'adressePostale' | 'latitude' | 'longitude'>): Promise<VenteModel> {
  return request('/vente', 'POST', vente);
}

export function updateVente({ id, ...payload }: UpdateContactTitleProjetVente): Promise<VenteModel> {
  return request(`/vente/${id}`, 'PUT', payload);
}

export function updateBien({ id, ...payload }: Pick<AchatModel, 'titre'> & Pick<Bien, 'id' | 'type' | 'statut' | 'ville' | 'codePostal' | 'adressePostale' | 'latitude' | 'longitude'>): Promise<Bien> {
  return request(`/bien/${id}`, 'PUT', payload);
}

export function showVente(venteId: string): Promise<VenteModel> {
  return request(`/vente/${venteId}`);
}

export function listVenteRendezVousAvailable({ venteId, page }: ListVenteRendezVousAvailablePayload): Promise<PagerModel<RendezVousModel>> {
  return request(`/vente/${venteId}/rendez-vous/available?page=${page}`);
}

export function createVenteRendezVousEstimation({ venteId, ...payload }: CreateVenteRendezVousEstimationPayload): Promise<VenteModel> {
  return request(`/vente/${venteId}/rendez-vous/estimation`, 'POST', payload);
}
