import { useState } from 'react';
import { useIntl } from 'react-intl';

import IconOpen from '../../../img/icon_ouverture_modal.svg';
import IconShare from '../../Icon/IconShare';
import ModalGlow from '../../Modal/ModalGlow/ModalGlow';
import Button from '../Button';

export default function Share({
  title, text, url, onShare,
}) {
  const [resultMessage, setResultMessage] = useState('');
  const [copied, setCopied] = useState(false);
  const { formatMessage } = useIntl();
  const shareData = {
    title,
    text,
    url,
  };

  const handleShareData = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        setResultMessage('Partage du lien');
        if (onShare) {
          onShare();
        }
      } catch (err) {
        setResultMessage(`Error: ${err}`);
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareData.url);
        setResultMessage('URL copié dans le presse-papiers');
        if (onShare) {
          onShare();
        }
        setCopied(true);
      } catch (err) {
        setResultMessage(`Error: ${err}`);
      }
    }
  };

  return (
    <>
      <Button onClick={() => handleShareData(title, text, url)}>
        <IconShare className="img-wxl img-hxl" />
      </Button>
      <ModalGlow
        open={copied}
        src1={<i className="fa-solid fa-xmark" />}
        src2={<IconOpen />}
        title={formatMessage({ id: 'achats.share.modal.titleForThisAchat' })}
        underTitle={formatMessage({ id: 'achats.share.modal.underTitleForThisAchat' })}
      />
    </>
  );
}
