import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconPartenaire.scss';

function IconPartenaire({
  className, classColor = 'partenaire',
}: IconProps) {
  return (
    <div className="containerImage">
      <svg className={classNames('icon_partenaire', className)} id="Account_Ideeri" xmlns="http://www.w3.org/2000/svg" width="40" height="53" viewBox="0 0 40 53">
        <title id="icon_upload">Ajout compte partenaire</title>
        <g id="Ellipse_22" data-name="Ellipse 22" className={`${classColor}-cls-1`} transform="translate(0 6)">
          <circle className={`${classColor}-cls-3`} cx="20" cy="20" r="20" />
          <circle className={`${classColor}-cls-4`} cx="20" cy="20" r="18.5" />
        </g>
        <text id="_" data-name="+" className={`${classColor}-cls-2`} transform="translate(10 38)"><tspan x="0" y="0">+</tspan></text>
      </svg>

    </div>
  );
}

export default IconPartenaire;
