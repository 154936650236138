import { FormattedMessage } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';

import PageHeader from '../../Component/PageHeader/PageHeader';
import useQueryParams from '../../hooks/useQueryParams';
import useShowBien from '../ShowBienLayout/useShowBien';

function ProjetLayout(): JSX.Element {
  const queryParams = useQueryParams();
  const bienIdQuery = queryParams.get('bienId');
  const { bienId } = useParams();

  useShowBien(bienId);

  const hasBcg = bienId || bienIdQuery ? '_bien' : '_base';

  return (
    <>
      <PageHeader bcg={hasBcg} />
      <Outlet />
    </>
  );
}

export default ProjetLayout;
