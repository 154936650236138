import Routes from './route.const';

export function makeRoutePath(
  routeName: keyof typeof Routes | null,
  params: Record<string, string|string[]|undefined> = {},
  withHostname = false,
): string {
  const rawPath = routeName ? Routes[routeName] : window.location.pathname;
  const searchParams = new URLSearchParams();

  const path = Object.entries(params)
    .reduce((path: string, [key, value]) => {
      if (value === undefined) {
        return path;
      }

      const segment = `:${key}`;
      const rawValue = Array.isArray(value) ? value.join(',') : value;

      if (path.includes(segment)) {
        return path.replaceAll(segment, rawValue);
      }

      searchParams.set(key, rawValue);

      return path;
    }, rawPath);

  const search = searchParams.toString();
  const url = new URL(window.location.href);

  url.pathname = path;
  url.search = search;

  return withHostname ? url.toString() : (url.pathname + url.search);
}

export function isRoutePath(path: string|undefined|null): path is string {
  if (!path) {
    return false;
  }

  const url = new URL(path, window.location.origin);

  return url.origin === window.location.origin;
}
