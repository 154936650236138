import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { geoGouvAdresseResultSchema } from '../../../API/Adresse_France/geoGouv.schema';
import useGeoGouvLoadOptions from '../../../API/Adresse_France/useGeoGouvLoadOptions';
import Button from '../../../Component/Button/Button';
import Container from '../../../Component/Container/Container';
import FlexRow from '../../../Component/FlexRow/FlexRow';
import Form from '../../../Component/Form/Form';
import FormControl from '../../../Component/FormControl/FormControl';
import FormError from '../../../Component/FormError/FormError';
import FormFooter from '../../../Component/FormFooter/FormFooter';
import FormInput from '../../../Component/FormInput/FormInput';
import FormLabel from '../../../Component/FormLabel/FormLabel';
import FormSelect from '../../../Component/FormSelect/FormSelect';
import FormSelectAsync from '../../../Component/FormSelect/FormSelectAsync';
import Title from '../../../Component/Title/Title';
import usePapirisErrorForm from '../../../hooks/usePapirisErrorForm';
import useQueryParams from '../../../hooks/useQueryParams';
import useZodForm from '../../../hooks/useZodForm';
import { Variant } from '../../../lib/Achats/Achats.types';
import { BIEN_STATUSES, BIEN_TYPES } from '../../../lib/Biens/bien.const';
import { createVente, updateBien } from '../../../lib/Ventes/Ventes.api';
import { useUser } from '../../../provider/UserProvider';
import { makeRoutePath } from '../../../store/route/route.api';
import RedirectError from '../../Error/RedirectError';

import useCreateVenteStore from './useCreateVenteStore';

function CreateVenteBienPage(): JSX.Element {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { vente, setVente } = useCreateVenteStore();
  const { user } = useUser();
  const [papirisError, setPapirisError] = useState<unknown>(null);
  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || undefined;
  const locationVariant = variant === 'location' ? Variant.location : Variant.achatvente;
  if (!user?.contact) {
    throw new RedirectError(makeRoutePath('vente.create.contact', { variant }));
  }

  const queryClient = useQueryClient();
  const createVenteMutation = useMutation(createVente);
  const updateBienMutation = useMutation(updateBien);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      titre: z.string().nullable(),
      type: z.string().min(1),
      address: geoGouvAdresseResultSchema,
      status: z.string().min(1),
    }), []),
    initialValues: useMemo(() => {
      if (vente?.bien) {
        return {
          titre: vente.titre ?? undefined,
          type: vente.bien.type,
          status: vente?.bien?.statut ?? undefined,
          address: {
            ville: vente.bien.ville,
            codePostal: vente.bien.codePostal,
            adressePostale: vente.bien.adressePostale,
            latitude: vente.bien.latitude,
            longitude: vente.bien.longitude,
          },
        };
      }

      return {};
    }, [vente]),

    onSubmit: async (values) => {
      try {
        const payload = {
          titre: values.titre ?? undefined,
          type: values.type,
          variant: locationVariant,
          statut: values.status ?? null,
          ...values.address,
        };

        if (vente?.bien) {
          await updateBienMutation.mutateAsync({
            id: vente.bien.id,
            ...payload,
          });
          await queryClient.invalidateQueries({ queryKey: 'vente' });
        } else if (user?.contact) {
          const vente = await createVenteMutation.mutateAsync({
            contacts: [user.contact.id],
            ...payload,
          });

          setVente(vente);
        }

        navigate(makeRoutePath('vente.create.rendezvous', { variant }));
      } catch (error) {
        setPapirisError(error);
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  usePapirisErrorForm(form, papirisError);

  const typeOptions = useMemo(() => BIEN_TYPES.map((type) => ({
    label: formatMessage({ id: `model.bien.type.${type}` }),
    value: type,
  })), [formatMessage]);

  const statusOptions = useMemo(() => BIEN_STATUSES.map((status) => ({
    label: formatMessage({ id: `model.bien.status.${status}` }),
    value: status,
  })), [formatMessage]);

  const adresseSelectProps = useGeoGouvLoadOptions('housenumber');

  return (
    <Container size="md">
      <Title
        title={formatMessage({ id: 'vente.create.bien.title' })}
        size={3}
        subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 2, total: 4 })}
      />
      <Form onSubmit={onSubmit} {...form}>
        <FlexRow gap="1em">
          <FormControl name="titre">
            <FormLabel>{formatMessage({ id: 'vente.create.bien.type.titleClient' })}</FormLabel>
            <FormInput type="texte" placeholder={formatMessage({ id: 'vente.create.bien.type.titleClient.placeholder' })} />
            <FormError />
          </FormControl>
          <FormControl name="type">
            <FormLabel>{formatMessage({ id: 'vente.create.bien.type.label' })}</FormLabel>
            <FormSelect
              options={typeOptions}
            />
            <FormError />
          </FormControl>
          <FormControl name="address">
            <FormLabel>{formatMessage({ id: 'vente.create.bien.address.label' })}</FormLabel>
            <FormSelectAsync
              placeholder={formatMessage({ id: 'vente.create.bien.address.placeholder' })}
              noOptionsMessage={() => formatMessage({ id: 'address_lookup.no_option_message' })}
              {...adresseSelectProps as any}
            />
            <FormError />
            <FormError name="root.adressePostale" />
            <FormError name="root.ville" />
          </FormControl>
          <FormControl name="status">
            <FormLabel>{formatMessage({ id: 'vente.create.bien.status.label' })}</FormLabel>
            <FormSelect
              options={statusOptions}
            />
            <FormError />
          </FormControl>
        </FlexRow>
        <FormFooter>
          <FlexRow alignItems="center" justifyContent="space-between" direction="row">
            <Button
              className="button-simple"
              icon={<i className="fa-solid fa-chevron-left" />}
              to={makeRoutePath('vente.create.contact', { variant })}
            >
              {formatMessage({ id: 'label.back' })}
            </Button>
            <Button
              className={`button submit ${form.formState.isValid ? 'yellow' : 'not_Valid'}`}
              disabled={form.formState.isSubmitting}
              type="submit"
            >
              {formatMessage({ id: 'label.continue' })}
            </Button>
          </FlexRow>
        </FormFooter>
      </Form>
    </Container>
  );
}

export default CreateVenteBienPage;
