import './FormFieldset.scss';

import classNames from 'classnames';
import { CSSProperties } from 'react';

import useUuid from '../../hooks/useUuid';
import stylenames from '../../lib/stylenames';

export type FormFieldsetProps = React.ComponentProps<'div'> & {
    legend?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    direction?: 'row-wrap';
    margin?: CSSProperties['margin'];
}

function FormFieldset({
  legend, children, className, style, direction, margin,
  ...props
}:FormFieldsetProps): JSX.Element {
  const legendId = useUuid();

  const styles = stylenames(style, {
    '--FlexRow-margin': margin,
  });

  return (
    <div {...props} className="FormFieldset" role="group" aria-labelledby={legend ? legendId : undefined} style={styles}>
      {legend ? <div className={`FormFieldset_legend ${className}`} id={legendId}>{legend}</div> : null}
      {children ? (
        <div className={`FormFieldset_content ${direction}`}>
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default FormFieldset;
