import './range_slider.scss';
import 'react-rangeslider/lib/index.css';

import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Slider from 'react-rangeslider';

import OpenStreetMap from '../../../API/OpenStreetMap/OpenStreetMap.jsx';
import FIXED_OPTIONS from '../Fixed_Options/fixed_options.jsx';

export default function RANGE_SLIDER({
  selectedCommune, onSouhaitCommunesChange, value,
}) {
  const { formatMessage } = useIntl();
  const [radius, setRadius] = useState(value);
  const [nearbyCommunes, setNearbyCommunes] = useState([]);

  const handleRadiusChange = (value) => {
    setRadius(value);
  };

  useEffect(() => {
    const fetchNearbyCommunesRef = async () => {
      if (selectedCommune) {
        try {
          const { latitude, longitude } = selectedCommune;
          const communes = await OpenStreetMap.fetchNearbyCommunes(
            latitude,
            longitude,
            radius,
          );

          const communesNamesOption = communes.map((commune) => ({
            ville: commune.communeName,
            codePostal: commune.postalCode,
            value: `${commune.communeName} (${commune.postalCode})`,
            label: `${commune.communeName} (${commune.postalCode})`,
          }));

          setNearbyCommunes(communesNamesOption);
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des communes voisines',
            error,
          );
        }
      }

      return [];
    };

    fetchNearbyCommunesRef();
  }, [selectedCommune, radius]);

  return (
    <div className="slider custom-labels">
      <label htmlFor="commune_rayon_Ref" className="little_label">
        {formatMessage({ id: 'form.range_slider.label' }, { radius })}
      </label>
      <div className="container_commune_rayon_Ref">
        <Slider
          min={2}
          max={30}
          step={1}
          className="rangeslider__radius"
          orientation="horizontal"
          name="rangeslider__radius"
          value={radius}
          onChange={handleRadiusChange}
          tooltip={false} // info-bulle
        />
      </div>
      {nearbyCommunes.length
        ? (
          <FIXED_OPTIONS
            selectedCommune={selectedCommune}
            onSouhaitCommunesChange={onSouhaitCommunesChange}
            optionnels_Communes={nearbyCommunes}
          />
        ) : null}
    </div>
  );
}
