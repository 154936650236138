import './Container.scss';

import classnames from 'classnames';

import { ComponentProps } from '../../types/ComponentProps';

export type ContainerProps = ComponentProps<'div', {
  size?: 'sm'|'md'|'lg';
}>

export default function Container({
  className,
  children,
  size,
  ...props
}: ContainerProps) {
  const classes = classnames('Container', className, {
    [`Container-${size}`]: Boolean(size),
  });

  return <div {...props} className={classes}>{children}</div>;
}
