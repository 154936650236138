import './IconButton.scss';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ComponentProps } from '../../types/ComponentProps';

export type IconButtonProps = ComponentProps<'button'|'a'|typeof Link, {
  to?: string;
  href?: string;
  color?: 'primary'|'secondary';
  disabled?: boolean;
}>

export default function IconButton({
  className,
  color,
  to,
  href,
  disabled,
  children,
  ...props
}: IconButtonProps) {
  const classes = classnames('IconButton', className, {
    'IconButton-disabled': disabled,
    [`IconButton-${color}`]: Boolean(color),
  });

  if (typeof to === 'string') {
    return (
      <Link
        to={to}
        className={classes}
        {...props as any}
      >
        {children}
      </Link>
    );
  }

  if (typeof href === 'string') {
    return (
      <a
        href={href}
        className={classes}
        {...props as any}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={classes}
      disabled={disabled}
      {...props as any}
    >
      {children}
    </button>
  );
}
