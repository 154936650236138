import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../Component/Button/Button';
import Container from '../../../Component/Container/Container';
import FlexRow from '../../../Component/FlexRow/FlexRow';
import Form from '../../../Component/Form/Form';
import FormControl from '../../../Component/FormControl/FormControl';
import FormError from '../../../Component/FormError/FormError';
import FormFooter from '../../../Component/FormFooter/FormFooter';
import FormInput from '../../../Component/FormInput/FormInput';
import FormLabel from '../../../Component/FormLabel/FormLabel';
import FormTextarea from '../../../Component/FormTextarea/FormTextarea';
import IconRendezVousConfirmation from '../../../Component/Icon/IconRendezVousConfirmation';
import Modal from '../../../Component/Modal/Modal';
import ModalInfoMedium from '../../../Component/Modal/Modal_Info/ModalInfoMedium/ModalInfoMedium';
import Title from '../../../Component/Title/Title';
import useZodForm from '../../../hooks/useZodForm';
import { createVenteRendezVousEstimation } from '../../../lib/Ventes/Ventes.api';
import { VenteModel } from '../../../lib/Ventes/Ventes.types';
import { makeRoutePath } from '../../../store/route/route.api';
import RedirectError from '../../Error/RedirectError';

import useCreateVenteStore from './useCreateVenteStore';
import { Variant } from '../../../lib/Achats/Achats.types';

function CreateVenteConfirmationPage(): JSX.Element {
  const { vente, rendezVous, reset } = useCreateVenteStore();
  const [completedVente, setCompletedVente] = useState<VenteModel|null>(null);
  const variant = vente?.variant;
  const [confirmedCompletedVente, setConfirmedCompletedVente] = useState(false);

  useEffect(() => {
    setConfirmedCompletedVente(false);
  }, []);

  const handleConfirmedCompletedVente = () => {
    setConfirmedCompletedVente((previousShow) => !previousShow);
  };

  if (!vente && !completedVente) {
    throw new RedirectError(makeRoutePath('vente.create.rendezvous', { variant }));
  }

  const { formatMessage, formatDate } = useIntl();
  const navigate = useNavigate();

  const createRendezVousEstimationMutation = useMutation(createVenteRendezVousEstimation);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      comment: z.string(),
    }), []),
    initialValues: useMemo(() => ({
      comment: vente?.modalitesVisite ?? '',
    }), [vente]),
    onSubmit: async (values) => {
      if (vente && rendezVous) {
        await createRendezVousEstimationMutation.mutateAsync({
          venteId: vente.id,
          dateDebut: rendezVous.dateDebut,
          dateFin: rendezVous.dateFin,
          commentaire: values.comment,
        });

        setCompletedVente(vente);
      }

      await reset();
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  return (
    <Container size="md">
      <Title
        title={formatMessage({ id: 'vente.create.confirmation.title' })}
        size={3}
        subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 4, total: 4 })}
      />
      <Form onSubmit={onSubmit} {...form}>
        <FlexRow gap="1em">
          <FormControl>
            <FormLabel>{formatMessage({ id: 'vente.create.confirmation.date.label' })}</FormLabel>
            <FormInput disabled value={rendezVous ? formatDate(rendezVous.dateDebut, { dateStyle: 'short' }) : undefined} />
          </FormControl>
          <FlexRow gap="1em" padding="0" direction="row">
            <FormControl>
              <FormLabel>{formatMessage({ id: 'vente.create.confirmation.time_start.label' })}</FormLabel>
              <FormInput disabled value={rendezVous ? formatDate(rendezVous.dateDebut, { timeStyle: 'short' }) : undefined} />
            </FormControl>
            <FormControl>
              <FormLabel>{formatMessage({ id: 'vente.create.confirmation.time_end.label' })}</FormLabel>
              <FormInput disabled value={rendezVous ? formatDate(rendezVous.dateFin, { timeStyle: 'short' }) : undefined} />
            </FormControl>
          </FlexRow>
          <FormControl>
            <FormLabel>{formatMessage({ id: 'vente.create.confirmation.comment.label' })}</FormLabel>
            <FormTextarea name="comment" placeholder={formatMessage({ id: 'vente.create.confirmation.comment.placeholder' })} />
            <FormError name="comment" />
          </FormControl>
        </FlexRow>
        <FormFooter>
          <FlexRow alignItems="center" justifyContent="space-between" direction="row">
            <Button
              className="button-simple"
              icon={<i className="fa-solid fa-chevron-left" />}
              to={makeRoutePath('vente.create.rendezvous', { variant })}
            >
              {formatMessage({ id: 'label.back' })}
            </Button>
            <Button
              className={`button submit ${form.formState.isValid ? 'yellow' : 'not_Valid'}`}
              disabled={form.formState.isSubmitting}
              type="submit"
            >
              {formatMessage({ id: 'label.confirm' })}
            </Button>
          </FlexRow>
        </FormFooter>
      </Form>
      <ModalInfoMedium
        open={completedVente !== null && confirmedCompletedVente === false}
        title={formatMessage({ id: 'achat-vente.create.awaitConfirmation.dialog_title' })}
        text={formatMessage({ id: 'achat-vente.create.awaitConfirmation.dialog_text' })}
        textMoreInfo={formatMessage({ id: 'achat-vente.create.awaitConfirmation.dialog_more_Info' })}
        textButton={formatMessage({ id: 'achat-vente.create.awaitConfirmation.dialog_continue' })}
        onClick={handleConfirmedCompletedVente}
      />

      <Modal
        open={confirmedCompletedVente === true}
        title={formatMessage({ id: 'vente-location.create.confirmation.dialog_title' })}
        subtitle={rendezVous ? formatDate(rendezVous.dateDebut, { dateStyle: 'full', timeStyle: 'short' }) : undefined}
        icon={<IconRendezVousConfirmation />}
        onClose={() => navigate(makeRoutePath('projet.list'))}
      >
        <p>{formatMessage({ id: 'vente-location.create.confirmation.dialog_text' })}</p>
        <Button className="standard" to={completedVente ? makeRoutePath('vente.show', { venteId: completedVente?.id, variant }) : undefined}>
          {formatMessage({ id: 'vente-location.create.confirmation.dialog_continue' })}
        </Button>
      </Modal>
    </Container>
  );
}

export default CreateVenteConfirmationPage;
