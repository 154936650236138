import './InfoPastilleNumber.scss';

import {
} from '../../Function/URL_path.jsx';

export type InfoPastilleNumberProps = {infoPastilleNumber?: number}

export default function InfoPastilleNumber({ infoPastilleNumber }: InfoPastilleNumberProps) {
  return (
    infoPastilleNumber
      ? (
        <span className="infoNumber">
          { infoPastilleNumber >= 10 ? '+9'
            : infoPastilleNumber }
        </span>
      )
      : ''

  );
}
