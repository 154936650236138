import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { showAchat } from '../../lib/papirisClient';

import useShowAchatStore from './useShowAchatStore';

export default function useShowAchat(achatId?:string) :void {
  const { setAchat, setLoading, setError } = useShowAchatStore();

  /** Refresh achat if any was found in store, or use the draft */
  const showAchatQuery = useQuery({
    queryKey: ['achat', achatId],
    queryFn: () => (achatId && achatId !== 'nouveau' ? showAchat(achatId) : undefined),
  });

  useEffect(() => {
    if (showAchatQuery.isSuccess) {
      if (showAchatQuery.data) {
        setAchat(showAchatQuery.data);
        setLoading(showAchatQuery.isLoading);
      }
    }
    setError(showAchatQuery.isError);
    setLoading(showAchatQuery.isLoading);
  }, [showAchatQuery.data, setAchat, showAchatQuery.isSuccess, showAchatQuery.isError, showAchatQuery.isLoading, setError, setLoading]);
}
