import './SectionLink.scss';
import { Link } from 'react-router-dom';

export default function SectionLink({ to, label }) {
  return (
    <Link to={to} className="section-button">
      <div className="section-button-content">
        <span className="section-button-label">{label}</span>
        <i className="fa-solid fa-chevron-right section-button-icon" />
      </div>
    </Link>
  );
}
