import './MesProjetsPage.scss';
import {
  useMemo, useState, useEffect, useRef, useCallback,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import ButtonProjet from '../../Component/Button/ButtonProjet/button_projet_card';
import CreateProject from '../../Component/Button/CreateProject/CreateProject';
import Container from '../../Component/Container/Container';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import ICON_HOUSE from '../../Component/Icon/IconHouse';
import useQueryParams from '../../hooks/useQueryParams';
import { AchatModel } from '../../lib/Achats/Achats.types';
import { VenteModel } from '../../lib/Ventes/Ventes.types';
import { listAchat, listVente } from '../../lib/papirisClient';
import { makeRoutePath } from '../../store/route/route.api';

export type AchatCardProps = {
  achat: AchatModel;
}

export function AchatCard({ achat }: AchatCardProps) {
  const { formatMessage } = useIntl();

  return (
    <ButtonProjet
      className="button_projet_card size_card_project"
      title_card={formatMessage({ id: 'mes_projets.achat.title' })}
      subtitle_card={achat?.titre ?? achat?.createdAt}
      maison={<ICON_HOUSE className="size-card-Project position-card-Project" classColor="colorAchat" />}
      url={makeRoutePath('achat.show', { achatId: achat?.id })}
    />
  );
}

export type VenteCardProps = {
  vente: VenteModel;
}

export function VenteCard({ vente }: VenteCardProps) {
  const { formatMessage } = useIntl();

  return (
    <ButtonProjet
      className="button_projet_card size_card_project"
      title_card={formatMessage({ id: 'mes_projets.vente.title' })}
      subtitle_card={vente?.nom ?? vente?.titre ?? vente?.bien?.createdAt ?? ''}
      maison={<ICON_HOUSE className="size-card-Project position-card-Project" classColor="colorVente" />}
      url={makeRoutePath('vente.show', { venteId: vente?.id })}
    />
  );
}

export type LocationCardProps = {
  data: VenteModel | AchatModel;
}

export function LocationCard({ data }: LocationCardProps) {
  const { formatMessage } = useIntl();

  const isVenteModel = (data: VenteModel | AchatModel): data is VenteModel => 'bien' in data;

  return (
    <ButtonProjet
      className="button_projet_card size_card_project"
      title_card={formatMessage({ id: 'mes_projets.location.title' })}
      subtitle_card={isVenteModel(data)
        ? data?.nom || data?.titre || data?.bien?.createdAt || '' : data?.titre || data?.createdAt || ''}
      maison={<ICON_HOUSE className="size-card-Project position-card-Project" classColor="colorLocation" />}
      url={isVenteModel(data) ? makeRoutePath('vente.show', { venteId: data?.id })
        : makeRoutePath('achat.show', { achatId: data?.id })}
    />
  );
}

export default function MesProjetsPage() {
  const [scrollValue, setScrollValue] = useState(0);
  const [lastScrollValue, setLastScrollValue] = useState(0);

  // Permet de mettre le visuel du hover sur le projet qui ce trouve le plus au centre de l'écran
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const cards = document.getElementsByClassName('button_projet_card');
      Array.from(cards).forEach((card) => {
        const intersectionObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio >= 0.75) {
              entry.target.classList.add('active');
            } else {
              entry.target.classList.remove('active');
            }
          });
        }, { threshold: [0.75] });

        intersectionObserver.observe(card);
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      const cards = document.getElementsByClassName('button_projet_card');
      Array.from(cards).forEach((card) => {
        card.classList.remove('active');
      });
    };
  }, []);

  useEffect(() => {
    if (scrollValue !== lastScrollValue) {
      setLastScrollValue(scrollValue);
    }
  }, [scrollValue, lastScrollValue]);

  const listAchatQuery = useQuery(['achat', 'list', 1], () => listAchat({ page: 1 }));
  const listVenteQuery = useQuery(['vente', 'list', 1], () => listVente({ page: 1, perPage: 100, mine: true }));
  const params = useQueryParams();
  const bienIdUrl = params.get('bienId');

type ProjetAchat = {
  id: string;
  variant: string;
  createdAt: string;
  achat: AchatModel;
}

type ProjetVente = {
  id: string;
  variant: string;
  createdAt: string | undefined;
  vente: VenteModel;
}

  type Projet = ProjetAchat | ProjetVente

  const projets = useMemo(() => {
    const projetsMemo : Projet[] = [];

    if (listAchatQuery.data) {
      projetsMemo.push(
        ...listAchatQuery.data.items.map((achat) => ({
          id: achat?.id,
          variant: achat?.variant,
          createdAt: achat?.createdAt,
          achat,
        })),
      );
    }

    if (listVenteQuery.data) {
      projetsMemo.push(
        ...listVenteQuery.data.items.map((vente) => ({
          id: vente?.id,
          variant: vente?.variant,
          createdAt: vente?.createdAt,
          vente,
        })),
      );
    }

    return projetsMemo;
  }, [listAchatQuery.data, listVenteQuery.data]);

  function isProjetVente(projet: Projet): projet is ProjetVente {
    return (projet as ProjetVente).vente !== undefined;
  }
  const projetsAvecVenteEtBienId = projets.filter((projet) => isProjetVente(projet) && projet.vente?.bien?.id === bienIdUrl);
  const loading = listAchatQuery.isLoading || listVenteQuery.isLoading;

  function renderProjet(projet: typeof projets[number]) {
    try {
      if (!projet || !projet.id) {
        throw new Error('Projet invalide');
      }

      if (projet?.variant === 'achatvente') {
        if ('achat' in projet) {
          return (
            <AchatCard key={projet?.id} achat={projet?.achat} />
          );
        }

        if ('vente' in projet) {
          return (
            <VenteCard key={projet?.id} vente={projet?.vente} />
          );
        }
      }
      if (projet?.variant === 'location') {
        if ('achat' in projet) {
          return (
            <LocationCard key={projet?.id} data={projet?.achat} />
          );
        }
        if ('vente' in projet) {
          return (
            <LocationCard key={projet?.id} data={projet?.vente} />
          );
        }
      }
    } catch (error) {
      console.error('Erreur lors du rendu du projet:', error);

      return 'Erreur lors du rendu';
    }

    return null;
  }

  const VisuelProjectBienId = (
    <FlexRow
      className="screenSize container_Buttons_Projets"
      direction="row"
      grow="0.5"
      alignItems="center"
      justifyContent="start"
      gap="2rem"
    >
      {projetsAvecVenteEtBienId.map(renderProjet)}
    </FlexRow>
  );
  const VisuelAllProject = (
    <FlexRow
      className="screenSize container_Buttons_Projets"
      direction="row"
      grow="0.5"
      alignItems="center"
      justifyContent="start"
      gap="2rem"
    >
      {projets.map(renderProjet)}
    </FlexRow>
  );
  const VisuelWithoutProject = (
    <FlexRow
      className="container_CreateProject"
      direction="column"
      grow="1"
      gap="2rem"
      justifyContent="center"
      alignItems="center"
    >
      <CreateProject />
    </FlexRow>
  );

  return (
    loading ? (
      <DefaultLoader />
    )
      : (
        <>
          {bienIdUrl ? (
            <HeaderRetour
              classBEM="_homeAchatVenteBienBack"
              transparent
            />
          ) : ''}
          <FlexRow
            className="container_Mes_Projet"
            direction="column"
            grow="1"
            gap="1rem"
            justifyContent="center"
          >
            <div className="titre">
              <h2>
                <FormattedMessage
                  id="mes_projets.title"
                  values={{
                    title: (children) => <p className="p_Second">{children}</p>,
                    subtitle: (children) => <p className="p_First">{children}</p>,
                  }}
                />
              </h2>
            </div>
            {bienIdUrl ? (VisuelProjectBienId) : (listAchatQuery?.data?.items?.length ?? 0) || (listVenteQuery?.data?.items?.length ?? 0) > 0 ? (
              VisuelAllProject
            )
              : (
                VisuelWithoutProject
              )}
          </FlexRow>
        </>
      )
  );
}
