import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { AchatModel } from '../../lib/Achats/Achats.types';

 type ShowAchatState = {
   achat: AchatModel | null;
   isLoading: boolean;
   isError: boolean;
}

type ShowAchatActions = {
  setAchat(achat: AchatModel | null): void;
  setLoading(isLoading: boolean): void;
  setError(isError: boolean): void;
  reset():Promise<void>;
}

export type ShowAchatStore = ShowAchatState & ShowAchatActions;

const initialState: ShowAchatState = {
  achat: null,
  isLoading: false,
  isError: false,
};

const useShowAchatStore = create<ShowAchatStore>()(
  persist((set) => ({
    ...initialState,
    setAchat: (achat) => set(() => ({ achat })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setError: (isError) => set(() => ({ isError })),
    reset: async () => set(initialState),
  }), {
    name: 'show-achat-store',
  }),
);

export default useShowAchatStore;
