import { FirebaseError } from 'firebase/app';
import {
  MouseEventHandler, useCallback, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../Component/Button/Button';
import IconButton from '../../Component/Button/IconButton';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormAddon from '../../Component/FormAddon/FormAddon';
import FormAddonWrapper from '../../Component/FormAddonWrapper/FormAddonWrapper';
import FormCheckbox from '../../Component/FormCheckbox/FormCheckbox';
import FormControl from '../../Component/FormControl/FormControl';
import FormError from '../../Component/FormError/FormError';
import FormFooter from '../../Component/FormFooter/FormFooter';
import FormInput from '../../Component/FormInput/FormInput';
import FormLabel from '../../Component/FormLabel/FormLabel';
import IconAnonyme from '../../Component/Icon/IconAnnonyme';
import GoogleLogin from '../../Component/Selecteur/GoogleLogin/GoogleLogin.jsx';
import { SrOnly } from '../../Component/SrOnly/SrOnly';
import Title from '../../Component/Title/Title';
import useQueryParams from '../../hooks/useQueryParams';
import useZodForm from '../../hooks/useZodForm';
import { createUser } from '../../lib/firebaseClient';
import { makeRoutePath } from '../../store/route/route.api';

import AuthLayout from './AuthLayout';

function isValidPassword(password: string): boolean {
  const containsUppercase = (ch: string) => /[A-Z]/.test(ch);
  const containsLowercase = (ch: string) => /[a-z]/.test(ch);
  let countOfUpperCase = 0;
  let countOfLowerCase = 0;
  for (let i = 0; i < password.length; i += 1) {
    const ch = password.charAt(i);
    if (containsUppercase(ch)) countOfUpperCase += 1;
    else if (containsLowercase(ch)) countOfLowerCase += 1;
  }

  return (
    password.length >= 12
    && countOfLowerCase >= 1
    && countOfUpperCase >= 1
  );
}

export default function InscriptionPage() {
  const { formatMessage } = useIntl();

  const query = useQueryParams();
  const to = query.get('to') ?? undefined;

  const [showPassword, setShowPassword] = useState(false);
  const [firebaseError, setFirebaseError] = useState<FirebaseError | null>(null);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      email: z.string().email(),
      password: z.string().superRefine((password, ctx) => {
        if (!isValidPassword(password)) {
          ctx.addIssue({
            code: 'custom',
            message: formatMessage({ id: 'error.firebase.auth/weak-password' }),
          });
        }
      }),
      cgu: z.literal(true),
    }), [formatMessage]),
    initialValues: useMemo(() => ({
      email: '',
      password: '',
      cgu: undefined,
    }), []),
    onSubmit: async (values) => {
      setFirebaseError(null);

      try {
        await createUser(values.email, values.password);
      } catch (error) {
        if (error instanceof FirebaseError) {
          setFirebaseError(error);
        } else throw error;
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  const onClickShowPassword: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <AuthLayout title={<Title size={1} color="first">{formatMessage({ id: 'accueil.title' })}</Title>}>
      <Container size="md">
        <Form onSubmit={onSubmit} {...form}>
          <FlexRow gap="1rem" alignItems="center">
            <IconAnonyme className="size-img-card-profil icon-position-profil" />
            <GoogleLogin />
            <FormControl name="email">
              <SrOnly>
                <FormLabel>{formatMessage({ id: 'accueil.email.label' })}</FormLabel>
              </SrOnly>
              <FormInput variant="outline" type="email" placeholder={formatMessage({ id: 'accueil.email.label' })} />
              <FormError />
            </FormControl>
            <FormControl name="password">
              <SrOnly>
                <FormLabel>{formatMessage({ id: 'accueil.password.label' })}</FormLabel>
              </SrOnly>
              <FormAddonWrapper>
                <FormInput variant="outline" type={showPassword ? 'text' : 'password'} placeholder={formatMessage({ id: 'accueil.password.label' })} />
                <FormAddon side="right">
                  <IconButton
                    type="button"
                    onClick={onClickShowPassword}
                    aria-label={formatMessage({ id: 'accueil.password.show_password' })}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye-slash" style={{ color: 'var(--text_Secondaire_Fade)' }} />
                    ) : (
                      <i className="fa-solid fa-eye" style={{ color: 'var(--text_Secondaire_Fade)' }} />
                    )}
                  </IconButton>
                </FormAddon>
              </FormAddonWrapper>
              <FormError />
            </FormControl>
            <Container size="md">
              <FormControl name="cgu">
                <FormCheckbox
                  type="checkbox"
                  label={(
                    <FormattedMessage
                      id="accueil.cgu.label"
                      values={{
                        conditions: (children) => <a href={`${process.env.PUBLIC_URL}/ideeri-cgu.pdf`} target="_blank" rel="noopener noreferrer">{children}</a>,
                        privacy: (children) => <a href={`${process.env.PUBLIC_URL}/ideeri-politique-confidentialite.pdf`} target="_blank" rel="noopener noreferrer">{children}</a>,
                      }}
                    />
                  )}
                />
                <FormError />
              </FormControl>
            </Container>
            {firebaseError ? (
              <FormError>
                {formatMessage({ id: `error.firebase.${firebaseError.code}` })}
              </FormError>
            ) : null}
          </FlexRow>
          <FormFooter>
            <FlexRow alignItems="center">
              <Button
                className="button yellow"
                disabled={form.formState.isSubmitting}
                type="submit"
              >
                {formatMessage({ id: 'accueil.sign_up' })}
              </Button>
            </FlexRow>
          </FormFooter>
        </Form>
        <FlexRow alignItems="center" gap="1rem" padding="1rem">
          <Button className="button small-bold" to={makeRoutePath('auth.sign_in', { to })}>
            {formatMessage({ id: 'accueil.sign_in' })}
          </Button>
        </FlexRow>
      </Container>
    </AuthLayout>
  );
}
