import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconFile.scss';

function IconFile({
  className, classColor = 'file',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_file', className)} id="nouveau_fichier" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.55 104.733">
        <title id="icon_file">Icon Fichier</title>
        <path id="file-solid" className={`${classColor}-cls-1`} d="M0,13.092A13.1,13.1,0,0,1,13.092,0H45.821V26.183a6.539,6.539,0,0,0,6.546,6.546H78.55V91.642a13.1,13.1,0,0,1-13.092,13.092H13.092A13.1,13.1,0,0,1,0,91.642ZM78.55,26.183H52.367V0Z" />
      </svg>

    </div>
  );
}

export default IconFile;
