import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconLocalityRound.scss';

function IconLocality({
  className, classColor = 'locality',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" className={classNames('icon_locality', className)} id="Bouton_localisation" viewBox="0 0 31 31">
        <title id="icon_locality">Icon visite</title>
        <defs />
        <circle id="Ellipse_113" data-name="Ellipse 113" className={`${classColor}-cls-1`} cx="15.5" cy="15.5" r="15.5" />
        <g id="Icon_feather-map-pin" data-name="Icon feather-map-pin" transform="translate(8.35 6.761)">
          <path
            id="Tracé_3981"
            data-name="Tracé 3981"
            className={`${classColor}-cls-2`}
            d="M18.8,8.65c0,5.561-7.15,10.328-7.15,10.328S4.5,14.211,4.5,8.65a7.15,7.15,0,1,1,14.3,0Z"
            transform="translate(-4.5 -1.5)"
          />
          <path
            id="Tracé_3982"
            data-name="Tracé 3982"
            className={`${classColor}-cls-2`}
            d="M18.267,12.883A2.383,2.383,0,1,1,15.883,10.5,2.383,2.383,0,0,1,18.267,12.883Z"
            transform="translate(-8.733 -5.733)"
          />
        </g>
      </svg>
    </div>
  );
}

export default IconLocality;
