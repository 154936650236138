import './decouverte_1.scss';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import useCreateAchatStore from '../../../Pages/CreateAchatLayout/useCreateAchatStore';
import useShowAchatStore from '../../../Pages/ShowAchatLayout/useShowAchatStore';
import usePapirisErrorForm from '../../../hooks/usePapirisErrorForm';
import useQueryParams from '../../../hooks/useQueryParams';
import useZodForm from '../../../hooks/useZodForm';
import { Variant } from '../../../lib/Achats/Achats.types';
import { updateAchat, createAchat } from '../../../lib/papirisClient';
import { useUser } from '../../../provider/UserProvider';
import { makeRoutePath } from '../../../store/route/route.api';
import Button from '../../Button/Button';
import Container from '../../Container/Container';
import FlexRow from '../../FlexRow/FlexRow';
import FormControl from '../../FormControl/FormControl';
import FormError from '../../FormError/FormError';
import FormFieldset from '../../FormFieldset/FormFieldset';
import FormFooter from '../../FormFooter/FormFooter';
import FormInput from '../../FormInput/FormInput';
import FormLabel from '../../FormLabel/FormLabel';
import MesPartenaires from '../../Mes_Partenaires/mes_partenaires.jsx';
import Title from '../../Title/Title';
import Form from '../Form';
// import useZodForm from '../../../hooks/useZodForm';

export default function DECOUVERTE_1() {
  const { formatMessage } = useIntl();
  const { user } = useUser();

  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || undefined;

  const { achat, setAchat } = useCreateAchatStore();
  const { achat: projetAchatUpdate } = useShowAchatStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [titreProjet, setTitreProjet] = useState(achat?.achat?.titre);
  const [papirisError, setPapirisError] = useState(null);
  const locationUrl = useLocation();

  const locationVariant = variant === 'location' ? Variant.location : Variant.achatvente;

  const mutationAchat = useMutation(createAchat);
  const mutationUpdateAchat = useMutation(updateAchat);
  const initialTitle = locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id }) ? projetAchatUpdate?.titre : achat?.titre;

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      titre: z.string().nullish(),
    }), []),

    initialValues: useMemo(() => ({
      titre: initialTitle,
    }), [initialTitle]),

    onSubmit: async (values) => {
      try {
        if (locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id })) {
          if (projetAchatUpdate && user?.contact) {
            await mutationUpdateAchat.mutateAsync({
              achatId: projetAchatUpdate?.id,
              contacts: [user?.contact?.id],
              variant: locationVariant,
              titre: values?.titre,
            });
            await queryClient.invalidateQueries({ queryKey: 'achat' });
          }
        } else if (!achat && user?.contact) {
          const newAchat = await mutationAchat.mutateAsync({
            contacts: [user?.contact?.id],
            variant: locationVariant,
            titre: values?.titre,
          });
          setAchat(newAchat);
          navigate('../famille');
        } else if (achat && user?.contact) {
          const achatUpdate = await mutationUpdateAchat.mutateAsync({
            achatId: projetAchatUpdate?.id,
            contacts: [user?.contact?.id],
            variant: locationVariant,
            titre: values?.titre,
          });
          await queryClient.invalidateQueries({ queryKey: 'achat' });
          setAchat(achatUpdate);
          navigate('../famille');
        }
      } catch (error) {
        setPapirisError(error);
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  const {
    formState: {
      isSubmitting,
    },
  } = form;

  usePapirisErrorForm(form, papirisError);

  return (
    <FlexRow
      className="container_Mes_Projet _Partenaire"
      direction="column"
      grow="0.5"
      gap="2rem"
      justifyContent="center"
    >
      <Container size="lg">
        <Title
          title={formatMessage({ id: 'achats-ventes.create.contact.title' })}
          position="start"
          size={3}
          subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 1, total: 4 })}
        />
      </Container>
      <MesPartenaires />
      <Container size="lg">
        <Form onSubmit={onSubmit} {...form}>
          <FormFieldset legend={formatMessage({ id: 'vente.create.bien.type.titleClient' })}>
            <FormControl name="titre">
              <FormLabel>
                <FormInput type="texte" placeholder={formatMessage({ id: 'vente.create.bien.type.titleClient.placeholder' })} />
              </FormLabel>
              <FormError />
            </FormControl>
          </FormFieldset>
          <FormFooter>
            <FlexRow alignItems="center" justifyContent="space-around" direction="row">
              <Button
                className="button submit yellow"
                type="submit"
                disabled={!user?.contact || isSubmitting}
              >
                {formatMessage({ id: 'label.continue' })}
              </Button>
            </FlexRow>
          </FormFooter>
        </Form>
      </Container>
    </FlexRow>
  );
}
