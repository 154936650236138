import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconFavorisRound.scss';

function IconFavorisRound({
  className, classColor = 'favorisRound',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" className={classNames('icon_favoris', className)} id="Bouton_favoris" viewBox="0 0 48 48">
        <title id="icon_favoris">Icon Favoris</title>
        <g id="Groupe_2032" data-name="Groupe 2032" transform="translate(-91 -238)">
          <g id="icon_favoris" data-name="icon_favoris" transform="translate(91 238)">
            <circle id="Ellipse_113" data-name="Ellipse 113" className={`${classColor}-cls-1`} cx="24" cy="24" r="24" />
            <path id="Icon_awesome-heart" data-name="Icon awesome-heart" className={`${classColor}-cls-2`} d="M25.773,3.932a7.7,7.7,0,0,0-10.4.748L14.272,5.8l-1.1-1.116a7.7,7.7,0,0,0-10.4-.748,7.822,7.822,0,0,0-.552,11.432L13.006,26.35a1.765,1.765,0,0,0,2.525,0L26.319,15.364a7.817,7.817,0,0,0-.546-11.432Z" transform="translate(9.729 11.752)" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default IconFavorisRound;
