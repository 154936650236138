import './ProfileForm.scss';

import { useCallback, useMemo, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { geoGouvAdresseResultSchema } from '../../../API/Adresse_France/geoGouv.schema';
import useGeoGouvLoadOptions from '../../../API/Adresse_France/useGeoGouvLoadOptions';
import usePapirisErrorForm from '../../../hooks/usePapirisErrorForm';
import useZodForm from '../../../hooks/useZodForm';
import ICON_CHECK from '../../../img/icon_Check.svg';
import { updateContact } from '../../../lib/papirisClient';
import { useUser } from '../../../provider/UserProvider';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import Container from '../../Container/Container';
import FlexCell from '../../FlexCell/FlexCell';
import FlexRow from '../../FlexRow/FlexRow';
import FormControl from '../../FormControl/FormControl';
import FormError from '../../FormError/FormError';
import FormFieldset from '../../FormFieldset/FormFieldset';
import FormFooter from '../../FormFooter/FormFooter';
import FormInput from '../../FormInput/FormInput';
import FormLabel from '../../FormLabel/FormLabel';
import FormPhone from '../../FormPhone/FormPhone';
import FormRadio from '../../FormRadio/FormRadio';
import FormSelect from '../../FormSelect/FormSelect';
import FormSelectAsync from '../../FormSelect/FormSelectAsync';
import FormTextarea from '../../FormTextarea/FormTextarea';
import IconAddWhiteAndYellow from '../../Icon/IconAddWhiteAndYellow';
import IconDelete from '../../Icon/IconDelete';
import ModalAlerte from '../../Modal/ModalAlerte/ModalAlerte';
import Form from '../Form';

const SITUATION_FAMILIALES = [
  'Célibataire',
  'Divorcé(e)',
  'Marié(e)',
  'Pacsé(e)',
  'Veuf/Veuve',
];

export type ProfileFormProps = {
  backTo?: string;
}

export default function ProfileForm({ backTo }: ProfileFormProps) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { user } = useUser();
  const queryClient = useQueryClient();

  const [success, setSuccess] = useState(false);
  const [papirisError, setPapirisError] = useState<unknown>(null);

  const mutation = useMutation(updateContact);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      genre: z.string().min(1),
      nom: z.string().min(1),
      prenom: z.string().min(1),
      telephones: z.string().min(1).array().min(1),
      email: z.string().min(1),
      adresse: geoGouvAdresseResultSchema.nullish(),
      profession: z.string().nullish(),
      dateDeNaissance: z.string().nullish(),
      situationFamiliale: z.string().nullish(),
      commentaireClient: z.string().nullish(),
    }), []),
    initialValues: useMemo(() => {
      if (user) {
        return {
          genre: user.contact?.civilite,
          nom: user.contact?.nom,
          prenom: user.contact?.prenom,
          telephones: user.contact?.telephones,
          email: user.email,
          adresse: user.contact?.ville ? {
            ville: user.contact.ville ?? '',
            codePostal: user.contact.codePostal ?? '',
            adressePostale: user.contact.adressePostale ?? '',
          } : undefined,
          profession: user.contact?.profession,
          dateDeNaissance: user.contact?.dateDeNaissance,
          situationFamiliale: user.contact?.situationFamiliale,
          commentaireClient: user.contact?.commentaireClient,
        };
      }

      return {
        telephone: [''],
      };
    }, [user]),

    onSubmit: async (values) => {
      try {
        await mutation.mutateAsync({
          civilite: values.genre,
          nom: values.nom,
          prenom: values.prenom,
          telephones: values.telephones,
          email: values.email,
          adressePostale: values.adresse?.adressePostale,
          codePostal: values.adresse?.codePostal,
          ville: values.adresse?.ville,
          profession: values.profession,
          dateDeNaissance: values.dateDeNaissance,
          situationFamiliale: values.situationFamiliale,
          commentaireClient: values.commentaireClient as any,
        });

        setSuccess(true);

        await queryClient.invalidateQueries({ queryKey: 'user' });
      } catch (error) {
        setPapirisError(error);
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  usePapirisErrorForm(form, papirisError);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'telephones',
  } as any);

  const situationFamilialeOptions = useMemo(() => SITUATION_FAMILIALES.map((situationFamiliale) => ({
    value: situationFamiliale,
    label: formatMessage({ id: `profil.contact.situationFamiliale.${situationFamiliale}` }),
  })), [formatMessage]);

  const adresseSelectProps = useGeoGouvLoadOptions('housenumber');

  return (
    <Form onSubmit={onSubmit} {...form}>
      <FlexRow gap="2em">
        <FormFieldset legend={formatMessage({ id: 'profil.contact.fieldset_personal_data' })}>
          <FormControl name="genre">
            <FormLabel required>{formatMessage({ id: 'profil.contact.genre' })}</FormLabel>
            <FlexRow gap="0.5em" direction="row">
              <FormRadio value="M." label={formatMessage({ id: 'profil.contact.genre.monsieur' })} />
              <FormRadio value="Mme." label={formatMessage({ id: 'profil.contact.genre.madame' })} />
              <FormRadio value="N/A" label={formatMessage({ id: 'profil.contact.genre.na' })} />
            </FlexRow>
            <FormError />
          </FormControl>
          <FormControl name="nom">
            <FormLabel required>{formatMessage({ id: 'profil.contact.nom' })}</FormLabel>
            <FormInput />
            <FormError />
          </FormControl>
          <FormControl name="prenom">
            <FormLabel required>{formatMessage({ id: 'profil.contact.prenom' })}</FormLabel>
            <FormInput />
            <FormError />
          </FormControl>
          <FormControl>
            <FormLabel required>{formatMessage({ id: 'profil.contact.telephone.label' })}</FormLabel>
            {fields.map((item, index) => (
              <FlexRow key={item.id} gap="0.5em" direction="column">
                <FlexRow direction="row" gap="0.5em">
                  <FlexCell grow={1}>
                    <FormPhone name={`telephones.${index}`} placeholder={formatMessage({ id: 'profil.contact.telephone.placeholder' })} />
                  </FlexCell>
                  {index >= 1 ? (
                    <IconButton color="secondary" onClick={() => remove(index)} aria-label={formatMessage({ id: 'profil.contact.telephone.delete' })}>
                      <IconDelete className="img-wxl img-hxl" />
                    </IconButton>
                  ) : null}
                </FlexRow>
                <FormError name={`telephones.${index}`} />
              </FlexRow>
            ))}
            <IconButton
              className="container_Button_addPhone"
              aria-label={formatMessage({ id: 'profil.contact.telephone.placeholder' })}
              onClick={() => append('')}
            >
              <IconAddWhiteAndYellow className="img-w3xl img-h3xl" />
            </IconButton>
            <FormError name="telephones" />
          </FormControl>
          <FormControl name="email">
            <FormLabel required>{formatMessage({ id: 'profil.contact.email.label' })}</FormLabel>
            <FormInput type="email" placeholder={formatMessage({ id: 'profil.contact.email.placeholder' })} />
            <FormError />
          </FormControl>
          <p>{formatMessage({ id: 'profil.contact.asterisk' })}</p>
        </FormFieldset>

        <FormFieldset legend={formatMessage({ id: 'profil.contact.fieldset_additional_data' })}>
          <FormControl name="adresse">
            <FormLabel>{formatMessage({ id: 'profil.contact.adresse.label' })}</FormLabel>
            <FormSelectAsync
              placeholder={formatMessage({ id: 'profil.contact.adresse.placeholder' })}
              noOptionsMessage={() => formatMessage({ id: 'address_lookup.no_option_message' })}
              {...adresseSelectProps as any}
            />
            <FormError />
          </FormControl>
          <FormControl name="profession">
            <FormLabel>{formatMessage({ id: 'profil.contact.profession.label' })}</FormLabel>
            <FormInput placeholder={formatMessage({ id: 'profil.contact.profession.placeholder' })} />
            <FormError />
          </FormControl>
        </FormFieldset>
        <FormFieldset legend={formatMessage({ id: 'profil.contact.fieldset_other_data' })}>
          <FormControl name="dateDeNaissance">
            <FormLabel>{formatMessage({ id: 'profil.contact.dateDeNaissance.label' })}</FormLabel>
            <FormInput type="date" placeholder={formatMessage({ id: 'profil.contact.dateDeNaissance.placeholder' })} />
            <FormError />
          </FormControl>
          <FormControl name="situationFamiliale">
            <FormLabel>{formatMessage({ id: 'profil.contact.situationFamiliale.label' })}</FormLabel>
            <FormSelect options={situationFamilialeOptions} />
            <FormError />
          </FormControl>
          <FormControl name="commentaireClient">
            <FormLabel>{formatMessage({ id: 'profil.contact.commentaire.label' })}</FormLabel>
            <FormTextarea rows={5} />
            <FormError />
          </FormControl>
        </FormFieldset>
      </FlexRow>
      <FormFooter>
        <FlexRow alignItems="center">
          {success ? (
            <ModalAlerte src={ICON_CHECK} alt={formatMessage({ id: 'alt.icon.check' })} onClick={backTo ? () => navigate(backTo) : undefined}>
              {formatMessage({ id: 'profil.contact.modal.confirmation' })}
            </ModalAlerte>
          ) : null}
          <Button
            className={`button submit ${form.formState.isValid ? 'yellow' : 'not_Valid'}`}
            disabled={form.formState.isSubmitting}
            type="submit"
          >
            {formatMessage({ id: 'label.confirm' })}
          </Button>
        </FlexRow>
      </FormFooter>
      <FormError name="root" />
    </Form>
  );
}
