import './FormControl.scss';

import classNames from 'classnames';
import { useMemo } from 'react';

import useUuid from '../../hooks/useUuid';

import FormControlContext, { FormControlContextValue } from './FormControlContext';

export type FormControlProps = {
    children?: React.ReactNode;
    name?: string;
    className?: string;
}

function FormControl({ children, name, className }:FormControlProps): JSX.Element {
  const classes = classNames('FormControl', className);
  const inputId = useUuid();

  const contextValue = useMemo((): FormControlContextValue => ({
    inputId, name,
  }), [inputId, name]);

  return (
    <div className={classes}>
      <FormControlContext.Provider value={contextValue}>
        {children}
      </FormControlContext.Provider>
    </div>
  );
}

export default FormControl;
