import './header_filter.scss';
import { useIntl } from 'react-intl';

import Button from '../Button/Button';
import Container from '../Container/Container';

/**
Component for created a HEADER_FILTER.
@param {object} props - The props object for the component.
@param {function} props.onClick - The function to call when the button is clicked.
@example
return (
  <div className="header_container">
        <button onClick={props.onClick}>
          <img src={CHEVRON_LEFT} />
          Retour
        </button>
        <h2>Filtrer ma recherche</h2>
      </div>
 )
@returns {JSX.Element} A JSX element representing the button component.
*/

export default function HEADER_FILTER({ onClick }) {
  const { formatMessage } = useIntl();

  return (
    <div className="header_container">
      <h2>
        {formatMessage({ id: 'search.header.filtre.title' })}
      </h2>
      <Button
        className="button_close"
        icon={<i className="fa-solid fa-xmark" alt={formatMessage({ id: 'alt.button.close' })} />}
        onClick={onClick}
      />
    </div>
  );
}
