import { useCallback } from 'react';

import searchAdresse, { GeoGouvApiFeatureType, GeoGouvAdresse } from './geoGouvSearch';

export function formatGeoGouvOptionLabel(adresse: Partial<GeoGouvAdresse>, valueType: GeoGouvApiFeatureType) {
  if (valueType === 'municipality') {
    if (adresse.codePostal) {
      return `${adresse.ville} (${adresse.codePostal})`;
    }

    return adresse.ville;
  }

  return `${adresse.adressePostale}, ${adresse.codePostal} ${adresse.ville}`;
}

function useGeoGouvLoadOptions(valueType: GeoGouvApiFeatureType) {
  const loadOptions = useCallback(async (inputValue: string) => {
    if (inputValue.length >= 3) {
      try {
        const adresses = await searchAdresse(inputValue, valueType);

        return adresses.sort();
      } catch (error) {
        console.error('Erreur lors de la récupération des adresses:', error);

        return [];
      }
    }

    return [];
  }, [valueType]);

  const formatOptionLabel = useCallback((adresse: Partial<GeoGouvAdresse>) => formatGeoGouvOptionLabel(adresse, valueType), []);

  const getOptionValue = useCallback((adresse: Partial<GeoGouvAdresse>) => ([
    adresse.ville,
    adresse.codePostal,
    adresse.adressePostale,
  ].join('/')), []);

  return {
    loadOptions,
    formatOptionLabel,
    getOptionValue,
  };
}

export default useGeoGouvLoadOptions;
