import type { ReactNode } from 'react';

import Button from '../Button/Button';

import './PageHeader.scss';

export type PageHeaderProps = {
  title?: ReactNode;
  titleRotate?: ReactNode;
  url?: string;
  icon_house?: string;
  title_house?: string;
  classBEM?: string;
  bcg?: '_base' | '_param'| '_bien'| '_bienRectangle';
  project_Name?: string;
}

export default function PageHeader({
  title, titleRotate, bcg = '_base',
}: PageHeaderProps) {
  return (
    <div className="PageHeader">
      <div className={`PageHeader_header ${bcg}`}>
        {title ? <div className="title">{title}</div> : null}
        {titleRotate ? <div className="titleRotate">{titleRotate}</div> : null}
      </div>
    </div>
  );
}
