import './FormTextarea.scss';

import classNames from 'classnames';
import { useController } from 'react-hook-form';

import { ComponentProps } from '../../types/ComponentProps';
import useFormControl from '../FormControl/useFormControl';

export type FormTextareaProps = ComponentProps<'textarea', {
  name?: string;
  invalid?: boolean;
}>

function FormTextarea({
  className,
  name,
  disabled,
  invalid,
  ...props
}:FormTextareaProps): JSX.Element {
  const formControl = useFormControl();
  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const classes = classNames('FormTextarea', className, {
    'FormTextarea-invalid': invalid ?? fieldState.invalid,
    'FormTextarea-disabled': disabled,
  });

  return (
    <textarea
      id={formControl.inputId}
      className={classes}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      ref={field.ref}
      disabled={disabled}
      {...props}
    />
  );
}

export default FormTextarea;
