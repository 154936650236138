import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

import RedirectError from './RedirectError';

export default function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (error instanceof RedirectError) {
      navigate(error.to, { replace: true });
    }
  }, [error, navigate]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Désolé, une erreur inattendue s‘est produite.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
