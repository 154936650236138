import './GoogleLogin.scss';

import { useIntl } from 'react-intl';

import { signInWithGoogle } from '../../../lib/firebaseClient';
import IconGoogle from '../../Icon/IconGoogle';

export default function GoogleLogin() {
  const { formatMessage } = useIntl();

  const handleGoogleSignIn = async () => {
    signInWithGoogle();
  };

  return (
    <button type="button" onClick={handleGoogleSignIn} className="GoogleLogin">
      <IconGoogle className="GoogleLogin_icon" />
      <span className="GoogleLogin_text">{formatMessage({ id: 'accueil.sign_in_with_google' })}</span>
    </button>
  );
}
