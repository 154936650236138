import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

import singulierPluriel from '../../../JS/utils/singulier_Pluriel';
import './fixed_options.scss';
import Button from '../../Button/Button';

import { useIntl } from 'react-intl';

const orderOptions = (values) => values
  .filter((v) => v.isFixed)
  .concat(values.filter((v) => !v.isFixed));

/**
 * Composant FIXED_OPTIONS pour la sélection des options fixes.
 * @component
 * @param {Object} props - Les props du composant.
 * @param {Array} props.optionnels_Communes - Les options des communes.
 * @returns {JSX.Element} - Le JSX du composant FIXED_OPTIONS.
 */
export default function FIXED_OPTIONS(props) {
  const { formatMessage } = useIntl();
  const [values, setValues] = useState(orderOptions(props.optionnels_Communes));
  const [communesCount, setCommunesCount] = useState(
    props.optionnels_Communes.length,
  );

  useEffect(() => {
    setValues(orderOptions(props.optionnels_Communes));
    setCommunesCount(props.optionnels_Communes.length);
  }, [props.optionnels_Communes]);

  /**
   * Fonction de gestion du changement de sélection des options.
   * @param {Object} newValues - La nouvelle valeur sélectionnée.
   * @param {Object} actionMeta - Les métadonnées de l'action effectuée.
   */
  const onChange = (newValues, actionMeta) => {
    switch (actionMeta.action) {
    case 'pop-value':
    case 'clear':
      if (actionMeta.removedValue && actionMeta.removedValue.isFixed) {
        return;
      }
      break;
    default:
    }
    setValues(orderOptions(newValues));
    setCommunesCount(newValues.length);
  };

  const handleValidation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedValues = values.filter((v) => !v.isFixed);
    props.onSouhaitCommunesChange(selectedValues);
  }, [props, values]);

  return (
    <div spacing="small" className="container_More_Communes">
      <p className="label_nb_Communes">
        {formatMessage({ id: 'form.range_slider.matches' }, { count: communesCount })}
      </p>
      <div className="container_Communes_Proximity">
        <div className="communes_Proximity">
          <Select
            isMulti
            value={values}
            name="optionnels_Communes"
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable
            onChange={onChange}
            options={props.optionnels_Communes}
          />
          <Button className="standard" onClick={handleValidation}>
            {formatMessage({ id: 'form.range_slider.validate' })}
          </Button>
        </div>
      </div>
    </div>
  );
}
FIXED_OPTIONS.propTypes = {
  optionnels_Communes: PropTypes.array,
};
