export const BIEN_TYPES = [
  'Appartement',
  'Appartement Duplex',
  'Appartement Triplex',
  'Autres',
  'Bâtiment',
  'Cave',
  'Château',
  'Ferme',
  'Fond de commerce',
  'Garages',
  'Grange',
  'Immeuble',
  'Local commercial',
  'Local industriel',
  'Loft',
  'Maison',
  'Maison de village',
  'Parking',
  'Plateau',
  'Terrain',
  'Villa',
] as const;

export type BienType = typeof BIEN_TYPES[number]

export const BIEN_STATUSES = [
  'Copropriété',
  'Individuel',
  'Lotissement',
] as const;

export type BienStatut = typeof BIEN_STATUSES[number]

export const BIEN_VARIANT = [
  'achatvente',
  'location',
] as const;

export type BienVariant = typeof BIEN_VARIANT[number]

export const BIEN_POUR_FILTRE = [
  {
    label: 'Appartement',
    value: [
      'Appartement',
      'Appartement Duplex',
      'Appartement Triplex',
      'Loft',
      'Plateau'],
  },

  {
    label: 'Maison',
    value: [
      'Loft',
      'Maison',
      'Maison de village',
      'Grange',
      'Villa',
      'Ferme',
      'Château',
    ],
  },

  {
    label: 'Terrain',
    value: [
      'Terrain',
    ],
  },
  {
    label: 'Commerce / Industrie',
    value: [
      'Local commercial',
      'Local industriel',
      'Fond de commerce',
    ],
  },
  {
    label: 'Autres',
    value: [
      'Autres',
      'Parking',
      'Bâtiment',
      'Ferme',
      'Château',
      'Immeuble',
      'Cave',
      'Garages',
      'Grange',
      'Loft',
      'Plateau',
    ],
  },
] as const;

export type BienPourFiltreStatut = typeof BIEN_POUR_FILTRE[number]

export const CLOSE_STATUSES = [
  "N'est plus intéressé",
  'A acheté ailleurs',
] as const;

export type closeStatut = typeof CLOSE_STATUSES[number]
