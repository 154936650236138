import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconFooter.scss';

function IconHome({
  className, classColor = 'footer',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_accueil', className)} id="icone_accueil" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.545 28.441">
        <title id="icone_accueil-Accueil">Icon Accueil</title>
        <g id="Icon_feather-home" data-name="Icon feather-home">
          <path id="Tracé_73" data-name="Tracé 73" className={`${classColor}-cls-1`} d="M4.427,12.8C7.639,6.5,17.272,3,17.272,3s9.634,3.5,12.845,9.8,0,15.4,0,15.4S28.839,31,27.263,31H7.282c-1.576,0-2.854-2.8-2.854-2.8S1.216,19.1,4.427,12.8Z" transform="translate(-3 -3)" />
          <path id="Tracé_74" data-name="Tracé 74" className={`${classColor}-cls-2`} d="M13.451,30.185s-2.141-9.263,0-12.351a5.116,5.116,0,0,1,8.563,0c2.141,3.088,0,12.351,0,12.351" transform="translate(-3.461 -2.189)" />
        </g>
      </svg>
    </div>
  );
}

export default IconHome;
