import { Navigate, Outlet } from 'react-router-dom';

import { usePermission } from '../../provider/PermissionProvider';
import { makeRoutePath } from '../../store/route/route.api';

export default function AuthenticatedOutlet() {
  const { hasPermission } = usePermission();
  const isAuthenticated = hasPermission('authenticated');

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to={makeRoutePath('auth.sign_in', { to: window.location.pathname === '/' ? undefined : window.location.pathname })} replace />;
}
