import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconInfoRound.scss';

function IconInfoRound({
  className, classColor = 'infoRound',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_information', className)} id="Bouton_information" viewBox="0 0 29.25 29.25">
        <title id="icon_information">Icon demande information</title>
        <path id="Icon_ionic-ios-information-circle" data-name="Icon ionic-ios-information-circle" className={`${classColor}-cls-1`} d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375ZM19.336,24.75H16.65V14.618h2.686ZM17.993,13.514a1.4,1.4,0,1,1,1.455-1.406A1.409,1.409,0,0,1,17.993,13.514Z" transform="translate(-3.375 -3.375)" />
      </svg>
    </div>
  );
}

export default IconInfoRound;
