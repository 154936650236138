import { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import useShowListAchatStore from '../../Pages/ShowAchatLayout/useShowListAchatStore';
import useFormatError from '../../hooks/useFormatError';
import useQueryParams from '../../hooks/useQueryParams';
import { AchatModel, Variant } from '../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button';
import FlexRow from '../FlexRow/FlexRow';
import Form from '../Form/Form';
import FormControl from '../FormControl/FormControl';
import FormError from '../FormError/FormError';
import FormFooter from '../FormFooter/FormFooter';
import FormLabel from '../FormLabel/FormLabel';
import FormSelect from '../FormSelect/FormSelect';
import IconDelete from '../Icon/IconDelete';
import IconeLike from '../Icon/IconeLike';
import { SrOnly } from '../SrOnly/SrOnly';

import Modal from './Modal';

export type AchatLocSelectionModalProps = {
    open: boolean;
    action?: string;
    title?: string;
    placeholder?: string;
    onClose(): void;
    onSubmit(achat: AchatModel): void;
}

export default function AchatLocSelectionModal({
  open, action, title, placeholder, onClose, onSubmit,
}:AchatLocSelectionModalProps): any {
  const { formatMessage, formatDate } = useIntl();
  const queryParams = useQueryParams();
  const variant = queryParams.get('variant');

  const titleAction = useCallback(() => {
    switch (action) {
    case 'favori':
      return formatMessage({ id: 'modal.achat_selection_favoris.title' });

    case 'ignoree':
      return formatMessage({ id: 'modal.achat_selection_suppression.title' });

    default:
      return 'erreur titleAction';
    }
  }, [action]);

  const textLabelforAction = useCallback(() => {
    switch (variant) {
    case Variant.location:
      return formatMessage({ id: 'modal.achat_selection.location_label' });

    default:
      return formatMessage({ id: 'modal.achat_selection.achat_label' });
    }
  }, [formatMessage, variant]);
  const textLabelWithoutProjet = useCallback(() => {
    switch (variant) {
    case Variant.location:
      return formatMessage({ id: 'modal.achat_selection.text_without_location' });
    default:
      return formatMessage({ id: 'modal.achat_selection.text_without_achat' });
    }
  }, [formatMessage, variant]);

  const lienWithoutProjet = useCallback(() => {
    switch (variant) {
    case Variant.location:
      return makeRoutePath('achat.create', { variant });

    default:
      return makeRoutePath('achat.create');
    }
  }, [variant]);

  const formatError = useFormatError();
  const { listAchats, isLoading: listAchatsIsLoading, isError: listAchatsIsError } = useShowListAchatStore();

  const achatOptions = useMemo(() => listAchats?.items?.map((achat) => ({
    value: achat?.id,
    label: achat?.titre ?? formatMessage({ id: 'achat.title_with_date' }, { date: formatDate(achat.createdAt, { dateStyle: 'short' }) }),
  }
  )), [formatDate, formatMessage, listAchats?.items]);

  const form = useForm({
    mode: 'onTouched',
  });

  const handleSubmit = useCallback((values: any) => {
    const achat = listAchats?.items?.find((achat) => achat.id === values.achatId);
    if (achat) {
      onSubmit(achat);
    }
  }, [listAchats?.items, onSubmit]);

  const modalRoot = document.getElementById('modal-root');

  const getIcon = (action: string | undefined) => {
    if (action === 'favori') {
      return <IconeLike favori className="img-wxl img-hxl" />;
    }
    if (action === 'ignoree') {
      return <IconDelete className="img-wlg img-hlg" />;
    }

    return null;
  };

  return (
    open === true && modalRoot ? createPortal(
      <Modal
        open={open}
        icon={getIcon(action)}
        title={listAchats?.items.length && listAchats?.items[0].id !== 'nouveau' ? titleAction() : formatMessage({ id: 'modal.achat.no_option_message' })}
        onClose={onClose}
      >
        <Form onSubmit={handleSubmit} {...form}>
          {listAchats?.items.length && listAchats?.items[0].id !== 'nouveau' ? (
            <>
              <FormControl name="achatId">
                <SrOnly>
                  <FormLabel>
                    {textLabelforAction()}
                  </FormLabel>
                </SrOnly>
                <FormSelect placeholder={formatMessage({ id: 'modal.achat.placeholder' })} options={achatOptions} isSearchable={false} menuPlacement="top" />
                <FormError />
              </FormControl>
              <FormFooter>
                <FlexRow alignItems="center">
                  <Button type="submit" className="button yellow">
                    {formatMessage({ id: 'label.continue' })}
                  </Button>
                </FlexRow>
              </FormFooter>
            </>
          ) : (
            <>
              {textLabelWithoutProjet()}
              <FormFooter>
                <FlexRow alignItems="center">
                  <Button type="submit" className="button yellow" to={lienWithoutProjet()}>
                    {formatMessage({ id: 'label.continue' })}
                  </Button>
                </FlexRow>
              </FormFooter>
            </>
          )}
        </Form>
      </Modal>,
      modalRoot,
    ) : ''
  );
}
