import classnames from 'classnames';
import {
  ReactNode, useCallback, useEffect, useState,
} from 'react';

import Button from '../../Button/Button';

import './ModalGrid.scss';
import { useIntl } from 'react-intl';

import FlexRow from '../../FlexRow/FlexRow';

export type ModalGridProps = {
  open?: boolean;
  className:string;
  onClose?(): void;
  onSubmit?(): void;
  children: ReactNode;
  title: ReactNode;
  underTitle: string;
  src1: ReactNode;
  alt1?: string;
  src2?: ReactNode;
  alt2?: string;
  url: string;
}

export default function ModalGrid({
  open = true,
  className,
  onClose,
  onSubmit,
  children,
  title,
  underTitle,
  src1,
  src2,
  url,
}: ModalGridProps) {
  const [show, setShow] = useState(open);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleHide = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      setShow((previousShow) => !previousShow);
    }
  }, [onClose]);

  return (
    <div className={classnames('container_Modal_Grid', { open: show, close: !show })}>
      <div className="modal-content">
        <div className="buttons_Modal">
          {title ? <p className="title">{title}</p> : ''}
          <Button
            className="close-button"
            ariaLabel={formatMessage({ id: 'label.close' })}
            onClick={handleHide}
          >
            <i
              className="fa-solid fa-xmark"
            />
          </Button>
        </div>
        <FlexRow gap="1rem">
          {underTitle ? <p className="underTitle">{underTitle}</p> : ''}
          <div className="children">{children}</div>
        </FlexRow>
      </div>
    </div>
  );
}
