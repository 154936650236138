import './ButtonV2.scss';

import classnames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import stylenames from '../../lib/stylenames';

export type ButtonProps = {
  icon?: ReactNode;
  to?: string;
  href?: string;
  className?: string;
  type?: 'submit'|'button';
  onClick?: MouseEventHandler;
  children?: ReactNode;
  'aria-label'?: string;
  disabled?: boolean;
  fontSize?: 'xs'|'sm'|'md'|'lg'|'xl'|'xxl'|'3xl';
}

export default function ButtonV2({
  to,
  href,
  icon,
  children,
  className,
  'aria-label': ariaLabel,
  fontSize,
  onClick,
  type,
  disabled,
}: ButtonProps) {
  const classes = classnames('ButtonV2', className, {
    'ButtonV2-disabled': disabled,
  });

  const styles = stylenames({}, {
    '--ButtonV2-fontSize': fontSize ? `var(--fs-${fontSize})` : undefined,
  });

  const iconElement = icon ? <div className="ButtonV2_icon">{icon}</div> : null;

  if (to) {
    return (
      <Link
        to={to}
        className={classes}
        style={styles}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {iconElement}
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={classes}
        style={styles}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {iconElement}
        {children}
      </a>
    );
  }

  return (
    <button
      className={classes}
      style={styles}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {iconElement}
      {children}
    </button>
  );
}
