import React from 'react';
import '../../Pages/Mes_Documents/mes_documents.scss';
import './header_title.scss';

import ToggleBig from '../ToggleBig/ToggleBig';

interface HeaderTitleProps{
  title:string;
  activeLink: string;
  toggleLeft: {
    key: string;
    name: string;
    value: string;
  };
  toggleRight: {
    key: string;
    name: string;
    value: string;
  };
  handleLinkClick: (link:string)=> void;
}

export default function HeaderTitle({
  title, activeLink, toggleLeft, toggleRight, handleLinkClick,
}:HeaderTitleProps) {
  return (
    <div className="container_Header_Title">
      <h1 className="titre">{title}</h1>
      <ToggleBig activeLink={activeLink} toggleLeft={toggleLeft} toggleRight={toggleRight} handleLinkClick={handleLinkClick} />
    </div>
  );
}
