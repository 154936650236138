import './CreateProjetPage.scss';

import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import IconAddWhiteAndYellow from '../../Component/Icon/IconAddWhiteAndYellow';
import IconHouseWithIcon from '../../Component/Icon/IconHouseWithIcon';
import TitleGlow from '../../Component/Title_Glow/title_glow';
import useQueryParams from '../../hooks/useQueryParams';
import { makeRoutePath } from '../../store/route/route.api';
import useShowBienStore from '../ShowBienLayout/useShowBienStore';

export default function CreateProjetPage() {
  const { formatMessage } = useIntl();

  const queryParams = useQueryParams();
  const bienId = queryParams.get('bienId');
  const { bien } = useShowBienStore();
  const titleOfProjet = bien?.titre || bien?.type || 'Bien';

  return (
    <>
      <div className="container_Home_Modal">
        {bienId && (
          <TitleGlow
            icon={<IconHouseWithIcon className="img-w4xl img-h4xl" />}
            title={titleOfProjet}
            underTitle={bien?.adressePostale}
          />
        )}
      </div>
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        transparent
      />
      <FlexRow
        width="initial"
        direction="column"
        gap="4"
        grow="1"
        alignItems="center"
        justifyContent="start"
      >
        <div className="container_New_Projet">
          <div className="titre">
            <h1>
              <FormattedMessage
                id="new_projet.title"
                values={{
                  title: (children) => <p className="p_Second">{children}</p>,
                  undertitle: (children) => <p className="p_Second">{children}</p>,
                  subtitle: (children) => <p className="p_First">{children}</p>,
                }}
              />
            </h1>
          </div>
          <div className="buttons_New_Projets">
            { bienId
              ? (
                <>
                  <Button
                    className="button standard projet big"
                    icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />}
                    to={makeRoutePath('vente.create')}
                  >
                    <p className="fs-xl">
                      {formatMessage({ id: 'bien.button.create projet travaux' })}
                    </p>
                  </Button>
                  <Button
                    className="button standard projet big"
                    icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />}
                    to={makeRoutePath('achat.create')}
                  >
                    <p className="fs-xl">{formatMessage({ id: 'bien.button.create projet vendre' })}</p>
                  </Button>
                  <Button
                    className="button standard projet big"
                    icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />}
                    to={makeRoutePath('location.choice')}
                  >
                    <p className="fs-xl">{formatMessage({ id: 'bien.button.create projet louer' })}</p>
                  </Button>
                </>
              )
              : (
                <>
                  <Button
                    className="button standard projet big"
                    icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />}
                    to={makeRoutePath('vente.create')}
                  >
                    <p className="fs-xl">
                      {formatMessage({ id: 'vente.button.create projet vente' })}
                    </p>
                  </Button>
                  <Button
                    className="button standard projet big"
                    icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />}
                    to={makeRoutePath('achat.create')}
                  >
                    <p className="fs-xl">{formatMessage({ id: 'achat.button.create projet achat' })}</p>
                  </Button>
                  <Button
                    className="button standard projet big"
                    icon={<IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />}
                    to={makeRoutePath('location.choice')}
                  >
                    <p className="fs-xl">{formatMessage({ id: 'location.button.create projet location' })}</p>
                  </Button>
                </>
              )}
          </div>
        </div>
      </FlexRow>
    </>

  );
}
