import React, { useState, useEffect } from 'react';
import './mes_documents_edit.scss';

// Mise en place des Buttons
import { useIntl } from 'react-intl';

import ButtonEditFilesFolders from '../../Component/Button/button_Edit_Files_And_Folders/button_Edit_Files_And_Folders.jsx';
import ButtonFolderFiles from '../../Component/Button/button_folder_file.jsx';

// Mise en place Modal
import MODAL_GLOW from '../../Component/Modal/ModalGlow/ModalGlow';
import { makeRoutePath } from '../../store/route/route.api';

export default function MES_DOCUMENTS_EDIT(props) {
  const { formatMessage } = useIntl();
  const Dossiers_Perso_BDD = [
    {
      id: 1,
      title: 'Edit',
      categorie: 'isEnabled',
      icon: <i className="fa-solid fa-folder-plus" />,
      checked: false,
      url: '#',
    },
    {
      id: 2,
      title: "Justificatif d'identité",
      categorie: 'isDefault',
      icon: <i className="fa-solid fa-folder" />,
      checked: null,
      url: '#',
    },
    {
      id: 3,
      title: 'Contrat de mariage',
      categorie: 'isDefault',
      icon: <i className="fa-solid fa-folder" />,
      checked: null,
      url: '#',
    },
    {
      id: 4,
      title: 'Nouveau dossier',
      categorie: 'isEnabled',
      icon: <i className="fa-solid fa-folder-plus" />,
      checked: true,
      url: '#',
    },
    {
      id: 5,
      title: 'Justificatif de domicile',
      categorie: 'isDefault',
      icon: <i className="fa-solid fa-folder" />,
      checked: null,
      url: '#',
    },
  ];

  const Files_Perso_BDD = [
    {
      id: 6,
      title: 'Photo_du_Chat',
      categorie: 'isEnabled',
      icon: <i className="fa-solid fa-file" />,
      checked: false,
      url: '#',
    },
    {
      id: 7,
      title: 'Photo_du_Chien',
      categorie: 'isEnabled',
      icon: <i className="fa-solid fa-file" />,
      checked: false,
      url: '#',
    },
  ];

  const [dossiers, setDossiers] = useState(Dossiers_Perso_BDD);
  const [fichiers, setFichiers] = useState(Files_Perso_BDD);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const clickedDossiers = dossiers.filter((dossier) => dossier.checked);
    const clickedFichiers = fichiers.filter((fichier) => fichier.checked);
  }, [dossiers, fichiers]);

  const handleCheckedChange = (itemType, itemId) => {
    if (itemType === 'dossier') {
      const updatedDossiers = dossiers.map((dossier) => {
        const updatedDossier = dossier.id === itemId
          ? { ...dossier, checked: !dossier.checked }
          : dossier;

        return updatedDossier;
      });
      setDossiers(updatedDossiers);
    } else if (itemType === 'fichier') {
      const updatedFichiers = fichiers.map((fichier) => {
        const updatedFichier = fichier.id === itemId
          ? { ...fichier, checked: !fichier.checked }
          : fichier;

        return updatedFichier;
      });
      setFichiers(updatedFichiers);
    }
  };
  const handleModalChange = () => {
    if (modal === true) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  return (
    <>
      <div className="container_Documents_Edit bk_doc">
        <div className="buttons_Folders_Files_Edit">
          {dossiers.map((dossier) => (
            <div
              className="container_checkbox_folder_file_card_Edit"
              key={dossier.id}
            >
              {dossier.categorie === 'isDefault' ? (
                <ButtonFolderFiles
                  categorie={dossier.categorie}
                  icon={dossier.icon}
                  onClick={() => handleCheckedChange('dossier', dossier.id)}
                >
                  <span>{dossier.title}</span>
                </ButtonFolderFiles>
              ) : (
                <>
                  <label
                    htmlFor={`dossier_${dossier.id}`}
                    className="styledButton"
                  >
                    {dossier.icon}
                    <span>{dossier.title}</span>
                  </label>
                  <input
                    type="checkbox"
                    id={`dossier_${dossier.id}`}
                    name={dossier.title}
                    checked={dossier.checked}
                    onChange={() => handleCheckedChange('dossier', dossier.id)}
                    className="hiddenCheckbox"
                  />
                </>
              )}
            </div>
          ))}
          {fichiers.map((fichier) => (
            <div
              className="container_checkbox_folder_file_card_Edit"
              key={fichier.id}
            >
              {fichier.categorie === 'isDefault' ? (
                <ButtonFolderFiles
                  categorie={fichier.categorie}
                  icon={fichier.icon}
                  onClick={() => handleCheckedChange('fichier', fichier.id)}
                >
                  <span>{fichier.title}</span>
                </ButtonFolderFiles>
              ) : (
                <>
                  <label
                    htmlFor={`fichier_${fichier.id}`}
                    className="styledButton"
                  >
                    {fichier.icon}
                    <span>{fichier.title}</span>
                  </label>
                  <input
                    type="checkbox"
                    id={`fichier_${fichier.id}`}
                    name={fichier.title}
                    checked={fichier.checked}
                    onChange={() => handleCheckedChange('fichier', fichier.id)}
                    className="hiddenCheckbox"
                  />
                </>
              )}
            </div>
          ))}
        </div>
        <ButtonEditFilesFolders onClick={handleModalChange} />
      </div>
      {modal === true && (
        <MODAL_GLOW
          open
          src1={<i className="fa-solid fa-xmark" />}
          src2={props.src2}
          title={formatMessage({ id: 'label.suppression.title' })}
          url="https://ideeri-landing.webflow.io/"
        >
          {formatMessage({ id: 'document.modal.suppression' })}
        </MODAL_GLOW>
      )}
    </>
  );
}
