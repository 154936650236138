import { useForm } from 'react-hook-form';

// /*Import des CSS*/
import './bien_a_estimer.scss';
import '../../Component/Button/button.scss';
import { useState } from 'react';

import INPUT from '../../Component/Form/Input/input';
import SELECT_DROPDOWN from '../../Component/Form/Select_Dropdown/select_dropdown';

const wait = function (duration = 1000) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, duration);
  });
};

export default function BIEN_A_ESTIMER() {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onTouched',
  });
  const {
    isSubmitting, errors, isSubmitSuccessful, isValid,
  } = formState;
  const [optionsBiensValider, setOptionsBiensValider] = useState(null);
  const [optionsStatusValider, setOptionsStatusValider] = useState(null);

  const handleOptionsBiens = (selectedValues) => {
    setOptionsBiensValider(selectedValues);
  };
  const handleOptionsStatus = (selectedValues) => {
    setOptionsStatusValider(selectedValues);
  };

  const optionsBiens = [
    { label: 'Terrain', value: 'terrain' },
    { label: 'Maison', value: 'maison' },
    { label: 'Immeuble', value: 'immeuble' },
    { label: 'Commerce', value: 'commerce' },
  ];
  const optionsStatus = [
    { label: 'Copropriété', value: 'copropriété' },
    { label: 'Individuel', value: 'individuel' },
    { label: 'Lotissement', value: 'lotissement' },
  ];

  return (
    <form className="form_estimation" onSubmit={handleSubmit}>
      <h2 className="titre_form_estimation">Nouveau bien à estimer</h2>
      <div className="container_secondaire_form">
        <div className="label_group">
          <label htmlFor="typeProperty" aria-label="typeProperty">
            Type de bien
            {' '}
            <spans className="required">*</spans>
          </label>
          <SELECT_DROPDOWN
            id="typeProperty"
            register={register}
            errors={errors}
            options={optionsBiens}
            onChange={handleOptionsBiens}
            register_name="typeProperty"
            required_value
            required_message="Le type de bien est requis"
            isSearchable
          />
        </div>
        <div className="label_group">
          <label htmlFor="adresse_Postal" aria-label="adresse_Postal">
            Adresse
            {' '}
            <spans className="required">*</spans>
          </label>
          <INPUT
            type="text"
            register={register}
            errors={errors}
            options={optionsBiens}
            register_name="adresse_Postal"
            required_value
            required_message="L'adresse postal complet est requis"
          />
        </div>

        <div className="label_group">
          <label htmlFor="statut" aria-label="statut">
            Statut
          </label>
          <SELECT_DROPDOWN
            register={register}
            errors={errors}
            options={optionsStatus}
            onChange={handleOptionsStatus}
            register_name="statut"
          />
        </div>
      </div>

      <div className="container_submit">
        {isSubmitSuccessful && (
          <h2 className="alerte"> Enregistrement réussi </h2>
        )}
        <button
          className={`button submit ${isValid ? 'yellow' : 'not_Valid'}`}
          disabled={isSubmitting}
          type="submit"
        >
          Continuer
        </button>
      </div>
    </form>
  );
}
