import React from 'react';
import './documents_projets.scss';

// Mise en place des Buttons
import ButtonProjet from '../../Component/Button/ButtonProjet/button_projet_card';
import ICON_HOUSE from '../../Component/Icon/IconHouse';

export default function ACCUEIL_LOGIN({
  titleGreen,
  titleYellow,
}) {
  const arrayProjet = [
    {
      className: 'button projet_card',
      title_card: 'Achat',
      subtitle_card: 'Maison du Bonheur de pépé et mémé',
      maison: <ICON_HOUSE
        className="icon_house_HomeAchat img-w4xl img-h4xl"
        classColor="colorAchat"
      />,
      url: '',
    },
    {
      className: 'button projet_card',
      title_card: 'Location',
      subtitle_card: 'Investissement',
      maison: <ICON_HOUSE
        className="icon_house_HomeAchat img-w4xl img-h4xl"
        classColor="colorLocation"
      />,
      url: '',
    },
    {
      className: 'button projet_card',
      title_card: 'Vente',
      subtitle_card: 'Maison de pépé',
      maison: <ICON_HOUSE
        className="icon_house_HomeAchat img-w4xl img-h4xl"
        classColor="colorVente"
      />,
      url: '',
    },
    {
      className: 'button projet_card',
      title_card: 'Location',
      subtitle_card: 'La Casa',
      maison: <ICON_HOUSE
        className="icon_house_HomeAchat img-w4xl img-h4xl"
        classColor="colorAchat"
      />,
    },
  ];

  const Cards = arrayProjet.map((element) => (
    <ButtonProjet
      key={element.url}
      className={element.className}
      title_card={element.title_card}
      subtitle_card={element.subtitle_card}
      maison={element.maison}
      url={element.url}
    />
  ));

  return (
    <div className="container_Documents">
      <div className="titre">
        <h2>
          <p className="p_Second">{titleGreen}</p>
          <p className="p_First">{titleYellow}</p>
        </h2>
      </div>
      <div className="buttons_Projets">{Cards}</div>
    </div>
  );
}
