import classNames from 'classnames';

import { IconProps } from './Icon.types';

function IconCheck({ className }: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('Icon Icon-close', className)} xmlns="http://www.w3.org/2000/svg" width="36" height="36" data-name="icone check">
        <circle cx="17" cy="17" r="17" data-name="Ellipse 58" style={{ fill: '#fff' }} transform="translate(1 1)" />
        <path d="M36.563 18.563a18 18 0 1 1-18-18 18 18 0 0 1 18 18Zm-20.083 9.53 13.355-13.354a1.161 1.161 0 0 0 0-1.642l-1.642-1.642a1.161 1.161 0 0 0-1.642 0l-10.892 10.89-5.085-5.085a1.161 1.161 0 0 0-1.642 0L7.29 18.9a1.161 1.161 0 0 0 0 1.642l7.548 7.548a1.161 1.161 0 0 0 1.642.003Z" data-name="Icon awesome-check-circle" style={{ fill: '#aec0b7' }} transform="translate(-.563 -.563)" />
      </svg>
    </div>

  );
}

export default IconCheck;
