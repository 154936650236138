import React, { useState } from 'react';

import Button from '../Button';
import './button_Edit_Files_And_Folders.scss';

// Mise en place des icons
import IconDelete from '../../Icon/IconDelete';

export default function ButtonEditFilesFolders(props) {
  return (
    <div className="container_edit_del_file_folder">
      <Button icon={<IconDelete className="img-wlg img-hlg" />} className="options_edit" onClick={props.onClick}>
        Supprimer
      </Button>
    </div>
  );
}
