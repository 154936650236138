import './select_dropdown.scss';

import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <i className="fa-solid fa-chevron-up" />
      ) : (
        <i className="fa-solid fa-chevron-down" />
      )}
    </components.DropdownIndicator>
  );
}

function SelectDropdown({
  id,
  placeholder,
  register,
  register_name,
  name,
  errors,
  options,
  isCreatable,
  isSearchable,
  value,
  onChange,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(value ? options.find((option) => option.value === value) : null);
  }, [value, options]);

  const handleChange = useCallback((newOption) => {
    onChange(newOption.value);
  }, [onChange]);

  return (
    <Select
      id={id}
      name={name ?? register_name}
      errors={errors}
      className="basic-select"
      classNamePrefix="select"
      isCreatable={isCreatable}
      value={selectedOption}
      options={options}
      isSearchable={!!isSearchable}
      onChange={handleChange}
      placeholder={placeholder}
      components={{ DropdownIndicator }} // Utilise la fonction de rendu personnalisée pour le composant DropdownIndicators
    />
  );
}

export default SelectDropdown;
