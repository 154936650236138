import { FirebaseError } from 'firebase/app';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormControl from '../../Component/FormControl/FormControl';
import FormError from '../../Component/FormError/FormError';
import FormFooter from '../../Component/FormFooter/FormFooter';
import FormHelp from '../../Component/FormHelp/FormHelp';
import FormInput from '../../Component/FormInput/FormInput';
import IconAnonyme from '../../Component/Icon/IconAnnonyme';
import Title from '../../Component/Title/Title';
import useQueryParams from '../../hooks/useQueryParams';
import useZodForm from '../../hooks/useZodForm';
import { resetPassword } from '../../lib/firebaseClient';
import { makeRoutePath } from '../../store/route/route.api';

import AuthLayout from './AuthLayout';

export default function MotdepassePage() {
  const { formatMessage } = useIntl();

  const query = useQueryParams();
  const to = query.get('to') ?? undefined;

  const [firebaseError, setFirebaseError] = useState<FirebaseError | null>(null);
  const [firebaseSuccess, setFirebaseSuccess] = useState(false);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      email: z.string().email(),
    }), []),
    initialValues: useMemo(() => ({
      email: '',
    }), []),
    onSubmit: async (values) => {
      setFirebaseError(null);
      setFirebaseSuccess(false);

      try {
        await resetPassword(values.email);

        setFirebaseSuccess(true);
      } catch (error) {
        if (error instanceof FirebaseError) {
          setFirebaseError(error);
        } else throw error;
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  return (
    <AuthLayout title={<Title size={1} color="first">{formatMessage({ id: 'accueil.title' })}</Title>}>
      <Container size="sm">
        <Form onSubmit={onSubmit} {...form}>
          <FlexRow gap="1rem" alignItems="center">
            <IconAnonyme className="size-img-card-profil icon-position-profil" />
            <FormControl name="email">
              <FormInput variant="outline" type="email" placeholder={formatMessage({ id: 'accueil.email.label' })} />
              <FormError />
            </FormControl>
            {firebaseError ? (
              <FormError>
                {formatMessage({ id: `error.firebase.${firebaseError.code}` })}
              </FormError>
            ) : null}
            {firebaseSuccess ? (
              <FormHelp>
                {formatMessage({ id: 'accueil.password_forgotten.success' })}
              </FormHelp>
            ) : null}
          </FlexRow>
          <FormFooter>
            <FlexRow alignItems="center">
              <Button
                className="button yellow"
                disabled={form.formState.isSubmitting}
                type="submit"
              >
                {formatMessage({ id: 'label.submit' })}
              </Button>
            </FlexRow>
          </FormFooter>
        </Form>
        <FlexRow alignItems="center" gap="1rem" padding="1rem">
          <Button className="button small-bold" to={makeRoutePath('auth.sign_in', { to })}>
            {formatMessage({ id: 'accueil.sign_in' })}
          </Button>
        </FlexRow>
      </Container>
    </AuthLayout>
  );
}
