import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import CARROUSEL_IMMO from '../../Component/Carrousel/carrousel';
import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import IconGallery from '../../Component/Icon/IconGallery';
import ListTab from '../../Component/ListTab/ListTab';
import useQueryParams from '../../hooks/useQueryParams';
import { makeRoutePath } from '../../store/route/route.api';
import useShowBienStore from '../ShowBienLayout/useShowBienStore';

import './GaleriePage.scss';

export default function Galerie_Photos() {
  const { bien } = useShowBienStore();
  const { bienId } = useParams();
  const params = useQueryParams();
  const roomId = params.get('roomId');
  const backTo = params.get('backTo');
  // const bien = bienMocked;

  const { formatMessage } = useIntl();

  const roomListTitle = useMemo(() => {
    const roomNames = [];
    const roomCounts = {};
    const list = [];

    if (bien && bien.pieces) {
      bien?.pieces.forEach((room) => {
        const name = room?.nom;
        const count = roomNames.filter((n) => n.startsWith(name)).length;
        const number = count > 0 ? count + 1 : '';
        roomCounts[name] = number;
        roomNames.push(number ? `${name} ${number}` : name);
        if (room.photos && room.photos.length > 0) {
          list.push({
            key: number ? `${name} ${number} ${room.id}` : `${name} ${room.id}`,
            id: room.id,
            title: number ? `${name} ${number}` : name,
            url: backTo ? makeRoutePath('bien.show.photos', { bienId, roomId: room.id, backTo }) : makeRoutePath('bien.show.photos', { bienId, roomId: room.id }),
          });
        }
      });
    }

    return list;
  }, [backTo, bien, bienId]);

  const findObjectByKey = (array, key, value) => array.find((element) => element[key] === value);

  const pieceActuel = bien?.pieces && findObjectByKey(bien?.pieces, 'id', roomId);

  const hasPhoto = (room) => room?.photos?.length > 0;
  const pieceActuelHasPhoto = hasPhoto(pieceActuel);
  const sectionGeneralHasPhoto = hasPhoto(bien);

  const pieceActuelPhotos = pieceActuel?.photos?.map((photo) => photo.path);
  const sectionGeneralPhotos = bien?.photos?.map((photo) => photo.path);

  const slidesImages = pieceActuel ? pieceActuelPhotos : sectionGeneralPhotos;

  return (
    <>
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        to={backTo || makeRoutePath('bien.show', { bienId })}
        transparent
      />
      {
        bien?.pieces
          ? (
            <FlexRow
              className="container_Galerie"
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <div className="search_toggle_container">
                <ListTab
                  defaultTitle={sectionGeneralHasPhoto ? 'Généralité' : ''}
                  routePathDefaultTitle={sectionGeneralHasPhoto ? makeRoutePath('bien.show.photos', { bienId }) : ''}
                  listItems={roomListTitle}
                  idParam="roomId"
                />
              </div>
              {pieceActuelHasPhoto || sectionGeneralHasPhoto
                ? <CARROUSEL_IMMO slides={slidesImages} /> : 'Aucune Photo trouvée'}

              <FlexRow
                direction="row"
                gap="2rem"
                alignItems="center"
                justifyContent="space-evenly"
              >
                {/* EN ATTENTE DE l API */}
                {/* <Button
            className="button submit yellow"
            type="file"
            htmlFor="takePicture"
            action="/takePicture"
            accept="image/*"
            capture="environment"
            name="photo"
            icon={<IconAPF className="img-wxxl img-hxxl" />}
          >
            {formatMessage({ id: 'label.takePicture' })}
          </Button>
          <Button
            className="button submit yellow"
            type="file"
            htmlFor="upload"
            action="/upload"
            accept="image/*"
            capture="environment"
            name="galerie"
            icon={<IconImportExport className="img-wxl img-hxl" classColor="import" />}
          >
            {formatMessage({ id: 'label.importer' })}
          </Button> */}
              </FlexRow>

            </FlexRow>
          )
          : (
            <FlexRow
              className="container_Galerie"
              alignItems="center"
              justifyContent="center"
            >
              <IconGallery
                className="img-w5xl img-h5xl"
                classColor="first"
              />
              <p className="textInfo">{formatMessage({ id: 'gallery.empty.info' })}</p>
            </FlexRow>
          )
      }
    </>
  );
}
