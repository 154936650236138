import './FormError.scss';

import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { Fragment } from 'react';
import { FieldErrors, get, useFormState } from 'react-hook-form';

import useFormControl from '../FormControl/useFormControl';

function ErrorMessage({ children }: {children: React.ReactNode}): JSX.Element {
  return <p>{children}</p>;
}

function renderErrorMessages(errors: unknown): JSX.Element|null {
  if (errors === null || undefined === errors) {
    return null;
  }

  if (Array.isArray(errors)) {
    return <>{errors.map((error, i) => <Fragment key={nanoid()}>{renderErrorMessages(error)}</Fragment>)}</>;
  }

  if (typeof errors !== 'object') {
    return null;
  }

  if ('root' in errors) {
    return renderErrorMessages(errors.root);
  }

  if ('message' in errors && typeof errors.message === 'string') {
    return <ErrorMessage>{errors.message}</ErrorMessage>;
  }

  return <>{Object.values(errors).map((error) => <Fragment key={nanoid()}>{renderErrorMessages(error)}</Fragment>)}</>;
}

export type FormErrorProps = {
  name?: string;
  children?: React.ReactNode;
}

function FormError({ name, children }:FormErrorProps): JSX.Element|null {
  const formControl = useFormControl();
  const classes = classNames('FormError');
  const formState = useFormState({ name: name ?? formControl?.name });
  const errors: FieldErrors = get(formState?.errors, name ?? formControl?.name);

  const content = renderErrorMessages(errors) ?? children;

  if (content) {
    return (
      <div className={classes}>
        {content}
      </div>
    );
  }

  return null;
}

export default FormError;
