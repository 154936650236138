import './ShowInvitationPage.scss';

import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormError from '../../Component/FormError/FormError';
import PageHeader from '../../Component/PageHeader/PageHeader';
import useFormatError from '../../hooks/useFormatError';
import { useUser } from '../../provider/UserProvider';
import { acceptInvitation, declineInvitation, showInvitation } from '../../store/invitation/invitation.api';
import { InvitationModel } from '../../store/invitation/invitation.type';
import { makeRoutePath } from '../../store/route/route.api';

function ShowInvitationPage(): JSX.Element {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const formatError = useFormatError();
  const { user, loading: userLoading } = useUser();
  const { invitationId } = useParams<{invitationId: string}>();

  const { data: invitation, isLoading: invitationLoading, error } = useQuery({
    queryKey: ['invitation', invitationId],
    queryFn: () => (invitationId ? showInvitation(invitationId) : undefined),
  });

  const acceptInvitationMutation = useMutation(acceptInvitation);
  const declineInvitationMutation = useMutation(declineInvitation);

  const handleAcceptInvitation = useCallback(async () => {
    if (invitation) {
      await acceptInvitationMutation.mutateAsync({
        id: invitation.id,
      });

      if (invitation.contactId) {
        navigate(makeRoutePath('home'));
      } else if (invitation.achatId) {
        navigate(makeRoutePath('achat.show', { achatId: invitation.achatId }));
      } else if (invitation.venteId) {
        navigate(makeRoutePath('vente.show', { venteId: invitation.venteId }));
      }
    }
  }, [invitation, acceptInvitationMutation, navigate]);

  const handleDeclineInvitation = useCallback(async () => {
    if (invitation) {
      await declineInvitationMutation.mutateAsync({
        id: invitation.id,
      });

      navigate(makeRoutePath('home'));
    }
  }, [invitation, declineInvitationMutation, navigate]);

  function renderLoading(): JSX.Element {
    return <DefaultLoader />;
  }

  function renderError(error: unknown): JSX.Element {
    return <FormError>{formatError(error)}</FormError>;
  }

  function renderNeedsContact(): JSX.Element {
    return (
      <>
        <p>{formatMessage({ id: 'invitation.show.no_contact.text' })}</p>
        <Button className="button yellow" to={`/profil/contact?backTo=${location.pathname}`}>
          {formatMessage({ id: 'invitation.show.no_contact.button' })}
        </Button>
      </>
    );
  }

  function renderNotMatchingEmail(): JSX.Element {
    return renderError(new Error('invitation:answer:not_allowed'));
  }

  function renderInvitation(invitation: InvitationModel): JSX.Element {
    if (invitation.status === 'pending') {
      return (
        <>
          <p>
            {formatMessage({ id: 'invitation.show.answer.text' }, {
              fullName: invitation.sourceContact?.nomComplet ?? invitation.sourceCollaborateur?.nomComplet,
            })}
          </p>
          <Button className="button yellow" onClick={handleAcceptInvitation}>{formatMessage({ id: 'invitation.show.answer.accept' })}</Button>
          <p>{formatMessage({ id: 'label.or' })}</p>
          <Button className="button standard" onClick={handleDeclineInvitation}>{formatMessage({ id: 'invitation.show.answer.decline' })}</Button>
          {acceptInvitationMutation.error || declineInvitationMutation.error ? renderError(acceptInvitationMutation.error ?? declineInvitationMutation.error) : null}
        </>
      );
    }

    return (
      <p>
        {formatMessage({ id: `invitation.show.status.${invitation.status}` })}
      </p>
    );
  }

  const form = useForm();

  const loading = userLoading || invitationLoading;
  const needsContact = invitation && !invitation.contactId && !user?.contact;
  const notMatchingEmail = invitation && invitation.email !== user?.email && invitation.email !== user?.contact?.email;

  return (
    <>
      <PageHeader
        titleRotate={(
          <span>
            {formatMessage({ id: 'invitation.show.header_title' }, { br: () => <br /> })}
          </span>
        )}
      />
      <Container>
        <Form {...form}>
          <FlexRow alignItems="center" gap="1em" padding="1em">
            {loading ? renderLoading()
              : needsContact ? renderNeedsContact()
                : notMatchingEmail ? renderNotMatchingEmail()
                  : invitation ? renderInvitation(invitation)
                    : error ? renderError(error) : null}
          </FlexRow>
        </Form>
      </Container>
    </>
  );
}

export default ShowInvitationPage;
