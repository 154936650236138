import React from 'react';

import './IconeLike.scss';

export default function IconeLike({
  favori = false, className = '', classColor = 'cls-1', classColor2 = 'cls-2',
}) {
  const isFavori = favori === true ? 'favori' : 'cls-1';

  return (
    <svg className={`containerImage ${className} ${isFavori}`} viewBox="0 0 35 32">
      <g id="like" transform="translate(3.001 3.003)">
        <g className={classColor2} transform="matrix(1, 0, 0, 1, -3, -3)">
          <path id="Icon_awesome-heart-2" data-name="Icon awesome-heart" className={isFavori} d="M27.114,4.029A8.069,8.069,0,0,0,16.17,4.82L15.014,6,13.859,4.82A8.068,8.068,0,0,0,2.915,4.029a8.3,8.3,0,0,0-.581,12.092L13.683,27.742a1.849,1.849,0,0,0,2.657,0L27.689,16.121a8.3,8.3,0,0,0-.575-12.092Z" transform="translate(3 0.76)" />
        </g>
      </g>
    </svg>
  );
}
