import './Title.scss';

import classNames from 'classnames';

export type TitleProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  position?: 'start'|'center'|'end';
  color?: 'first'|'second';
  size?: 1|2|3|4;
}

function Title({
  title, subtitle, children, position = 'center', size = 3, color = 'second',
}:TitleProps): JSX.Element {
  const classes = classNames({
    [`Title-${position}`]: Boolean(position),
    [`Title-${size}`]: Boolean(size),
    [`Title-${color}`]: Boolean(color),
  });

  return (
    <header className={classes}>
      <div className="Title_content">
        {title || children ? <h1 className="Title_title">{title ?? children}</h1> : null}
        {subtitle ? <p className="Title_subtitle" role="doc-subtitle">{subtitle}</p> : null}
      </div>
    </header>
  );
}

export default Title;
