import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconFiltre.scss';

function IconFiltre({
  className, classColor = 'filtre',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_filtre', className)} id="Bouton_filtre" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 15">
        <line id="Ligne_4" data-name="Ligne 4" className={`${classColor}-cls-1`} x2="17" transform="translate(0 2.5)" />
        <line id="Ligne_5" data-name="Ligne 5" className={`${classColor}-cls-1`} x2="17" transform="translate(0 8)" />
        <line id="Ligne_6" data-name="Ligne 6" className={`${classColor}-cls-1`} x2="17" transform="translate(0 13.5)" />
        <circle id="Ellipse_1" data-name="Ellipse 1" className={`${classColor}-cls-2`} cx="2" cy="2" r="2" transform="translate(0.5 6)" />
        <circle id="Ellipse_2" data-name="Ellipse 2" className={`${classColor}-cls-2`} cx="2" cy="2" r="2" transform="translate(6.5 11)" />
        <circle id="Ellipse_3" data-name="Ellipse 3" className={`${classColor}-cls-2`} cx="2" cy="2" r="2" transform="translate(11.5)" />
      </svg>
    </div>
  );
}

export default IconFiltre;
