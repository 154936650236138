import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconShare.scss';

function IconShare({
  className, classColor = 'share',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <title id="icon_upload">Icon Galerie</title>
      <defs />
      <svg className={classNames('icon_share', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.472 28.472">
        <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2 -1.586)">
          <path id="Tracé_3039" data-name="Tracé 3039" d="M30.832,3,16.5,17.332" transform="translate(-1.774)" className={`${classColor}-cls-1`} />
          <path id="Tracé_3040" data-name="Tracé 3040" d="M29.057,3l-9.12,26.057L14.726,17.332,3,12.12Z" className={`${classColor}-cls-1`} />
        </g>
      </svg>

    </div>
  );
}

export default IconShare;
