import { useCallback } from 'react';
import { useIntl } from 'react-intl';

type UseFormatErrorReturn = (error?: unknown) => string|undefined;

function useFormatError(): UseFormatErrorReturn {
  const { formatMessage } = useIntl();

  const formatError = useCallback<UseFormatErrorReturn>((error) => {
    if (error instanceof Error) {
      return formatMessage({ id: `error.${error.message}` });
    }

    return undefined;
  }, [formatMessage]);

  return formatError;
}

export default useFormatError;
