import './FormFooter.scss';

import classNames from 'classnames';

export type FormFooterProps = {
    children?: React.ReactNode;
}

function FormFooter({ children }:FormFooterProps): JSX.Element {
  const classes = classNames('FormFooter');

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export default FormFooter;
