import { request } from '../../lib/papirisClient';

import { AnswerInvitationPayload, CreateInvitationPayload, InvitationModel } from './invitation.type';

export function createInvitation(payload: CreateInvitationPayload): Promise<InvitationModel> {
  return request('/invitation', 'POST', payload);
}

export function showInvitation(id: string): Promise<InvitationModel> {
  return request(`/invitation/${id}`, 'GET');
}

export function cancelInvitation({ id, ...payload }: AnswerInvitationPayload): Promise<InvitationModel> {
  return request(`/invitation/${id}/cancel`, 'POST', payload);
}

export function acceptInvitation({ id, ...payload }: AnswerInvitationPayload): Promise<InvitationModel> {
  return request(`/invitation/${id}/accept`, 'POST', payload);
}

export function declineInvitation({ id, ...payload }: AnswerInvitationPayload): Promise<InvitationModel> {
  return request(`/invitation/${id}/decline`, 'POST', payload);
}
