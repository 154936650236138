import './immo.scss';

import { CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import CreateProject from '../../Component/Button/CreateProject/CreateProject';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import ListTab from '../../Component/ListTab/ListTab.jsx';
import CardImmo from '../../Component/Selecteur/Card_Immo/card_immo';
import SwitcherSearchButton from '../../Component/SwitcherSearchButton/switcherSearchButton';
import ToggleBig from '../../Component/ToggleBig/ToggleBig';
import { capitalizeFirstLetter } from '../../Function/String';
import ICON_CREATED_ACCOUNT from '../../img/icon_account_+.svg';
import { Variant } from '../../lib/Achats/Achats.types';
import { useFilter } from '../../provider/FilterProvider';
import { usePermission } from '../../provider/PermissionProvider';
import { makeRoutePath } from '../../store/route/route.api';
import useShowListAchat from '../ShowAchatLayout/useListAchat';
import useShowListAchatStore from '../ShowAchatLayout/useShowListAchatStore';

export default function PAGE_IMMO() {
  const { formatMessage, formatDate } = useIntl();
  const { hasPermission } = usePermission();
  const isAuthenticated = hasPermission('authenticated');
  useShowListAchat();
  const { listAchats, isLoading: listAchatsIsLoading, isError: listAchatsIsError } = useShowListAchatStore();
  const [allListVentePages, setAllListVentePages] = useState([]);
  const [lastListVentePage, setLastListVentePage] = useState([]);

  const {
    page,
    achatId,
    villes,
    bienTypes,
    variant: variantRequest,
    distance,
    budgetMin,
    budgetMax,
    chambresMin,
    surfaceHabitableMin,
    surfaceHabitableMax,
    surfaceTerrainMin,
    surfaceTerrainMax,
    dpeMin,
    listVenteQuery,
    modalState,
    modalSearch,
    setModalSearch,
    addFilters,
  } = useFilter();

  const infoTitle = useCallback(() => {
    if (variantRequest === String(Variant.location)) {
      return capitalizeFirstLetter(variantRequest);
    }

    return 'Achat';
  }, [variantRequest]);

  const [activeLinkVariant, setActiveLinkVariant] = useState(infoTitle);
  const queryClient = useQueryClient();

  // Flatten all list achat page items into one list
  const achatListFavoris = useMemo(() => {
    const listFavoris = [];

    listAchats?.items.forEach((achat) => {
      listFavoris?.push(achat);
    });

    return listFavoris;
  }, [listAchats]);

  // Flatten all list vente page items into one list
  const venteList = useMemo(() => {
    const venteListArray = [];

    allListVentePages?.forEach((listVentePage) => {
      venteListArray?.push(...listVentePage.items);
    });

    return venteListArray;
  }, [allListVentePages]);

  const location = useLocation();
  const { venteId } = location?.state ?? {};

  const scrollRestoredRef = useRef(false);
  useLayoutEffect(() => {
    if (scrollRestoredRef.current) {
      return undefined;
    }
    if (venteId && venteList.length) {
      scrollRestoredRef.current = true;

      const venteElement = document.getElementById(venteId);
      if (venteElement) {
        const { top, bottom } = venteElement.getBoundingClientRect();
        const notVisible = top < 0 || bottom > window.innerHeight;

        if (notVisible) {
          venteElement.scrollIntoView({ block: 'center' });
        }
      }
    }

    return undefined;
  }, [venteId, venteList]);

  const venteMeta = lastListVentePage?.meta;
  const previousPage = (allListVentePages[0]?.meta?.current_page ?? 0) - 1;
  const nextPage = (allListVentePages[allListVentePages.length - 1]?.meta?.current_page ?? 0)
    + 1;

  const handleScroll = useCallback(
    (e) => {
      if (e.currentTarget.scrollTop === 0 && previousPage >= 1) {
        addFilters({ page: previousPage });
      }
    },
    [addFilters, previousPage],
  );

  /* This part of the code manages the page layout during scrolling : */
  const [scrollValue, setScrollValue] = useState(0);
  const [lastScrollValue, setLastScrollValue] = useState(0);
  const [modalContainer, setModalContainer] = useState('_lessSlide');
  const elementRef = useRef(null);

  const useDebouncedEffect = (effect, dependences, delay) => {
    const logic = useCallback(effect, dependences);
    useEffect(() => {
      const handler = setTimeout(() => {
        logic();
      }, delay);

      return () => {
        clearTimeout(handler); // if dependencies change before the time limit has elapsed.
      };
    }, [logic, delay]);
  };

  const updateScroll = (
    lastScrollValue,
    scrollValue,
    setModalContainer,
    setModalSearch,
  ) => {
    if (lastScrollValue > scrollValue) {
      setTimeout(() => setModalContainer('_lessSlide'), 100);
      setTimeout(() => setModalSearch('_openHalf'), 200);
    } else if (lastScrollValue < scrollValue) {
      setTimeout(() => setModalSearch('_closedHalf'), 100);
      setTimeout(() => setModalContainer('_moreSlide'), 200);
    }
  };

  const handleScrollDebounce = useCallback(
    debounce(() => {
      if (elementRef.current) {
        const { scrollTop } = elementRef.current;
        setScrollValue(scrollTop);
      }
    }, 200),
    [],
  );

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;
      element.addEventListener('scroll', handleScrollDebounce);

      return () => {
        element.removeEventListener('scroll', handleScrollDebounce);
      };
    }

    return () => {};
  }, [handleScrollDebounce]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const element = document.getElementsByClassName('info')[0];
      if (element) {
        elementRef.current = element;
        element.addEventListener('scroll', handleScrollDebounce);
        observer.disconnect(); // Disconnect the observer after finding the element
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      if (elementRef.current) {
        elementRef.current.removeEventListener('scroll', handleScrollDebounce);
      }
    };
  }, [handleScrollDebounce]);

  const logicScroll = () => {
    if (scrollValue !== lastScrollValue) {
      updateScroll(lastScrollValue, scrollValue, setModalContainer, setModalSearch);
      setLastScrollValue(scrollValue);
    }
  };

  useDebouncedEffect(logicScroll, [lastScrollValue, scrollValue], 200);

  const projetsAchat = listAchats?.items;

  /* This `useEffect` hook is responsible for resetting the state variables
  `scrollRestoredRef`, `allListVentePages`, and `lastListVentePage` to their initial values
  whenever any of the dependencies specified in the dependency array change. */
  useEffect(() => {
    scrollRestoredRef.current = false;
    setAllListVentePages([]);
    setLastListVentePage(undefined);
  }, [variantRequest, achatId, villes, bienTypes, distance, budgetMin, budgetMax, chambresMin, surfaceHabitableMin, surfaceHabitableMax, surfaceTerrainMin, surfaceTerrainMax, dpeMin]);

  /* This `useEffect` hook is responsible for updating the state related to the list of vente pages
  based on the data fetched from the `listVenteQuery` */
  useEffect(() => {
    if (achatId !== 'nouveau') {
      if (listVenteQuery?.data) {
        if (queryClient.getMutationCache().mutationId !== 0) {
          scrollRestoredRef.current = false;
          setAllListVentePages([listVenteQuery.data]);
          setLastListVentePage(listVenteQuery.data);
        } else {
          setLastListVentePage(listVenteQuery.data);
          setAllListVentePages((listVentePages) => {
            const newListVentePages = [...listVentePages, listVenteQuery.data];

            newListVentePages.sort(
              (a, b) => a.meta.current_page - b.meta.current_page,
            );

            return newListVentePages;
          });
        }
      }
    }
  }, [achatId, listVenteQuery.data, queryClient]);

  const achatList = useMemo(() => {
    if (isAuthenticated && projetsAchat?.length >= 1) {
      return projetsAchat.map((projet) => {
        const id = projet?.id;
        const title = projet?.titre || formatMessage(
          { id: 'achat.title_with_date' },
          { date: formatDate(projet?.createdAt, { dateStyle: 'short' }) },
        );
        const url = projet?.variant === Variant.location
          ? makeRoutePath('search.achat.show', { achatId: id, variant: Variant.location })
          : makeRoutePath('search.achat.show', { achatId: id });

        return {
          key: `${title} ${id}`,
          id,
          title,
          rapprochements: projet?.rapprochements,
          variant: projet?.variant,
          url,
        };
      });
    }

    return [
      {
        id: 'nouveau',
        title: formatMessage({ id: 'achat.create.projet' }),
        url: makeRoutePath('projet.nouveau'),
      },
    ];
  }, [formatDate, formatMessage, isAuthenticated, projetsAchat]);

  if (listAchatsIsLoading) {
    return <DefaultLoader />;
  }

  if (listVenteQuery?.isError || listAchatsIsError) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  const anonymousButton = (
    <Button
      className="button standard projet medium"
      icon={(
        <img
          src={ICON_CREATED_ACCOUNT}
          alt={`icon ${formatMessage({ id: 'accueil.sign_up' })}`}
        />
      )}
      to={makeRoutePath('auth.sign_up')}
    >
      <p>{formatMessage({ id: 'accueil.sign_up' })}</p>
    </Button>
  );

  const handleLinkClick = (link) => {
    setActiveLinkVariant(link);
  };

  return (
    <div id="immo_container">
      <FlexRow
        className={`containerChoice ${modalSearch}`}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <FlexRow
          className="containerToggleAchatLocation"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <ToggleBig
            toggleLeft={{
              key: String(Variant.achatvente),
              name: 'Achat',
              value: undefined, // leave 'undefined' to make the url cleaner
            }}
            toggleRight={{
              key: String(Variant.location),
              name: 'Location',
              value: Variant.location,
            }}
            filter
            activeLink={activeLinkVariant}
            handleLinkClick={handleLinkClick}
          />
        </FlexRow>
        <div className="search_toggle_container">
          <ListTab
            listItems={achatList}
            defaultTitle="Tous les biens"
            idParam="achatId"
            routePathDefaultTitle={variantRequest === Variant.location
              ? makeRoutePath('search', { variant: variantRequest })
              : makeRoutePath('search')}
          />
        </div>
        <div className="container_counter_button_title">
          {venteMeta?.total ? (
            <div className="property_counter">
              <p>
                <FormattedMessage
                  id="search.property.counter"
                  values={{ count: venteMeta?.total }}
                />
              </p>
            </div>
          ) : null}
          <SwitcherSearchButton />
        </div>
      </FlexRow>

      <div
        className={`info cards_immos${
          modalState === '_open' ? ' disabledCard' : ''
        } ${modalContainer}`}
        onScroll={handleScroll}
      >
        <FlexRow
          direction="column"
          gap="4"
          grow="1"
          alignItems="center"
          justifyContent="center"
        >
          {achatId === 'nouveau' ? (
            isAuthenticated ? (
              <FlexRow
                direction="column"
                gap="4"
                grow="1"
                alignItems="center"
                justifyContent="center"
              >
                <CreateProject />
                <div className="containerNothingFind">
                  <p>
                    <FormattedMessage id="search.nothingFind.title" />
                  </p>
                  <DefaultLoader />
                </div>
              </FlexRow>
            ) : (
              anonymousButton
            )
          ) : venteList.length ? (
            <>
              {listVenteQuery?.isLoading && page <= previousPage ? (
                <CircularProgress
                  aria-label={formatMessage({ id: 'label.loading' })}
                />
              ) : null}
              {venteList.map((cardImmoData) => (
                <CardImmo
                  key={`card-immo-${cardImmoData?.id}`}
                  {...cardImmoData}
                  listProjetsAchats={achatList}
                />
              ))}
              {listVenteQuery?.isLoading && page >= nextPage ? (
                <CircularProgress
                  aria-label={formatMessage({ id: 'label.loading' })}
                />
              ) : page < venteMeta?.last_page ? (
                <div>
                  <Button
                    className="standard"
                    type="button"
                    onClick={() => addFilters({ page: nextPage })}
                  >
                    {formatMessage({ id: 'achats.show_more' })}
                  </Button>
                </div>
              ) : null}
            </>
          ) : listVenteQuery?.isLoading ? (
            <CircularProgress
              aria-label={formatMessage({ id: 'label.loading' })}
            />
          ) : (
            <div className="containerNothingFind">
              <p>
                <FormattedMessage id="search.nothingFind.title" />
              </p>
              <Button
                to={makeRoutePath('achat.show.update', { achatId })}
                className="button medium standard"
                aria-label="Modifier mon projet d'achat"
              >
                Modifier mes critères de recherches
              </Button>
            </div>
          )}
        </FlexRow>
      </div>
    </div>
  );
}
