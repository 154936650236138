import classNames from 'classnames';
import './FormAddon.scss';

export type FormAddonProps = {
    children: React.ReactNode;
    side: 'left'|'right';
}

function FormAddon({ children, side }: FormAddonProps): JSX.Element {
  const classes = classNames('FormAddon', {
    [`FormAddon-${side}`]: Boolean(side),
  });

  return <div className={classes}>{children}</div>;
}

export default FormAddon;
