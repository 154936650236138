import './PresentationConseiller.scss';

import { useIntl } from 'react-intl';

export type PresentationConseillerProps = {
  avatar?: string;
  prenom?: string;
  nom?: string;
};

export default function PresentationConseiller({ avatar, prenom, nom }: PresentationConseillerProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="PresentationConseiller">
      <img className="PresentationConseiller_avatar" src={avatar ?? ''} alt={formatMessage({ id: 'conseiller.avatar' })} />
      {prenom ? (
        <h2 className="PresentationConseiller_prenom">
          {prenom}
        </h2>
      ) : null}
      {nom ? (
        <h2 className="PresentationConseiller_nom">
          {nom}
        </h2>
      ) : null}
    </div>
  );
}
