import './home.scss';

import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import Button from '../../Component/Button/Button';
import ButtonDragonfly from '../../Component/Button/ButtonDragonfly/ButtonDragonfly';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import IconAddWhiteAndYellow from '../../Component/Icon/IconAddWhiteAndYellow';
import InfoPastilleNumber from '../../Component/InfoPastilleNumber/InfoPastilleNumber';
import PresentationUser from '../../Component/PresentationUser/PresentationUser';
import ICON_PARRAINAGE from '../../img/bouton_Parrainage.svg';
import NotificationList from '../../notification/NotificationList';
import { useUser } from '../../provider/UserProvider.tsx';
import { makeRoutePath } from '../../store/route/route.api';
import useListBien from '../ShowBienLayout/useListBien';
import useListBienStore from '../ShowBienLayout/useListBienStore';

export default function HomePage() {
  const { user, loading, error } = useUser();
  const { formatMessage } = useIntl();
  useListBien();
  const { list } = useListBienStore();
  const nbBien = list?.items?.length;

  if (loading) {
    return <DefaultLoader />;
  }
  if (error) {
    return console.log(error);
  }

  return (
    <>
      <NotificationList />
      <div className="container_Home">
        <FlexRow
          direction="column"
          grow="1"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <div className="container_PresentationUser">
            <PresentationUser
              avatar={user?.contact?.avatar}
              prenom={user?.contact?.prenom ?? user?.email}
              nom={user?.contact?.nom}
            />
          </div>
          <div className="container_ButtonsDragonfly">
            <FlexRow
              width="initial"
              direction="row"
              gap="4"
              grow="1"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <ButtonDragonfly
                className="button_home"
                topLeftRadius
                topRightRadius
                bottomLeftRadius
                to={makeRoutePath('profile')}
              >
                Profil
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
              <ButtonDragonfly
                className="button_home"
                topLeftRadius
                topRightRadius
                bottomRightRadius
                to={makeRoutePath('messagerie')}
              >
                Messagerie
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
            </FlexRow>
            <FlexRow
              direction="row"
              gap="4"
              grow="1"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <ButtonDragonfly
                className="button_home"
                topLeftRadius
                bottomLeftRadius
                to={makeRoutePath('projet.list')}
              >
                Projets
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
              <ButtonDragonfly
                className="button_home"
                topRightRadius
                bottomRightRadius
                to={makeRoutePath('documents')}
              >
                Documents
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
            </FlexRow>
            <ButtonDragonfly
              className="button_home"
              bottomLeftRadius
              bottomRightRadius
              to={nbBien === 1
                ? makeRoutePath('bien.show', { bienId: list.items[0].id }) : makeRoutePath('bien.list')}
            >
              Mes biens
              <InfoPastilleNumber infoPastilleNumber={0} />
            </ButtonDragonfly>
          </div>
          <Button
            className="button standard button_home projet big "
            icon={(
              <IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />
            )}
            to={makeRoutePath('projet.nouveau')}
          >
            <p className="fs-xl">{formatMessage({ id: 'achat.create.projet' })}</p>
          </Button>

          <div className="button_Parrainage">
            <Button
              className="button yellow button_home parrainage"
              icon={
                <img src={ICON_PARRAINAGE} alt="icon Parrainer un proche'" />
              }
              to="/#"
            >
              Parrainer un proche
            </Button>
          </div>
        </FlexRow>
      </div>
    </>
  );
}
