import './FormRadio.scss';

import classNames from 'classnames';
import { useController } from 'react-hook-form';

import { ComponentProps } from '../../types/ComponentProps';
import useFormControl from '../FormControl/useFormControl';

export type FormRadioProps = ComponentProps<'input', {
  name?: string;
  invalid?: boolean;
  label?: React.ReactNode;
}>

function FormRadio({
  className,
  name,
  label,
  disabled,
  invalid,
  value,
  ...props
}: FormRadioProps): JSX.Element {
  const formControl = useFormControl();
  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const classes = classNames('FormRadio', className, {
    'FormRadio-invalid': invalid ?? fieldState.invalid,
    'FormRadio-disabled': disabled,
  });

  return (
    <label
      className={classes}
    >
      <input
        type="radio"
        className="FormRadio_input"
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
        disabled={disabled}
        {...props}
        value={value}
        checked={field.value === value}
      />
      <span className="FormRadio_label">
        {label}
      </span>
    </label>
  );
}

export default FormRadio;
