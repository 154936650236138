import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconFooter.scss';

function IconSearch({
  className, classColor = 'footer-search',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_recherche', className)} id="icone_recherche" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.041 29.04">
        <title id="icone_recherche">Icon Recherche</title>
        <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(2 2)">
          <path id="Tracé_75" data-name="Tracé 75" className={`${classColor}-cls-1`} d="M26.022,15.261A10.761,10.761,0,1,1,15.261,4.5,10.761,10.761,0,0,1,26.022,15.261Z" transform="translate(-4.5 -4.5)" />
          <path id="Tracé_76" data-name="Tracé 76" className={`${classColor}-cls-2`} d="M30.826,30.826l-5.851-5.851" transform="translate(-6.614 -6.614)" />
        </g>
      </svg>
    </div>
  );
}

export default IconSearch;
