// Import Css
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import './slider_2buttons.scss';

// Creation Composant
export default function Slider2buttons({
  valueMin, valueMax, coutMin, coutMax, value_littleInfo,
}) {
  const value = [valueMin, valueMax];

  return (
    <div className="container_slider_2buttons">
      <Box sx={{ width: '80%' }}>
        <Slider
          getAriaLabel={() => "Plage estimative des coûts annuels d'énergies du logement"}
          value={value}
          valueLabelDisplay="off"
        />
      </Box>
      <small>
        entre
        {' '}
        <strong className="estimativeValue">{`${coutMin}`}</strong>
        {' '}
        et
        {' '}
        <strong className="estimativeValue">{`${coutMax}`}</strong>
        {' '}
        par an
      </small>

      <div className="container_littleInfo">
        <small className="littleInfo">
          Les coûts sont estimés en fonction des caractéristiques du logement.
        </small>
        <small className="littleInfo">
          Prix moyen des énergies indexés sur l‘année :
          {value_littleInfo}
        </small>

      </div>
    </div>
  );
}
