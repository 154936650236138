import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { logout } from '../../lib/firebaseClient';

export default function DeconnexionPage() {
  const navigate = useNavigate();

  const logoutFromEverything = useCallback(async () => {
    await logout();
    await localStorage.clear();

    navigate('/');
  }, [navigate]);

  useEffect(() => {
    logoutFromEverything();
  }, [logoutFromEverything]);

  return null;
}
