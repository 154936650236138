import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useShowAchat from '../../Pages/ShowAchatLayout/useShowAchat';
import useShowAchatStore from '../../Pages/ShowAchatLayout/useShowAchatStore';
import { createVenteFavori, removeVenteFavori } from '../../lib/papirisClient';

function useFavoriteAction(vId, aId) {
  useShowAchat(aId);
  const { achat } = useShowAchatStore();

  const favorites = useMemo(() => achat?.ventesFavorites || [], [achat?.ventesFavorites]);
  const isFavoriteForThisVente = useMemo(() => favorites.some((favoriVente) => favoriVente.id === vId), [favorites, vId]);
  const queryClient = useQueryClient();

  const addMutationFavori = useMutation(createVenteFavori);
  const removeMutationFavori = useMutation(removeVenteFavori);

  const onFavorite = useCallback(async (venteId, achatId) => {
    const currentIsFavorite = favorites.some((favoriVente) => favoriVente.id === venteId);
    const mutation = currentIsFavorite === true ? removeMutationFavori : addMutationFavori;
    await mutation.mutateAsync({ achatId, venteId });
    await queryClient.invalidateQueries({ queryKey: 'achat' });
    await queryClient.invalidateQueries({ queryKey: 'vente' });
  }, [favorites, removeMutationFavori, addMutationFavori, queryClient]);

  return { isFavoriteForThisVente, onFavorite };
}

export default useFavoriteAction;
