import { useIntl } from 'react-intl';

// import BEE from '../../img/icon_Bee_mouv_ailes.gif';
import BIRD from '../../img/icon_bird_mouv_ailes.gif';
import FlexRow from '../FlexRow/FlexRow';
import './DefaultLoader.scss';

export default function DefaultLoader(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <FlexRow grow="1" alignItems="center" justifyContent="center">
      <img className="birdLoader" src={BIRD} alt={formatMessage({ id: 'label.loading' })} aria-label={formatMessage({ id: 'label.loading' })} style={{ maxWidth: '70%' }} />
    </FlexRow>
  );
}
