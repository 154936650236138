import './TagNew.scss';
import { useIntl } from 'react-intl';

export default function TagNew() {
  const { formatMessage } = useIntl();

  return (
    <div className="card_containerNew">
      <p className="tag_new">{formatMessage({ id: 'my.project.tag.new' })}</p>
    </div>

  );
}
