import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { showBien } from '../../lib/papirisClient';

import useShowBienStore from './useShowBienStore';

export default function useShowBien(bienId?:string):void {
  const { setBien, setLoading, setError } = useShowBienStore();

  const showBienQuery = useQuery({
    queryKey: ['bien', bienId],
    queryFn: () => (bienId ? showBien(bienId) : undefined),
  });

  useEffect(() => {
    if (showBienQuery.isSuccess) {
      if (showBienQuery.data) {
        setBien(showBienQuery.data);
        setLoading(showBienQuery.isLoading);
      }
    }
    setError(showBienQuery.isError);
    setLoading(showBienQuery.isLoading);
  }, [setBien, setError, setLoading, showBienQuery.data, showBienQuery.isError, showBienQuery.isLoading, showBienQuery.isSuccess]);
}
