import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconPartageRound.scss';

function IconPartageRound({
  className, classColor = 'partageRound',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>

      <svg className={classNames('icon_partage', className)} id="Bouton_partage" viewBox="0 0 31 31">
        <title id="icon_partage">Icon Partage</title>
        <circle id="Ellipse_116" data-name="Ellipse 116" className={`${classColor}-cls-1`} cx="15.5" cy="15.5" r="15.5" />
        <path id="Icon_open-share" data-name="Icon open-share" className={`${classColor}-cls-2`} d="M10.536,0V4.214C2.107,4.214,0,8.534,0,14.75c1.1-4.172,4.214-6.321,8.429-6.321h2.107v4.214l6.321-6.659Z" transform="translate(7.071 8.125)" />
      </svg>
    </div>
  );
}

export default IconPartageRound;
