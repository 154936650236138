import React from 'react';
import './cardInfoData.scss'; // A CSS file to style our component
import { FormattedNumber } from 'react-intl';

import { Variant } from '../../../lib/Achats/Achats.types';

import { isNaN } from 'lodash';

export default function CardInfoData({
  ...data
}) {
  const getFormattedPrice = (data) => {
    if (data?.variant === Variant.achatvente) {
      const value = data.prixAgence / 100;

      return isNaN(value) ? '' : value;
    }
    if (data?.variant === Variant.location) {
      const value = data.loyerChargesComprises / 100;

      return isNaN(value) ? '' : value;
    }

    return '';
  };

  const formattedPrice = getFormattedPrice(data);

  return (

    <div className="data_card_container">
      <div className="data_card_info">
        <p className="data_card_title">
          {data?.bien?.titre || data?.reference}
        </p>
        <div className="data_card_location">
          <p className="data_card_ville">
            {data?.bien?.ville}
          </p>
          <p className="data_card_postalCode">
            {data?.bien?.codePostal}
          </p>
        </div>
        <p className="data_card_price">
          <FormattedNumber
            value={formattedPrice}
            style="currency"
            currency="EUR"
            minimumFractionDigits="0"
            maximumFractionDigits={data?.variant === Variant.location ? '2' : '0'}
          />
        </p>
      </div>
      <img src={data?.bien?.firstPhoto?.path ?? "https://via.placeholder.com/260x260.png/ebbc02?text=En attente d'une photo"} alt={data?.bien?.titre ? `photo de ${data?.bien?.titre}` : 'Photo du bien'} />
    </div>
  );
}
