export type GeoGouvAdresse = {
    ville: string;
    codePostal: string;
    adressePostale: string;
    latitude: number;
    longitude: number;
    objectID?: string;
};

export type GeoGouvApiFeatureType = 'housenumber' | 'street' | 'municipality';

export type GeoGouvApiFeature = {
  type: 'Feature';
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  properties: {
    label: string;
    score: number;
    housenumber: string;
    id: string;
    type: GeoGouvApiFeatureType;
    x: number;
    y: number;
    importance: number;
    name: string;
    postcode: string;
    citycode: string;
    city: string;
    context: string;
    street: string;
  };
}

export type GeoGouvApiResult = {
  type: string;
  version: string;
  attribution: string;
  licence: string;
  query: string;
  limit: string;
  features: GeoGouvApiFeature[];
}

const formatResult = (result: GeoGouvApiResult): GeoGouvAdresse[] => result.features.map((feature) => ({
  ville: feature.properties.city,
  codePostal: feature.properties.postcode,
  adressePostale: feature.properties.name,
  latitude: feature.geometry.coordinates[1],
  longitude: feature.geometry.coordinates[0],
  objectID: feature.properties.id,
}));

export const searchAdresse = async (
  query: string,
  type: GeoGouvApiFeatureType,
  lat ?: number,
  lon ?: number,
  limit = 10,
) => {
  const params = new URLSearchParams({
    q: query,
    type: type || 'municipality',
    lat: lat ? lat.toString() : '',
    lon: lon ? lon.toString() : '',
    limit: limit.toString(),
  });

  const response = await fetch(`https://api-adresse.data.gouv.fr/search/?${params.toString()}`);
  if (!response.ok) {
    throw new Error('Réponse réseau non OK');
  }
  const result = await response.json();

  return formatResult(result);
};

export default searchAdresse;
