/** V2 */
import React, { useState } from 'react';
import './collapse.scss';

/**
Component for created a COLLAPSE.
@param {string} title - The title of the collapse.
@param {(string|[object])} content - The content to be displayed in the collapse. Can be either a string or an array of objects.
@example
return (
  <COLLAPSE
            key={"1"}
            title={"Description du bien"}
            content={`Vue sur Pilat ! Proche gare et commodités ! Venez découvrir cette
          superbe maison de 180 m² située dans un secteur très calme de Saint
          Clair du Rhône. Vous retrouverez au rez-de-chaussée un hall d’entrée
          donnant sur un séjour de 47 m² ouvert sur une belle terrasse avec
          vue sur le Pilat, une cuisine équipée de 14 m², un espace buanderie,
          une chambre de 16 m² avec une douche, ainsi qu’un WC. A l’étage, 4
          autres chambres de 17 m², 13 m², 18 m², et 17 m², une salle d’eau,
          ainsi qu’un deuxième WC. Le tout sur un terrain de 1929 m²,
          agrémenté d’une piscine, un terrain de boule, ainsi qu’une cuisine
          d’été.`}
          />
          ||
  <COLLAPSE
            key={"2"}
            title="INFO"
            content={[
              {
                id: 1,
                title: "Type de bien",
                content: "Maison",
              },
              {
                id: 2,
                title: "Fenêtre",
                content: "Doubles vitrage",
              }
          ></COLLAPSE>
 )
@returns {JSX.Element} A JSX element representing the button component.
*/

export default function COLLAPSE({ title, contents, children }) {
  const [isClosed, setIsClosed] = useState(true);

  function openCollapse() {
    setIsClosed(!isClosed);
  }

  return (
    <div className={`collapse collapse${isClosed ? '-closed' : '-open'}`}>
      <button type="button" className="collapse-title" onClick={() => openCollapse()}>
        <h2>{title}</h2>
        <i
          className={`fa-solid ${
            isClosed ? 'fa-chevron-down' : 'fa-chevron-up'
          }`}
          aria-hidden="true"
          role="img"
        />
      </button>
      {typeof contents === 'string' || Array.isArray(contents) ? (
        <section
          className={`collapse-content${
            Array.isArray(contents) ? '-array' : ''
          }${isClosed ? '_closed' : '_open'}`}
        >
          {Array.isArray(contents) ? (
            contents?.map((element) => (
              <ul key={`info_Bien-${element.id}`} className="info_Bien">
                <li className="element_title">{element.title}</li>
                <ul key={`element_title-ul-${element.id}`}>
                  {typeof element.content === 'string' && element.content.includes(',') ? (
                    <ul>
                      {element.content.split(',').map((subElement) => (
                        <li key={`element_content-li-${subElement}`} className="element_content">
                          {subElement.trim()}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <li className="element_content">{element.content}</li>
                  )}
                </ul>
              </ul>
            ))
          ) : (
            <p>{contents}</p>
          )}
        </section>
      ) : (
        <div className={`collapse-content${isClosed ? '_closed' : '_open'}`}>
          {children}
        </div>
      )}
    </div>
  );
}
