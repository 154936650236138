function removeAccents(str: string): string {
  if (str === (null || undefined)) {
    return '';
  }

  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function toCamelCase(str: string): string {
  // D'abord, nous supprimons les accents.
  const noAccentsStr = removeAccents(str);

  const newStr = noAccentsStr
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  if (/[\dA-Za-z]$/.test(noAccentsStr)) {
    return newStr;
  }

  return newStr.slice(0, -1);
}
export function toUpperCase(str: string): string {
  // D'abord, nous supprimons les accents.
  const noAccentsStr = removeAccents(str);

  const newStr = noAccentsStr
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  if (/[\dA-Za-z]$/.test(noAccentsStr)) {
    return newStr;
  }

  return newStr.slice(0, -1);
}

export function capitalizeFirstLetter(str: string): string {
  // D'abord, nous supprimons les accents.
  const noAccentsStr = removeAccents(str);

  const newStr = noAccentsStr
    .charAt(0).toUpperCase() + noAccentsStr.slice(1);

  return newStr;
}

export default { toCamelCase, toUpperCase, capitalizeFirstLetter };
