import './card_detail_immo.scss';
import { isNaN } from 'lodash';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedNumber, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import useShowAchat from '../../../Pages/ShowAchatLayout/useShowAchat';
import useShowAchatStore from '../../../Pages/ShowAchatLayout/useShowAchatStore';
import useFavoriteAction from '../../../hooks/ActionCard/useFavoriteAction';
// Mise en place des icones
import useActionToProject from '../../../hooks/ActionConnected/useActionToProject';
import ICON_CHECK from '../../../img/icon_Check.svg';
import ICON_POSITION from '../../../img/icon_position.svg';
import { Variant } from '../../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../../store/route/route.api';
import Button from '../../Button/Button';
import Share from '../../Button/Share/button_Share';
import CARROUSEL_IMMO from '../../Carrousel/carrousel';
import FlexRow from '../../FlexRow/FlexRow';
import FormFooter from '../../FormFooter/FormFooter';
import IconDelete from '../../Icon/IconDelete';
import ICON_COEUR from '../../Icon/IconeLike.jsx';
import AchatLocSelectionModal from '../../Modal/AchatLocSelectionModal';
import ModalAlerte from '../../Modal/ModalAlerte/ModalAlerte';

export default function CardDetailImmo({
  prixAgence, loyerChargesComprises, diffusion, reference, listProjetsAchats, bien, location, ...vente
}) {
  const { achat } = useShowAchatStore();
  const { venteId } = useParams();
  const form = useForm({
    mode: 'onTouched',
  });

  const { isFavoriteForThisVente } = useFavoriteAction(venteId, achat?.achatId);

  const { formatMessage } = useIntl();
  const [saveActionType, setSaveActionType] = useState('');
  const [actionFinish, setActionFinish] = useState(false);
  const [achatRequiredOpen, setAchatRequiredOpen] = useState(false);
  const [selectedAchatID, setSelectedAchatID] = useState(null);
  const { achatId: achatIdUrl } = useParams();
  const params = useParams();

  const idParam1 = 'achatId';
  const idParam2 = 'venteId';
  const testIdUrl = params[idParam1];
  const venteIdUrl = params[idParam2];

  const handleHideFinish = useCallback(() => {
    setActionFinish(false);
  }, []);

  const handleHideAchatRequired = useCallback(() => {
    setAchatRequiredOpen(false);
  }, []);

  useShowAchat(selectedAchatID);

  const { executeAction } = useActionToProject(setActionFinish, setAchatRequiredOpen);

  const handleExecuteAction = useCallback((actType) => {
    setSaveActionType(actType);
    executeAction(actType, venteId, achatIdUrl, listProjetsAchats);
  }, [achatIdUrl, executeAction, listProjetsAchats, venteId]);

  const onSubmit = useCallback((data) => {
    setSelectedAchatID(data.id);
    executeAction(saveActionType, venteId, data.id, listProjetsAchats);
    setAchatRequiredOpen(false);
  }, [executeAction, saveActionType, venteId, listProjetsAchats]);

  const actionChoiceToFavori = useCallback(() => {
    handleExecuteAction('favori');
  }, [handleExecuteAction]);

  const actionChoiceToIgnore = useCallback(() => {
    handleExecuteAction('ignoree');
  }, [handleExecuteAction]);

  const slidesImages = bien?.photos?.map((photo) => photo.path) ?? [bien?.firstPhoto?.path];

  const getFormattedPrice = (vente, prixAgence, loyerChargesComprises) => {
    if (vente?.variant === Variant.achatvente) {
      const value = prixAgence / 100;

      return isNaN(value) ? '' : value;
    }
    if (vente?.variant === Variant.location) {
      const value = loyerChargesComprises / 100;

      return isNaN(value) ? '' : value;
    }

    return '';
  };

  const formattedPrice = getFormattedPrice(vente, prixAgence, loyerChargesComprises);

  return (
    <div className=" container_Card_Detail_Immo">
      <div className="card_Top_Detail_Immo">
        <h2 className="title_Card_Detail_Immo">{ diffusion?.titre || ''}</h2>
        <div className="container_Price_Coordonee">
          <div className="title_Price_Card_Detail_Immo">
            <FormattedNumber
              value={formattedPrice}
              style="currency"
              currency="EUR"
              minimumFractionDigits="0"
              maximumFractionDigits={vente?.variant === Variant.location ? '2' : '0'}
            />
            {' '}
            <small className="infoMontant">
              {vente?.variant === Variant.location ? 'CC/Mois' : ''}
            </small>
          </div>
          <div className="title_Coordonee_Card_Detail_Immo">
            <img
              src={
                ICON_POSITION
              }
              alt="icon localisation maison"
            />
            <span className="title_Ville_Card_Detail_Immo">
              {diffusion?.ville}
            </span>
            <span className="title_Cp_Card_Detail_Immo">{diffusion?.codePostal}</span>
          </div>
        </div>
      </div>
      <div className="card_Middle_Card_Detail_Immo">
        <CARROUSEL_IMMO slides={slidesImages} />
      </div>
      {actionFinish === true ? (
        <FormFooter>
          <FlexRow alignItems="center">
            <ModalAlerte src={ICON_CHECK} alt={formatMessage({ id: 'alt.icon.check' })} onClick={() => handleHideFinish()}>
              {formatMessage({ id: 'search.action.like.delete' })}
            </ModalAlerte>
          </FlexRow>
        </FormFooter>
      ) : ''}
      {achatRequiredOpen === true
        ? <AchatLocSelectionModal onSubmit={onSubmit} open={achatRequiredOpen} action={saveActionType} onClose={() => handleHideAchatRequired()} />
        : ''}
      <div className="card_Bottom_Card_Detail_Immo">
        <Button onClick={actionChoiceToFavori}>
          <ICON_COEUR className="img-wxl img-hxl" favori={isFavoriteForThisVente} />
        </Button>
        <Share title={formatMessage({ id: 'share.title' })} text={formatMessage({ id: 'share.text' })} url={makeRoutePath('search.vente.show', { venteId }, true)} />
        <Button onClick={actionChoiceToIgnore}>
          <IconDelete className="img-wlg img-hlg" />
        </Button>
      </div>
    </div>
  );
}
