import './AccordionGroup.scss';

import classNames from 'classnames';

export type AccordionGroupProps = {
    children?: React.ReactNode;
}

function AccordionGroup({ children }:AccordionGroupProps): JSX.Element {
  const classes = classNames('AccordionGroup');

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export default AccordionGroup;
