import 'react-phone-number-input/style.css';
import './FormPhone.scss';

import classNames from 'classnames';
import { useController } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';

import { ComponentProps } from '../../types/ComponentProps';
import useFormControl from '../FormControl/useFormControl';

export type FormPhoneProps = ComponentProps<typeof PhoneInput, {
  name?: string;
  invalid?: boolean;
}>

function FormPhone({
  className,
  name,
  disabled,
  invalid,
  ...props
}: FormPhoneProps): JSX.Element {
  const formControl = useFormControl();
  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const classes = classNames('FormPhone', className, {
    'FormPhone-invalid': invalid ?? fieldState.invalid,
    'FormPhone-disabled': disabled,
  });

  return (
    <PhoneInput
      className={classes}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      ref={field.ref}
      disabled={disabled}
      defaultCountry="FR"
      numberInputProps={{ className: 'FormPhone_input' }}
      {...props}
    />

  );
}

export default FormPhone;
