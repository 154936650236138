import { useState } from 'react';
import { useIntl } from 'react-intl';
import './conso.scss';

function calculateEpcLetter(value) {
  switch (true) {
  case value >= 1 && value <= 5:
    return 'A';
  case value >= 6 && value <= 10:
    return 'B';
  case value >= 11 && value <= 20:
    return 'C';
  case value >= 21 && value <= 35:
    return 'D';
  case value >= 36 && value <= 55:
    return 'E';
  case value >= 56 && value <= 80:
    return 'F';
  case value >= 80:
    return 'G';
  default:
    return 'none';
  }
}

export default function CONSO({ gesEpcValue }) {
  const { formatMessage } = useIntl();
  const [gesEpcLetter] = useState(
    calculateEpcLetter(gesEpcValue),
  );

  return (
    <div className="container_chart_DPE_GES">
      <div className="chart_CONSO">
        <div className="bars">
          {['A', 'B', 'C', 'D', 'E', 'F', 'G'].map((letter) => (
            <div
              key={`Letter-conso-${letter}`}
              className={`bar conso-${letter} ${gesEpcLetter === letter ? 'active' : ''}`}
            >
              <div className="bar-letter">{letter}</div>
              {gesEpcLetter === letter && (
                <div className={`cons-conso conso-bloc conso-${letter}`}>
                  <div className="point" />
                  <div className="value">
                    <div className="infoCONSO">
                      <strong>{gesEpcValue}</strong>
                      <small>{formatMessage({ id: 'info.bien.CO2' })}</small>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {gesEpcLetter === 'none' && (
        <div className="cons-conso conso-bloc conso-NC">
          <div className="point" />
          <div className="value">
            <div className="infoCONSO">
              <strong>{gesEpcValue}</strong>
              <small>{formatMessage({ id: 'info.bien.CO2' })}</small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
