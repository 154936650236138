import axios from 'axios';

async function fetchNearbyCommunes(lat, lon, radius) {
  const overpassQuery = `
[out:json];
(
  node["admin_level"="8"](around:${radius * 1000},${lat},${lon});
  way["admin_level"="8"](around:${radius * 1000},${lat},${lon});
  relation["admin_level"="8"](around:${radius * 1000},${lat},${lon});
  );
  out center;
  `;
  const communeNameFilter = [];
  try {
    const url = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`;
    const response = await axios.get(url);
    if (response.data && response.data.elements) {
      const communes = response.data.elements;
      communes.forEach((element) => {
        const key = element.id;
        const communeName = element.tags && element.tags.name;
        const postalCode = element.tags && element.tags.postal_code;
        if (postalCode !== undefined) {
          communeNameFilter.push({ key, communeName, postalCode });
        }
      });
    }
  } catch (error) {
    throw new Error(`Erreur lors de la récupération des entités : ${error}`);
  }

  return communeNameFilter;
}

const OpenStreetMap = {
  fetchNearbyCommunes,
};

export default OpenStreetMap;
