import './ListTab.scss';
import { useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import useQueryParams from '../../hooks/useQueryParams';
import { Variant } from '../../lib/Achats/Achats.types';
import { useFilter } from '../../provider/FilterProvider';
import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button.tsx';
import ButtonTitleItems from '../Button/ButtonTitleItems/ButtonTitleItems';

export default function ListTab({
  listItems,
  idParam,
  defaultTitle,
  routePathDefaultTitle,
}) {
  const params = useParams();
  const queryParams = useQueryParams();
  const dynamicParamsId = params[idParam];
  const dynamicQueryParamsId = queryParams.get(idParam);
  const element = dynamicParamsId || dynamicQueryParamsId;

  // Places the visual in the center of the screen
  const scrollRestoredRef = useRef(false);
  useLayoutEffect(() => {
    if (scrollRestoredRef.current) {
      return undefined;
    }
    if (element && listItems.length) {
      scrollRestoredRef.current = true;
      const itemsElement = document.getElementById(element);
      if (itemsElement) {
        const { left, right } = itemsElement.getBoundingClientRect();
        const notVisible = left < 0 || right > window.innerWidth;
        if (notVisible) {
          itemsElement.scrollIntoView({
            block: 'center',
            inline: 'center',
          });
        }
      }
    }

    return undefined;
  }, [dynamicQueryParamsId, listItems.length, element]);

  return (
    <div id="select_toggle">
      <div className="button-container">
        {defaultTitle
          ? (
            <Button
              key="containerItems"
              to={routePathDefaultTitle}
              className="containerItems"
              value={null}
            >
              <p
                className={`defaultTitle ${
                  (dynamicParamsId || dynamicQueryParamsId) === (undefined || null) ? 'activeTitle' : ''
                }`}
              >
                {defaultTitle}
              </p>
            </Button>
          ) : ''}
        {listItems?.map((item) => (
          <ButtonTitleItems key={`${item.key}`} item={item} routePath={item.url} idParam={(dynamicParamsId || dynamicQueryParamsId)} />
        )) ?? null}
      </div>
    </div>
  );
}
