import './CreateAchatLayout.scss';

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';

import PageHeader from '../../Component/PageHeader/PageHeader';
import useQueryParams from '../../hooks/useQueryParams';
import { showAchat } from '../../lib/papirisClient';

import useCreateAchatStore from './useCreateAchatStore';

function CreateAchatLayout(): JSX.Element {
  const { achat, setAchat } = useCreateAchatStore();
  const { formatMessage } = useIntl();
  const params = useParams();
  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || achat?.variant || undefined;

  /** Refresh achat if any was found in store, or use the draft */
  const showAchatQuery = useQuery({
    queryKey: ['achat', params.achatId],
    queryFn: () => showAchat(params.achatId ?? 'draft'),
  });

  useEffect(() => {
    if (showAchatQuery.data) {
      setAchat(showAchatQuery.data);
    }
  }, [showAchatQuery.data, setAchat]);

  return (
    <>
      <PageHeader
        titleRotate={(
          <span>
            {variant === 'location' ? formatMessage({ id: 'location.create.header_title' }, { br: () => <br /> }) : formatMessage({ id: 'achat.create.header_title' }, { br: () => <br /> })}
          </span>
        )}
      />
      <Outlet />
    </>
  );
}

export default CreateAchatLayout;
