import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { BienListModel } from '../../lib/papirisClient.types';

type ListBienState = {
    list: BienListModel | null;
    isLoading: boolean;
    isError:boolean;
}

type ListBienAction = {
    setList(list: BienListModel | null): void;
    setLoading(isLoading: boolean): void;
    setError(isError: boolean): void;
    reset():Promise<void>;
}

export type ListBienStore = ListBienState & ListBienAction;

const initialState: ListBienState = {
  list: null,
  isLoading: false,
  isError: false,
};

const useListBienStore = create<ListBienStore>()(
  persist((set) => ({
    ...initialState,
    setList: (list) => set(() => ({ list })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setError: (isError) => set(() => ({ isError })),
    reset: async () => set(initialState),
  }), { name: 'list-bien-store' }),
);

export default useListBienStore;
