import React from 'react';
import './button.scss';
import PropTypes from 'prop-types';

import Button from './Button';

/**
Component for created a ButtonDossier.
@param {object} props - The props object for the component.
@param {string} props.url - The URL to link to when the button is clicked.
@param {string} props.className - The CSS class name(s) for the button.
@param {string} props.download - The URL of the icon image to display.
@param {string} props.eye - The URL of the icon image to display.
@param {string} props.title - The title for the folder.
@example
return (
    <Link to={props.url}>
        <div className="container_dossier">
          <button className={props.className}>
            <button className="Ouvrir" alt="Ouvrir_Document">
              {icon_Download}
            </button>
            {icon_Eye}
          </button>
          <h2>{props.title}</h2>
        </div>
      </Link>
 )
@returns {JSX.Element} A JSX element representing the button component.
 */
export default function ButtonFolderFiles(props) {
  const { icon } = props;
  const iconFontAwesome = icon ? props.icon : '';

  return (
    <div className="container_folder_file">
      <Button
        className="folder_file_card"
        onClick={props.url}
        icon={iconFontAwesome}
      >
        {props.children}
      </Button>
    </div>
  );
}
ButtonFolderFiles.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.string,
  eye: PropTypes.string,
  title: PropTypes.string,
};
