import {
  useEffect, useState,
} from 'react';

import Button from '../../../Button/Button';
import FlexRow from '../../../FlexRow/FlexRow';
import './ModalInfoMedium.scss';
import IconInfoRound from '../../../Icon/IconInfoRound';
import ModalInfoLight from '../ModalInfoLight/ModalInfoLight';

export type ModalProps = {
  title?: string;
  text?: string;
  textMoreInfo?: string;
  textButton?: string;
  ariaLabel?: string;
  onClick?: () => void;
  open: boolean;
}

export default function ModalInfoMedium({
  title, text, textMoreInfo, textButton, ariaLabel, onClick, open,
}: ModalProps) {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);

  useEffect(() => {
    setOpenMoreInfo(false);
  }, []);

  const handleMoreInfo = () => {
    setOpenMoreInfo((previousShow) => !previousShow);
  };

  if (!open) return null;

  return (
    <div
      className="container_Modal_InfoMedium"
      role="dialog"
      aria-modal="true"
    >
      <FlexRow
        className="modal_InfoMedium"
        direction="column"
        grow="0rem"
        alignItems="center"
        justifyContent="center"
      >
        <h1 className="titleInfoMedium">{title}</h1>
        <p className="textInfoMedium">{text}</p>
        <Button
          className="iconMoreInfo"
          aria-label={ariaLabel}
          onClick={handleMoreInfo}
        >
          <IconInfoRound className="img-wxxl img-hxxl" classColor="ColorInfoMedium" />
        </Button>
        <Button
          className="button submit yellow"
          aria-label={ariaLabel}
          onClick={onClick}
        >
          {textButton}
        </Button>

        <ModalInfoLight
          open={openMoreInfo === true}
          onClick={handleMoreInfo}
          textMoreInfo={textMoreInfo}
        />

      </FlexRow>
    </div>
  );
}
