import { Resolver, zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as z from 'zod';

type Zod = typeof z

// eslint-disable-next-line no-shadow
type UseZodFormSchemaBuilder<S extends Zod.Schema> = (z: Zod) => S

type UseZodFormParam<S extends Zod.Schema, V extends Partial<Zod.infer<S>>> = {
  schemaBuilder: UseZodFormSchemaBuilder<S>;
  initialValues: V;
  onSubmit(values: Exclude<Required<V>, undefined>): void;
}

type UseZodFormReturn<S extends Zod.Schema, V extends Partial<Zod.infer<S>>> = {
    z: Zod;
    schema: S;
    resolver: ReturnType<Resolver>;
    initialValues: V;
    onSubmit(values: Exclude<Required<V>, undefined>): void;
}

function useZodForm<S extends Zod.Schema, V extends Partial<Zod.infer<S>>>({
  schemaBuilder,
  initialValues,
  onSubmit,
}: UseZodFormParam<S, V>): UseZodFormReturn<S, V> {
  const { formatMessage } = useIntl();

  const errorMap = useCallback<z.ZodErrorMap>((issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === 'email') {
        return { message: formatMessage({ id: 'error.form.invalid_email' }) };
      }
    }

    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: formatMessage({ id: 'error.form.required' }) };
    }

    if (issue.code === z.ZodIssueCode.invalid_literal) {
      if (issue.expected === true) {
        return { message: formatMessage({ id: 'error.form.checkbox_required' }) };
      }
    }

    if (issue.code === z.ZodIssueCode.too_small) {
      if (issue.type === 'string' && issue.minimum === 1) {
        return { message: formatMessage({ id: 'error.form.required' }) };
      }

      if (issue.type === 'string') {
        return { message: formatMessage({ id: 'error.form.string_too_small' }, { minimum: String(issue.minimum) }) };
      }

      if (issue.type === 'array' && issue.minimum === 1) {
        return { message: formatMessage({ id: 'error.form.array_too_small' }, { minimum: issue.minimum }) };
      }
    }

    return { message: ctx.defaultError };
  }, [formatMessage]);

  useEffect(() => {
    z.setErrorMap(errorMap);
  }, [errorMap]);

  const schema = useMemo(() => schemaBuilder(z), [schemaBuilder]);
  const resolver = useMemo(() => zodResolver(schema), [schema]);

  return {
    z,
    schema,
    resolver,
    initialValues,
    onSubmit,
  };
}

export default useZodForm;
