import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconIgnoreRound.scss';

function IconIgnoreRound({
  className, classColor = 'IgnoreRound',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_ignorer', className)} id="icon_ignorer" data-name="icon ignorer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 31">
        <title id="icon_ignorer">Icon ignorer</title>
        <ellipse id="Ellipse_117" data-name="Ellipse 117" className={`${classColor}-cls-1`} cx="16" cy="15.5" rx="16" ry="15.5" />
        <path id="Icon_metro-cross" data-name="Icon metro-cross" className={`${classColor}-cls-2`} d="M16.569,13.108h0L12.283,8.886l4.286-4.222h0a.432.432,0,0,0,0-.615L14.544,2.055a.446.446,0,0,0-.467-.1.44.44,0,0,0-.158.1h0L9.634,6.277,5.349,2.055h0a.439.439,0,0,0-.157-.1.446.446,0,0,0-.467.1L2.7,4.049a.432.432,0,0,0,0,.615h0L6.985,8.886,2.7,13.108h0a.432.432,0,0,0,0,.615l2.024,1.994a.446.446,0,0,0,.467.1.44.44,0,0,0,.157-.1h0L9.634,11.5l4.286,4.222h0a.44.44,0,0,0,.157.1.446.446,0,0,0,.467-.1l2.024-1.994a.431.431,0,0,0,0-.615Z" transform="translate(6.366 7.093)" />
      </svg>
    </div>
  );
}

export default IconIgnoreRound;
