import { useMatch } from 'react-router-dom';

import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button';
import Search from '../Selecteur/SearchBar/SearchBar';

export default function SwitcherSearchButton() {
  const match = useMatch(makeRoutePath('search.achat.show'));
  if (!match) {
    return <Search />;
  }
  const { achatId } = match.params;

  if (achatId === 'nouveau') {
    return null;
  }

  return (
    <Button
      to={makeRoutePath('achat.show', { achatId })}
      className="button submit yellow"
      aria-label="Voir mon projet d'achat"
    >
      <p>Mon activité</p>
    </Button>
  );
}
