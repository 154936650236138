import './MonContactPage.scss';

import { useState } from 'react';

// Import des Composants
import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import ProfileForm from '../../Component/Form/ProfileForm/ProfileForm';
import HEADER_RETOUR from '../../Component/Header_Retour/header_retour.jsx';
import IconDelete from '../../Component/Icon/IconDelete';
import MODAL_BOTTOM from '../../Component/Modal/Modal_Bottom/modal_bottom.jsx';
import PRESENTATION_USER from '../../Component/PresentationUser/PresentationUser';
import useQueryParams from '../../hooks/useQueryParams';
import { useUser } from '../../provider/UserProvider';
import { isRoutePath } from '../../store/route/route.api';
import IconUpload from '../../Component/Icon/IconUpload';
import IconAddWhiteAndYellow from '../../Component/Icon/IconAddWhiteAndYellow';

const DEFAULT_TO = '/profil';

export default function MonContactPage() {
  const { user } = useUser();
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const query = useQueryParams();
  const backTo = query.get('backTo');
  const to = isRoutePath(backTo) ? backTo : DEFAULT_TO;

  return (
    <>
      <HEADER_RETOUR to={to} transparent />
      <div className={`container_Formulaire_User ${modal ? 'disabled' : ''}`}>
        <FlexRow alignItems="center">
          <div className="user">
            <Button
              className="edit_User"
              alt="button edit picture"
              onClick={toggleModal}
            >
              {user?.contact
                ? (
                  <>
                    <PRESENTATION_USER
                      avatar={user.contact.avatar}
                      prenom={user.contact.prenom}
                      nom={user.contact.nom}
                    />
                    <span className="editAvatar">+</span>
                  </>
                ) : null}
            </Button>
          </div>
        </FlexRow>
        <Container>
          <ProfileForm backTo={to} />
        </Container>
      </div>
      {modal && (
        <div className="modal_edition">
          <div className="modal_edition-content">
            <MODAL_BOTTOM
              title="Modifier ma photo de profil"
              buttons={[
                {
                  src: <IconUpload className="img-wxl img-hxl" />,
                  text: 'Importer depuis la galerie',
                },
                {
                  src: <IconAddWhiteAndYellow className="img-wxl img-hxl" />,
                  text: 'Prendre une photo',
                },
                {
                  src: <IconDelete className="img-wxl img-hxl" />,
                  text: 'Supprimer la photo',
                },
              ]}
              onClick={toggleModal}
            />
          </div>
        </div>
      )}
    </>
  );
}
