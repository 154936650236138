import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconHouse.scss';

function IconHouse({
  className, classColor,
}: IconProps) {
  return (
    <div className="containerImage">
      <svg
        className={classNames('Icon Icon-maison', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 89.345 83.335"
      >
        <title id="icon-house-title">Icon du Projet</title>
        <g transform="translate(44.672 41.698)">
          <g id="Groupe_1017" data-name="Groupe 1017" transform="translate(-44 -40.965)">
            <g id="Groupe_1016" data-name="Groupe 1016" transform="translate(5.001 12.219)">
              <path
                id="Tracé_3422"
                data-name="Tracé 3422"
                className={`${classColor}-cls-1`}
                d="M111.112,106.01a1.336,1.336,0,0,1,.517.933q.143,1.553.143,3.141c0,20.087-17.461,36.371-39,36.371s-39-16.284-39-36.371q0-1.587.143-3.141a1.337,1.337,0,0,1,.517-.933L71.949,77.025a1.348,1.348,0,0,1,1.648,0Z"
                transform="translate(-33.773 -76.744)"
              />
            </g>
            <path
              id="Tracé_3423"
              data-name="Tracé 3423"
              className={`${classColor}-cls-1`}
              d="M8.342,42.871,47.158,11.807a1.348,1.348,0,0,1,1.686,0L87.569,42.89a1.332,1.332,0,0,0,.936.293A3.445,3.445,0,0,0,92,39.891a1.355,1.355,0,0,0-.5-1.14L48.84,4.3a1.348,1.348,0,0,0-1.683-.008L4.511,38.054A1.345,1.345,0,0,0,4,39.118c.009,1.232.4,3.717,3.366,4.043A1.334,1.334,0,0,0,8.342,42.871Z"
              transform="translate(-4 -4)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default IconHouse;
