// Import Swiper React components
import { Keyboard, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import './carrousel.scss';

// import modules

/** Composant pour donnée mocked */
// export default function CARROUSEL_IMMO(props) {
//   return (
//     <div className="container_Carroussel">
//       <Swiper
//         Swiper
//         id="main"
//         tag="section"
//         slidesPerView={1}
//         spaceBetween={50}
//         keyboard={{
//           enabled: true,
//         }}
//         pagination={{
//           clickable: true,
//         }}
//         modules={[Keyboard, Pagination]}
//         className="mySwiper"
//         onInit={(swiper) => {
//         }}
//         onSlideChange={(swiper) => {
//         }}
//         onReachEnd={() => {
//         }}
//       >
//         {props.slides}
//       </Swiper>
//     </div>
//   );
// }

/** Composant pour vrai data */
export default function CARROUSEL_IMMO({ slides }) {
  // Vérifie si slides est un tableau, sinon le transforme en tableau
  const slidesObject = Array.isArray(slides) ? slides : [slides];

  return (
    <div className="container_Carroussel">
      <Swiper
        id="main"
        tag="section"
        slidesPerView={1}
        spaceBetween={50}
        // keyboard={{
        //   enabled: true,
        //   onlyInViewport: true,
        // }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {slidesObject.map((url, index) => (
          <SwiperSlide
            key={`SwiperSlide-${url}`}
            virtualIndex={index}
          >
            <img src={url} alt={`Slide ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
