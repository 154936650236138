import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { AchatModel } from '../../lib/Achats/Achats.types';

type CreateAchatState = {
  achat: AchatModel|null;
  invitations: string[];
}

type CreateAchatActions = {
  setAchat(achat: AchatModel|null): void;
  addInvitation(email: string): void;
  removeInvitation(email: string): void;
  reset(): void;
}

export type CreateAchatStore = CreateAchatState & CreateAchatActions

const initialState: CreateAchatState = {
  achat: null,
  invitations: [],
};

const useCreateAchatStore = create<CreateAchatStore>()(
  persist((set) => ({
    ...initialState,
    setAchat: (achat) => set(() => ({ achat })),
    addInvitation: (email) => set((state) => ({ invitations: [...state.invitations.filter((e) => e !== email), email] })),
    removeInvitation: (email) => set((state) => ({ invitations: [...state.invitations.filter((e) => e !== email)] })),
    reset: () => set(initialState),
  }), {
    name: 'create-achat',
  }),
);

export default useCreateAchatStore;
