import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import IconPhoneRound from '../../Component/Icon/IconPhoneRound';
import PresentationConseiller from '../../Component/PresentationConseiller/PresentationConseiller';
import ICON_POSITION from '../../img/icon_position.svg';
import { showCollaborateur } from '../../lib/papirisClient';

import './conseillerPresentation.scss';
import { FormattedMessage } from 'react-intl';

export default function ConseillerLayout() {
  const { id } = useParams();

  const { data: conseiller, isLoading, isError } = useQuery(['show', showCollaborateur], () => showCollaborateur(id));

  if (isLoading) {
    <DefaultLoader />;
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  return (
    <FlexRow
      direction="row"
      gap="4"
      grow="1"
      alignItems="start"
      justifyContent="space-evenly"
    >
      <Container
        size="lg"
        className="container_Conseiller_Presentation"
      >
        <div className="carte_Agence">
          <div className="agence">
            <h1 className="title">
              { conseiller?.agence?.nom }
            </h1>
            <div className="container_Adresse">
              <img src={ICON_POSITION} alt="icon adresse de l'agence" />
              <div className="fullAdresse">
                {conseiller?.agence?.adresse ? (
                  <a href={`https://www.google.com/maps/search/?api=1&query=${conseiller.agence.adresse} ${conseiller.agence.codePostal} ${conseiller?.agence?.ville}`} className="adresse">
                    { conseiller?.agence?.adresse }
                    <div className="cp">
                      {`${conseiller?.agence?.codePostal} ${conseiller?.agence?.ville}`}
                    </div>
                  </a>
                ) : null}

              </div>
            </div>

            <Button className="button standard telephone" href={`tel:${conseiller?.agence?.telephone}`}>
              <IconPhoneRound className="img-wxxl img-hxxl" />
              Appeler
            </Button>
          </div>
        </div>
        <FlexRow
          className="container_Conseiller_Description"
          direction="column"
          gap="4"
          grow="1"
          alignItems="center"
        >
          <PresentationConseiller
            avatar={conseiller?.avatar ?? ''}
            prenom={conseiller?.prenom ?? ''}
            nom={conseiller?.nom}
          />
          <p className="description">
            {conseiller?.biographie ? conseiller?.biographie : ''}
          </p>
        </FlexRow>
      </Container>
    </FlexRow>
  );
}
