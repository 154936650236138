import classnames from 'classnames';
import {
  ReactNode, useCallback, useEffect, useState,
} from 'react';

import Button from '../../Button/Button';

import './ModalGlow.scss';
import { useIntl } from 'react-intl';

import FlexRow from '../../FlexRow/FlexRow';

export type ModalGlowProps = {
  open?: boolean;
  className:string;
  onClose?(): void;
  onSubmit?(): void;
  children: ReactNode;
  title: ReactNode;
  underTitle: string;
  src1: ReactNode;
  alt1?: string;
  src2?: ReactNode;
  alt2?: string;
  url: string;
}

export default function ModalGlow({
  open = true,
  className,
  onClose,
  onSubmit,
  children,
  title,
  underTitle,
  src1,
  src2,
  url,
}: ModalGlowProps) {
  const [show, setShow] = useState(open);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleHide = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      setShow((previousShow) => !previousShow);
    }
  }, [onClose]);

  return (
    <div className={classnames('container_Modal_Glow', className, { open: show, close: !show })}>
      <div className="modal-content">
        <FlexRow alignItems="center" gap="1rem" className="text-content-button">
          <div className="buttons_Modal">
            {src2
              ? (
                <Button
                  className="open-button"
                  ariaLabel={formatMessage({ id: 'label.open' })}
                  onClick={onSubmit}
                >
                  {typeof src2 === 'string' ? <img src={src2} alt={formatMessage({ id: 'label.open' })} /> : null}
                </Button>
              ) : ''}
            {title ? <p className="title_Modal_Glow">{title}</p> : ''}
            {src1 ? (
              <Button
                className="close-button"
                ariaLabel={formatMessage({ id: 'label.close' })}
                onClick={handleHide}
              >
                {src1}
              </Button>
            ) : ''}
          </div>
          {url ? (
            <a className="text-content-button" href={url}>
              {underTitle ? <p className="underTitle">{underTitle}</p> : ''}
              <div className="children">{children}</div>
            </a>
          ) : (
            <>
              {underTitle ? <p className="underTitle">{underTitle}</p> : ''}
              <div className="children">{children}</div>
            </>
          )}
        </FlexRow>
      </div>
    </div>
  );
}
