import './option_Checkbox_Value_String.scss';

export type OptionType = {
  label: string;
  value: string;
};

export type OPTION_CHECKBOX_VALUE_STRING_Props = {
  labelTitle?: string;
  icon?: string;
  options?: OptionType[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  alt?: string;
  name?: string;
}
export default function OPTION_CHECKBOX_VALUE_STRING({
  labelTitle,
  icon,
  options,
  onChange,
  alt,
  name,
}:OPTION_CHECKBOX_VALUE_STRING_Props) {
  return (
    icon ? (
      <div className="options_container">

        <div className="info_option">
          <img src={icon} alt={alt || 'Icon'} className="icon" />
          <div>{labelTitle}</div>
        </div>
        <div className="boutons_checkbox">
          {options?.map((option, index) => (
            <div className="option" key={`option_${option.label}_${labelTitle}`}>
              <input
                type="checkbox"
                value={option?.label || option?.value}
                id={`option_${index}_${labelTitle}`}
                name={name}
                onChange={onChange}
              />
              <label htmlFor={`option_${index}_${labelTitle}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
      : (
        <div className="boutons_checkbox">
          {options?.map((option, index) => (
            <div className="option" key={`option_${option.label}_${labelTitle}`}>
              <input
                type="checkbox"
                value={option?.label || option?.value}
                id={`option_${index}_${labelTitle}`}
                name={name}
                onChange={onChange}
              />
              <label htmlFor={`option_${index}_${labelTitle}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )

  );
}
