import './Footer.scss';
import iconHome from '../../img/icon_accueil.svg';
import iconMessage from '../../img/icon_message.svg';
import iconRecherche from '../../img/icon_recherche.svg';
import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button';
import IconHome from '../Icon/IconHome';
import IconMessage from '../Icon/IconMessage';
import IconSearch from '../Icon/IconRecherche';
import InfoPastilleNumber from '../InfoPastilleNumber/InfoPastilleNumber';

export type FooterProps = {nbMessage?: number}

export default function Footer({ nbMessage }: FooterProps) {
  return (
    <>
      <div className="footer_spacer" />
      <div className="footer">
        <div className="container_icon">
          <Button className="icon_footer" to={makeRoutePath('search')} icon={<IconSearch className="img-wxl img-hxl" />} />
          <Button className="icon_footer" to={makeRoutePath('home')} icon={<IconHome className="img-wxl img-hxl" />} />
          <Button className="icon_footer" to={makeRoutePath('messagerie')} icon={<IconMessage className="img-wxl img-hxl" />}>
            {nbMessage ? <InfoPastilleNumber infoPastilleNumber={nbMessage} /> : null}
          </Button>
        </div>
      </div>
    </>
  );
}
