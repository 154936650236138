import './decouverte_2.scss';

import {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import useCreateAchatStore from '../../../Pages/CreateAchatLayout/useCreateAchatStore';
import RedirectError from '../../../Pages/Error/RedirectError';
import useShowAchatStore from '../../../Pages/ShowAchatLayout/useShowAchatStore';
import usePapirisErrorForm from '../../../hooks/usePapirisErrorForm';
import useZodForm from '../../../hooks/useZodForm';
import { updateDecouverteFamille } from '../../../lib/papirisClient';
import { makeRoutePath } from '../../../store/route/route.api';
import Button from '../../Button/Button';
import CounterField from '../../Button/CounterField';
import Container from '../../Container/Container';
import FlexRow from '../../FlexRow/FlexRow';
import FormControl from '../../FormControl/FormControl';
import FormFooter from '../../FormFooter/FormFooter';
import FormLabel from '../../FormLabel/FormLabel';
import FormSelect from '../../FormSelect/FormSelect';
import Title from '../../Title/Title';
import Form from '../Form';

const CHILD_SCHOOL_OPTIONS = [
  {
    id: 1,
    value: 'Une crèche/assistante maternelle',
    label: 'Une crèche/assistante maternelle',
  },
  {
    id: 2,
    value: 'Une école maternelle',
    label: 'Une école maternelle',
  },
  {
    id: 3,
    value: 'Une école primaire',
    label: 'Une école primaire',
  },
  { id: 4, value: 'Un collège', label: 'Un collège' },
  { id: 5, value: 'Un lycée', label: 'Un lycée' },
  {
    id: 6,
    value: "Un établissement d'enseignement supérieur",
    label: "Un établissement d'enseignement supérieur",
  },
  {
    id: 7,
    value: 'Un établissement spécialisé',
    label: 'Un établissement spécialisé',
  },
];

export default function DECOUVERTE_2() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const locationUrl = useLocation();
  const { achat } = useCreateAchatStore();
  const { achat: projetAchatUpdate } = useShowAchatStore();
  const queryClient = useQueryClient();
  const mutation = useMutation(updateDecouverteFamille);
  const [papirisError, setPapirisError] = useState(null);

  const typeOfProjet = locationUrl?.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id }) ? projetAchatUpdate : achat;

  useEffect(() => {
    if (!typeOfProjet) {
      throw new RedirectError('../contact');
    }
  }, [typeOfProjet]);

  const initialValuesFamille = useMemo(() => (
    {
      adultsCount: typeOfProjet?.decouverte?.adultsCount ?? 1,
      childrenCount: typeOfProjet?.decouverte?.childrenCount ?? 0,
      childrenSchools: typeOfProjet?.decouverte?.childrenSchools ?? [],
    }
  ), [typeOfProjet?.decouverte?.adultsCount, typeOfProjet?.decouverte?.childrenCount, typeOfProjet?.decouverte?.childrenSchools]);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      adultsCount: z.number().min(1),
      childrenCount: z.number().min(0),
      childrenSchools: z.array(z.string()).optional(),
    }), []),

    initialValues: useMemo(() => (
      initialValuesFamille
    ), [initialValuesFamille]),

    onSubmit: async (values) => {
      try {
        if (typeOfProjet?.decouverte?.id) {
          await mutation.mutateAsync({
            id: typeOfProjet?.decouverte?.id,
            childrenCount: values?.childrenCount,
            adultsCount: values?.adultsCount,
            childrenSchools: [...new Set(values?.childrenSchools)],
          });
        }
        if (locationUrl?.pathname === makeRoutePath('achat.show.update', { achatId: typeOfProjet?.id })) {
          await queryClient.invalidateQueries({ queryKey: 'achat' });
        } else {
          await queryClient.invalidateQueries({ queryKey: 'achat' });
          navigate('../immobilier');
        }
      } catch (error) {
        setPapirisError(error);
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  const {
    watch,
    setValue,
    formState: {
      isSubmitting,
    },
  } = form;

  usePapirisErrorForm(form, papirisError);

  const { adultsCount, childrenCount, childrenSchools } = watch();

  const handleEtablissementScolaireChange = useCallback((childSchool, index) => {
    const newChildrenSchools = [
      ...childrenSchools,
    ];
    newChildrenSchools[index] = childSchool;

    setValue('childrenSchools', newChildrenSchools);
  }, [setValue, childrenSchools]);

  const selectedOptions = [];

  for (let index = 0; index < childrenCount; index += 1) {
    const name = `typeEtablissementScolaire${index}`;

    selectedOptions.push(
      <div key={`enfant${index}`} id={`enfant${index}`}>
        <FormControl>
          <FormLabel>
            <FormattedMessage id="achats.create.famille.child_school" values={{ index: index + 1 }} />
          </FormLabel>
          <FormSelect
            options={CHILD_SCHOOL_OPTIONS}
            value={childrenSchools[index]}
            onChange={(newValue) => handleEtablissementScolaireChange(newValue, index)}
          />
        </FormControl>
      </div>,
    );
  }

  const handleAdultsCountChange = useCallback((adultsCount) => {
    setValue('adultsCount', adultsCount);
  }, [setValue]);

  const handleChildrenCountChange = useCallback((childrenCount) => {
    setValue('childrenCount', childrenCount);
  }, [setValue]);

  return (
    <Container size="lg">
      <Title
        title={formatMessage({ id: 'achats.create.famille.title' })}
        positon="start"
        subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 2, total: 4 })}
      />
      <Form onSubmit={onSubmit} {...form}>
        <FlexRow gap="1em">
          <FlexRow direction="row" gap="1em" justifyContent="center">
            <CounterField
              label={formatMessage({ id: 'achats.create.famille.adult_label' })}
              value={adultsCount}
              onChange={handleAdultsCountChange}
              min={1}
            />
            <CounterField
              label={formatMessage({ id: 'achats.create.famille.child_label' })}
              value={childrenCount}
              onChange={handleChildrenCountChange}
              min={0}
            />
          </FlexRow>
          <FormattedMessage id="achats.create.famille.child_schools" values={{ count: childrenCount }} />
          {selectedOptions}
        </FlexRow>
        <FormFooter>
          <FlexRow alignItems="center" justifyContent="space-around" direction="row">
            { locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id })
              ? ''
              : (
                <Button
                  icon={<i className="fa-solid fa-chevron-left" />}
                  to="../contact"
                >
                  <FormattedMessage id="label.back" />
                </Button>
              )}

            <Button
              className="button submit yellow"
              type="submit"
              disabled={isSubmitting}
            >
              <FormattedMessage id="label.continue" />
            </Button>
          </FlexRow>
        </FormFooter>
      </Form>
    </Container>
  );
}
