import './CreateLocationLayout.scss';

import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';

import PageHeader from '../../Component/PageHeader/PageHeader';

function CreateLocationLayout(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader
        titleRotate={(
          <span>
            {formatMessage({ id: 'location.create.header_title' }, { br: () => <br /> })}
          </span>
        )}
      />
      <Outlet />
    </>
  );
}

export default CreateLocationLayout;
