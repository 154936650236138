import { ReactNode, JSX } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

export type QueryProviderProps = {
    children: ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function QueryProvider(props: QueryProviderProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClient} {...props} />
  );
}
