import React from 'react';
import './option_Checkbox_Value_Circle.scss';

export type OptionCheckValueCircleProps = {
  className: string;
  label: string;
  name: string;
  value1?: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
  value6?: string;
  value7?: string;
  icon?: string;
  alt: string;
  labelTitle: string;
  labelSubtitle: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export default function OptionCheckValueCircle({
  icon,
  value1,
  value2,
  value3,
  value4,
  value5,
  value6,
  value7,
  alt,
  labelSubtitle,
  labelTitle,
  onChange,
  name,
}: OptionCheckValueCircleProps) {
  const values = [
    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
  ];

  const filteredValues = values.filter((value) => value !== undefined);

  return icon ? (
    <div className="options_container">
      <div className="info_option">
        <img src={icon} alt={alt} className="icon" />
        <div>{labelTitle}</div>
      </div>

      <div className="boutons_checkbox_number">
        <h3 className="filter_title">{labelSubtitle}</h3>
        <div className="numbers">
          {filteredValues.map((value, index) => (
            <div className="option" key={value}>
              <input
                type="checkbox"
                value={value}
                id={`bouton${index + 1}_${labelTitle}`}
                name={name}
                onChange={onChange}
              />
              <label htmlFor={`bouton${index + 1}_${labelTitle}`}>{value}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="boutons_checkbox_number">
      <h3 className="filter_title">{labelSubtitle}</h3>
      <div className="numbers">
        {filteredValues.map((value, index) => (
          <div className="option" key={value}>
            <input
              type="checkbox"
              value={value}
              id={`bouton${index + 1}_${labelTitle}`}
              name={name}
              onChange={onChange}
            />
            <label htmlFor={`bouton${index + 1}_${labelTitle}`}>{value}</label>
          </div>
        ))}
      </div>
    </div>
  );
}
