import type { User } from 'firebase/auth';
import {
  ReactNode, createContext, useContext, useEffect, useState, JSX,
} from 'react';

import { subscribeAuthChange } from '../lib/firebaseClient';

import { usePermission } from './PermissionProvider';

export type AuthValue = {
    user: User | null;
    ready: boolean;
}

export type AuthProviderProps = {
    children: ReactNode;
}

const defaultValue: AuthValue = {
  user: null,
  ready: false,
};

export const AuthContext = createContext<AuthValue>(defaultValue);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider(props: AuthProviderProps): JSX.Element {
  const [auth, setAuth] = useState<AuthValue>(defaultValue);

  /** Listens to auth changes */
  useEffect(() => {
    const unsubscribe = subscribeAuthChange((user) => {
      setAuth((previousAuth) => ({
        ...previousAuth,
        user,
        ready: true,
      }));
    });

    return unsubscribe;
  }, []);

  /** Toggles auth permissions */
  const { user, ready } = auth;
  const { togglePermission } = usePermission();
  useEffect(() => {
    if (ready) {
      const isAuthenticated = user !== null && user.emailVerified;
      togglePermission('authenticated', isAuthenticated);
      togglePermission('anonymous', !isAuthenticated);
    }
  }, [user, user?.emailVerified, ready, togglePermission]);

  return <AuthContext.Provider value={auth} {...props} />;
}
