import { CircularProgress } from '@mui/material';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Button from '../Component/Button/Button';
import Container from '../Component/Container/Container';
import FlexRow from '../Component/FlexRow/FlexRow';
import IconFile from '../Component/Icon/IconFile';
import IconHouse from '../Component/Icon/IconHouse';
import IconInfoRound from '../Component/Icon/IconInfoRound';
import IconMessage from '../Component/Icon/IconMessage';
import { listNotification, readAllNotification } from '../lib/papirisClient';
import { NotificationContext } from '../provider/NotificationProvider';
import { makeRoutePath } from '../store/route/route.api';

import NotificationItem from './NotificationItem';

import './NotificationList.scss';

export default function NotificationList() {
  const modalRoot = document.getElementById('modal-root');
  const { formatMessage } = useIntl();
  const [listNotif, setListNotif] = useState([]);
  const [listNotifSeeByClient, setListNotifSeeByClient] = useState([]);
  const queryClient = useQueryClient();
  const mutationReadAllNotification = useMutation(readAllNotification);
  const { getPermission, onNotification } = useContext(NotificationContext);
  const hasPermission = getPermission() === 'granted';

  const listNotificationQuery = useQuery(['listNotif'], () => listNotification(), {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: hasPermission,
  });

  const {
    data, isLoading: loading, isError: error, isRefetching, refetch,
  } = listNotificationQuery;

  useEffect(() => {
    const unsubscribe = onNotification(() => {
      refetch();
    });

    return unsubscribe;
  }, [onNotification, refetch]);

  useEffect(() => {
    if (hasPermission && isRefetching === true) {
      setListNotifSeeByClient([]);
    }
    if (hasPermission && isRefetching === false && data?.items.length > 0) {
      setListNotif(data?.items);
      setListNotifSeeByClient(data?.items);
    }
  }, [data, isRefetching, loading, hasPermission]);

  const handleAllClose = useCallback(async () => {
    setListNotif([]);
    setListNotifSeeByClient([]);
    await mutationReadAllNotification.mutateAsync();
    await queryClient.invalidateQueries(['listNotif']);
  }, []);

  const showNotification = listNotifSeeByClient?.length > 0;

  function renderNotification(notif) {
    if (notif?.type === 'bien') {
      return <NotificationItem key={`${notif?.title} ${notif?.body}`} data={notif} src2={<IconHouse className="img-wxxl img-hxxl" classColor="colorNotification" />} title={notif?.title} body={notif?.body ?? notif?.titreRecherche} url={notif?.metadata?.achatId ? makeRoutePath('search.achat.show', { achatId: notif?.metadata?.achatId, variant: notif?.metadata?.variant }) : makeRoutePath('search')} />;
    }
    if (notif?.type === 'document') {
      return <NotificationItem key={`${notif?.title} ${notif?.body}`} data={notif} src2={<IconFile className="img-wxxl img-hxxl" classColor="colorNotification" />} title={notif?.title} body={notif?.body ?? notif?.titreRecherche} url={notif?.metadata?.documentId ? makeRoutePath('documents.show', { documentId: notif?.metadata?.documentId }) : makeRoutePath('documents')} />;
    }
    if (notif?.type === 'message') {
      return <NotificationItem key={`${notif?.title} ${notif?.body}`} data={notif} src2={<IconMessage className="img-wxxl img-hxxl" classColor="colorNotification" />} title={notif?.title} body={notif?.body ?? notif?.titreRecherche} url={notif?.metadata?.messageId ? makeRoutePath('messagerie.show', { messageId: notif?.metadata?.messageId }) : makeRoutePath('messagerie')} />;
    }

    return <NotificationItem key={`${notif?.title} ${notif?.body}`} data={notif} src2={<IconInfoRound className="img-wxxl img-hxxl" classColor="colorNotification" />} title={notif?.title} body={notif?.body ?? notif?.titreRecherche} url={notif?.metadata?.infoId ? makeRoutePath('info.show', { infoId: notif?.metadata?.infoId }) : makeRoutePath('info')} />;
  }

  return (
    showNotification === true && modalRoot ? createPortal(
      loading ? (
        <FlexRow
          justifyContent="center"
          alignItems="center"
          className="container_Centre_Notification"
        >

          <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />

        </FlexRow>
      )
        : error ? console.log(error?.stack) && (
          <FlexRow
            justifyContent="center"
            alignItems="center"
            className="container_Centre_Notification"
          >

            <FormattedMessage id="error.status.500" />

          </FlexRow>
        )
          : (
            <FlexRow
              justifyContent="center"
              alignItems="center"
            >
              <Container
                className="container_Centre_Notification"
              >
                <FlexRow
                  justifyContent="center"
                  alignItems="center"
                >

                  <FlexRow
                    width="inherit"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="3rem"
                  >
                    <h3 className="title_Container_Notification">
                      {formatMessage({ id: 'notification.label.title' })}
                    </h3>
                    <Button
                      className="button button-close-notification"
                      onClick={handleAllClose}
                    >
                      {formatMessage({ id: 'notification.button.allClose' })}
                    </Button>
                  </FlexRow>
                  {(listNotif?.length ?? 0) > 0 ? (
                    <FlexRow
                      className="container_Buttons_Notification"
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap="0.5rem"
                    >
                      {listNotifSeeByClient?.map(renderNotification)}
                    </FlexRow>
                  ) : (
                    ''
                  )}
                </FlexRow>
              </Container>
            </FlexRow>
          ),
      modalRoot,
    )
      : null
  );
}
