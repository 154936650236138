import {
  ReactElement, useCallback, useMemo, useState,
} from 'react';

import Button from '../Button/Button';

import './title_glow.scss';
import { useForm } from 'react-hook-form';

import FlexRow from '../FlexRow/FlexRow';
import Form from '../Form/Form';
import FormControl from '../FormControl/FormControl';
import FormError from '../FormError/FormError';
import FormInput from '../FormInput/FormInput';
import FormLabel from '../FormLabel/FormLabel';

import { useIntl } from 'react-intl';

import usePapirisErrorForm from '../../hooks/usePapirisErrorForm';
import useShowAchatStore from '../../Pages/ShowAchatLayout/useShowAchatStore';
import useShowVenteStore from '../../Pages/ShowVenteLayout/useShowVenteStore';
import { updateAchat } from '../../lib/papirisClient';
import { updateVente } from '../../lib/Ventes/Ventes.api';

import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

export type TitleGlowProps = {
  className?: string;
  title: string;
  underTitle?: string;
  adresse?: string;
  phone?: string;
  icon?: string|ReactElement;
  alt?: string;
  url?: string;
  edit?: boolean;
  onSaveTitle?: (newUnderTitle: string) => void; // Callback function to save the new title
}

export default function TitleGlow({
  className, icon, title, underTitle, adresse, phone, url, edit, onSaveTitle,
}: TitleGlowProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [newUnderTitle, setNewUnderTitle] = useState('');
  const { achat, setAchat } = useShowAchatStore();
  const { vente, setVente } = useShowVenteStore();

  const form = useForm({
    mode: 'onTouched',
  });

  const {
    formState: {
      isSubmitting,
    },
  } = form;

  const { formatMessage } = useIntl();
  const queryParams = useParams();
  const queryClient = useQueryClient();
  const mutationProjetAchat = useMutation(updateAchat);
  const mutationProjetVente = useMutation(updateVente);

  const handleEditClick = () => {
    setIsEditing(!isEditing); // Inverse l'état actuel de isEditing
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUnderTitle(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleEditClick(); // Appelle handleEditClick pour inverser isEditing
    }
  };

  const handleValide = useCallback(async () => {
    try {
      if (achat && achat.id && achat.id === queryParams?.achatId) {
        const achatUpdate = await mutationProjetAchat.mutateAsync({
          achatId: achat.id,
          titre: newUnderTitle || underTitle || '',
        });
        await queryClient.invalidateQueries({ queryKey: 'achat' });
        setIsEditing(false);
      } else if (vente && vente.id && vente.id === queryParams?.venteId) {
        const venteUpdate = await mutationProjetVente.mutateAsync({
          id: vente.id,
          titre: newUnderTitle || underTitle || '',
        });
        await queryClient.invalidateQueries({ queryKey: 'vente' });
        setIsEditing(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [achat, queryParams?.achatId, queryParams?.venteId, vente, mutationProjetAchat, newUnderTitle, underTitle, setAchat, queryClient, mutationProjetVente, setVente]);

  return (
    <Button className={`container_Modal_Title_Glow ${className || ''}`} to={url}>
      <div className={`modal-content ${className || ''}`}>
        {icon || null}
        <div className="text-content-button">
          {title ? <p className={`title ${className || ''}`}>{ title }</p> : null}
          {isEditing ? (
            <>
              <i
                className="fa-solid fa-xmark titleGlow_edit"
                onClick={handleEditClick}
                onKeyDown={handleKeyPress}
                role="button"
                tabIndex={0}
                aria-label="close Edit title"
              />
              <Form onSubmit={handleValide} {...form}>
                <FlexRow gap="1em">

                  <FormControl name="titre">
                    <FormLabel>{formatMessage({ id: 'vente.create.bien.type.titleClient' })}</FormLabel>
                    <FormInput type="texte" onChange={handleTitleChange} placeholder={formatMessage({ id: 'vente.create.bien.type.titleClient.placeholder' })} />
                    <FormError />
                  </FormControl>
                  <Button
                    className="button submit yellow"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {formatMessage({ id: 'label.continue' })}
                  </Button>
                </FlexRow>
              </Form>
            </>
          ) : (
            <p className="children">{ newUnderTitle || underTitle }</p>
          )}
          {adresse ? <small className="adresse">{ adresse }</small> : null}
          {phone ? <small className="phone">{ phone }</small> : null}
        </div>
        {edit === true && !isEditing ? (
          <i
            className="fa-solid fa-pen titleGlow_edit"
            onClick={handleEditClick}
            onKeyDown={handleKeyPress}
            role="button"
            tabIndex={0}
            aria-label="Edit title"
          />
        ) : null}
      </div>
    </Button>
  );
}
