import './CreateVenteLayout.scss';

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Outlet, useLocation } from 'react-router-dom';

import PageHeader from '../../../Component/PageHeader/PageHeader';
import useQueryParams from '../../../hooks/useQueryParams';
import { showVente } from '../../../lib/papirisClient';

import useCreateVenteStore from './useCreateVenteStore';

function CreateVenteLayout(): JSX.Element {
  const { formatMessage } = useIntl();
  const { vente, setVente } = useCreateVenteStore();

  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || vente?.variant || undefined;

  const showVenteQuery = useQuery({
    queryKey: ['vente', vente?.id],
    queryFn: () => (vente ? showVente(vente.id) : undefined),
  });

  const location = useLocation();

  useEffect(() => {
    if (showVenteQuery.data) {
      setVente(showVenteQuery.data);
    }
  }, [showVenteQuery.data, setVente]);

  return (
    <>
      <PageHeader
        titleRotate={(
          <span>
            {variant === 'location' ? formatMessage({ id: 'location.create.header_title' }, { br: () => <br /> }) : formatMessage({ id: 'vente.create.header_title' }, { br: () => <br /> })}
          </span>
        )}
      />
      <Outlet />
    </>
  );
}

export default CreateVenteLayout;
