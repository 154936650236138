import { useIntl } from 'react-intl';

import FlexRow from '../../Component/FlexRow/FlexRow';
import PageHeader from '../../Component/PageHeader/PageHeader';
import Title from '../../Component/Title/Title';
import HeaderRetour from '../../Component/Header_Retour/header_retour';

function EnConstruction(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader />
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        transparent
      />
      <FlexRow grow="1" alignItems="center" justifyContent="center">
        <Title
          title={formatMessage({ id: 'enConstruction.title' })}
        />
        <p>{formatMessage({ id: 'enConstruction.info' })}</p>
      </FlexRow>
    </>
  );
}

export default EnConstruction;
