import { DEFAULT_LOCALE } from '../provider/TranslationProvider';

export default function formatNumberWithLocale(value: number | string) {
  if (value === null || value === undefined) {
    return '';
  }

  const numericalValue = typeof value === 'number' ? value : Number(value);
  const valuePerHundred = numericalValue / 100;

  return valuePerHundred
    .toLocaleString(DEFAULT_LOCALE, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    .replace(/\s/g, '\u00A0');
}
