import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useFilter } from '../../provider/FilterProvider';

import './ToggleBig.scss';
import { Variant } from '../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../store/route/route.api';

import { useNavigate } from 'react-router-dom';

interface ToggleValue {
  key: string;
  name: string;
  value: string;
}
interface ToggleBigProps {
  activeLink: string;
  toggleLeft: ToggleValue;
  toggleRight:ToggleValue;
  handleLinkClick: (link: string) => void;
  filter?:boolean;
}

export default function ToggleBig({
  activeLink, toggleLeft, toggleRight, handleLinkClick, filter,
}: ToggleBigProps) {
  const {
    setFilters,
  } = useFilter();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onClick = useCallback(async (changedValue: ToggleValue) => {
    handleLinkClick(changedValue.name);
    navigate(makeRoutePath('search', { variant: changedValue.value }));
  }, [handleLinkClick, navigate]);

  return (
    <ul id="select_toggleBig">
      <li
        className={`nav-item ${
          activeLink === toggleLeft.name ? 'activeToggle' : ''
        }`}
        value={Variant.achatvente}
      >
        <button
          type="button"
          className="nav-link"
          onClick={() => (filter ? onClick(toggleLeft) : handleLinkClick(toggleLeft.name))}
        >
          {toggleLeft.name}
        </button>
      </li>
      <li
        className={`nav-item ${
          activeLink === toggleRight.name ? 'activeToggle' : ''
        }`}
        value={String(toggleRight.value)}
      >
        <button
          type="button"
          className="nav-link"
          onClick={() => (filter ? onClick(toggleRight) : handleLinkClick(toggleRight.name))}
        >
          {toggleRight.name}
        </button>
      </li>
    </ul>
  );
}
