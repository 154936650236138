import { Outlet } from 'react-router-dom';

import Footer from '../../Component/Footer/Footer';

function AuthenticatedLayout(): JSX.Element {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

export default AuthenticatedLayout;
