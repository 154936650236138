import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { listRapprochementByAchat } from '../../lib/papirisClient';

import useRapprochementsByAchatStore from './useRapprochementsByAchatStore';

export default function useShowRapprochement(achatId?: string): void {
  const { setRapprochement, setLoading, setError } = useRapprochementsByAchatStore();

  /** Refresh rapprochements if any was found in store */
  const showRapprochementQuery = useQuery({
    queryKey: ['rapprochements', achatId],
    queryFn: () => (achatId && achatId !== 'nouveau' ? listRapprochementByAchat(achatId) : null),
  });

  useEffect(() => {
    if (showRapprochementQuery.isSuccess) {
      if (showRapprochementQuery.data) {
        setRapprochement(showRapprochementQuery.data);
        setLoading(showRapprochementQuery.isLoading);
      }
    }
    setError(showRapprochementQuery.isError);
    setLoading(showRapprochementQuery.isLoading);
  }, [setError, setLoading, setRapprochement, showRapprochementQuery.data, showRapprochementQuery.isError, showRapprochementQuery.isLoading, showRapprochementQuery.isSuccess]);
}
