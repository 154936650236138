import { useState } from 'react';
import { useIntl } from 'react-intl';
import './dpe.scss';

function calculateEpcLetter(value) {
  switch (true) {
  case value >= 1 && value <= 50:
    return 'A';
  case value >= 51 && value <= 90:
    return 'B';
  case value >= 91 && value <= 150:
    return 'C';
  case value >= 151 && value <= 230:
    return 'D';
  case value >= 231 && value <= 330:
    return 'E';
  case value >= 331 && value <= 450:
    return 'F';
  case value >= 450:
    return 'G';
  default:
    return 'none';
  }
}

export default function DPE({ gesEpcValue, consEpcValue }) {
  const { formatMessage } = useIntl();
  const [consEpcLetter] = useState(
    calculateEpcLetter(consEpcValue),
  );

  return (
    <div className="container_chart_DPE_GES">
      <div className="chart_DPE">
        <div className="bars">
          {['A', 'B', 'C', 'D', 'E', 'F', 'G'].map((letter) => (
            <div
              key={`Letter-epc-${letter}`}
              className={`bar epc-${letter} ${consEpcLetter === letter ? 'active' : ''}`}
            >
              <div className="bar-letter">{letter}</div>
              {consEpcLetter === letter && (
                <div className={`epc-bloc dpe-epc epc-${letter}`}>
                  <div className="point" />
                  <div className="value">
                    <div className="infoDPE">
                      <strong>{consEpcValue}</strong>
                      <small>{formatMessage({ id: 'info.bien.kWh' })}</small>
                    </div>
                    <div className="separateur" />
                    <div className="infoDPE">
                      <strong>{gesEpcValue}</strong>
                      <div><small>{formatMessage({ id: 'info.bien.CO2' })}</small></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {consEpcLetter === 'none' && (
        <div className="epc-NC epc-bloc dpe-epc epc-none ">
          <div className="point" />
          <div className="value">
            <div className="infoDPE">
              <strong>{consEpcValue}</strong>
              <small>{formatMessage({ id: 'info.bien.kWh' })}</small>
            </div>
            <div className="separateur" />
            <div className="infoDPE">
              <strong>{gesEpcValue}</strong>
              <div><small>{formatMessage({ id: 'info.bien.CO2' })}</small></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
