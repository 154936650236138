import './Accordion.scss';

import classNames from 'classnames';
import { useState } from 'react';

import useUuid from '../../hooks/useUuid';

export type AccordionProps = {
    title: React.ReactNode;
    children?: React.ReactNode;
}

function Accordion({ title, children }:AccordionProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const classes = classNames('Accordion', { 'Accordion-open': open });

  const triggerId = useUuid();
  const panelId = useUuid();

  return (
    <div className={classes}>
      <div className="Accordion_header">
        <button
          type="button"
          aria-expanded={open ? 'true' : 'false'}
          className="Accordion_trigger"
          aria-controls={panelId}
          id={triggerId}
          onClick={() => setOpen((open) => !open)}
        >
          <div className="Accordion_title">
            {title}
          </div>
          <span className="Accordion_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.452" height="7.027" viewBox="0 0 12.452 7.027">
              <path d="M-185.151-2420.41l4.812,4.613,4.812-4.613" transform="translate(186.565 2421.824)" />
            </svg>
          </span>
        </button>
      </div>
      <div
        id={panelId}
        role="region"
        aria-labelledby={triggerId}
        className="Accordion_panel"
        aria-hidden={open ? 'false' : 'true'}
      >
        {children}
      </div>
    </div>
  );
}

export default Accordion;
