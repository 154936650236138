import classNames from 'classnames';

import { IconProps } from './Icon.types';

function IconDelete({ className }: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('Icon Icon-delete', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.919 28.057">
        <path d="M4.91 30.55a3.684 3.684 0 0 0 3.82 3.507h11.459a3.684 3.684 0 0 0 3.82-3.507V13.014H4.91Zm21.009-22.8h-5.73L18.279 6H10.64L8.73 7.754H3v3.507h22.919Z" transform="translate(-3 -6)" />
      </svg>
    </div>
  );
}

export default IconDelete;
