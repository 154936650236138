import { AchatModel } from './Achats/Achats.types';
import { DemandeRenseignementModel } from './Achats/DemandeRenseignement.types';
import { Bien } from './Biens/biens.types';
import { DecouverteModel } from './Decouvertes/Decouvertes.types';
import { NotificationModel } from './Notification/Notification.types';
import { PagerModel } from './Pagers/Pagers.types';
import { RapprochementModel } from './Rapprochement/rapprochements.types';
import { VenteModel } from './Ventes/Ventes.types';

export type CreateInvitationPayload = {
   email: string;
   message?: string;
   achatId?: string;
   venteId?: string;
}
export type DemandeRenseignementPayload = Pick<DemandeRenseignementModel,
  | 'achatId'
  | 'venteId'
  | 'commentaire'
  >;
export type ClotureAchatPayload = Pick<AchatModel,
  | 'achatId'
  | 'raisonCloture'
  >;
export type RapprochementListModel = PagerModel<RapprochementModel>

export type CreateVenteFavoriPayload = {
  achatId?: string;
  venteId?: string;
} & PagerModel<AchatModel>;
export type RemoveVenteFavoriPayload ={
  achatId?: string;
  venteId?: string;
};
export type CreateVenteIgnorePayload = {
  achatId?: string;
  venteId?: string;
} & PagerModel<AchatModel>;

export type RemoveVenteIgnorePayload ={
  achatId?: string;
  venteId?: string;
};
export type AchatListModel = PagerModel<AchatModel>;

export type VenteListModel = PagerModel<VenteModel>;
export type BienListModel = PagerModel<Bien>;
export type NotificationListModel = PagerModel<NotificationModel>;

export type UpdateContactTitleProjetAchat = Pick<AchatModel,
  | 'achatId'
  | 'titre'
>
export type UpdateContactTitleProjetVente = Pick<VenteModel,
  | 'id'
  | 'titre'
>
export type UpdateDecouverteFamillePayload = Pick<DecouverteModel,
  | 'id'
  | 'childrenSchools'
  | 'childrenCount'
  | 'adultsCount'
>

export type UpdateDecouverteImmobilierPayload = Pick<DecouverteModel,
  | 'id'
  | 'villes'
  | 'bienTypes'
  | 'surfaceHabitable'
  | 'surfaceTerrain'
  | 'surfaceDependance'
  | 'chambresCount'
  | 'niveauStanding'
  | 'budgetMax'
  | 'budgetMin'
  | 'travauxAmplitude'
  | 'budgetCommentaire'
  | 'demarchesBancaires'
  | 'dateSouhaiteeAcquisition'
  | 'commentaire'
  | 'transmettre'
>

export type UpdateDecouverteCriterePayload = Pick<DecouverteModel,
  | 'id'
  | 'childrenSchools'
  | 'childrenCount'
  | 'adultsCount'
>

export type PapirisErrorModel = {
  message: string;
  errors?: Record<string, string[]>;
}

export class PapirisError extends Error {
  errors: PapirisErrorModel['errors'];

  constructor(message: string, errors: PapirisErrorModel['errors']) {
    super(message);
    this.name = this.constructor.name;
    this.errors = errors;
  }
}

export function isPapirisErrorModel(error: unknown): error is PapirisErrorModel {
  return error !== null && typeof error === 'object' && 'message' in error;
}
