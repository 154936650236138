import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { VenteModel } from '../../lib/Ventes/Ventes.types';

 type ShowVenteState = {
   vente: VenteModel | null;
   isLoading: boolean;
   isError: boolean;

}

type ShowVenteActions = {
  setVente(vente: VenteModel | null): void;
  setLoading(isLoading: boolean): void;
  setError(isError: boolean): void;
  reset(): Promise<void>;
}

export type ShowVenteStore = ShowVenteState & ShowVenteActions;

const initialState: ShowVenteState = {
  vente: null,
  isLoading: false,
  isError: false,
};

const useShowVenteStore = create<ShowVenteStore>()(
  persist((set) => ({
    ...initialState,
    setVente: (vente) => set(() => ({ vente })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setError: (isError) => set(() => ({ isError })),
    reset: async () => set(initialState),
  }), {
    name: 'show-vente',
  }),
);

export default useShowVenteStore;
