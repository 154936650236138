import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormControl from '../../Component/FormControl/FormControl';
import FormError from '../../Component/FormError/FormError';
import FormFooter from '../../Component/FormFooter/FormFooter';
import FormLabel from '../../Component/FormLabel/FormLabel';
import FormTextarea from '../../Component/FormTextarea/FormTextarea';
import HeaderBack from '../../Component/Header_Retour/header_retour';
import IconCheck from '../../Component/Icon/IconCheck';
import Modal from '../../Component/Modal/Modal';
import { SrOnly } from '../../Component/SrOnly/SrOnly';
import Title from '../../Component/Title/Title';
import useZodForm from '../../hooks/useZodForm';
import { createDemandeRenseignement } from '../../lib/papirisClient';
import useShowAchatStore from '../ShowAchatLayout/useShowAchatStore';
import useShowVenteStore from '../ShowVenteLayout/useShowVenteStore';

export default function DEMANDE_RENSEIGNEMENT() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { achat } = useShowAchatStore();
  const { vente } = useShowVenteStore();

  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useMutation(createDemandeRenseignement);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      commentaire: z.string().min(1),
    }), []),
    initialValues: useMemo(() => ({
      commentaire: '',
    }), []),
    onSubmit: async (values) => {
      if (achat && vente) {
        await mutation.mutateAsync({
          achatId: achat.id,
          venteId: vente.id,
          commentaire: values.commentaire,
        });

        await queryClient.invalidateQueries({ queryKey: 'demandeRenseignement' });

        setSuccessModalOpen(true);
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  return (
    <>
      <HeaderBack />
      <FlexRow
        direction="column"
        grow="1"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Container>
          <Title>
            {formatMessage({ id: 'demande_renseignements.title' })}
          </Title>
          <Form onSubmit={onSubmit} {...form}>
            <p>
              {formatMessage({ id: 'demande_renseignements.text' })}
            </p>
            <FormControl name="commentaire">
              <SrOnly>
                <FormLabel>{formatMessage({ id: 'demande_renseignements.commentaire.label' })}</FormLabel>
              </SrOnly>
              <FormTextarea
                cols="30"
                rows="10"
                placeholder={formatMessage({ id: 'demande_renseignements.commentaire.placeholder' })}
              />
              <FormError />
            </FormControl>
            <FormFooter>
              <FlexRow alignItems="center">
                <Button
                  className={`button submit ${
                    form.formState.isValid
                      ? 'yellow'
                      : 'not_Valid'
                  }`}
                  disabled={form.formState.isSubmitting}
                  type="submit"
                >
                  {formatMessage({ id: 'label.submit' })}
                </Button>
              </FlexRow>
            </FormFooter>
          </Form>
          <Modal
            open={successModalOpen}
            icon={<IconCheck />}
            onClose={() => navigate(-1)}
            title={formatMessage({ id: 'demande_renseignements.modal.title' })}
          >
            <Button className="standard" onClick={() => navigate(-1)}>
              {formatMessage({ id: 'label.back' })}
            </Button>
          </Modal>
        </Container>
      </FlexRow>
    </>
  );
}
