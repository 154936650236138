import {
  ReactNode, createContext, useContext, useEffect, useState, JSX, useMemo, useCallback,
} from 'react';
import { useQuery } from 'react-query';

import { UserModel } from '../lib/Users/Users.types';
import { addNotificationToken, showUser } from '../lib/papirisClient';

import { NotificationContext } from './NotificationProvider';

const DEFAULT_DEVICE_TOKENS: string[] = [];

export type UserContextValue = {
    user: UserModel | null;
    loading: boolean;
    error: Error | null;
    ready: boolean;
    setUser(user: UserModel|null): void;
}

export type UserProviderProps = {
    children: ReactNode;
}

const defaultValue: UserContextValue = {
  user: null,
  loading: false,
  error: null,
  ready: false,
  setUser: () => {},
};

export const UserContext = createContext<UserContextValue>(defaultValue);

export function useUser() {
  return useContext(UserContext);
}

export default function UserProvider(props: UserProviderProps): JSX.Element|null {
  const [value, setValue] = useState<UserContextValue>(defaultValue);

  const { isLoading, error, data } = useQuery({
    queryKey: 'user',
    queryFn: showUser,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    setValue((previousValue) => ({
      ...previousValue,
      user: data ?? null,
      loading: isLoading,
      error: error instanceof Error ? error : null,
      ready: true,
    }));
  }, [data, error, isLoading]);

  const setUser = useCallback((user: UserModel|null) => {
    setValue((previousValue) => ({
      ...previousValue,
      user,
    }));
  }, []);

  const memoValue = useMemo(() => ({
    ...value,
    setUser,
  }), [value, setUser]);

  // Update user device token if not yet registered.
  const deviceTokens = value.user?.firebaseDeviceTokens ?? DEFAULT_DEVICE_TOKENS;
  const { deviceToken } = useContext(NotificationContext);
  useEffect(() => {
    if (deviceToken && !deviceTokens.includes(deviceToken)) {
      addNotificationToken(deviceToken);
    }
  }, [deviceTokens, deviceToken]);

  return <UserContext.Provider value={memoValue} {...props} />;
}
