import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconContreVisiteRound.scss';

function IconContreVisiteRound({
  className, classColor = 'ContreVisiteRound',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg
        className={classNames('icon_contreVisite', className)}
        id="picto_contreVisite"
        data-name="picto return"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 31"
      >
        <title id="icon_contreVisite">Icon contre visite</title>
        <circle id="Ellipse_112" data-name="Ellipse 112" className={`${classColor}-cls-1`} cx="15.5" cy="15.5" r="15.5" />
        <path
          id="Icon_ionic-ios-return-left"
          data-name="Icon ionic-ios-return-left"
          className={`${classColor}-cls-2`}
          d="M4.851,16.794l3.215-3.181a.753.753,0,0,1,.534-.22.749.749,0,0,1,.534,1.28l-.005.005L6.911,16.826h14.1a2.693,2.693,0,0,0,1.9-.787,2.626,2.626,0,0,0,.793-1.89v-2.57a.755.755,0,0,1,1.51,0v2.57A4.125,4.125,0,0,1,23.979,17.1a4.186,4.186,0,0,1-2.972,1.226H6.89l2.136,2.153a.742.742,0,0,1,.221.53.73.73,0,0,1-.221.53l-.005.005a.783.783,0,0,1-.529.209.723.723,0,0,1-.534-.22L4.851,18.459a1.179,1.179,0,0,1-.351-.841A1.142,1.142,0,0,1,4.851,16.794Z"
          transform="translate(0.643 -0.375)"
        />
      </svg>
    </div>
  );
}

export default IconContreVisiteRound;
