import './FormInput.scss';

import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ComponentProps } from '../../types/ComponentProps';
import Button from '../Button/Button';
import useFormControl from '../FormControl/useFormControl';

export type FormInputProps = ComponentProps<'input', {
  name?: string;
  invalid?: boolean;
  variant?: 'outline';
  valueReadOnly?: string;
  isMultiReadOnly?: boolean;
  readOnly?: boolean;
  onClick?: () => void;
}>

function FormInput({
  className,
  name,
  disabled,
  invalid,
  variant,
  valueReadOnly,
  isMultiReadOnly,
  readOnly,
  onClick,
  ...props
}: FormInputProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const formControl = useFormControl();
  const { formatMessage } = useIntl();

  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const classes = classNames('FormInput', 'FormInput-focus', className, {
    'FormInput-invalid': invalid ?? fieldState.invalid,
    'FormInput-disabled': disabled,
    [`FormInput-${variant}`]: Boolean(variant),
  });

  if (readOnly) {
    if (isMultiReadOnly) {
      return (
        <Button
          className={classes}
          ariaLabel={formatMessage({ id: 'label.close' })}
          onClick={onClick}
        >
          {valueReadOnly}
        </Button>
      );
    }

    return (
      <input
        id={formControl.inputId}
        className={classes}
        value={valueReadOnly}
        readOnly
        {...props}
      />
    );
  }

  return (
    <input
      id={formControl.inputId}
      className={classes}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      ref={field.ref}
      readOnly={readOnly}
      disabled={disabled}
      {...props}
    />
  );
}

export default FormInput;
