import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { PapirisError } from '../lib/papirisClient.types';

export default function usePapirisErrorForm(form: UseFormReturn<any>, error: unknown) {
  useEffect(() => {
    if (error instanceof PapirisError && error.errors) {
      const errors = Object.entries(error.errors);

      if (errors.length) {
        errors.forEach(([name, value]) => {
          form.setError(`root.${name}`, {
            message: value[0],
          });
        });
      } else {
        form.setError('root', {
          message: error.message,
        });
      }
    }
  }, [form, error]);
}
