import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { RapprochementModel } from '../../lib/Rapprochement/rapprochements.types';

 type ShowRapprochementsByAchatState = {
   rapprochements: RapprochementModel | null;
   isLoading: boolean;
   isError: boolean;
}

type ShowRapprochementsByAchatActions = {
  setRapprochement(rapprochements: RapprochementModel | null): void;
  setLoading(isLoading: boolean): void;
  setError(isError: boolean): void;
  reset():Promise<void>;
}

export type ShowRapprochementsByAchatStore = ShowRapprochementsByAchatState & ShowRapprochementsByAchatActions;

const initialState: ShowRapprochementsByAchatState = {
  rapprochements: null,
  isLoading: false,
  isError: false,
};

const useRapprochementsByAchatStore = create < ShowRapprochementsByAchatStore >()(
  persist((set) => ({
    ...initialState,
    setRapprochement: (rapprochements) => set(() => ({ rapprochements })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setError: (isError) => set(() => ({ isError })),
    reset: async () => set(initialState),
  }), {
    name: 'show-rapprochements',
  }),
);

export default useRapprochementsByAchatStore;
