import { FormLabel } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import IconButton from '../../Component/Button/IconButton';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormAddon from '../../Component/FormAddon/FormAddon';
import FormAddonWrapper from '../../Component/FormAddonWrapper/FormAddonWrapper';
import FormControl from '../../Component/FormControl/FormControl';
import FormError from '../../Component/FormError/FormError';
import FormFooter from '../../Component/FormFooter/FormFooter';
import FormInput from '../../Component/FormInput/FormInput';
import HEADER_RETOUR from '../../Component/Header_Retour/header_retour.jsx';
import IconCheck from '../../Component/Icon/IconCheck';
import Modal from '../../Component/Modal/Modal';
import PageHeader from '../../Component/PageHeader/PageHeader';
import Title from '../../Component/Title/Title';
import useZodForm from '../../hooks/useZodForm';
import { changePassword } from '../../lib/firebaseClient';
import { makeRoutePath } from '../../store/route/route.api';

export default function MonMotdepassePage() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [firebaseError, setFirebaseError] = useState<FirebaseError | null>(null);
  const [firebaseSuccess, setFirebaseSuccess] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      currentPassword: z.string(),
      newPassword: z.string(),
      newPasswordConfirmation: z.string(),
    }).refine((data) => data.newPassword === data.newPasswordConfirmation, {
      message: formatMessage({ id: 'error.form.invalid_password_confirmation' }),
      path: ['newPasswordConfirmation'],
    }), [formatMessage]),
    initialValues: useMemo(() => ({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    }), []),
    onSubmit: async (values) => {
      setFirebaseError(null);
      setFirebaseSuccess(false);

      try {
        await changePassword(values.currentPassword, values.newPassword);

        setFirebaseSuccess(true);
      } catch (error) {
        if (error instanceof FirebaseError) {
          setFirebaseError(error);
        } else throw error;
      }
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  return (
    <>
      <HEADER_RETOUR to={makeRoutePath('profile')} transparent />
      <Title>{formatMessage({ id: 'profil.motdepasse.title' })}</Title>
      <Container size="sm">
        <Form
          onSubmit={onSubmit}
          {...form}
        >
          <FlexRow gap="1rem" alignItems="center">
            <FormControl name="currentPassword">
              <FormLabel>{formatMessage({ id: 'profil.motdepasse.current_password.label' })}</FormLabel>
              <FormAddonWrapper>
                <FormInput type={showCurrentPassword ? 'text' : 'password'} />
                <FormAddon side="right">
                  <IconButton
                    type="button"
                    onClick={() => setShowCurrentPassword((v) => !v)}
                    aria-label={formatMessage({ id: 'accueil.password.show_password' })}
                  >
                    {showCurrentPassword ? (
                      <i className="fa-solid fa-eye-slash" />
                    ) : (
                      <i className="fa-solid fa-eye" />
                    )}
                  </IconButton>
                </FormAddon>
              </FormAddonWrapper>
              <FormError />
            </FormControl>

            <FormControl name="newPassword">
              <FormLabel>{formatMessage({ id: 'profil.motdepasse.new_password.label' })}</FormLabel>
              <FormAddonWrapper>
                <FormInput type={showNewPassword ? 'text' : 'password'} />
                <FormAddon side="right">
                  <IconButton
                    type="button"
                    onClick={() => setShowNewPassword((v) => !v)}
                    aria-label={formatMessage({ id: 'accueil.password.show_password' })}
                  >
                    {showNewPassword ? (
                      <i className="fa-solid fa-eye-slash" />
                    ) : (
                      <i className="fa-solid fa-eye" />
                    )}
                  </IconButton>
                </FormAddon>
              </FormAddonWrapper>
              <FormError />
            </FormControl>

            <FormControl name="newPasswordConfirmation">
              <FormLabel>{formatMessage({ id: 'profil.motdepasse.new_password_confirmation.label' })}</FormLabel>
              <FormAddonWrapper>
                <FormInput type={showNewPasswordConfirmation ? 'text' : 'password'} />
                <FormAddon side="right">
                  <IconButton
                    type="button"
                    onClick={() => setShowNewPasswordConfirmation((v) => !v)}
                    aria-label={formatMessage({ id: 'accueil.password.show_password' })}
                  >
                    {showNewPasswordConfirmation ? (
                      <i className="fa-solid fa-eye-slash" />
                    ) : (
                      <i className="fa-solid fa-eye" />
                    )}
                  </IconButton>
                </FormAddon>
              </FormAddonWrapper>
              <FormError />
            </FormControl>

            {firebaseError ? (
              <FormError>
                {formatMessage({ id: `error.firebase.${firebaseError.code}` })}
              </FormError>
            ) : null}
          </FlexRow>
          <FormFooter>
            <FlexRow alignItems="center">
              <Button
                className="button yellow"
                disabled={form.formState.isSubmitting}
                type="submit"
              >
                {formatMessage({ id: 'label.confirm' })}
              </Button>
            </FlexRow>
          </FormFooter>
        </Form>

      </Container>
      {firebaseSuccess ? (
        <Modal
          icon={<IconCheck />}
          onClose={() => navigate(makeRoutePath('profile'))}
          title={formatMessage({ id: 'profil.motdepasse.modal.title' })}
        >
          <Button className="standard" to={makeRoutePath('profile')}>
            {formatMessage({ id: 'label.continue' })}
          </Button>
        </Modal>
      ) : null}
    </>
  );
}
