import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';

import useQueryParams from '../../hooks/useQueryParams';
import { makeRoutePath } from '../../store/route/route.api';

import useCreateAchatStore from './useCreateAchatStore';

function CreateAchatStart(): JSX.Element|null {
  const { reset, achat } = useCreateAchatStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || undefined;

  useEffect(() => {
    queryClient
      .invalidateQueries({ queryKey: 'achat' })
      .then(() => {
        reset();
      });
  }, [queryClient, navigate, reset]);

  if (achat) {
    return null;
  }

  return <Navigate to={makeRoutePath('achat.create.contact', { variant })} replace />;
}

export default CreateAchatStart;
