import {
  MouseEventHandler, ReactElement,
} from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../Button/Button';
import './ModalInfoLight.scss';
import FlexRow from '../../../FlexRow/FlexRow';
import IconInfoRound from '../../../Icon/IconInfoRound';

export type ModalProps = {
  open: boolean;
  textMoreInfo?: string;
  onClick?: MouseEventHandler;
}

export default function ModalInfoLight({
  open, onClick, textMoreInfo,
}: ModalProps) {
  const { formatMessage } = useIntl();

  if (!open) return null;

  return (
    <div
      className="container_Modal_InfoLight"
      role="dialog"
      aria-modal="true"
    >
      <FlexRow
        className="modal_InfoLight"
        direction="column"
        grow="0rem"
        alignItems="center"
        justifyContent="center"
      >
        <Button className="button_InfoLight" onClick={onClick}>
          <IconInfoRound className="img-wxl img-hxl" classColor="iconFill" />
          <p className="text_InfoLight">{textMoreInfo}</p>
        </Button>
      </FlexRow>
    </div>
  );
}
