import './PresentationUser.scss';

import { useIntl } from 'react-intl';

import IconAnonyme from '../Icon/IconAnnonyme';

export type PresentationUserProps = {
  avatar?: string;
  prenom?: string;
  nom?: string;
};

export default function PresentationUser({ avatar, prenom, nom }: PresentationUserProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="PresentationUser">
      {avatar ? <img className="PresentationUser_avatar" src={avatar} alt={formatMessage({ id: 'mon_profil.avatar' })} /> : <IconAnonyme className="size-img-card-profil icon-position-profil" />}
      {prenom ? (
        <h2 className="PresentationUser_prenom">
          {prenom}
        </h2>
      ) : null}
      {nom ? (
        <h2 className="PresentationUser_nom">
          {nom}
        </h2>
      ) : null}
    </div>
  );
}
