import './FormSearch.scss';

import { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';

import searchCommune from '../../../API/Adresse_France/geoGouvSearch';
import useUuid from '../../../hooks/useUuid';

export default function FormSearch({
  id, label, value, onChange, placeholder, name, exempleText, isDisabled, isMulti, valueType,
}) {
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [selectedOption, value]);

  const promiseOptions = async (inputValue) => {
    if (inputValue.length >= 3) {
      try {
        const communes = await searchCommune(inputValue, valueType);

        return communes.map((commune) => {
          const label = valueType === 'municipality' ? `${commune.ville} (${commune.codePostal})` : `${commune.adressePostale}, ${commune.codePostal} ${commune.ville}`;

          return {
            value: commune.ville,
            label,
          };
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des communes:', error);

        return [];
      }
    }

    return [];
  };

  const handleChange = useCallback((changedValue) => {
    onChange(changedValue);
  }, [onChange]);

  const selectId = useUuid(id);

  return (
    <>
      {label
        ? (
          <label htmlFor={selectId} aria-label={selectId}>
            {label}
            {selectedOption && (
              <div>
                <small className="info">{exempleText}</small>
              </div>
            )}
          </label>
        )
        : ''}
      <AsyncSelect
        aria-live="polite"
        backspaceRemovesValue
        blurInputOnSelect={false}
        name={name}
        id={selectId}
        isMulti={isMulti || false}
        value={selectedOption}
        isDisabled={isDisabled || false}
        className="FormSearch"
        classNamePrefix="FormSearch"
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        isLoading={false}
        isClearable
        isRtl={false}
        isSearchable
        placeholder={placeholder}
        onChange={handleChange}
        noOptionsMessage={() => null}
      />
    </>
  );
}
