import {
  useContext, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';

import Button from '../Component/Button/Button';
import Modal from '../Component/Modal/Modal';
import { NotificationContext } from '../provider/NotificationProvider';

export type NotificationAvailableModalProps = Record<string, never>

export default function NotificationAvailableModal(): JSX.Element|null {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const { refreshDeviceToken, getPermission } = useContext(NotificationContext);

  const handleSubmit = async () => {
    await refreshDeviceToken();
    setOpen(false);
  };

  useEffect(() => {
    if (getPermission() === 'default') {
      setOpen(true);
    }
  }, [getPermission]);

  return (
    getPermission() === 'default' ? (
      <Modal
        open={open}
        title={formatMessage({ id: 'modal.notification_available.title' })}
        onClose={() => setOpen(false)}
      >
        <p>{formatMessage({ id: 'modal.notification_available.text' })}</p>
        <Button className="standard" onClick={handleSubmit}>
          {formatMessage({ id: 'modal.notification_available.button' })}
        </Button>
      </Modal>
    ) : null
  );
}
