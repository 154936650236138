import { CircularProgress } from '@mui/material';
import groupBy from 'lodash/groupBy';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import Accordion from '../../../Component/Accordion/Accordion';
import AccordionGroup from '../../../Component/AccordionGroup/AccordionGroup';
import Button from '../../../Component/Button/Button';
import Container from '../../../Component/Container/Container';
import FlexCell from '../../../Component/FlexCell/FlexCell';
import FlexRow from '../../../Component/FlexRow/FlexRow';
import Form from '../../../Component/Form/Form';
import FormChip from '../../../Component/FormChip/FormChip';
import FormError from '../../../Component/FormError/FormError';
import FormFooter from '../../../Component/FormFooter/FormFooter';
import Title from '../../../Component/Title/Title';
import useFormatError from '../../../hooks/useFormatError';
import useZodForm from '../../../hooks/useZodForm';
import { RendezVousModel } from '../../../lib/RendezVous/rendezVous.types';
import { listVenteRendezVousAvailable } from '../../../lib/Ventes/Ventes.api';
import { makeRoutePath } from '../../../store/route/route.api';
import RedirectError from '../../Error/RedirectError';

import useCreateVenteStore from './useCreateVenteStore';

function CreateVenteRendezvousPage(): JSX.Element {
  const { formatMessage, formatDate } = useIntl();
  const navigate = useNavigate();
  const { vente, rendezVous, setRendezVous } = useCreateVenteStore();
  const variant = vente?.variant;

  if (!vente) {
    throw new RedirectError(makeRoutePath('vente.create.bien', { variant }));
  }

  const formatError = useFormatError();
  const [page, setPage] = useState(1);
  const { isLoading, data, error } = useQuery({
    queryKey: ['vente', vente?.id, 'rendez-vous', 'rendez-vous-available', `page-${page}`],
    queryFn: () => (vente ? listVenteRendezVousAvailable({ venteId: vente.id, page }) : undefined),
  });

  const [listRendezVous, setListRendezVous] = useState<RendezVousModel[]>([]);

  useEffect(() => {
    setListRendezVous((listRendezVous) => {
      // Remove duplicated rendezVous just in case.
      const moreRendezVous = (data?.items ?? []).filter(
        (rendezVous) => listRendezVous.find((r) => r.dateDebut === rendezVous.dateDebut) === undefined,
      );

      return [
        ...listRendezVous,
        ...moreRendezVous,
      ];
    });
  }, [data?.items]);

  const groupedSlots = useMemo(
    () => {
      const slots = listRendezVous.map((rendezVous) => ({
        date: formatDate(rendezVous.dateDebut, { dateStyle: 'full' }),
        time: formatDate(rendezVous.dateDebut, { timeStyle: 'short' }),
        datetime: rendezVous.dateDebut,
      })) ?? [];

      const groupedSlots = Object.entries(groupBy(slots, 'date'));

      return groupedSlots;
    },
    [listRendezVous, formatDate],
  );

  const { resolver, initialValues, onSubmit } = useZodForm({
    schemaBuilder: useCallback((z) => z.object({
      dateDebut: z.string().min(1),
    }), []),
    initialValues: useMemo(() => ({
      dateDebut: rendezVous?.dateDebut ?? vente?.estimationRendezVous?.dateDebut ?? '',
    }), [vente]),
    onSubmit: (values) => {
      const rendezVous = data?.items?.find((rv) => rv.dateDebut === values.dateDebut) ?? null;

      setRendezVous(rendezVous);

      navigate(makeRoutePath('vente.create.confirmation', { variant }));
    },
  });

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
    resolver,
  });

  return (
    <Container size="md">
      <Title
        title={formatMessage({ id: 'vente.create.rendezvous.title' })}
        size={3}
        subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 3, total: 4 })}
      />
      <Form onSubmit={onSubmit} {...form}>
        <FlexRow gap="1em" alignItems="center">
          {error ? <FormError>{formatError(error)}</FormError> : null}
          {groupedSlots.length ? (
            <AccordionGroup>
              {groupedSlots.map(([date, slots]) => (
                <Accordion key={date} title={date}>
                  <FlexRow direction="row" gap="0.5em" justifyContent="center" padding="0.5em" wrap="wrap">
                    {slots.map((slot) => (
                      <FormChip
                        key={slot.datetime}
                        name="dateDebut"
                        value={slot.datetime}
                      >
                        {slot.time}
                      </FormChip>
                    ))}
                  </FlexRow>
                </Accordion>
              ))}
            </AccordionGroup>
          ) : null}
          {isLoading ? (
            <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />
          ) : (
            <Button
              className="standard"
              disabled={form.formState.isSubmitting}
              type="button"
              onClick={() => setPage((page) => page + 1)}
            >
              {formatMessage({ id: 'vente.create.rendezvous.show_more' })}
            </Button>
          )}
          <FormError name="dateDebut" />
        </FlexRow>
        <FormFooter>
          <FlexRow alignItems="center" justifyContent="space-between" direction="row">
            <Button
              className="button-simple"
              icon={<i className="fa-solid fa-chevron-left" />}
              to={makeRoutePath('vente.create.bien', { variant })}
            >
              {formatMessage({ id: 'label.back' })}
            </Button>
            <Button
              className={`button submit ${form.formState.isValid ? 'yellow' : 'not_Valid'}`}
              disabled={form.formState.isSubmitting}
              type="submit"
            >
              {formatMessage({ id: 'label.continue' })}
            </Button>
          </FlexRow>
        </FormFooter>
      </Form>
    </Container>
  );
}

export default CreateVenteRendezvousPage;
