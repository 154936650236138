import { useEffect } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { VenteModel } from '../../lib/Ventes/Ventes.types';
import { showVente } from '../../lib/papirisClient';

import useShowVenteStore from './useShowVenteStore';

export default function useShowVente(venteId?: string) : UseQueryResult<VenteModel | undefined, unknown> {
  const { setVente, setLoading, setError } = useShowVenteStore();

  const showVenteQuery = useQuery({
    queryKey: ['venteId', venteId],
    queryFn: () => (venteId ? showVente(venteId) : undefined),
  });

  useEffect(() => {
    if (showVenteQuery.isSuccess) {
      if (showVenteQuery.data) {
        setVente(showVenteQuery.data);
        setLoading(showVenteQuery.isLoading);
      }
    }
    setError(showVenteQuery.isError);
    setLoading(showVenteQuery.isLoading);
  }, [showVenteQuery.data, setVente, showVenteQuery.isSuccess, showVenteQuery.isError, showVenteQuery.isLoading, setError, setLoading]);

  return showVenteQuery;
}
