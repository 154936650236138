import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconPuzzleHouse.scss';

function IconPuzzleHouse({
  className, classColor = 'puzzleHouse',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg
        className={classNames('icon_puzzleHouse', className)}
        id="picto_puzzleHouse"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 95.994 97.203"
      >
        <title id="icon_contreVisite">Icon Puzzle House</title>
        <path id="Exclusion_1" data-name="Exclusion 1" className={`${classColor}-cls-1`} d="M-13297.693-6961.534h-67.561a9.449,9.449,0,0,1-9.65-9.209v-31a17.558,17.558,0,0,1,1.566-7.267,18.339,18.339,0,0,1,4.443-6.089l30.777-27.885a9.759,9.759,0,0,1,1.484-1.1,5.089,5.089,0,0,0-.156,1.222v9.341a.276.276,0,0,0,.283.27h3.744a7.576,7.576,0,0,1,7.518,7.277,7.542,7.542,0,0,1-2.219,5.374,7.282,7.282,0,0,1-5.191,2.214h-3.852a.274.274,0,0,0-.283.264v9.341a5.427,5.427,0,0,0,5.516,5.26h9.791a.267.267,0,0,0,.275-.265v-2.779a7.393,7.393,0,0,1,2.246-5.259,7.883,7.883,0,0,1,5.406-2.313l.2,0c4.336,0,7.73,2.932,7.73,6.675v3.679a.272.272,0,0,0,.277.265h9.9a5.293,5.293,0,0,0,5.406-5.158v-1.216a17.834,17.834,0,0,1,2,8.157v31A9.447,9.447,0,0,1-13297.693-6961.534Zm7.648-48.366a18.408,18.408,0,0,0-4.008-5.193l-30.771-27.885a9.869,9.869,0,0,0-6.645-2.53,9.965,9.965,0,0,0-5.164,1.426,5.459,5.459,0,0,1,5.359-4.033h10.746a.272.272,0,0,0,.277-.269v-3.781a6.452,6.452,0,0,1,2.061-4.685,7.036,7.036,0,0,1,4.83-1.887h.121a6.936,6.936,0,0,1,4.8,2.015,6.548,6.548,0,0,1,1.965,4.667v3.67a.276.276,0,0,0,.283.269h10.746a5.291,5.291,0,0,1,5.406,5.153v10.255a.267.267,0,0,0,.277.265h3.846a7.321,7.321,0,0,1,4.92,1.847,6.146,6.146,0,0,1,2.088,4.607,6.562,6.562,0,0,1-2,4.729,6.953,6.953,0,0,1-4.887,1.965h-3.963a.268.268,0,0,0-.277.265v9.13Z" transform="translate(13374.904 7058.737)" />
      </svg>
    </div>
  );
}

export default IconPuzzleHouse;
