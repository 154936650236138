import './ToggleSwitch.scss';

import {
  ChangeEventHandler, ReactNode, useCallback,
} from 'react';

import useUuid from '../../../hooks/useUuid';

export type ToggleSwitchProps = {
  id?: string;
  value: boolean;
  onChange(value: boolean): void;
  label?: ReactNode;
  children?: ReactNode;
}

function ToggleSwitch({
  id: defaultId,
  value,
  onChange,
  label,
  children,
}: ToggleSwitchProps) {
  const id = useUuid(defaultId);

  const handleCheckboxChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    onChange(event.target.checked);
  }, [onChange]);

  return (
    <div className="ToggleSwitch">
      <div>
        <div className="ToggleSwitch_switch">
          <input
            className="ToggleSwitch_input"
            id={id}
            type="checkbox"
            onChange={handleCheckboxChange}
            checked={value}
          />
          <div className="ToggleSwitch_slider" />
        </div>
      </div>
      {label || children
        ? (
          <label className="ToggleSwitch_label" htmlFor={id}>
            {label ?? children}
          </label>
        ) : null}
    </div>
  );
}

export default ToggleSwitch;
