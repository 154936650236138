import './home_bien.scss';

// Import des Composants
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ButtonDragonfly from '../../Component/Button/ButtonDragonfly/ButtonDragonfly';
import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderRetour from '../../Component/Header_Retour/header_retour.jsx';
import InfoPastilleNumber from '../../Component/InfoPastilleNumber/InfoPastilleNumber';
import { listBien } from '../../lib/papirisClient';
import { makeRoutePath } from '../../store/route/route.api';
import useListBienStore from '../ShowBienLayout/useListBienStore';
import useShowBienStore from '../ShowBienLayout/useShowBienStore';

interface urlBienParams {
  bienId?: string;
}

export default function HomeBien() {
  const { formatMessage } = useIntl();
  const { list } = useListBienStore();
  const { bienId }: urlBienParams = useParams();

  const nbBien = list?.items?.length;

  return (
    <>
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        to={nbBien === 1
          ? makeRoutePath('home') : makeRoutePath('bien.list')}
        transparent
      />
      <FlexRow
        direction="row"
        grow="1"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <div className="container_Home_Bien">
          <div className="container_ButtonsDragonfly">
            <FlexRow
              direction="row"
              gap="4"
              grow="1"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <ButtonDragonfly
                topLeftRadius
                topRightRadius
                bottomLeftRadius
                to={makeRoutePath('projet.list', { bienId })}
              >
                {formatMessage({ id: 'bien.show.bien' })}
                {/* <InfoPastilleNumber
                  infoPastilleNumber={rapprochements?.items?.length ?? 0}
                /> */}
              </ButtonDragonfly>
              <ButtonDragonfly
                topLeftRadius
                topRightRadius
                bottomRightRadius
                to={makeRoutePath('bien.show.details', { bienId })}
              >
                {formatMessage({ id: 'bien.show.details' })}
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
            </FlexRow>
            <FlexRow
              direction="row"
              gap="4"
              grow="1"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <ButtonDragonfly
                topLeftRadius
                bottomLeftRadius
                to={makeRoutePath('bien.show.photos', { bienId })}
              >
                {formatMessage({ id: 'bien.show.photos' })}
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
              <ButtonDragonfly
                disabled
                topRightRadius
                bottomRightRadius
                to={makeRoutePath('documents', { bienId })}
              >
                {formatMessage({ id: 'bien.show.documents' })}
                <InfoPastilleNumber infoPastilleNumber={0} />
              </ButtonDragonfly>
            </FlexRow>
          </div>
          {/* EN ATTENTE DE LA DUPLICATION DE PROJET */}
          {/* <Button
            className="button standard button_home projet big "
            icon={
              <IconAddWhiteAndYellow className="container_icon_plus_ideeri img-w3xl img-h3xl" />
            }
            to={makeRoutePath('projet.nouveau', { bienId })}
          >
            <p className="fs-xl">
              {formatMessage({ id: 'achat.create.projet' })}
            </p>
          </Button> */}
        </div>
      </FlexRow>
    </>
  );
}
