import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { RendezVousModel } from '../../../lib/RendezVous/rendezVous.types';
import { VenteModel } from '../../../lib/Ventes/Ventes.types';

 type CreateVenteState = {
  vente: VenteModel|null;
  rendezVous: RendezVousModel|null;
  invitations: string[];
}

type CreateVenteActions = {
  setVente(vente: VenteModel|null): void;
  setRendezVous(vente: RendezVousModel|null): void;
  addInvitation(email: string): void;
  removeInvitation(email: string): void;
  reset(): Promise<void>;
}

export type CreateVenteStore = CreateVenteState & CreateVenteActions

const initialState: CreateVenteState = {
  vente: null,
  rendezVous: null,
  invitations: [],
};

const useCreateVenteStore = create<CreateVenteStore>()(
  persist((set) => ({
    ...initialState,
    setVente: (vente) => set(() => ({ vente })),
    setRendezVous: (rendezVous) => set(() => ({ rendezVous })),
    addInvitation: (email) => set((state) => ({ invitations: [...state.invitations.filter((e) => e !== email), email] })),
    removeInvitation: (email) => set((state) => ({ invitations: [...state.invitations.filter((e) => e !== email)] })),
    reset: async () => set(initialState),
  }), {
    name: 'create-vente',
  }),
);

export default useCreateVenteStore;
