import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';

import { ServiceWorkerContext } from '../../provider/ServiceWorkerProvider';
import Button from '../Button/Button';

import Modal from './Modal';

export type UpdateAvailableModalProps = Record<string, never>

export default function UpdateAvailableModal(): JSX.Element {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const { hasUpdate, update } = useContext(ServiceWorkerContext);

  useEffect(() => {
    setOpen(hasUpdate);
  }, [hasUpdate]);

  const handleUpdate = useCallback(() => {
    update();
    setOpen(false);
    window.location.reload();
  }, [update]);

  return (
    <Modal
      open={open}
      title={formatMessage({ id: 'modal.update_available.title' })}
      onClose={() => setOpen(false)}
    >
      <p>{formatMessage({ id: 'modal.update_available.text' })}</p>
      <Button className="standard" onClick={handleUpdate}>
        {formatMessage({ id: 'modal.update_available.button' })}
      </Button>
    </Modal>
  );
}
