import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { listAchat } from '../../lib/papirisClient';
import { useFilter } from '../../provider/FilterProvider';

import useShowListAchatStore from './useShowListAchatStore';

export default function useShowListAchat() :void {
  const { setListAchat, setLoading, setError } = useShowListAchatStore();
  const {
    variant: variantRequest,
  } = useFilter();
  const [pageAchat] = useState(1);

  const showlistAchatQuery = useQuery({
    queryKey: ['achat', 'list', 'vente', variantRequest],
    queryFn: () => (pageAchat
      ? listAchat({ page: pageAchat, variant: variantRequest }) : undefined),
  });

  useEffect(() => {
    if (showlistAchatQuery?.isSuccess) {
      if (showlistAchatQuery?.data) {
        setListAchat(showlistAchatQuery?.data);
        setLoading(showlistAchatQuery?.isLoading);
      }
    }
    setError(showlistAchatQuery?.isError);
    setLoading(showlistAchatQuery?.isLoading);
  }, [showlistAchatQuery?.data, showlistAchatQuery?.isSuccess, showlistAchatQuery?.isError, showlistAchatQuery?.isLoading, setError, setLoading, setListAchat]);
}
