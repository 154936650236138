const ROUTES = {
  home: '/',
  'auth.sign_in': '/connexion',
  'auth.sign_up': '/inscription',
  'auth.password_reset': '/mot-de-passe',
  'auth.sign_out': '/deconnexion',
  messagerie: '/messagerie',
  'messagerie.show': '/messagerie/:messageId',
  info: '/info',
  'info.show': '/info/:infoId',
  profile: '/profil',
  documents: '/documents',
  'documents.show': '/documents/:documentId',
  'profile.contact': '/profil/contact',
  'profile.notification': '/profil/notification',
  'profile.password': '/profil/mot-de-passe',
  'profile.documents': '/profil/documents',
  'invitation.index': '/invitation',
  'invitation.show': '/invitation/:invitationId',
  'projet.list': '/projets',
  'projet.nouveau': '/projets/nouveau',
  'achat.index': '/achat',
  'achat.show': '/achat/:achatId',
  'achat.show.update': '/achat/:achatId/update',
  'achat.projet.agenda': '/achat/:achatId/agenda',
  'achat.projet.messagerie': '/achat/:achatId/messagerie',
  'achat.projet.documents': '/achat/:achatId/documents',
  'achat.projet.professionnel': '/achat/:achatId/professionnel',
  'achat.projet.visites': '/achat/:achatId/visites',
  'achat.projet.favoris': '/achat/:achatId/favoris',
  'achat.projet.demandes': '/achat/:achatId/demandes',
  'achat.projet.ignores': '/achat/:achatId/ignores',
  'achat.create': '/achat/nouveau',
  'achat.create.contact': '/achat/nouveau/contact',
  'achat.create.famille': '/achat/nouveau/famille',
  'achat.create.immobilier': '/achat/nouveau/immobilier',
  'achat.create.critere': '/achat/nouveau/critere',
  'achat.show.contact': '/achat/:achatId/contact',
  'achat.show.famille': '/achat/:achatId/famille',
  'achat.show.immobilier': '/achat/:achatId/immobilier',
  'achat.show.critere': '/achat/:achatId/critere',
  'location.index': '/location',
  'location.choice': '/location/choix',
  'bien.index': '/bien',
  'bien.list': '/bien/listes',
  'bien.show': '/bien/:bienId',
  'bien.show.details': '/bien/:bienId/details',
  'bien.show.details.roomId': '/bien/:bienId/details/:roomId',
  'bien.show.photos': '/bien/:bienId/photos',
  search: '/search',
  'search.vente.index': '/search/vente',
  'search.vente.show': '/search/vente/:venteId',
  'reservation.visite': '/reservation-visite',
  'search.achat.show': '/search/achat/:achatId',
  'search.achat.vente.show': '/search/achat/:achatId/vente/:venteId',
  'achat.show.bien.show': '/achat/:achatId/bien/:venteId',
  'achat.vente.demande_renseignements': '/achat/:achatId/vente/:venteId/demande-renseignements',
  'vente.index': '/vente',
  'vente.show': '/vente/:venteId',
  'vente.projet.agenda': '/vente/:venteId/agenda',
  'vente.projet.messagerie': '/vente/:venteId/messagerie',
  'vente.projet.documents': '/vente/:venteId/documents',
  'vente.projet.monbien': '/vente/:venteId/monbien',
  'vente.projet.professionnel': '/vente/:venteId/professionnel',
  'vente.projet.data.visites': '/vente/:venteId/visites',
  'vente.projet.data.contre_visites': '/vente/:venteId/contre-visites',
  'vente.projet.data.demandes': '/vente/:venteId/demandes',
  'vente.projet.data.favoris': '/vente/:venteId/favoris',
  'vente.projet.data.partages': '/vente/:venteId/partages',
  'vente.create': '/vente/nouvelle',
  'vente.create.contact': '/vente/nouvelle/contact',
  'vente.create.bien': '/vente/nouvelle/bien',
  'vente.create.rendezvous': '/vente/nouvelle/rendezvous',
  'vente.create.confirmation': '/vente/nouvelle/confirmation',
} as const;

export default ROUTES;
