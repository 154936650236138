import './FormSelect.scss';

import classNames from 'classnames';
import {
  useCallback, useMemo,
} from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, {
  DropdownIndicatorProps, MultiValue, OnChangeValue, SingleValue, components, MenuPlacement, StylesConfig,
} from 'react-select';

import { ComponentProps } from '../../types/ComponentProps';
import useFormControl from '../FormControl/useFormControl';

export type FormSelectOption = {
  label: string;
  value: string;
}

function DropdownIndicator(props: DropdownIndicatorProps<FormSelectOption>) {
  const { selectProps } = props;

  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? (
        <i className="fa-solid fa-chevron-up" />
      ) : (
        <i className="fa-solid fa-chevron-down" />
      )}
    </components.DropdownIndicator>
  );
}

export type FormSelectProps<IsMulti extends boolean> = ComponentProps<Select, {
  disabled?: boolean;
  invalid?: boolean;
  options?: FormSelectOption[];
  value?: IsMulti extends true ? FormSelectOption['value'][] : FormSelectOption['value'];
  isMulti?: IsMulti;
  menuPlacement?: MenuPlacement;
  readOnly?: boolean;
  valueReadOnly?: IsMulti extends true ? FormSelectOption['value'][] : FormSelectOption['value'];
  onChange?(value: IsMulti extends true ? FormSelectOption['value'][] : FormSelectOption['value']): void;
}>

function FormSelect<IsMulti extends boolean>({
  className,
  placeholder,
  name,
  options,
  isSearchable,
  noOptionsMessage,
  value,
  onChange,
  disabled,
  invalid,
  isMulti,
  menuPlacement = 'auto',
  readOnly,
  valueReadOnly,
  ...props
}: FormSelectProps<IsMulti>) {
  const { formatMessage } = useIntl();

  const formControl = useFormControl();
  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const selectedOptions = useMemo(() => {
    if (!options) {
      return undefined;
    }

    const _value = value ?? field.value;

    if (isMulti) {
      if (Array.isArray(_value)) {
        return _value.map((v) => options.find((option) => option.value === v));
      }

      return [];
    }

    return options.find((option) => option.value === _value);
  }, [isMulti, field.value, value, options]);

  const handleChange = useCallback((selectValue: OnChangeValue<FormSelectOption, IsMulti>) => {
    let newValue: any;

    if (isMulti) {
      newValue = (selectValue as MultiValue<FormSelectOption>).map((v) => v.value);
    } else {
      newValue = (selectValue as SingleValue<FormSelectOption>)?.value ?? null;
    }

    field.onChange(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }, [field, isMulti, onChange]);

  const classes = classNames('FormSelect', className, {
    'FormSelect-invalid': invalid ?? fieldState.invalid,
    'FormSelect-disabled': disabled,
  });

  if (readOnly) {
    const styles: StylesConfig = Array.isArray(valueReadOnly) && valueReadOnly.length === 1 ? {
      multiValue: (base) => ({
        ...base, padding: 0, paddingLeft: 0, fontSize: 18, backgroundColor: 'transparent',
      }),
      multiValueLabel: (base) => (
        {
          ...base, padding: 0, paddingLeft: 0,
        }),
      multiValueRemove: (base) => ({ ...base, display: 'none' }),
    } : {
      multiValue: (base) => ({
        ...base, padding: 0, paddingLeft: 0, fontSize: 18,
      }),
      multiValueLabel: (base) => (
        {
          ...base, paddingRight: 4, paddingLeft: 4,
        }),
      multiValueRemove: (base) => ({ ...base, display: 'none' }),
    };

    return (
      <Select
        id={formControl.inputId}
        styles={styles}
        name={name}
        className={classes}
        classNamePrefix="FormSelect"
        isMulti={isMulti}
        value={valueReadOnly}
        isClearable={false}
        menuIsOpen={false}
        isSearchable={false}
        {...props}
      />
    );
  }

  return (
    <Select
      ref={field.ref}
      id={formControl.inputId}
      name={name}
      className={classes}
      classNamePrefix="FormSelect"
      value={selectedOptions as any}
      options={options}
      isSearchable={isSearchable}
      isDisabled={disabled}
      isMulti={isMulti}
      onChange={handleChange as any}
      onBlur={field.onBlur}
      placeholder={placeholder ?? formatMessage({ id: 'label.select' })}
      components={{ DropdownIndicator }}
      noOptionsMessage={noOptionsMessage}
      menuPlacement={menuPlacement}
    />
  );
}

export default FormSelect;
