import { useIntl } from 'react-intl';

// Import Component
import FlexRow from '../../Component/FlexRow/FlexRow';
import HEADER_RETOUR from '../../Component/Header_Retour/header_retour';
import ToggleSwitch from '../../Component/Selecteur/ToggleSwitch/ToggleSwitch';
// Import des CSS
import './notification.scss';
import Title from '../../Component/Title/Title';
import { makeRoutePath } from '../../store/route/route.api';

import { useState } from 'react';

export default function NOTIFICATION({
  url,
}) {
  const { formatMessage } = useIntl();
  const hasPermission = Notification?.permission === 'granted';
  const BACKENDnotifBien = true;
  const BACKENDnotifMessage = false;
  const BACKENDnotifAppointment = null;
  const [notifBien, setNotifBien] = useState(BACKENDnotifBien ?? hasPermission);
  const [notifMessage, setNotifMessage] = useState(BACKENDnotifMessage ?? hasPermission);
  const [notifAppointment, setNotifAppointment] = useState(BACKENDnotifAppointment ?? hasPermission);

  return (
    <>
      <HEADER_RETOUR to={makeRoutePath('profile')} transparent />
      <FlexRow
        direction="column"
        gap="4"
        grow="1"
        alignItems="center"
        justifyContent="space-evenly"
      >

        <Title>{formatMessage({ id: 'profil.notification.title' })}</Title>
        <div className="container_Notification">
          <div className="container_legends">
            <legend className="legend_Notif">
              {formatMessage({ id: 'profil.notification.legend_Notif_Bien' })}
            </legend>
            <div className="edit_Notification">
              <ToggleSwitch
                value={notifBien}
                id="show_Notif_bien"
                onChange={setNotifBien}
              >
                <span>
                  {formatMessage({ id: 'profil.notification.button.show_Notif' })}
                </span>
              </ToggleSwitch>
            </div>
            <hr />
            <legend className="legend_Notif">
              {formatMessage({ id: 'profil.notification.legend_Notif_Messagerie' })}
            </legend>
            <div className="edit_Notification">
              <ToggleSwitch
                value={notifMessage}
                id="show_Notif_Message"
                onChange={setNotifMessage}
              >
                <span>
                  {formatMessage({ id: 'profil.notification.button.show_Notif' })}
                </span>
              </ToggleSwitch>
            </div>
            <hr />
            <legend className="legend_Notif">
              {formatMessage({ id: 'profil.notification.legend_Notif_Appointment' })}
            </legend>
            <div className="edit_Notification">
              <ToggleSwitch
                value={notifAppointment}
                id="show_Notif_Appointment"
                onChange={setNotifAppointment}
              >
                <span>
                  {formatMessage({ id: 'profil.notification.button.show_Notif' })}
                </span>
              </ToggleSwitch>
            </div>
            <hr />
          </div>
        </div>
      </FlexRow>
    </>
  );
}
