import './FormSelect';

import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DropdownIndicatorProps, OnChangeValue, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { ComponentProps } from '../../types/ComponentProps';
import useFormControl from '../FormControl/useFormControl';

function DropdownIndicator(props: DropdownIndicatorProps) {
  const { selectProps } = props;

  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? (
        <i className="fa-solid fa-chevron-up" />
      ) : (
        <i className="fa-solid fa-chevron-down" />
      )}
    </components.DropdownIndicator>
  );
}

export type FormSelectAsyncProps = ComponentProps<AsyncSelect, {
  disabled?: boolean;
  invalid?: boolean;
  onChange?(value: unknown): void;
}>

function FormSelectAsync({
  className,
  id,
  placeholder,
  name,
  options,
  isSearchable,
  noOptionsMessage,
  value,
  onChange,
  disabled,
  invalid,
  loadOptions,
  formatOptionLabel,
  getOptionValue,
  isMulti,
  ...props
}: FormSelectAsyncProps) {
  const { formatMessage } = useIntl();

  const formControl = useFormControl();

  const {
    field,
    fieldState,
  } = useController({
    name: name ?? formControl.name ?? '',
    ...props,
  });

  const _value = value ?? field.value ?? null;

  const fieldOnChange = field.onChange;
  const handleChange = useCallback((value: OnChangeValue<unknown, false>) => {
    fieldOnChange(value);

    if (onChange) {
      onChange(value);
    }
  }, [fieldOnChange, onChange]);

  const classes = classNames('FormSelect', className, {
    'FormSelect-invalid': invalid ?? fieldState.invalid,
    'FormSelect-disabled': disabled,
  });

  return (
    <AsyncSelect
      ref={field.ref}
      id={id}
      name={name}
      className={classes}
      classNamePrefix="FormSelect"
      value={_value}
      isSearchable={isSearchable}
      isDisabled={disabled}
      options={options}
      onChange={handleChange}
      loadOptions={loadOptions}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={getOptionValue}
      onBlur={field.onBlur}
      placeholder={placeholder ?? formatMessage({ id: 'label.select' })}
      components={{ DropdownIndicator }}
      cacheOptions
      defaultOptions
      isLoading={false}
      isClearable
      isRtl={false}
      noOptionsMessage={noOptionsMessage}
      isMulti={isMulti}
    />
  );
}

export default FormSelectAsync;
