import {
  ReactNode, createContext, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';

import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export type ServiceWorkerProviderProps = {
    children: ReactNode;
}

export type ServiceWorkerContextValue = {
  hasUpdate: boolean;
  update(): void;
}

const defaultContextValue: ServiceWorkerContextValue = {
  hasUpdate: false,
  update: () => {},
};

export const ServiceWorkerContext = createContext<ServiceWorkerContextValue>(defaultContextValue);

// Learn more about service workers: https://cra.link/PWA
export default function ServiceWorkerProvider({ children }: ServiceWorkerProviderProps): JSX.Element {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  useLayoutEffect(() => {
    serviceWorkerRegistration.register({
      onSuccess: ((registration) => {
        console.log('SW success.');
      }),
      onUpdate: ((registration) => {
        console.log('SW update.');

        setWaitingWorker(registration.waiting);
      }),
      onError: (error) => {
        console.error('SW failed:', error);
      },
    });
  }, []);

  const contextValue = useMemo<ServiceWorkerContextValue>(() => ({
    hasUpdate: waitingWorker !== null,
    update: () => {
      if (waitingWorker) {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  }), [waitingWorker]);

  return <ServiceWorkerContext.Provider value={contextValue}>{children}</ServiceWorkerContext.Provider>;
}
