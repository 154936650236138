import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import messages from '../translation/fr.json';

export const DEFAULT_LOCALE = 'fr';

export type TranslationProviderProps = {
  children: ReactNode;
}

export default function TranslationProvider({ children }: TranslationProviderProps) {
  return (
    <IntlProvider messages={messages} locale={DEFAULT_LOCALE} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </IntlProvider>
  );
}
