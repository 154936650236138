import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import {
  Routes, Route, Navigate, createBrowserRouter, RouterProvider,
} from 'react-router-dom';

import DataInfoPage from '../Component/DataInfo/dataInfo';
import DECOUVERTE_1 from '../Component/Form/Decouverte_1/decouverte_1';
import DECOUVERTE_2 from '../Component/Form/Decouverte_2/decouverte_2';
import DECOUVERTE_3 from '../Component/Form/Decouverte_3/decouverte_3';
import DecouverteCriterePage from '../Component/Form/Decouverte_4/decouverte_4';
import HEADER_RETOUR from '../Component/Header_Retour/header_retour';
import HEADER_TITLE from '../Component/Header_Title/header_title';
import PageHeader from '../Component/PageHeader/PageHeader';
import {
  URL_BIEN_A_ESTIMER,
  URL_MES_DOCUMENTS_EDIT,
} from '../Function/URL_path';
import AgendaProjetPage from '../Pages/AgendaProjetPage';
import AuthenticatedLayout from '../Pages/AuthenticatedLayout/AuthenticatedLayout';
import BIEN_A_ESTIMER from '../Pages/Bien_a_Estimer/bien_a_estimer';
import ConseillerPresentation from '../Pages/ConseillerPresentation/conseillerPresentation';
import CreateAchatLayout from '../Pages/CreateAchatLayout/CreateAchatLayout';
import CreateAchatStart from '../Pages/CreateAchatLayout/CreateAchatStart';
import CreateLocationLayout from '../Pages/CreateLocationLayout/CreateLocationLayout';
import CreateProjetPage from '../Pages/CreateProjectPage/CreateProjectPage';
import DEMANDE_RENSEIGNEMENT from '../Pages/Demande_Renseignement/demandeRenseignement';
import DetailRoomBienPage from '../Pages/DetailRoomBienPage/DetailRoomBienPage';
import DETAIL_IMMO from '../Pages/Detail_Immo/detail_immo';
import DetailBienPage from '../Pages/DetailsBienPage/DetailBienPage';
import DocumentGeneralPage from '../Pages/DocumentGeneralPage';
import DocumentProjetPage from '../Pages/DocumentProjetPage';
import DOCUMENTS_PROJETS from '../Pages/Documents_Projets/documents_projets';
import EnConstruction from '../Pages/EnConstruction/EnConstruction';
import ErrorBoundary from '../Pages/Error/ErrorBoundary';
import GaleriePage from '../Pages/GaleriePage/GaleriePage';
import HomePage from '../Pages/Home/home';
import HOME_ACHAT from '../Pages/Home_Achat/home_achat';
import HOME_BIEN from '../Pages/Home_Bien/home_bien';
import HOME_VENTE from '../Pages/Home_Vente/home_vente';
import PAGE_IMMO from '../Pages/Immo/immo';
import InvitationLayout from '../Pages/InvitationLayout/InvitationLayout';
import LOADER from '../Pages/Loader/loader';
import MesBiensPage from '../Pages/MesBiensPage/MesBiensPage';
import MesProjetsLocationPage from '../Pages/MesProjetsLocationPage/MesProjetsLocationPage';
import MesProjetsPage from '../Pages/MesProjetsPage/MesProjetsPage';
import MES_DOCUMENTS from '../Pages/Mes_Documents/mes_documents';
import MES_DOCUMENTS_EDIT from '../Pages/Mes_Documents_Edit/mes_documents_edit';
import MessageriePage from '../Pages/MessageriePage';
import ModificationDesCriteresDuProjet from '../Pages/Modification_Des_Critere_Du_Projet/ModificationDesCriteresDuProjet';
import MonMotdepassePage from '../Pages/Modification_Mp/MonMotdepassePage';
import MonBienPage from '../Pages/MonBienPage';
import MonContactPage from '../Pages/MonContactPage/MonContactPage';
import MonProfilPage from '../Pages/MonProfilPage/MonProfilPage';
import Notification from '../Pages/Notification/notification';
import PageNotFound from '../Pages/PageNotFound/PageNotFound';
import ProfessionnelProjetPage from '../Pages/ProfessionnelProjetPage';
import ProfilLayout from '../Pages/ProfilLayout/ProfilLayout';
import ProfileDocumentsPage from '../Pages/ProfileDocumentsPage';
import ProjetLayout from '../Pages/ProjetLayout/ProjetLayout';
import ReservationVisite from '../Pages/ReservationVisite';
import RootLayout from '../Pages/RootLayout/RootLayout';
import SearchLayout from '../Pages/SearchLayout/SearchLayout';
import ShowAchatLayout from '../Pages/ShowAchatLayout/ShowAchatLayout';
import ShowBienLayout from '../Pages/ShowBienLayout/ShowBienLayout';
import ShowInvitationPage from '../Pages/ShowInvitationPage/ShowInvitationPage';
import ShowVenteLayout from '../Pages/ShowVenteLayout/ShowVenteLayout';
import AnonymousOutlet from '../Pages/auth/AnonymousOutlet';
import AuthenticatedOutlet from '../Pages/auth/AuthenticatedOutlet';
import ConnexionPage from '../Pages/auth/ConnexionPage';
import DeconnexionPage from '../Pages/auth/DeconnexionPage';
import InscriptionPage from '../Pages/auth/InscriptionPage';
import MotdepassePage from '../Pages/auth/MotdepassePage';
import CreateVenteBienPage from '../Pages/vente/CreateVenteLayout/CreateVenteBienPage';
import CreateVenteConfirmationPage from '../Pages/vente/CreateVenteLayout/CreateVenteConfirmationPage';
import CreateVenteContactPage from '../Pages/vente/CreateVenteLayout/CreateVenteContactPage';
import CreateVenteLayout from '../Pages/vente/CreateVenteLayout/CreateVenteLayout';
import CreateVenteRendezvousPage from '../Pages/vente/CreateVenteLayout/CreateVenteRendezvousPage';
import useQueryParams from '../hooks/useQueryParams';
import iconOpen from '../img/icon_ouverture_modal.svg';
import { showVenteQuery } from '../lib/Ventes/vente.query';
import NotificationAvailableModal from '../notification/NotificationAvailableModal';
import { makeRoutePath } from '../store/route/route.api';

import FilterProvider from './FilterProvider';
import { usePermission } from './PermissionProvider';
import InfoPage from '../Pages/InfoPage';

function RouteProvider() {
  const { hasPermission } = usePermission();
  const queryClient = useQueryClient();
  const isAnonymous = hasPermission('anonymous');
  const isAuthenticated = hasPermission('authenticated');
  const hasLoader = !(isAnonymous || isAuthenticated);

  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || undefined;
  const [activeLinkDocuments, setActiveLinkDocuments] = useState('Personnels');

  const handleLinkClick = (link) => {
    setActiveLinkDocuments(link);
  };

  function renderAnonymousRoutes() {
    return (
      <>
        <Route path={makeRoutePath('auth.sign_in')} element={<ConnexionPage />} />
        <Route path={makeRoutePath('auth.sign_up')} element={<InscriptionPage />} />
        <Route path={makeRoutePath('auth.password_reset')} element={<MotdepassePage />} />
        <Route path={makeRoutePath('auth.password_reset')} element={<MotdepassePage />} />
      </>
    );
  }

  function renderAuthenticatedRoutes() {
    return (
      <Route path="" element={<AuthenticatedLayout />}>
        <Route
          path=""
          element={(
            <>
              <NotificationAvailableModal />
              <HomePage />
            </>
          )}
        />
        <Route path={makeRoutePath('messagerie')} element={<MessageriePage />} />
        <Route path={makeRoutePath('info')} element={<InfoPage />} />
        <Route
          path="profil"
          element={<ProfilLayout />}
        >
          <Route path="" element={<MonProfilPage />} />
          <Route path="contact" element={<MonContactPage />} />
          <Route path={makeRoutePath('profile.password')} element={<MonMotdepassePage url={makeRoutePath('profile')} />} />
          <Route
            path={makeRoutePath('profile.notification')}
            element={(
              <Notification url={makeRoutePath('profile')} />
            )}
          />
          <Route path={makeRoutePath('profile.documents')} element={<ProfileDocumentsPage />} />
        </Route>
        <Route
          path={makeRoutePath('documents')}
          element={<DocumentGeneralPage />}
          // element={(
          //   <>
          //     <HEADER_TITLE
          //       title="Mes documents"
          //       toggleLeft="Personnels"
          //       toggleRight="Projets"
          //       activeLink={activeLinkDocuments}
          //       handleLinkClick={handleLinkClick}
          //     />
          //     {activeLinkDocuments === 'Personnels' ? (
          //       <MES_DOCUMENTS url={URL_MES_DOCUMENTS_EDIT} />
          //     ) : (
          //       <DOCUMENTS_PROJETS />
          //     )}
          //   </>
          // )}
        />
        <Route
          path={URL_MES_DOCUMENTS_EDIT}
          element={(
            <>
              <HEADER_TITLE
                title="Mes documents"
                toggleLeft="Personnels"
                toggleRight="Projets"
                activeLink={activeLinkDocuments}
                handleLinkClick={handleLinkClick}
              />
              {activeLinkDocuments === 'Personnels' ? (
                <MES_DOCUMENTS_EDIT url={makeRoutePath('projet.documents')} src2={iconOpen} />
              ) : (
                <DOCUMENTS_PROJETS />
              )}
            </>
          )}
        />
        <Route path={makeRoutePath('auth.sign_out')} element={<DeconnexionPage />} />
        <Route path={makeRoutePath('invitation.index')} element={<InvitationLayout />}>
          <Route path={makeRoutePath('invitation.show')} element={<ShowInvitationPage />} />
        </Route>
        <Route path={makeRoutePath('projet.list')} element={<ProjetLayout />}>
          <Route path="" element={<MesProjetsPage />} />
          <Route path="nouveau" element={<CreateProjetPage />} />
        </Route>
        <Route path={makeRoutePath('vente.index')}>
          <Route path={makeRoutePath('vente.create')} element={<CreateVenteLayout />}>
            <Route path={makeRoutePath('vente.create')} element={<Navigate to={makeRoutePath('vente.create.contact', { variant })} replace />} />
            <Route path={makeRoutePath('vente.create.contact')} element={<CreateVenteContactPage />} />
            <Route path={makeRoutePath('vente.create.bien')} element={<CreateVenteBienPage />} />
            <Route path={makeRoutePath('vente.create.rendezvous')} element={<CreateVenteRendezvousPage />} />
            <Route path={makeRoutePath('vente.create.confirmation')} element={<CreateVenteConfirmationPage />} />
          </Route>
        </Route>
        <Route path={makeRoutePath('vente.show')} element={<ShowVenteLayout />}>
          <Route
            path=""
            element={(
              <>
                <PageHeader />
                <HOME_VENTE />
              </>
            )}
          />
          <Route path={makeRoutePath('vente.projet.agenda')} element={<AgendaProjetPage />} />
          <Route path={makeRoutePath('vente.projet.messagerie')} element={<MessageriePage />} />
          <Route path={makeRoutePath('vente.projet.documents')} element={<DocumentProjetPage />} />
          <Route path={makeRoutePath('vente.projet.monbien')} element={<MonBienPage />} />
          <Route path={makeRoutePath('vente.projet.professionnel')} element={<ProfessionnelProjetPage />} />
          <Route path="decouverte/:decouverteId" element={<PageHeader />} />
          <Route path="visites" element={<DataInfoPage />} />
          <Route path="contre-visites" element={<DataInfoPage />} />
          <Route path="demandes" element={<DataInfoPage />} />
          <Route path="favoris" element={<DataInfoPage />} />
          <Route path="partages" element={<DataInfoPage />} />
        </Route>
        <Route path={makeRoutePath('achat.index')}>
          <Route path={makeRoutePath('achat.create')} element={<CreateAchatLayout />}>
            <Route path={makeRoutePath('achat.create')} element={<CreateAchatStart />} />
            <Route path={makeRoutePath('achat.create.contact')} element={<DECOUVERTE_1 />} />
            <Route path={makeRoutePath('achat.create.famille')} element={<DECOUVERTE_2 />} />
            <Route path={makeRoutePath('achat.create.immobilier')} element={<DECOUVERTE_3 />} />
            <Route path={makeRoutePath('achat.create.critere')} element={<DecouverteCriterePage />} />
          </Route>
        </Route>
        <Route path={makeRoutePath('achat.show')} element={<ShowAchatLayout />}>
          <Route
            path=""
            element={(
              <>
                <PageHeader />
                <HOME_ACHAT />
              </>
            )}
          />
          <Route path={makeRoutePath('achat.projet.agenda')} element={<AgendaProjetPage />} />
          <Route path={makeRoutePath('achat.projet.messagerie')} element={<MessageriePage />} />
          <Route path={makeRoutePath('achat.projet.documents')} element={<DocumentProjetPage />} />
          <Route
            path={makeRoutePath('achat.show')}
            element={(
              <ProjetLayout />
            )}
          >
            <Route
              path={makeRoutePath('achat.show.update')}
              element={(
                <ModificationDesCriteresDuProjet />
              )}
            />
          </Route>
          <Route path={makeRoutePath('achat.projet.professionnel')} element={<ProfessionnelProjetPage />} />
          <Route path="visites" element={<DataInfoPage />} />
          <Route path="favoris" element={<DataInfoPage />} />
          <Route path="demandes" element={<DataInfoPage />} />
          <Route path="ignores" element={<DataInfoPage />} />
          <Route path={makeRoutePath('achat.vente.demande_renseignements')} element={<ShowVenteLayout><DEMANDE_RENSEIGNEMENT /></ShowVenteLayout>} />
        </Route>
        <Route path={makeRoutePath('bien.index')} element={<ShowBienLayout />}>
          <Route path={makeRoutePath('bien.list')} element={<MesBiensPage />} />
          <Route path={makeRoutePath('bien.show')} element={<HOME_BIEN />} />
          <Route path={makeRoutePath('bien.show.photos')} element={<GaleriePage />} />
          <Route path={makeRoutePath('bien.show.details')} element={<DetailBienPage />} />
          <Route path={makeRoutePath('bien.show.details.roomId')} element={<DetailRoomBienPage />} />

        </Route>
        <Route path={makeRoutePath('location.index')} element={<CreateLocationLayout />}>
          <Route path={makeRoutePath('location.choice')} element={<MesProjetsLocationPage />} />
        </Route>
        <Route path={makeRoutePath('reservation.visite')} element={<ReservationVisite />} />
        <Route path={makeRoutePath('search')} element={<SearchLayout />}>
          <Route
            path=""
            element={(
              <FilterProvider>
                <PAGE_IMMO />
              </FilterProvider>
            )}
          />
          <Route
            path="vente"
            element={(
              <FilterProvider>
                <PAGE_IMMO />
              </FilterProvider>
            )}
          />
          <Route
            path={makeRoutePath('search.vente.show')}
            loader={({ params }) => queryClient.fetchQuery(showVenteQuery(params.venteId))}
            element={<DETAIL_IMMO />}
          />
          <Route path={makeRoutePath('search.achat.show')} element={<ShowAchatLayout />}>
            <Route path="" element={<FilterProvider><PAGE_IMMO /></FilterProvider>} />
            <Route path={makeRoutePath('search.achat.vente.show')} element={<DETAIL_IMMO />} />
          </Route>
        </Route>
        <Route
          path="conseiller/:id"
          element={(
            <>
              <HEADER_RETOUR />
              <ConseillerPresentation />
            </>
          )}
        />
        <Route
          path="/enConstruction"
          element={(
            <EnConstruction />
          )}
        />
        <Route
          path={URL_BIEN_A_ESTIMER}
          element={(
            <>
              <PageHeader
                title={(
                  <span>
                    Projet
                    <br />
                    de vente
                  </span>
                )}
              />
              <BIEN_A_ESTIMER />
            </>
          )}
        />
        <Route path="*?" element={<PageNotFound />} />
      </Route>
    );
  }

  return hasLoader ? (
    <LOADER />
  ) : (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route path="" element={<AuthenticatedOutlet />}>
          {renderAuthenticatedRoutes()}
        </Route>
        <Route path="" element={<AnonymousOutlet />}>
          {renderAnonymousRoutes()}
        </Route>
      </Route>
    </Routes>
  );
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <RouteProvider />,
    errorElement: <ErrorBoundary />,
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
