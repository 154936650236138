import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button';

import Modal from './Modal';

export type AuthRequiredModalProps = {
    open: boolean;
    onClose(): void;
}

export default function AuthRequiredModal({ open, onClose }: AuthRequiredModalProps): JSX.Element | null {
  const { formatMessage } = useIntl();

  // Sélection du conteneur pour le portail
  const modalRoot = document.getElementById('modal-root');

  const location = useLocation();
  const to = location.pathname;

  return (
    open === true && modalRoot ? createPortal(
      <Modal
        open={open}
        title={formatMessage({ id: 'modal.auth_required.title' })}
        onClose={onClose}
      >
        <p>{formatMessage({ id: 'modal.auth_required.text' })}</p>
        <Button className="button yellow" to={makeRoutePath('auth.sign_in', { to })}>
          {formatMessage({ id: 'accueil.sign_in' })}
        </Button>
        <p>{formatMessage({ id: 'label.or' })}</p>
        <Button className="standard" to={makeRoutePath('auth.sign_up', { to })}>
          {formatMessage({ id: 'accueil.sign_up' })}
        </Button>
      </Modal>,
      modalRoot,
    ) : null
  );
}
