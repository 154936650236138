import './CounterField.scss';

import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

export type CounterFieldProps = {
  label: ReactNode;
  value: number;
  onChange(value: number): void;
  min?: number;
  max?: number;
}

export default function CounterField({
  label,
  value,
  onChange,
  min,
  max,
}: CounterFieldProps) {
  const { formatMessage } = useIntl();

  const handleChange = useCallback((increment: number) => {
    const newValue = value + increment;

    if (min !== undefined && newValue < min) {
      return;
    }

    if (max !== undefined && newValue > max) {
      return;
    }

    onChange(newValue);
  }, [max, min, onChange, value]);

  return (
    <div className="CounterField">
      <div className="CounterField_text">
        <span className="CounterField_label">
          {label}
        </span>
        <span className="CounterField_value">{value}</span>
      </div>
      <div className="CounterField_buttons">
        <button type="button" className="CounterField_button" onClick={() => handleChange(1)} aria-label={formatMessage({ id: 'label.plus' })}>
          <i className="CounterField_icon fa-solid fa-plus" />
        </button>
        <button type="button" className="CounterField_button" onClick={() => handleChange(-1)} aria-label={formatMessage({ id: 'label.minus' })}>
          <i className="CounterField_icon fa-solid fa-minus" />
        </button>
      </div>
    </div>
  );
}
