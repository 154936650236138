import { useIntl } from 'react-intl';

import BINOCULARS from '../../../img/icon_Jumelle_Decouverte.svg';
import Button from '../Button';
import { makeRoutePath } from '../../../store/route/route.api';

export default function CreateProject() {
  const { formatMessage } = useIntl();

  return (
    <Button
      className="button standard projet medium"
      icon={<img src={BINOCULARS} alt={`icon ${formatMessage({ id: 'achat.create.projet' })}`} />}
      to={makeRoutePath('projet.nouveau')}
    >
      <p>{formatMessage({ id: 'achat.create.projet' })}</p>
    </Button>
  );
}
