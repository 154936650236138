import './header_retour.scss';

import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';

export default function HeaderRetour({ to = '', transparent = false, classBEM = '' }) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (!to) {
      navigate(-1);
    }
  }, [to, navigate]);

  return (
    <div className={`HeaderBack${transparent ? '_transparent' : ''} ${classBEM || ''}`}>
      <Button className="-link-neutre" to={to} onClick={handleClick}>
        <i className="fa-solid fa-chevron-left" />
        {' '}
        <FormattedMessage id="label.back" />
      </Button>
    </div>

  );
}
