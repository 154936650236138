import './FlexRow.scss';

import classnames from 'classnames';
import { CSSProperties } from 'react';

import stylenames from '../../lib/stylenames';

export type FlexRowProps = React.ComponentProps<'div'> & {
  width?: CSSProperties['width'];
  direction?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  gap?: CSSProperties['gap'];
  padding?: CSSProperties['padding'];
  wrap?: CSSProperties['flexWrap'];
  grow?: CSSProperties['flexGrow'];
}

export default function FlexRow({
  className,
  children,
  width,
  direction,
  alignItems,
  justifyContent,
  gap,
  style,
  padding,
  wrap,
  grow,
  ...props
}: FlexRowProps) {
  const classes = classnames('FlexRow', className);
  const styles = stylenames(style, {
    '--FlexRow-width': width,
    '--FlexRow-direction': direction,
    '--FlexRow-alignItems': alignItems,
    '--FlexRow-justifyContent': justifyContent,
    '--FlexRow-gap': gap,
    '--FlexRow-padding': padding,
    '--FlexRow-wrap': wrap,
    '--FlexRow-grow': grow,
  });

  return <div {...props} className={classes} style={styles}>{children}</div>;
}
