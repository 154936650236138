import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconHouseWithIcon.scss';

function IconHouseWithIcon({
  className, classColor = 'IconHouseWithIcon',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_house_with_icone', className)} id="Bouton_house_with_icone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.579 92.881">
        <title id="icon_house_with_icone">Icon Project</title>
        <g id="icon_monbien" transform="translate(-145.211 -33.282)">
          <g id="Illustration_171" data-name="Illustration 171" transform="translate(195 79.758)">
            <g id="Groupe_4525" data-name="Groupe 4525" transform="translate(-49.04 -45.657)">
              <g id="Groupe_4524" data-name="Groupe 4524" transform="translate(5.574 13.619)">
                <path id="Tracé_10186" data-name="Tracé 10186" d="M119.972,109.362a1.489,1.489,0,0,1,.577,1.039q.159,1.731.16,3.5c0,22.388-19.461,40.537-43.468,40.537S33.773,136.29,33.773,113.9q0-1.769.16-3.5a1.49,1.49,0,0,1,.577-1.039l41.813-32.3a1.5,1.5,0,0,1,1.837,0Z" transform="translate(-33.773 -76.744)" className={`${classColor}-cls-1`} />
              </g>
              <path id="Tracé_10187" data-name="Tracé 10187" d="M8.839,47.324,52.1,12.7a1.5,1.5,0,0,1,1.879,0L97.142,47.345a1.485,1.485,0,0,0,1.044.327A3.839,3.839,0,0,0,102.077,44a1.511,1.511,0,0,0-.556-1.271L53.976,4.333A1.5,1.5,0,0,0,52.1,4.324L4.57,41.955A1.5,1.5,0,0,0,4,43.14c.01,1.373.445,4.143,3.752,4.506A1.487,1.487,0,0,0,8.839,47.324Z" transform="translate(-4 -4)" className={`${classColor}-cls-1`} />
            </g>
          </g>
          <path id="Icon_material-account-circle" data-name="Icon material-account-circle" d="M18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm0,4.5A4.5,4.5,0,1,1,13.5,12,4.494,4.494,0,0,1,18,7.5Zm0,21.3a10.8,10.8,0,0,1-9-4.83c.045-2.985,6-4.62,9-4.62s8.955,1.635,9,4.62a10.8,10.8,0,0,1-9,4.83Z" transform="translate(177 76.758)" className="IconHouseWithIcon-cls-2" />
        </g>
      </svg>
    </div>
  );
}

export default IconHouseWithIcon;
