import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { AchatListModel } from '../../lib/papirisClient.types';

 type ShowListAchatState = {
  listAchats: AchatListModel | null;
   isLoading: boolean;
   isError: boolean;
}

type ShowListAchatActions = {
  setListAchat(listAchats: AchatListModel | null): void;
  setLoading(isLoading: boolean): void;
  setError(isError: boolean): void;
  reset():Promise<void>;
}

export type ShowListAchatStore = ShowListAchatState & ShowListAchatActions;

const initialState: ShowListAchatState = {
  listAchats: null,
  isLoading: false,
  isError: false,
};

const useShowListAchatStore = create<ShowListAchatStore>()(
  persist((set) => ({
    ...initialState,
    setListAchat: (listAchats) => set(() => ({ listAchats })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setError: (isError) => set(() => ({ isError })),
    reset: async () => set(initialState),
  }), {
    name: 'show-list-achat-store',
  }),
);

export default useShowListAchatStore;
