import { createContext } from 'react';

export type FormControlContextValue = {
  inputId?: string;
  name?: string;
}

export const initialFormControlContextValue: FormControlContextValue = {};

const FormControlContext = createContext(initialFormControlContextValue);

export default FormControlContext;
