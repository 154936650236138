import { CircularProgress } from '@mui/material';
import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatch, useParams } from 'react-router-dom';

// Style
import './dataInfo.scss';

// Mise en place des function\
import useShowAchatStore from '../../Pages/ShowAchatLayout/useShowAchatStore';
import useShowVenteStore from '../../Pages/ShowVenteLayout/useShowVenteStore';
import { VisibleStatuses } from '../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../store/route/route.api';
import AllInfoData from '../AllInfoData/AllInfoData';
import Button from '../Button/Button';
import CardInfoData from '../Card/CardInfoData/cardInfoData.jsx';
import HeaderRetour from '../Header_Retour/header_retour';
import PageHeader from '../PageHeader/PageHeader';
// import { dataHomeAchatMocked } from '../../Pages/MesBiensPage/BienMocked';

function hasVisibleStatus(data) {
  return typeof data === 'object' && Object.values(VisibleStatuses).includes(data.statut);
}

export default function SpecificDataInfo() {
  const { formatMessage } = useIntl();

  const { achatId, venteId } = useParams();

  const { achat: projetAchatId, isLoading: isLoadingAchat, isError: isErrorAchat } = useShowAchatStore();
  const { vente: projetVenteId, isLoading: isLoadingVente, isError: isErrorVente } = useShowVenteStore();

  // const projetAchatId = dataHomeAchatMocked;

  const urlVisites = useMatch(makeRoutePath('achat.projet.visites', { achatId }));
  const urlFavoris = useMatch(makeRoutePath('achat.projet.favoris', { achatId }));
  const urlDemandes = useMatch(makeRoutePath('achat.projet.demandes', { achatId }));
  const urlIgnores = useMatch(makeRoutePath('achat.projet.ignores', { achatId }));

  const visitesDataAchat = useMemo(() => (Array.isArray(projetAchatId?.visites) ? projetAchatId?.visites.filter(hasVisibleStatus) : []), [projetAchatId]);
  const favorisDataAchat = useMemo(() => (Array.isArray(projetAchatId?.ventesFavorites) ? projetAchatId?.ventesFavorites.filter(hasVisibleStatus) : []), [projetAchatId]);
  const demandesDataAchat = useMemo(() => (Array.isArray(projetAchatId?.demandeRenseignements) ? projetAchatId?.demandeRenseignements : []), [projetAchatId]); // ne possède pas de status
  const ignoresDataAchat = useMemo(() => (Array.isArray(projetAchatId?.ventesIgnorees) ? projetAchatId?.ventesIgnorees.filter(hasVisibleStatus) : []), [projetAchatId]);

  const visitesDataVente = useMemo(() => projetVenteId?.visites ?? [], [projetVenteId?.visites]);
  const favorisDataVente = useMemo(() => projetVenteId?.ventesFavorites ?? [], [projetVenteId?.ventesFavorites]);
  const demandesDataVente = useMemo(() => projetVenteId?.demandeRenseignements ?? [], [projetVenteId?.demandeRenseignements]);
  const contreVisitesDataVente = useMemo(() => projetVenteId?.ventesIgnorees ?? [], [projetVenteId?.ventesIgnorees]);
  const partagesDataVente = useMemo(() => projetVenteId?.ventesIgnorees ?? [], [projetVenteId?.ventesIgnorees]);
  const [dataInfo, setDataInfo] = useState([]);

  useEffect(() => {
    if (urlVisites) {
      setDataInfo(visitesDataAchat.map((visite) => visite.vente));
    } else if (urlFavoris) {
      setDataInfo(favorisDataAchat);
    } else if (urlDemandes) {
      setDataInfo(demandesDataAchat);
    } else if (urlIgnores) {
      setDataInfo(ignoresDataAchat);
    }
  }, [demandesDataAchat, favorisDataAchat, ignoresDataAchat, projetAchatId?.ventesFavorites, urlDemandes, urlFavoris, urlIgnores, urlVisites, visitesDataAchat]);

  if (isLoadingAchat || isLoadingVente) {
    <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />;
  }

  if (isErrorAchat || isErrorVente) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  return (
    <>
      <PageHeader title="Mon activité" />
      <HeaderRetour classBEM="_homeAchatVenteBienBack" to={makeRoutePath('achat.show', { achatId })} transparent />
      <div className="container_Data_Info_Achat">
        <div className="container_Header_Retour" />
        {achatId ? <AllInfoData isLoading={isLoadingAchat} isError={isErrorAchat} visitesData={visitesDataAchat} favorisData={favorisDataAchat} demandesData={demandesDataAchat} ignoresData={ignoresDataAchat} /> : ''}
        {venteId ? <AllInfoData isLoading={isLoadingVente} isError={isErrorVente} visitesData={visitesDataVente} favorisData={favorisDataVente} demandesData={demandesDataVente} partagesData={partagesDataVente} contreVisitesData={contreVisitesDataVente} /> : ''}
        <div className="container_Cards">
          {
            dataInfo && dataInfo.length > 0 && achatId && dataInfo.map((data) => (
              <Button
                key={`Titre-${data?.bien?.titre}-${data?.id}-${data?.bien?.id}` || `Titre-${data?.reference}-${data?.id}-${data?.bien?.id}`}
                to={`/search/achat/${achatId}/vente/${urlDemandes ? data?.venteId : data?.id}`}
                aria-label={`carte ${data?.bien?.titre || data?.reference}`}
              >
                <CardInfoData
                  {...data}
                />
              </Button>
            ))
          }

        </div>
      </div>
    </>
  );
}
