import './AuthLayout.scss';

export type AuthLayoutProps = {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export default function AuthLayout({ title, children }: AuthLayoutProps) {
  return (
    <div className="AuthLayout">
      {title ? (
        <div className="AuthLayout_title">
          {title}
        </div>
      ) : null}
      <div className="AuthLayout_content">
        {children}
      </div>
      <div className="AuthLayout_background" />
    </div>
  );
}
