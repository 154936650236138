import { Outlet, useParams } from 'react-router-dom';

import useShowVente from './useShowVente';

export type ShowVenteLayoutProps = {
  children?: React.ReactNode;
}

function ShowVenteLayout({ children }: ShowVenteLayoutProps): JSX.Element {
  const { venteId } = useParams();

  useShowVente(venteId);

  return (
    <>
      {children}
      <Outlet />
    </>
  );
}

export default ShowVenteLayout;
