import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconPhoneRound.scss';

function IconPhoneRound({
  className, classColor = 'phone',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_phone', className)} id="Bouton_phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <g data-name="icon_phone" transform="translate(-218 -1135)">
          <g id="Ellipse_22" data-name="Ellipse 22" className={`${classColor}-cls-1`} transform="translate(218 1135)">
            <circle className={`${classColor}-cls-3`} cx="18" cy="18" r="18" />
            <circle className={`${classColor}-cls-4`} cx="18" cy="18" r="16.5" />
          </g>
          <path id="Icon_metro-phone" data-name="Icon metro-phone" className={`${classColor}-cls-2`} d="M17.5,16.068c-1.357,1.357-1.357,2.714-2.714,2.714s-2.714-1.357-4.071-2.714S8,13.355,8,12s1.357-1.357,2.714-2.714S8,3.856,6.641,3.856,2.571,7.927,2.571,7.927c0,2.714,2.788,8.216,5.428,10.855S16.14,24.21,18.854,24.21c0,0,4.071-2.714,4.071-4.071s-4.071-5.428-5.428-4.071Z" transform="translate(223.252 1138.967)" />
        </g>
      </svg>
    </div>
  );
}

export default IconPhoneRound;
