import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import './input.scss';

/**
 * Composant d'Input pour les formulaire.
 * @param {Object} props - Les propriétés du composant.
 * @param {string} props.type - Le type de l'input (ex. "text", "password").
 * @param {function} props.register - La fonction hook form pour le "registre" du formulaire.
 * @param {Object} props.errors - La fonction hook form pour les "erreurs" du formulaire.
 * @param {string} props.register_name - Le nom d'enregistrement de l'input.
 * @param {boolean} props.required_value - La valeur boolean si l'input est requise .
 * @param {string} props.required_message - Le message d'erreur affiché si l'input est requise.
 * @returns {JSX.Element} - Le composant INPUT.
 *  @example
    register={register}
    errors={errors}
    type="text"
    register_name="firstName"
    required_value={true}
    required_message="Le Prénom est requis"
 */
export default function INPUT(props) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const hasRequierd = props.register(props.register_name, {
    required: {
      value: props.required_value,
      message: props.required_message,
    },
  });

  const hasOnlyRegister = props.register(props.register_name);

  const hasErrorForAria = props.errors[props.register_name] ? 'true' : 'false';

  const hasError = props.errors[props.register_name]?.type === 'required'
    && props.errors[props.register_name].message ? (
      <p className="alerte" role="alert">
        {props.errors[props.register_name].message}
      </p>
    ) : (
      ''
    );

  switch (props.type) {
  case 'text':
    return (
      <>
        {isInputFocused && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}
        <input
          key={props.register_name}
          autoComplete={props.autocomplete}
          id={props.id ? props.id : props.register_name}
          name={props.register_name}
          type={props.type}
          pattern={props.pattern}
          {...(props.required_value ? hasRequierd : hasOnlyRegister)}
          aria-invalid={props.errors ? hasErrorForAria : ''}
          placeholder={props.placeholder}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <label
          htmlFor={props.id ? props.id : props.register_name}
          aria-label={props.id ? props.id : props.register_name}
        >
          {props.label}
        </label>
        {props.errors && hasError}
      </>
    );
  case 'email':
    return (
      <>
        {isInputFocused && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}
        <input
          key={props.register_name}
          autoComplete={props.autocomplete}
          id={props.id ? props.id : props.register_name}
          className={props.className ? props.className : props.register_name}
          name={props.register_name}
          type={props.type}
          pattern={props.pattern}
          {...(props.required_value ? hasRequierd : hasOnlyRegister)}
          placeholder={props.placeholder}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <label
          htmlFor={props.id ? props.id : props.register_name}
          aria-label={props.id ? props.id : props.register_name}
        >
          {props.label}
        </label>
        {props.errors && hasError}
      </>
    );
  case 'number':
    return (
      <>
        {isInputFocused && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}
        <input
          key={props.register_name}
          id={props.id ? props.id : props.register_name}
          name={props.register_name}
          type={props.type}
          min={props.min}
          max={props.max}
          onChange={props.onChange}
          pattern={props.pattern}
          {...(props.required_value ? hasRequierd : hasOnlyRegister)}
          aria-invalid={props.errors ? hasErrorForAria : ''}
          placeholder={props.placeholder}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <label
          htmlFor={props.id ? props.id : props.register_name}
          aria-label={props.id ? props.id : props.register_name}
        >
          {props.label}
        </label>
        {props.errors && hasError}
      </>
    );
  case 'checkbox':
    return (
      <>
        {isInputFocused && props.exempleText && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}
        <input
          key={props.register_name}
          id={props.id ? props.id : props.register_name}
          type={props.type}
          name={props.register_name}
          value={props.checked}
          {...(props.required_value ? hasRequierd : hasOnlyRegister)}
          aria-invalid={props.errors ? hasErrorForAria : ''}
          checked={props.checked}
          onChange={(event) => {
            props.onChange(!props.checked);
          }}
        />
        <label
          htmlFor={props.id ? props.id : props.register_name}
          aria-label={props.id ? props.id : props.register_name}
        >
          {props.label}
        </label>
        {props.errors && hasError}
      </>
    );
  case 'date':
    return (
      <>
        {isInputFocused && props.exempleText && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}
        <input
          key={props.register_name}
          id={props.id ? props.id : props.register_name}
          type={props.type}
          name={props.register_name}
          value={props.checked}
          {...(props.required_value ? hasRequierd : hasOnlyRegister)}
          pattern={props.pattern}
          {...(props.required_value ? hasRequierd : hasOnlyRegister)}
          aria-invalid={props.errors ? hasErrorForAria : ''}
          placeholder={props.placeholder}
        />
        <label
          htmlFor={props.id ? props.id : props.register_name}
          aria-label={props.id ? props.id : props.register_name}
        >
          {props.label}
        </label>
        {props.errors && hasError}
      </>
    );
  case 'radio':
    return (
      <>
        {isInputFocused && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}
        <div>
          <input
            key={props.register_name}
            id={props.id ? props.id : props.register_name}
            name={props.register_name}
            type={props.type}
            value={props.value}
            {...(props.required_value ? hasRequierd : hasOnlyRegister)}
            aria-invalid={props.errors ? hasErrorForAria : ''}
            checked={props.checked}
            onChange={props.onChange}
            onFocus={props.onFocus || handleInputFocus}
            onBlur={props.onBlur || handleInputBlur}
          />
          <label
            htmlFor={props.id ? props.id : props.register_name}
            aria-label={props.id ? props.id : props.register_name}
          >
            {props.label}
          </label>
        </div>
        {props.errors && props.required_value && hasError}
      </>
    );
  case 'select':
    return (
      <>
        {isInputFocused && (
          <div>
            <small className="info">{props.exempleText}</small>
          </div>
        )}

        <Select
          key={props.name}
          id={props.id ? props.id : props.name}
          name={props.name}
          onChange={props.onChange}
          options={props.options}
          aria-invalid={props.errors ? hasErrorForAria : ''}
        />
        <label
          htmlFor={props.id ? props.id : props.register_name}
          aria-label={props.id ? props.id : props.register_name}
        >
          {props.label}
        </label>
        {props.errors && hasError}
      </>
    );
  default:
    return null;
  }
}

INPUT.propTypes = {
  type: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.object,
  register_name: PropTypes.string,
  required_value: PropTypes.bool,
  required_message: PropTypes.string,
};
