import './ButtonDragonfly.scss';

import classnames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type ButtonDragonflyProps = {
  to:string;
  disabled?:boolean;
  className?:string;
  'aria-label'?: string;
  children?: ReactNode;
  topLeftRadius?: boolean;
  topRightRadius?: boolean;
  bottomLeftRadius?: boolean;
  bottomRightRadius?: boolean;
}

export default function ButtonDragonfly({
  to,
  disabled,
  className,
  'aria-label': ariaLabel,
  topLeftRadius,
  topRightRadius,
  bottomLeftRadius,
  bottomRightRadius,
  children,
  ...props
}: ButtonDragonflyProps) {
  const classes = classnames(
    'buttonDragonfly',
    disabled ? 'buttonDragonflyDisabled' : '',
    className,
    {
      topLeftRadius,
      topRightRadius,
      bottomLeftRadius,
      bottomRightRadius,
    },
  );

  return disabled ? (
    <span className={classes} aria-label={ariaLabel}>
      {children}
    </span>
  )
    : (
      <Link
        {...props}
        to={to}
        className={classes}
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    );
}
