class RedirectError extends Error {
  public to: string;

  constructor(to: string) {
    super('Redirect error');

    this.to = to;
  }
}

export default RedirectError;
