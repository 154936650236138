import { useIntl } from 'react-intl';

import HEADER_RETOUR from '../Component/Header_Retour/header_retour';
import './EnConstruction/EnConstruction.scss';
import FlexRow from '../Component/FlexRow/FlexRow';
import Title from '../Component/Title/Title';
import { makeRoutePath } from '../store/route/route.api';

function ProfileDocumentsPage(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <HEADER_RETOUR to={makeRoutePath('profile')} transparent />
      <FlexRow
        direction="column"
        grow="1"
        alignItems="center"
        justifyContent="center"
        padding="1rem"
      >

        <Title
          title={formatMessage({ id: 'profile.document.projet.title' })}
        />
        <p>{formatMessage({ id: 'profile.document.projet.text' })}</p>
      </FlexRow>
    </>
  );
}

export default ProfileDocumentsPage;
