import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { listBien } from '../../lib/papirisClient';

import useListBienStore from './useListBienStore';

export default function useListBien():void {
  const { setList, setLoading, setError } = useListBienStore();

  const listBienQuery = useQuery(['bien', 'list', 1], () => listBien({ page: 1 }));

  useEffect(() => {
    if (listBienQuery.isSuccess) {
      if (listBienQuery.data) {
        setList(listBienQuery.data);
        setLoading(listBienQuery.isLoading);
      }
    }
    setError(listBienQuery.isError);
    setLoading(listBienQuery.isLoading);
  }, [setList, setError, setLoading, listBienQuery.data, listBienQuery.isError, listBienQuery.isLoading, listBienQuery.isSuccess]);
}
