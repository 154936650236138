import './Modal.scss';

import classNames from 'classnames';
import {
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import IconClose from '../Icon/IconClose';

export type ModalProps = {
  open?: boolean;
  onClose?(): void;
  children: ReactNode;
  title?: ReactNode;
  subtitle?: string;
  icon?: ReactNode;
}

export default function Modal({
  open = true,
  onClose,
  children,
  title,
  subtitle,
  icon,
}: ModalProps) {
  const { formatMessage } = useIntl();
  const [show, setShow] = useState(open);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setShow((previousShow) => !previousShow);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <div
      className={classNames('Modal', { 'Modal-open': show, 'Modal-close': !show })}
      role="presentation"
      onKeyDown={handleKeyDown}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={-1}
    >
      <div
        className="Modal_overlay"
        role="button"
        aria-label={formatMessage({ id: 'label.close' })}
        onClick={handleClose}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      />
      <div className="Modal_popup">
        <button
          type="button"
          className="Modal_closeButton"
          aria-label={formatMessage({ id: 'label.close' })}
          onClick={handleClose}
        >
          <IconClose className="Modal_closeIcon" />
        </button>
        <div className="Modal_content">
          {icon ? <div className="Modal_icon">{icon}</div> : null}
          {title ? <div className="Modal_title">{title}</div> : null}
          {subtitle ? <div className="Modal_subtitle">{subtitle}</div> : null}
          {children ? <div className="Modal_children">{children}</div> : null}
        </div>
      </div>
    </div>
  );
}
