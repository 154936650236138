import { useIntl } from 'react-intl';

import FlexRow from '../Component/FlexRow/FlexRow';
import PageHeader from '../Component/PageHeader/PageHeader';
import Title from '../Component/Title/Title';
import useQueryParams from '../hooks/useQueryParams';
import HeaderRetour from '../Component/Header_Retour/header_retour';

function DocumentGeneralPage(): JSX.Element {
  const { formatMessage } = useIntl();

  const queryParams = useQueryParams();
  const bienId = queryParams.get('bienId');
  const colorBcg = bienId ? '_bien' : '_base';

  return (
    <>
      <PageHeader bcg={colorBcg} />
      <HeaderRetour
        classBEM="_homeAchatVenteBienBack"
        transparent
      />
      <FlexRow
        direction="column"
        grow="1"
        alignItems="center"
        justifyContent="center"
        padding="1rem"
      >
        <Title
          title={
            bienId
              ? formatMessage({ id: 'document.bien.title' })
              : formatMessage({ id: 'document.general.title' })
          }
        />
        <p>
          {bienId
            ? formatMessage({ id: 'document.bien.text' })
            : formatMessage({ id: 'document.general.text' })}
        </p>
      </FlexRow>
    </>
  );
}

export default DocumentGeneralPage;
