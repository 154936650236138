import InfoPastilleNumber from '../../InfoPastilleNumber/InfoPastilleNumber';
import Button from '../Button';

export default function ButtonTitleItems({ item, routePath, idParam }) {
  return item ? (
    item?.rapprochements ? (
      <Button
        key={item?.key || item?.id}
        to={routePath}
        className="containerMaSelection"
        id={item?.id}
        value={item?.id}
      >
        {item?.id === 'nouveau' ? '' : <InfoPastilleNumber infoPastilleNumber={item.rapprochements} />}
        <p
          className={`maSelection ${idParam === item?.id ? 'activeTitle' : ''}`}
        >
          {item?.title}
        </p>
      </Button>
    )
      : (
        <Button
          key={item?.key || item?.id}
          to={routePath}
          className="containerMaSelection"
          id={item?.id}
          value={item?.id}
        >
          <p
            className={`maSelection ${idParam === item?.id ? 'activeTitle' : ''}`}
          >
            {item?.title}
          </p>
        </Button>
      )
  ) : null;
}
