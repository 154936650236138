import './MonProfilPage.scss';

import { useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '../../Component/Button/Button';
import FlexRow from '../../Component/FlexRow/FlexRow';
import ModalGlow from '../../Component/Modal/ModalGlow/ModalGlow';
import PRESENTATION_USER from '../../Component/PresentationUser/PresentationUser';
import iconOpen from '../../img/icon_ouverture_modal.svg';
import { useAuth } from '../../provider/AuthProvider';
import { useUser } from '../../provider/UserProvider';
import { makeRoutePath } from '../../store/route/route.api';

export default function MonProfilPage() {
  const [modal, setModal] = useState(false);
  const { user } = useUser();
  const { formatMessage } = useIntl();

  const handleModalChange = () => {
    if (modal === true) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const auth = useAuth();
  const hasPasswordProvider = auth.user?.providerData?.some((provider) => provider.providerId === 'password') ?? false;

  return (
    <FlexRow
      direction="row"
      gap="4"
      grow="1"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <div className="container_Mon_Profil">
        <div className="user">
          {user?.contact ? (
            <PRESENTATION_USER
              avatar={user.contact.avatar}
              prenom={user.contact.prenom}
              nom={user.contact.nom}
            />
          ) : null}
        </div>
        <div className="container_Buttons_Profil">
          <Button
            keyInfo="Infos personnelles"
            className="button standard "
            ariaLabel="Infos personnelles"
            to="./contact"
          >
            {formatMessage({ id: 'profil.button.info_perso' })}
          </Button>
          {hasPasswordProvider ? (
            <Button
              keyInfo="Mot de passe"
              className="button standard"
              ariaLabel="Mot de passe"
              to={makeRoutePath('profile.password')}
            >
              {formatMessage({ id: 'profil.button.mot_de_passe' })}
            </Button>
          ) : null}
          <Button
            keyInfo="Notification"
            className="button standard "
            ariaLabel="Notification"
            to="./notification"
          >
            {formatMessage({ id: 'profil.button.notification' })}
          </Button>
          <Button
            keyInfo="Mes documents"
            className="button standard"
            ariaLabel="Mes documents"
            to={makeRoutePath('profile.documents')}
          >
            {formatMessage({ id: 'profil.button.mes_documents' })}
          </Button>
        </div>
        <Button type="submit" className="button submit yellow" to={makeRoutePath('auth.sign_out')}>
          {formatMessage({ id: 'profil.deconnexion' })}
        </Button>
        <Button
          className="button small-light"
          onClick={() => handleModalChange()}
        >
          <small>{formatMessage({ id: 'profil.suppression.compte' })}</small>
        </Button>
        {modal === true && (
          <ModalGlow
            open
            src1={<i className="fa-solid fa-xmark" />}
            src2={iconOpen}
            title={formatMessage({ id: 'profil.contact.modal.title.suppression.compte' })}
            url="https://ideeri-landing.webflow.io/"
          >
            <p>{formatMessage({ id: 'profil.contact.modal.label.suppression.compte' })}</p>
          </ModalGlow>
        )}
      </div>
    </FlexRow>
  );
}
