import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';

import HEADER_RETOUR from '../../Component/Header_Retour/header_retour';
import PageHeader from '../../Component/PageHeader/PageHeader';
import { makeRoutePath } from '../../store/route/route.api';

function ProfilLayout(): JSX.Element {
  return (
    <>
      <PageHeader
        bcg="_param"
        titleRotate={(
          <span>
            <FormattedMessage id="profil.header_title" />
          </span>
        )}
      />
      <Outlet />
    </>
  );
}

export default ProfilLayout;
