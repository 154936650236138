import './FormLabel.scss';

import classNames from 'classnames';

import useFormControl from '../FormControl/useFormControl';

export type FormLabelProps = {
  children?: React.ReactNode;
  required?: boolean;
}

function FormLabel({ children, required }:FormLabelProps): JSX.Element {
  const { inputId } = useFormControl();
  const classes = classNames('FormLabel');

  return (
    <label className={classes} htmlFor={inputId}>
      {children}
      {required ? <span className="FormLabel-required">*</span> : null}
    </label>
  );
}

export default FormLabel;
