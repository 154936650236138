import { Outlet, ScrollRestoration } from 'react-router-dom';

import UpdateAvailableModal from '../../Component/Modal/UpdateAvailableModal';

function RootLayout(): JSX.Element {
  return (
    <>
      <Outlet />
      <ScrollRestoration />
      <UpdateAvailableModal />
    </>
  );
}

export default RootLayout;
