import { showVente } from './Ventes.api';

export function showVenteQuery(venteId?: string) {
  return {
    queryKey: ['vente', venteId],
    queryFn: () => (venteId ? showVente(venteId) : undefined),
  };
}

export function showVenteQuerytmp(venteId?: string) {
  return {
    queryKey: ['vente', venteId],
    queryFn: () => (venteId ? showVente(venteId) : undefined),
  };
}
