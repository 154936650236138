import './decouverte_4.scss';

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import useCreateAchatStore from '../../../Pages/CreateAchatLayout/useCreateAchatStore';
import RedirectError from '../../../Pages/Error/RedirectError';
import useShowAchatStore from '../../../Pages/ShowAchatLayout/useShowAchatStore';
import { Variant } from '../../../lib/Achats/Achats.types';
import {
  createInvitation, listDecouverteCritere, terminerAchatDecouverte, updateDecouverteCritere, updateDecouverteImmobilier,
} from '../../../lib/papirisClient';
import { makeRoutePath } from '../../../store/route/route.api';
import Button from '../../Button/Button';
import WordSelection from '../../Button/Souhaits/critere_souhaites_nonSouhaites';
import Container from '../../Container/Container';
import FlexRow from '../../FlexRow/FlexRow';
import FormFooter from '../../FormFooter/FormFooter';
import Modal from '../../Modal/Modal';
import Title from '../../Title/Title';

function isCritereSouhaite(critere) {
  return critere.type === 'souhaite';
}

function isCritereRedhibitoire(critere) {
  return critere.type === 'redhibitoire';
}

function makeCritereOption(critere) {
  return {
    id: critere.id,
    value: critere.id,
    label: critere.label,
  };
}

function useCritereOptions(initialCriteres) {
  const listCritereQuery = useQuery('critere', listDecouverteCritere);

  const criteresSouhaitesOptions = useMemo(() => {
    const options = initialCriteres.filter(isCritereSouhaite).map(makeCritereOption);

    if (listCritereQuery?.data) {
      options.push(
        ...listCritereQuery.data.items
          .filter(isCritereSouhaite)
          .filter((critere) => undefined === options.find((c) => c.id === critere.id))
          .map(makeCritereOption),
      );
    }

    return options;
  }, [initialCriteres, listCritereQuery.data]);

  const critereRedhibitoiresOptions = useMemo(() => {
    const options = initialCriteres.filter(isCritereRedhibitoire).map(makeCritereOption);

    if (listCritereQuery?.data) {
      options.push(
        ...listCritereQuery.data.items
          .filter(isCritereRedhibitoire)
          .filter((critere) => undefined === options.find((c) => c.id === critere.id))
          .map(makeCritereOption),
      );
    }

    return options;
  }, [initialCriteres, listCritereQuery.data]);

  return { criteresSouhaitesOptions, critereRedhibitoiresOptions };
}

const DEFAULT_CRITERES = [];

export default function DecouverteCriterePage({ updating }) {
  const { formatMessage } = useIntl();
  const { achat: wipAchat, invitations, reset } = useCreateAchatStore();
  const { achat: projetAchatUpdate } = useShowAchatStore();
  const [finishedAchat, setFinishedAchat] = useState(null);
  const [modalFinished, setModalFinished] = useState(false);
  const queryClient = useQueryClient();
  const critereMutation = useMutation(updateDecouverteCritere);
  const invitationMutation = useMutation(createInvitation);
  const immobilierMutation = useMutation(updateDecouverteImmobilier);
  const terminerMutation = useMutation(terminerAchatDecouverte);
  const locationUrl = useLocation();

  const achat = finishedAchat ?? wipAchat;
  const navigate = useNavigate();

  // if (!achat) {
  //   throw new RedirectError('../contact');
  // }

  const typeOfProjet = locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id }) ? projetAchatUpdate : achat;
  const variant = typeOfProjet?.variant;

  const initialCriteres = typeOfProjet?.decouverte?.criteres ?? DEFAULT_CRITERES;
  const { criteresSouhaitesOptions, critereRedhibitoiresOptions } = useCritereOptions(initialCriteres);

  const initialValues = useMemo(() => {
    const criteresSouhaites = criteresSouhaitesOptions.filter(
      (option) => initialCriteres.find(
        (critere) => option?.id === critere?.id,
      ),
    );

    const criteresRedhibitoires = critereRedhibitoiresOptions.filter(
      (option) => initialCriteres.find(
        (critere) => option?.id === critere?.id,
      ),
    );

    return {
      criteresSouhaites,
      criteresRedhibitoires,
      commentaire: typeOfProjet?.decouverte?.commentaire ?? '',
      transmettre: typeOfProjet?.decouverte?.transmettre ?? false,
    };
  }, [critereRedhibitoiresOptions, criteresSouhaitesOptions, initialCriteres, typeOfProjet?.decouverte?.commentaire, typeOfProjet?.decouverte?.transmettre]);

  const form = useForm({
    mode: 'onTouched',
    values: initialValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {
      isSubmitting, isSubmitSuccessful, isValid,
    },
  } = form;

  const {
    criteresSouhaites,
    criteresRedhibitoires,
    commentaire,
    transmettre,
  } = watch();

  const onSubmit = useCallback(async (data) => {
    if (!typeOfProjet?.decouverte) {
      return;
    }

    // Build custom criteres list
    const criteres = [
      ...data.criteresSouhaites
        .filter((critere) => undefined === critere.id)
        .map((critere) => ({ label: critere?.label, type: 'souhaite' })),
      ...data.criteresRedhibitoires
        .filter((critere) => undefined === critere.id)
        .map((critere) => ({ label: critere?.label, type: 'redhibitoire' })),
    ];

    // Build existing criteres list
    const criteresIds = [
      ...data.criteresSouhaites,
      ...data.criteresRedhibitoires,
    ]
      .filter((critere) => undefined !== critere?.id)
      .map((critere) => critere?.id);

    // Save criteres first
    await critereMutation.mutateAsync({
      id: typeOfProjet?.decouverte?.id,
      criteres,
      criteresIds,
    });

    // Save comment and transmettre
    await immobilierMutation.mutateAsync({
      id: typeOfProjet?.decouverte?.id,
      commentaire,
      transmettre,
    });

    // Confirm the achat decouverte
    await terminerMutation.mutateAsync(typeOfProjet?.id);

    // Send invitations
    await Promise.all(invitations.map(
      (email) => invitationMutation.mutateAsync({
        achatId: typeOfProjet?.id,
        email,
      }),
    ));

    if (locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: achat?.id })) {
      await queryClient.invalidateQueries({ queryKey: 'achat' });
    } else {
      await queryClient.invalidateQueries({ queryKey: 'achat' });
      setFinishedAchat(achat);
      setModalFinished(true);
      await reset();
    }
  }, [achat, commentaire, critereMutation, immobilierMutation, invitationMutation, invitations, locationUrl.pathname, queryClient, reset, terminerMutation, transmettre, typeOfProjet?.decouverte, typeOfProjet?.id]);

  const handleCommentaireChange = useCallback((event) => {
    setValue('commentaire', event.target.value);
  }, [setValue]);

  const handleTransmettreChange = useCallback((value) => {
    setValue('transmettre', value);
  }, [setValue]);

  /** Redirects to contact if no achat was found in store. */
  useEffect(() => {
    if (!isValid) {
      if (!typeOfProjet) {
        throw new RedirectError('../contact');
      }
    }
  }, [typeOfProjet, isValid]);

  return (
    <form className="form_decouvert" onSubmit={handleSubmit(onSubmit)}>
      <Container size="lg">
        <Title
          title={formatMessage({ id: 'achats.create.critere.label' })}
          size={3}
          positon="start"
          subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 4, total: 4 })}
        />
        <div className="border_form_decouvert">
          <div className="container_principal_form_decouvert">
            <section className="section_middle">
              <div className="container_secondaire_form_decouvert">
                <Title
                  title={formatMessage({ id: 'achats.create.critere.souhaites.label' })}
                  size={4}
                  positon="start"
                />
                <div className="container_WordSelection">
                  <div className="word-selection-group">
                    <WordSelection
                      value={criteresSouhaites}
                      onChange={(v) => setValue('criteresSouhaites', v)}
                      options={criteresSouhaitesOptions}
                      tabIndexStart={0}
                      classColors="colorSouhait"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="section_middle">
              <div className="container_secondaire_form_decouvert">
                <Title
                  title={formatMessage({ id: 'achats.create.critere.redhibitoires.label' })}
                  size={4}
                  positon="start"
                />
                <div className="container_WordSelection">
                  <div className="word-selection-group">
                    <WordSelection
                      value={criteresRedhibitoires}
                      onChange={(v) => setValue('criteresRedhibitoires', v)}
                      options={critereRedhibitoiresOptions}
                      tabIndexStart={criteresSouhaites?.length}
                      classColors="colorRedhibitoires"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="section_middle">
              <div className="container_secondaire_form_decouvert commentaire_projet">
                <Title
                  title={formatMessage({ id: 'achats.create.critere.commentaire.label' })}
                  size={4}
                  positon="start"
                />
                <div className="container_projet">
                  <label
                    className="standard_label"
                    htmlFor="commentaire_Projet"
                    aria-label="commentaire_Projet"
                  >
                    <FormattedMessage id="achats.create.critere.commentaire.label.precis" />
                    <textarea
                      id="commentaire_Projets"
                      className="commentaire_Projets"
                      name="commentaire_Projet"
                      value={commentaire}
                      onChange={handleCommentaireChange}
                      rows="5"
                      cols="33"
                      autoCapitalize="sentences"
                      spellCheck="true"
                      autoComplete="on"
                    />
                  </label>
                </div>
              </div>
            </section>
          </div>
          {/* EN ATTENTE DES CGU  */}
          {/* <div className="edit_Notification">
            <ToggleSwitch
              value={transmettre}
              onChange={handleTransmettreChange}
            >
              <FormattedMessage id="achats.create.critere.transmettre.label" />
            </ToggleSwitch>
          </div> */}
        </div>
        <FormFooter>
          <FlexRow alignItems="center" justifyContent="space-around" direction="row">
            { locationUrl.pathname === makeRoutePath('achat.show.update', { achatId: projetAchatUpdate?.id })
              ? ''
              : (
                <Button
                  icon={<i className="fa-solid fa-chevron-left" />}
                  to="../immobilier"
                >
                  <FormattedMessage id="label.back" />
                </Button>
              )}
            <Button
              className="button submit yellow"
              disabled={isSubmitting || !isValid}
              type="submit"
            >
              {updating ? <FormattedMessage id="achats.update.finish" /> : <FormattedMessage id="achats.create.finish" />}
            </Button>
          </FlexRow>
        </FormFooter>
      </Container>
      <Modal
        open={modalFinished || isSubmitSuccessful}
        title={formatMessage({ id: variant === Variant.location ? 'achat-location-vente.create.confirmation.dialog_title' : 'achat.create.confirmation.dialog_title' })}
        onClose={() => navigate(makeRoutePath('projet.list'))}
      >
        <p>{formatMessage({ id: 'achat-location-vente.create.confirmation.dialog_text' })}</p>
        <Button className="standard" to={makeRoutePath('achat.show', { achatId: typeOfProjet?.id })}>
          {formatMessage({ id: 'achat-location-vente.create.confirmation.dialog_continue' })}
        </Button>
      </Modal>
    </form>
  );
}
