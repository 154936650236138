import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import useQueryParams from '../hooks/useQueryParams';
import { Variant } from '../lib/Achats/Achats.types';
import { listVente } from '../lib/papirisClient';
import { makeRoutePath } from '../store/route/route.api';

interface Filters {
  modalState: string;
  modalSearch: string;
  achatId: number | null;
  page: number;
  villes: string[];
  bienTypes: string[];
  variant:string|string[];
  budgetMin: string;
  budgetMax: string;
  arrayChambre: string;
  surfaceHabitableMin: string;
  surfaceHabitableMax: string;
  surfaceTerrainMin: string;
  surfaceTerrainMax: string;
  dpeMin: string;
  addFilters: (filters: Partial<Filters>) => void;
  setFilters: (filters: Partial<Filters>) => void;
}

const defaultValue: Filters = {
  modalState: '',
  modalSearch: '_openHalf' || '_closedHalf',
  achatId: null,
  page: 1,
  villes: [],
  bienTypes: [],
  variant: '',
  budgetMin: '',
  budgetMax: '',
  arrayChambre: '',
  surfaceHabitableMin: '',
  surfaceHabitableMax: '',
  surfaceTerrainMin: '',
  surfaceTerrainMax: '',
  dpeMin: '',
  addFilters: () => {},
  setFilters: () => {},
};

const FilterContext = createContext(defaultValue);

export function useFilter() {
  return useContext(FilterContext);
}
export default function FilterProvider(props) {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState('_closed');
  const [modalSearch, setModalSearch] = useState('_openHalf');

  const toggleModal = useCallback(() => {
    switch (modalState) {
    case '_open':
      setModalState('isClosing'); // visuel scss
      setTimeout(() => setModalState('_closed'), 500);
      break;
    case '_closed':
      setModalState('_open');
      break;
    default:
      break;
    }
  }, [modalState]);

  const { achatId } = useParams();

  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') ?? 1, 10);

  const villesQuery = queryParams.get('villes');
  const villes = useMemo(() => villesQuery?.split(',') ?? [], [villesQuery]);
  const variant = queryParams.get('variant') ?? String(Variant.achatvente); // leave by default 'String(Variant.achatvente)' to make the request
  const bienTypesQuery = queryParams.get('bienTypes');
  const bienTypes = useMemo(() => bienTypesQuery?.split(',') ?? [], [bienTypesQuery]);
  const chambresMin = queryParams.get('chambresMin') ?? undefined;
  const budgetMin = queryParams.get('budgetMin') ?? undefined;
  const budgetMax = queryParams.get('budgetMax') ?? undefined;
  const surfaceHabitableMin = queryParams.get('surfaceHabitableMin') ?? undefined;
  const surfaceHabitableMax = queryParams.get('surfaceHabitableMax') ?? undefined;
  const surfaceTerrainMin = queryParams.get('surfaceTerrainMin') ?? undefined;
  const surfaceTerrainMax = queryParams.get('surfaceTerrainMax') ?? undefined;
  const dpeMin = queryParams.get('dpeMin') ?? undefined;
  const distance = queryParams.get('distance') ?? undefined;

  const listVenteQuery = useQuery({
    queryKey: [
      'vente', 'list', page, achatId, villes, variant, bienTypes, chambresMin, budgetMin, budgetMax, surfaceHabitableMin, surfaceHabitableMax, surfaceTerrainMin, surfaceTerrainMax, dpeMin, distance,
    ],
    queryFn: () => (achatId === 'nouveau' ? undefined : listVente({
      page,
      achatId,
      villes: villes.length > 0 ? villes : undefined,
      variant: variant || undefined,
      bienTypes: bienTypes.length > 0 ? bienTypes : undefined,
      chambresMin,
      budgetMin: budgetMin ? budgetMin * 100 : undefined,
      budgetMax: budgetMax ? budgetMax * 100 : undefined,
      surfaceHabitableMin: surfaceHabitableMin ? surfaceHabitableMin * 100 : undefined,
      surfaceHabitableMax: surfaceHabitableMax ? surfaceHabitableMax * 100 : undefined,
      surfaceTerrainMin: surfaceTerrainMin ? surfaceTerrainMin * 100 : undefined,
      surfaceTerrainMax: surfaceTerrainMax ? surfaceTerrainMax * 100 : undefined,
      dpeMin,
      distance,
    })),
  });

  const setFilters = useCallback((filters) => {
    const params = {
      achatId: filters?.achatId ?? undefined,
      page: filters?.page > 1 ? filters.page : undefined,
      variant: filters?.variant ?? undefined,
      villes: filters?.villes?.length > 0 ? filters.villes : undefined,
      bienTypes: filters?.bienTypes?.length > 0 ? filters.bienTypes : undefined,
      chambresMin: filters?.chambresMin || undefined,
      budgetMin: filters?.budgetMin || undefined,
      budgetMax: filters?.budgetMax || undefined,
      surfaceHabitableMin: filters?.surfaceHabitableMin || undefined,
      surfaceHabitableMax: filters?.surfaceHabitableMax || undefined,
      surfaceTerrainMin: filters?.surfaceTerrainMin || undefined,
      surfaceTerrainMax: filters?.surfaceTerrainMax || undefined,
      dpeMin: filters?.dpeMin || undefined,
      distance: filters?.distance || undefined,
    };

    const to = makeRoutePath(null, params);

    navigate(to);
  }, [navigate]);

  const addFilters = useCallback((filters) => {
    const params = {
      achatId,
      page,
      variant,
      villes,
      bienTypes,
      chambresMin,
      budgetMin,
      budgetMax,
      surfaceHabitableMin,
      surfaceHabitableMax,
      surfaceTerrainMin,
      surfaceTerrainMax,
      dpeMin,
      distance,
      ...filters,
    };

    setFilters(params);
  }, [achatId, page, variant, villes, bienTypes, chambresMin, budgetMin, budgetMax, surfaceHabitableMin, surfaceHabitableMax, surfaceTerrainMin, surfaceTerrainMax, dpeMin, distance, setFilters]);

  const value = useMemo(
    () => ({
      achatId,
      page,
      variant,
      listVenteQuery,
      villes,
      distance,
      bienTypes,
      budgetMin,
      budgetMax,
      chambresMin,
      surfaceHabitableMin,
      surfaceHabitableMax,
      surfaceTerrainMin,
      surfaceTerrainMax,
      dpeMin,
      modalState,
      modalSearch,
      setModalSearch,
      toggleModal,
      addFilters,
      setFilters,
    }),
    [setFilters, addFilters, page, achatId, variant, listVenteQuery, villes, distance, bienTypes, budgetMin, budgetMax, chambresMin, surfaceHabitableMin, surfaceHabitableMax, surfaceTerrainMin, surfaceTerrainMax, dpeMin, modalState, toggleModal],
  );

  return <FilterContext.Provider value={value} {...props} />;
}
