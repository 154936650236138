import { useIntl } from 'react-intl';

import FlexRow from '../../Component/FlexRow/FlexRow';
import PageHeader from '../../Component/PageHeader/PageHeader';
import Title from '../../Component/Title/Title';

function PageNotFound(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader
        titleRotate={(
          <span>
            {formatMessage({ id: 'label.error' }, { br: () => <br /> })}
          </span>
        )}
      />
      <FlexRow grow="1" alignItems="center" justifyContent="center">
        <Title
          title={formatMessage({ id: 'error.status.404.title' })}
        />
        <p>{formatMessage({ id: 'error.status.404' })}</p>
      </FlexRow>
    </>
  );
}

export default PageNotFound;
