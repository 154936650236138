import * as z from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const geoGouvAdresseResultSchema = z.object({
  ville: z.string(),
  codePostal: z.string().optional(),
  adressePostale: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});
