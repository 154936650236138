import './button.scss';

import classnames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  id?: string;
  className?: string;
  icon?: ReactNode;
  to?: string;
  href?: string;
  /** @deprecated use `to` or `href` instead */
  url?: string;
  /** @deprecated use `icon` */
  src?: string;
  /** @deprecated use `icon` */
  i?: ReactNode;
  /** @deprecated use `icon` */
  alt?: string;
  type?: 'submit'|'button' |'file';
  action?: string;
  htmlFor?: string;
  accept?: string;
  capture?: 'user' | 'environment';
  name?: string;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
}

export default function Button({
  id,
  className,
  icon,
  to,
  href,
  type,
  action,
  htmlFor,
  accept,
  capture,
  name,
  onClick,
  children,
  ariaLabel,
  disabled,
}: ButtonProps) {
  const image = icon;

  const classes = classnames(className, { 'Button-disabled': disabled });

  if (to) {
    return (
      <Link
        id={id}
        to={to}
        className={classes}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {image}
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        id={id}
        href={href}
        className={classes}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {image}
        {children}
      </a>
    );
  }
  if (type === 'file') {
    return (
      <form className="button formFile yellow" action={action} method="post" encType="multipart/form-data">
        <label className="formFile" htmlFor={htmlFor}>
          {image}
          {children}
          <input
            id={htmlFor}
            className="testy"
            type={type}
            accept={accept || undefined}
            capture={capture || undefined}
            name={name || undefined}
          />
        </label>
      </form>

    );
  }

  return (
    <button
      className={classes}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {image}
      {children}
    </button>
  );
}
