import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { BienFull } from '../../lib/Biens/biens.types';

type ShowBienState = {
    bien: BienFull | null;
    isLoading: boolean;
    isError:boolean;
}

type ShowBienAction = {
    setBien(bien: BienFull | null): void;
    setLoading(isLoading: boolean): void;
    setError(isError: boolean): void;
    reset():Promise<void>;
}

export type ShowBienStore = ShowBienState & ShowBienAction;

const initialState: ShowBienState = {
  bien: null,
  isLoading: false,
  isError: false,
};

const useShowBienStore = create<ShowBienStore>()(
  persist((set) => ({
    ...initialState,
    setBien: (bien) => set(() => ({ bien })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setError: (isError) => set(() => ({ isError })),
    reset: async () => set(initialState),
  }), { name: 'show-bien-store' }),
);

export default useShowBienStore;
