import './MesBiensPage.scss';
import {
  useMemo, useState, useEffect,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import ButtonProjet from '../../Component/Button/ButtonProjet/button_projet_card';
import CreateProject from '../../Component/Button/CreateProject/CreateProject';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import IconHouseWithIcon from '../../Component/Icon/IconHouseWithIcon';
import { Variant } from '../../lib/Achats/Achats.types';
import { Bien } from '../../lib/Biens/biens.types';
import { listBien } from '../../lib/papirisClient';
import { makeRoutePath } from '../../store/route/route.api';
import useListBienStore from '../ShowBienLayout/useListBienStore';

export type BienCardProps = {
  bien: Bien;
};

export function BienCard({ bien }: BienCardProps) {
  const { formatMessage } = useIntl();

  return (
    <ButtonProjet
      className="button_projet_card size_card_project"
      title_card={bien?.type}
      subtitle_card={bien?.adressePostale}
      maison={(
        <IconHouseWithIcon
          className="size-card-Project position-card-Bien"
          classColor="colorVente"
        />
      )}
      url={makeRoutePath('bien.show', { bienId: bien?.id })}
    />
  );
}

export default function MesProjetsPage() {
  const [scrollValue, setScrollValue] = useState(0);
  const [lastScrollValue, setLastScrollValue] = useState(0);
  const { list, isLoading, isError } = useListBienStore();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const cards = document.getElementsByClassName('button_projet_card');
      Array.from(cards).forEach((card) => {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.intersectionRatio >= 0.75) {
                entry.target.classList.add('active');
              } else {
                entry.target.classList.remove('active');
              }
            });
          },
          { threshold: [0.75] },
        );

        intersectionObserver.observe(card);
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      const cards = document.getElementsByClassName('button_projet_card');
      Array.from(cards).forEach((card) => {
        card.classList.remove('active');
      });
    };
  }, []);

  useEffect(() => {
    if (scrollValue !== lastScrollValue) {
      setLastScrollValue(scrollValue);
    }
  }, [scrollValue, lastScrollValue]);

  const projets = useMemo(() => {
    const projetsMemo = [];

    if (list) {
      projetsMemo.push(
        ...list.items.map((bien) => ({
          id: bien?.id,
          createdAt: bien?.createdAt,
          bien,
        })),
      );
    }

    return projetsMemo;
  }, [list]);

  const loading = isLoading;

  function renderProjet(projet: typeof projets [number]) {
    try {
      if (!projet || !projet.id) {
        throw new Error('Projet invalide');
      }

      return <BienCard key={projet.id} bien={projet.bien} />;
    } catch (error) {
      console.error('Erreur lors du rendu du projet:', error);

      return 'Erreur lors du rendu';
    }
  }

  return loading ? (
    <DefaultLoader />
  ) : (
    <FlexRow
      className="container_Mes_Projet"
      direction="column"
      grow="1"
      gap="1rem"
      justifyContent="center"
    >
      <div className="titre">
        <h2>
          <FormattedMessage
            id="mes_bien_projets.title"
            values={{
              title: (children) => <p className="p_Second">{children}</p>,
              subtitle: (children) => <p className="p_First">{children}</p>,
            }}
          />
        </h2>
      </div>
      {(list?.items?.length ?? 0) > 0 ? (
        <FlexRow
          className="screenSize container_Buttons_Projets"
          direction="row"
          grow="0.5"
          alignItems="center"
          justifyContent="start"
          gap="2rem"
        >
          {projets.map(renderProjet)}
        </FlexRow>
      ) : (
        <FlexRow
          className="container_CreateProject"
          direction="column"
          grow="1"
          gap="2rem"
          justifyContent="center"
          alignItems="center"
        >
          <CreateProject />
        </FlexRow>
      )}
    </FlexRow>
  );
}
