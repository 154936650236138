import './option_Min_Max.scss';

export default function OPTION_MIN_MAX({
  icon, alt, labelTitle, labelSubtitle, onChangeMin, className, valueMin, valeur_Icon, onChangeMax, valueMax,
}) {
  return (
    <div className="options_container">
      <div className="info_option">
        <img src={icon} alt={alt} className="icon" />
        <div>{labelTitle}</div>
      </div>
      <div className="inputs_min_max">
        <h3 className="filter_title">{labelSubtitle}</h3>
        {onChangeMin
          ? (
            <div>
              <input
                className="filter_input"
                id={
                  `${
                    className}Min`
                }
                name={`${
                  className}Min`}
                type="number"
                placeholder="Minimum"
                value={valueMin}
                min="0"
                max="2000000000"
                onChange={onChangeMin}
              />
              <span>{valeur_Icon}</span>
            </div>
          ) : ''}
        {onChangeMax
          ? (
            <div>
              <input
                className="filter_input"
                id={
                  `${
                    className}Max`
                }
                name={`${
                  className}Max`}
                type="number"
                placeholder="Maximum"
                value={valueMax}
                min="0"
                max="2000000000"
                onChange={onChangeMax}
              />
              <span>{valeur_Icon}</span>
            </div>
          ) : ''}
      </div>
    </div>
  );
}
