import { useCallback } from 'react';

import useFavoriteAction from '../ActionCard/useFavoriteAction';
import useIgnoreeAction from '../ActionCard/useIgnoreeAction';

function useActionToProject(setActionFinish, setAchatRequiredOpen) {
  const actionOn = (projet) => {
    console.log('Action appliquée sur le projet:', projet);
  };

  const { onIgnoree } = useIgnoreeAction();
  const { onFavorite } = useFavoriteAction();

  const handleActionFinish = useCallback(() => {
    setActionFinish((previousShowModal) => !previousShowModal);
  }, [setActionFinish]);

  const handleAchatRequiredOpen = useCallback(() => {
    setAchatRequiredOpen((previousShowModal) => !previousShowModal);
  }, [setAchatRequiredOpen]);

  const handleFavoriteClick = useCallback((venteId, achatId) => {
    onFavorite(venteId, achatId);
  }, [onFavorite]);

  const handleIgnoreeClick = useCallback((venteId, achatId) => {
    onIgnoree(venteId, achatId);
  }, [onIgnoree]);

  const executeAction = (actionChoisie, venteId, choiceAchatID, listAchat) => {
    if (listAchat.length === 1 && listAchat[0].id === 'nouveau') {
      handleAchatRequiredOpen();
    } else if (listAchat.length === 1 && listAchat[0].id !== 'nouveau') {
      actionOn(listAchat);
      // actionOn(listAchat[0]);
      switch (actionChoisie) {
      case 'favori':
        handleFavoriteClick(venteId, listAchat[0].id);
        handleActionFinish();
        break;
      case 'ignoree':
        handleIgnoreeClick(venteId, listAchat[0].id);
        handleActionFinish();
        break;
      default:
        break;
      }
    } else if (listAchat.length >= 1 && choiceAchatID !== undefined) {
      actionOn(choiceAchatID);
      switch (actionChoisie) {
      case 'favori':
        handleFavoriteClick(venteId, choiceAchatID);
        handleActionFinish();
        break;
      case 'ignoree':
        handleIgnoreeClick(venteId, choiceAchatID);
        handleActionFinish();
        break;
      default:
        break;
      }
    } else if (listAchat.length >= 1 && Boolean(choiceAchatID) === false && listAchat[0].id !== 'Nouveau') {
      handleAchatRequiredOpen();
    }
  };

  return { executeAction };
}

export default useActionToProject;
