import './FlexCell.scss';

import classnames from 'classnames';
import { CSSProperties } from 'react';

import stylenames from '../../lib/stylenames';
import { ComponentProps } from '../../types/ComponentProps';

export type FlexCellProps = ComponentProps<'div', {
  alignSelf?: CSSProperties['alignSelf'];
  grow?: CSSProperties['flexGrow'];
  justifyContent?: CSSProperties['justifyContent'];
  size?: 'xs'|'sm'|'md'|'lg'|'xl';
}>

export default function FlexCell({
  className,
  style,
  children,
  alignSelf,
  justifyContent,
  grow,
  size,
  ...props
}: FlexCellProps) {
  const classes = classnames('FlexCell', className, {
    [`FlexCell-${size}`]: Boolean(size),
  });

  const styles = stylenames(style, {
    '--FlexCell-alignSelf': alignSelf,
    '--FlexRow-justifyContent': justifyContent,
    '--FlexCell-flexGrow': grow,
  });

  return <div {...props} className={classes} style={styles}>{children}</div>;
}
