import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import Button from '../../../Component/Button/Button';
import Container from '../../../Component/Container/Container';
import FlexRow from '../../../Component/FlexRow/FlexRow';
import FormFooter from '../../../Component/FormFooter/FormFooter';
import MesPartenaires from '../../../Component/Mes_Partenaires/mes_partenaires';
import Title from '../../../Component/Title/Title';
import useQueryParams from '../../../hooks/useQueryParams';
import { Variant } from '../../../lib/Achats/Achats.types';
import { useUser } from '../../../provider/UserProvider';
import { makeRoutePath } from '../../../store/route/route.api';

type CreateVenteContactPage= {
  withInvitation: boolean;
};

function CreateVenteContactPage(): JSX.Element {
  const { formatMessage } = useIntl();
  const { user } = useUser();

  const queryParams = useQueryParams();
  const variant = queryParams.get('variant') || undefined;

  return (
    <FlexRow
      className="container_Mes_Projet _Partenaire"
      direction="column"
      grow="0.5"
      gap="2rem"
      justifyContent="center"
    >

      <Container size="lg">
        <Title
          title={formatMessage({ id: 'achats-ventes.create.contact.title' })}
          position="start"
          size={3}
          subtitle={formatMessage({ id: 'label.step_on_total' }, { step: 1, total: 4 })}
        />
      </Container>
      <MesPartenaires />
      <Container size="lg">
        <FormFooter>
          <FlexRow alignItems="flex-end">
            <Button
              className="button yellow"
              disabled={!user?.contact}
              to={makeRoutePath('vente.create.bien', { variant })}
            >
              {formatMessage({ id: 'label.continue' })}
            </Button>
          </FlexRow>
        </FormFooter>
      </Container>
    </FlexRow>
  );
}

export default CreateVenteContactPage;
