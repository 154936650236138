import { CSSProperties } from 'react';

export default function stylenames(style: CSSProperties|undefined, moreStyles: Record<string, string|number|undefined>): CSSProperties {
  const moreStylesCopy = { ...moreStyles };

  Object.keys(moreStylesCopy).forEach((key) => (moreStylesCopy[key] === undefined ? delete moreStylesCopy[key] : {}));

  return {
    ...style,
    ...moreStylesCopy,
  };
}
