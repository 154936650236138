import classnames from 'classnames';
import {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Button from '../Component/Button/Button';
import { NotificationModel } from '../lib/Notification/Notification.types';
import { readNotification } from '../lib/papirisClient';

import './NotificationItem.scss';

export type NotificationItemProps = {
  data: NotificationModel;
  open?: boolean;
  className:string;
  title: ReactNode;
  body: string;
  src2?: ReactNode;
  url: string;
}

export default function NotificationItem({
  data,
  open = true,
  className,
  title,
  body,
  src2,
  url,
}: NotificationItemProps) {
  const [show, setShow] = useState(open);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutationReadNotification = useMutation(readNotification);

  const onClose = useCallback(async () => {
    setShow(false);
    await mutationReadNotification.mutateAsync(data?.id);
    await queryClient.invalidateQueries(['listNotif']);
  }, [data?.id, mutationReadNotification, queryClient]);

  const onSubmit = useCallback(async () => {
    onClose();
    navigate(url);
  }, [navigate, onClose, url]);

  useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <div className={classnames('container_Modal_Notification', className, { open: show, close: !show })}>
      <Button
        className="modal-content-Notification"
        onClick={onSubmit}
      >
        {typeof src2 === 'string' ? <img src={src2} alt={formatMessage({ id: 'label.open' })} /> : src2}
        <div className="text-content-button-Notification">
          {title ? <p className="title">{title}</p> : ''}
          {body ? <p className="underTitle">{body}</p> : ''}
        </div>
      </Button>
      <Button
        className="closed-button-Notification"
        ariaLabel={formatMessage({ id: 'label.close' })}
        onClick={onClose}
      >
        <i className="fa-solid fa-xmark" />
      </Button>
    </div>
  );
}
