import './FormAddonWrapper.scss';

export type FormAddonWrapperProps = {
    children: React.ReactNode;
}

function FormAddonWrapper({ children }: FormAddonWrapperProps): JSX.Element {
  return <div className="FormAddonWrapper">{children}</div>;
}

export default FormAddonWrapper;
