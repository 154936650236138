import './AllInfoData.scss';
import { CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import { useMatch, useParams } from 'react-router-dom';

import { VisibleStatuses } from '../../lib/Achats/Achats.types';
import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button';
import FlexRow from '../FlexRow/FlexRow';
import IconContreVisiteRound from '../Icon/IconContreVisiteRound';
import IconFavorisRound from '../Icon/IconFavorisRound';
import IconIgnoreRound from '../Icon/IconIgnoreRound';
import IconInfoRound from '../Icon/IconInfoRound';
import IconLocality from '../Icon/IconLocalityRound';
import IconPartageRound from '../Icon/IconPartageRound';

function hasVisibleStatus(data) {
  return typeof data === 'object' && Object.values(VisibleStatuses).includes(data?.statut);
}

export default function AllInfoData({
  isLoading, isError, visitesData, favorisData, demandesData, ignoresData, contreVisitesData, partagesData,
}) {
  const { formatMessage } = useIntl();
  const { achatId, venteId } = useParams();

  const urlVisites = useMatch(makeRoutePath('achat.projet.visites', { achatId }));
  const urlFavoris = useMatch(makeRoutePath('achat.projet.favoris', { achatId }));
  const urlDemandes = useMatch(makeRoutePath('achat.projet.demandes', { achatId }));
  const urlIgnores = useMatch(makeRoutePath('achat.projet.ignores', { achatId }));

  const url = achatId ? 'achatId' : venteId ? 'venteId' : null;

  let countVisites = 0;
  let countFavoris = 0;
  let countDemandes = 0;
  let countIgnores = 0;
  let countContreVisites = 0;
  let countPartages = 0;

  switch (url) {
  case 'achatId':
    countVisites = Array.isArray(visitesData) ? visitesData?.filter(hasVisibleStatus)?.length : countVisites;
    countFavoris = Array.isArray(favorisData) ? favorisData?.filter(hasVisibleStatus).length : countFavoris;
    countDemandes = Array.isArray(demandesData) ? demandesData?.length : countDemandes; // ne possède pas de status
    countIgnores = Array.isArray(ignoresData) ? ignoresData?.filter(hasVisibleStatus).length : countIgnores;
    break;
  case 'venteId':
    countVisites = visitesData;
    countFavoris = favorisData;
    countDemandes = demandesData;
    countIgnores = ignoresData;
    countContreVisites = contreVisitesData;
    countPartages = partagesData;
    break;
  default:
  }

  switch (url) {
  case 'achatId':
    return (
      <div className="container_Icon_Suivi">
        <FlexRow gap="1em" direction="row" alignItems="center" justifyContent="center">
          {
            isError ? (
              <div>Error</div>
            ) : isLoading ? (
              <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />
            ) : ((urlVisites || urlFavoris || urlDemandes || urlIgnores) ? (
              <>
                <Button to={makeRoutePath('achat.projet.visites', { achatId })}>
                  <div className="icon_bien">
                    <IconLocality className="img-w3xl img-h3xl" />
                    <p className={urlVisites ? 'green' : ''}>
                      <strong>{countVisites}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.visites' }, { count: countVisites })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to={makeRoutePath('achat.projet.favoris', { achatId })}>
                  <div className="icon_bien">
                    <IconFavorisRound className="img-w3xl img-h3xl" />
                    <p className={urlFavoris ? 'green' : ''}>
                      <strong>{countFavoris}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.favoris' }, { count: countFavoris })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to={makeRoutePath('achat.projet.demandes', { achatId })}>
                  <div className="icon_bien">
                    <IconInfoRound className="img-w3xl img-h3xl" />
                    <p className={urlDemandes ? 'green' : ''}>
                      <strong>{countDemandes}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.demandes' }, { count: countDemandes })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to={makeRoutePath('achat.projet.ignores', { achatId })}>
                  <div className="icon_bien">
                    <IconIgnoreRound className="img-w3xl img-h3xl" />
                    <p className={urlIgnores ? 'green' : ''}>
                      <strong>{countIgnores}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.ignores' }, { count: countIgnores })}
                      </span>
                    </p>
                  </div>
                </Button>
              </>
            )
              : (
                <>
                  <Button to={makeRoutePath('achat.projet.visites', { achatId })}>
                    <div className="icon_bien">
                      <IconLocality className="img-w3xl img-h3xl" />
                      <p className="green">
                        <strong>{countVisites}</strong>
                        <span className="text-Data">
                          {formatMessage({ id: 'info.data.visites' }, { count: countVisites })}
                        </span>
                      </p>
                    </div>
                  </Button>
                  <Button to={makeRoutePath('achat.projet.favoris', { achatId })}>
                    <div className="icon_bien">
                      <IconFavorisRound className="img-w3xl img-h3xl" />
                      <p className="green">
                        <strong>{countFavoris}</strong>
                        <span className="text-Data">
                          {formatMessage({ id: 'info.data.favoris' }, { count: countFavoris })}
                        </span>
                      </p>
                    </div>
                  </Button>
                  <Button to={makeRoutePath('achat.projet.demandes', { achatId })}>
                    <div className="icon_bien">
                      <IconInfoRound className="img-w3xl img-h3xl" />
                      <p className="green">
                        <strong>{countDemandes}</strong>
                        <span className="text-Data">
                          {formatMessage({ id: 'info.data.demandes' }, { count: countDemandes })}
                        </span>
                      </p>
                    </div>
                  </Button>
                  <Button to={makeRoutePath('achat.projet.ignores', { achatId })}>
                    <div className="icon_bien">
                      <IconIgnoreRound className="img-w3xl img-h3xl" />
                      <p className="green">
                        <strong>{countIgnores}</strong>
                        <span className="text-Data">
                          {formatMessage({ id: 'info.data.ignores' }, { count: countIgnores })}
                        </span>
                      </p>
                    </div>
                  </Button>
                </>
              )
            )
          }
        </FlexRow>
      </div>
    );
  case 'venteId':

    return (
      <div className="container_Icon_Suivi">
        <FlexRow direction="row" alignItems="start" justifyContent="center">
          {isLoading
            ? (
              <CircularProgress aria-label={formatMessage({ id: 'label.loading' })} />

            )
            : (
              <>
                <Button to="#">
                  <div className="icon_bien">
                    <IconLocality className="img-w3xl img-h3xl" />
                    <p className="green">
                      <strong>{countVisites}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.visites' }, { count: countVisites })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to="#">
                  <div className="icon_bien">
                    <IconContreVisiteRound className="img-w3xl img-h3xl" />
                    <p className="green">
                      <strong>{countContreVisites}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.contre visites' }, { count: countContreVisites })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to="#">
                  <div className="icon_bien">
                    <IconInfoRound className="img-w3xl img-h3xl" />
                    <p className="green">
                      <strong>{countDemandes}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.demandes' }, { count: countDemandes })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to="#">
                  <div className="icon_bien">
                    <IconFavorisRound className="img-w3xl img-h3xl" />
                    <p className="green">
                      <strong>{countFavoris}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.favoris' }, { count: countFavoris })}
                      </span>
                    </p>
                  </div>
                </Button>
                <Button to="#">
                  <div className="icon_bien">
                    <IconPartageRound className="img-w3xl img-h3xl" />
                    <p className="green">
                      <strong>{countPartages}</strong>
                      <span className="text-Data">
                        {formatMessage({ id: 'info.data.partages' }, { count: countPartages })}
                      </span>
                    </p>
                  </div>
                </Button>
              </>
            )}
        </FlexRow>
      </div>
    );
  default:
    break;
  }
}
