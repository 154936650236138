import './detail_immo.scss';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import CONSO from '../../Component/CONSO/conso.jsx';
import COLLAPSE from '../../Component/Collapse/collapse.jsx';
import Container from '../../Component/Container/Container';
import DPE from '../../Component/DPE/dpe.jsx';
import DefaultLoader from '../../Component/DefaultLoader/DefaultLoader';
import FlexRow from '../../Component/FlexRow/FlexRow';
import HeaderBack from '../../Component/Header_Retour/header_retour';
import CARD_DETAIL_IMMO from '../../Component/Selecteur/Card_Detail_Immo/card_detail_immo.jsx';
import Slider2buttons from '../../Component/Slider_2buttons/slider_2buttons.jsx';
import VenteConseillerMenu from '../../Component/VenteConseillerMenu/VenteConseillerMenu';
import formatNumberWithLocale from '../../Function/Number';
import { toCamelCase } from '../../Function/String';
import ICON_DPE from '../../img/icon_dpe_jaune.svg';
import ICON_NB_CHAMBRE from '../../img/icon_nb_piece.svg';
import ICON_SUPERFICIE from '../../img/icon_surface_jaune.svg';
import ICON_METRE_TERRAIN from '../../img/icon_terrain_jaune.svg';
import { Variant } from '../../lib/Achats/Achats.types';
import { listAchat } from '../../lib/papirisClient';
import { usePermission } from '../../provider/PermissionProvider';
import useShowVente from '../ShowVenteLayout/useShowVente';

export default function DETAIL_IMMO() {
  const { formatMessage, formatNumber, formatDate } = useIntl();
  const { venteId } = useParams();
  const {
    data: vente,
    isLoading,
    isError,
  } = useShowVente(venteId);
  const { hasPermission } = usePermission();
  const isAuthenticated = hasPermission('authenticated');
  const listAchatQuery = useQuery({
    queryKey: ['achat', 'list', 1],
    queryFn: () => listAchat({ page: 1 }),
  });
  const projetsAchat = listAchatQuery?.data?.items;
  const inLocation = vente?.variant === Variant.location;
  if (isLoading) {
    return <DefaultLoader />;
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="error.status.500" />
      </div>
    );
  }

  if (!vente) {
    return null;
  }

  let listProjetsAchats = [];
  if (isAuthenticated && projetsAchat?.length >= 1) {
    projetsAchat.forEach((projet) => {
      listProjetsAchats.push({
        key: projet?.titre || formatMessage({ id: 'achat.title_with_date' }, { date: formatDate(projet?.createdAt, { dateStyle: 'short' }) }),
        id: projet?.id,
        achat: projet?.titre || formatMessage({ id: 'achat.title_with_date' }, { date: formatDate(projet?.createdAt, { dateStyle: 'short' }) }),
      });
    });
  } else {
    listProjetsAchats = [{ id: 'nouveau', achat: 'Projet à créer' }];
  }

  const {
    bien, diffusion, collaborateur, modalitesCharges, garantie, charges, loyer, honorairesLocataireCommercialisation, honorairesLocataireEtatDesLieux,
  } = vente;
  const {
    sections, diagnostiqueEnergetique, classeGes, montantMinimumCoutsDpe, montantMaximumCoutsDpe, gazEffetSerre,
  } = bien;

  function filtringSections(sec) {
    if (Array.isArray(sec) && sec.length > 0) {
      // Aplatit les sections et les champs de chaque section
      const flattenedSections = sec.reduce((obj, item) => ({
        ...obj,
        [toCamelCase(item.title)]: item.fields.reduce((fieldsObj, field) => ({
          ...fieldsObj,
          [toCamelCase(field.title)]: field.value,
        }), {}),
      }), {});

      return flattenedSections;
    }

    return {};
  }

  const section = filtringSections(sections);

  const {
    anneeConstruction, charpente, couverture, definition, style, surfaceTerrain, taxeFonciere, ventilation,
    honorairesAgence, ...information
  } = section.informations;

  const { type: typeAssainissement, ...assainissement } = section.assainissement;
  const { type: typeChauffage, energie, ...chauffage } = section.chauffage;
  const { ...eauChaudeSanitaireEcs } = section.eauChaudeSanitaireEcs;
  const { vitragesFenetres, ...fenetres } = section.fenetres;

  /* It's a ternary operator for "icon_superficie". */
  const { surface, anneeReferenceMontantCoutsDpe, classeEnergetique } = bien;

  const info_superficie = Boolean(surface) === true ? (
    <>
      <img src={ICON_SUPERFICIE} alt="icon superficie" />
      <div className="container_Litle_Info">
        <small>Superficie:</small>
        <small>
          <FormattedNumber value={(surface / 100)} />
          {formatMessage({ id: 'info.bien.squareMetre' })}
        </small>
      </div>
    </>
  ) : (
    ''
  );

  /* It's a ternary operator for "icon_dpe". */
  const info_dpe = Boolean(classeEnergetique) === true ? (
    <div>
      <img src={ICON_DPE} alt="icon dpe" />
      <div className="container_Litle_Info">
        <small>DPE:</small>
        <small>{classeEnergetique}</small>
      </div>
    </div>
  ) : (
    ''
  );

  /* It's a ternary operator for "icon_chambre". */
  const { chambresCount } = bien;
  const info_chambre = Boolean(chambresCount) === true ? (
    <>
      <img src={ICON_NB_CHAMBRE} alt="icon chambre" />
      <div className="container_Litle_Info">
        <small>Chambre:</small>
        <small>
          <FormattedMessage id="detail_immo.chambres" values={{ chambresCount }} />
        </small>
      </div>
    </>
  ) : (
    ''
  );

  /* It's a ternary operator for "icon_terrain". */
  const info_terrain = Boolean(surfaceTerrain) === true ? (
    <>
      <img src={ICON_METRE_TERRAIN} alt="icon terrain" />
      <div className="container_Litle_Info">
        <small>Terrain:</small>
        <small>
          <FormattedNumber value={(surfaceTerrain / 100)} />
          {formatMessage({ id: 'info.bien.squareMetre' })}
        </small>
      </div>
    </>
  ) : (
    ''
  );

  function collapseData() {
    const rawData = [
      {
        id: 1,
        title: 'Type de bien',
        key: definition,
        content: definition
          ? `${definition
          }` : null,
      },
      {
        id: 2,
        title: 'Fenêtre',
        key: vitragesFenetres ? `${vitragesFenetres}` : null,
        content: vitragesFenetres ? `${vitragesFenetres}` : null,
      },
      {
        id: 3,
        title: 'Ventilation',
        key: ventilation ? `${ventilation}` : null,
        content: ventilation ? `${ventilation}` : null,
      },
      {
        id: 4,
        title: 'Couverture',
        key: couverture ? `${couverture}` : null,
        content: couverture ? `${couverture}` : null,
      },
      {
        id: 5,
        title: 'Charpente',
        key: charpente ? `${charpente}` : null,
        content: charpente ? `${charpente}` : null,
      },
      {
        id: 6,
        title: 'Chauffage',
        key: typeChauffage ? `${typeChauffage}` : null,
        content: typeChauffage ? `${typeChauffage}` : null,
      },
      {
        id: 7,
        title: 'Style',
        key: style ? `${style}` : null,
        content: style ? `${style}` : null,
      },
      {
        id: 8,
        title: 'Construction',
        key: anneeConstruction ? `${anneeConstruction}` : null,
        content: anneeConstruction ? `${anneeConstruction}` : null,
      },
      {
        id: 9,
        title: 'Assainissement',
        key: typeAssainissement ? `${typeAssainissement}` : null,
        content: typeAssainissement ? `${typeAssainissement}` : null,
      },
      {
        id: 10,
        title: 'Taxe foncière',
        key: taxeFonciere ? `${formatNumberWithLocale(Number(taxeFonciere))} €` : null,
        content: taxeFonciere ? `${formatNumberWithLocale(Number(taxeFonciere))} €` : null,
      },
    ];
    const filtredData = rawData.filter((item) => item.content !== null && (Array.isArray(item.content) ? item.content.length > 0 : Object.keys(item.content).length > 0))
      .map((item, index) => ({ ...item, id: index + 1 }));

    return filtredData;
  }

  const HLCAndHLEDL = <FormattedNumber value={(honorairesLocataireCommercialisation + honorairesLocataireEtatDesLieux) / 100} style="currency" currency="EUR" minimumFractionDigits="0" maximumFractionDigits="2" />;

  const HLEDL = (
    <FormattedNumber value={(honorairesLocataireEtatDesLieux / 100)} style="currency" currency="EUR" minimumFractionDigits="0" maximumFractionDigits="2" />
  );

  function collapseDataLocation() {
    const rawDataLocation = [
      {
        id: 1,
        title: 'Honoraires agence',
        key: honorairesLocataireCommercialisation && honorairesLocataireEtatDesLieux ? { HLCAndHLEDL } : null,
        content: honorairesLocataireCommercialisation && honorairesLocataireEtatDesLieux ? (
          <>
            {HLCAndHLEDL}
            {' '}
            (dont
            {' '}
            {HLEDL}
            {' '}
            pour Etat des lieux)
          </>
        )
          : null,
      },
      {
        id: 2,
        title: 'Loyer mensuel',
        key: loyer ? `${loyer / 100}` : null,
        content: loyer
          ? (
            <FormattedNumber
              value={(loyer / 100)}
              style="currency"
              currency="EUR"
              minimumFractionDigits="0"
              maximumFractionDigits={vente?.variant === Variant.location ? '2' : '0'}
            />
          ) : null,
      },
      {
        id: 3,
        title: 'Montant des charges',
        key: charges ? `${charges / 100}` : null,
        content: charges ? (
          <FormattedNumber
            value={(charges / 100)}
            style="currency"
            currency="EUR"
            minimumFractionDigits="0"
            maximumFractionDigits={vente?.variant === Variant.location ? '2' : '0'}
          />
        ) : null,
      },
      {
        id: 4,
        title: 'Dépôt de garantie',
        key: garantie ? `${garantie / 100}` : null,
        content: garantie ? (
          <FormattedNumber
            value={(garantie / 100)}
            style="currency"
            currency="EUR"
            minimumFractionDigits="0"
            maximumFractionDigits={vente?.variant === Variant.location ? '2' : '0'}
          />
        ) : null,
      },
      {
        id: 5,
        title: 'Modalités récupération charges',
        key: modalitesCharges ? `${modalitesCharges}` : null,
        content: modalitesCharges ? `${modalitesCharges}` : null,
      },
    ];
    const filtredDataLocation = rawDataLocation.filter((item) => item.content !== null && (Array.isArray(item.content) ? item.content.length > 0 : Object.keys(item.content).length > 0))
      .map((item, index) => ({ ...item, id: index + 1 }));

    return filtredDataLocation;
  }

  return (
    <>
      <HeaderBack />
      <Container>
        <FlexRow gap="1em" alignItems="center" justifyContent="center">
          <CARD_DETAIL_IMMO
            key={vente?.id}
            {...vente}
            listProjetsAchats={listProjetsAchats}
            location={inLocation}
          />
          <section className="description_Detail_Immo info_Card">
            {diffusion && diffusion.description !== null ? (
              <article>
                {diffusion.description}
              </article>
            ) : ''}
            {!inLocation && diffusion && diffusion.copropriete !== null ? (
              <article>
                {diffusion.copropriete}
              </article>
            ) : ''}

            <div className="container_Icons_Bien">
              <div className="container_Icons_Bien">

                {info_superficie
                  ? (
                    <span className="icon_bien">
                      {info_superficie}
                    </span>
                  ) : ''}
                {info_chambre
                  ? (
                    <span className="icon_bien">
                      {info_chambre}
                    </span>
                  ) : ''}
                {info_dpe
                  ? (
                    <span className="icon_bien">
                      {info_dpe}
                    </span>
                  ) : ''}
                {info_terrain
                  ? (
                    <span className="icon_bien">
                      {info_terrain}
                    </span>
                  ) : ''}
              </div>
            </div>
          </section>
          <section className="description_Detail_Immo">
            <COLLAPSE
              key="2"
              title="Description"
              contents={collapseData()}
            />
          </section>
          <section className="description_Detail_Immo">
            <COLLAPSE key="3" title="Performance énergétique ">
              <>
                <label className="title_Dpe_Conso">
                  Consommation énergétique
                </label>
                <DPE gesEpcValue={gazEffetSerre !== null ? formatNumberWithLocale(Number(gazEffetSerre)) : 'NC'} consEpcValue={diagnostiqueEnergetique !== null ? formatNumberWithLocale(Number(diagnostiqueEnergetique)) : 'NC'} />
                <label className="title_Dpe_Conso">Emission de gaz</label>
                <CONSO gesEpcValue={gazEffetSerre !== null ? formatNumberWithLocale(Number(gazEffetSerre)) : 'NC'} />
                <label className="title_Dpe_Conso">
                  Estimation des coûts annuels d‘énergies du logement
                </label>
                <Slider2buttons
                  valueMin={0}
                  coutMin={montantMinimumCoutsDpe !== null ? formatNumber((montantMinimumCoutsDpe / 100), { style: 'currency', currency: 'EUR', maximumFractionDigits: '0' }) : 'NC'}
                  valueMax={100}
                  coutMax={montantMaximumCoutsDpe !== null ? formatNumber((montantMaximumCoutsDpe / 100), { style: 'currency', currency: 'EUR', maximumFractionDigits: '0' }) : 'NC'}
                  value_littleInfo={anneeReferenceMontantCoutsDpe !== null ? anneeReferenceMontantCoutsDpe : 'NC'}
                />
              </>
            </COLLAPSE>
          </section>
          {inLocation && collapseDataLocation().length >= 1
            ? (
              <section className="description_Detail_Immo">
                <COLLAPSE
                  key="4"
                  title="Détails"
                  contents={collapseDataLocation()}
                />
              </section>
            ) : ''}
          <VenteConseillerMenu />
        </FlexRow>

      </Container>
    </>

  );
}
