import classNames from 'classnames';

import { IconProps } from './Icon.types';
import './IconGallery.scss';

function IconGallery({
  className, classColor = 'gallery',
}: IconProps) {
  return (
    <div className={`containerImage ${className}`}>
      <svg className={classNames('icon_gallery', className)} id="Bouton_gallery" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.62 23.187">
        <title id="icon_gallery">Icon Gallery</title>
        <path id="Icon_material-photo-library-2" data-name="Icon material-photo-library" className={`${classColor}-cls-1`} d="M26.62,19.231V5.319A2.348,2.348,0,0,0,24.258,3H10.086A2.348,2.348,0,0,0,7.724,5.319V19.231a2.348,2.348,0,0,0,2.362,2.319H24.258A2.348,2.348,0,0,0,26.62,19.231ZM13.629,14.593l2.4,3.142,3.508-4.3,4.724,5.8H10.086ZM3,7.637V23.868a2.348,2.348,0,0,0,2.362,2.319H21.9V23.868H5.362V7.637Z" transform="translate(-3 -3)" />
      </svg>
    </div>
  );
}

export default IconGallery;
