import {
  ReactNode, createContext, useCallback, useContext, useMemo, useState, JSX,
} from 'react';

export type Permission = 'ready' | 'authenticated' | 'anonymous';

export type PermissionValue = {
    permissions: Permission[];
    addPermission(permission: Permission): void;
    removePermission(permission: Permission): void;
    togglePermission(permission: Permission, toggle: boolean): void;
    hasPermission(permission: Permission): boolean;
}

export type PermissionProviderProps = {
    children: ReactNode;
}

const defaultValue: PermissionValue = {
  permissions: [],
  addPermission: () => {},
  removePermission: () => {},
  togglePermission: () => {},
  hasPermission: () => false,
};

export const PermissionContext = createContext<PermissionValue>(defaultValue);

export function usePermission() {
  return useContext(PermissionContext);
}

export default function PermissionProvider(props: PermissionProviderProps): JSX.Element {
  const [permissions, setPermissions] = useState<PermissionValue['permissions']>([]);

  const addPermission = useCallback<PermissionValue['addPermission']>((permission) => {
    setPermissions((previousPermissions) => {
      if (previousPermissions.includes(permission)) {
        return previousPermissions;
      }

      return [...previousPermissions, permission];
    });
  }, []);

  const removePermission = useCallback<PermissionValue['removePermission']>((permission) => {
    setPermissions((previousPermissions) => {
      if (previousPermissions.includes(permission)) {
        return previousPermissions.filter((p) => p !== permission);
      }

      return previousPermissions;
    });
  }, []);

  const togglePermission = useCallback<PermissionValue['togglePermission']>((permission, toggle) => {
    if (toggle) {
      addPermission(permission);
    } else {
      removePermission(permission);
    }
  }, [addPermission, removePermission]);

  const hasPermission = useCallback<PermissionValue['hasPermission']>((permission) => permissions.includes(permission), [permissions]);

  const value = useMemo<PermissionValue>(() => ({
    permissions,
    addPermission,
    removePermission,
    togglePermission,
    hasPermission,
  }), [permissions, addPermission, removePermission, togglePermission, hasPermission]);

  return <PermissionContext.Provider value={value} {...props} />;
}
