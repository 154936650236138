import {
  type MessagePayload, type NextFn, getMessaging, getToken, onMessage, type Unsubscribe,
} from 'firebase/messaging';

import { app } from '../lib/firebaseClient';

const messaging = getMessaging(app);

export async function getDeviceToken(): Promise<string|null> {
  const sw = await navigator.serviceWorker.getRegistration();

  if (sw) {
    try {
      return await getToken(messaging, {
        serviceWorkerRegistration: sw,
      });
    } catch (e) {
      console.error(e);
    }
  } else {
    console.warn('Service worker was not found.');
  }

  return null;
}

export function subscribeMessage(observer: NextFn<MessagePayload>): Unsubscribe {
  return onMessage(messaging, observer);
}
