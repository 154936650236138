import './VenteConseillerMenu.scss';

import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHref, useNavigate } from 'react-router-dom';

import useShowAchatStore from '../../Pages/ShowAchatLayout/useShowAchatStore';
import useShowVenteStore from '../../Pages/ShowVenteLayout/useShowVenteStore';
import ICON_PLUS from '../../img/icon_Plus_BC_Jaune.svg';
import ICON_AGENDA from '../../img/icon_calendar.svg';
import ICON_PROFIL from '../../img/icone_Account_Ideeri.svg';
import { useUser } from '../../provider/UserProvider';
import { makeRoutePath } from '../../store/route/route.api';
import Button from '../Button/Button';
import Container from '../Container/Container';
import FlexRow from '../FlexRow/FlexRow';
import IconAnonyme from '../Icon/IconAnnonyme';
import AchatLocSelectionModal, { AchatLocSelectionModalProps } from '../Modal/AchatLocSelectionModal';
import AuthRequiredModal from '../Modal/AuthRequiredModal';

export default function VenteConseillerMenu() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { achat } = useShowAchatStore();
  const { vente } = useShowVenteStore();
  const { user } = useUser();
  const [authRequiredOpen, setAuthRequiredOpen] = useState(false);
  const [achatSelectionOpen, setAchatSelectionOpen] = useState(false);
  const onAchatSelectionSubmitRef = useRef<AchatLocSelectionModalProps['onSubmit']|undefined>(undefined);

  const handleAchatRequiredCheck = useCallback((onAchatSelectionSubmit: AchatLocSelectionModalProps['onSubmit']) => {
    if (!user) {
      setAuthRequiredOpen(true);
    } else if (!achat) {
      onAchatSelectionSubmitRef.current = onAchatSelectionSubmit;
      setAchatSelectionOpen(true);
    }
  }, [user, achat]);

  const handleAchatRequiredClose = useCallback<AchatLocSelectionModalProps['onClose']>(() => {
    setAchatSelectionOpen(false);
    onAchatSelectionSubmitRef.current = undefined;
  }, []);

  const handleAchatRequiredSubmit = useCallback<AchatLocSelectionModalProps['onSubmit']>((achat) => {
    setAchatSelectionOpen(false);

    onAchatSelectionSubmitRef.current?.(achat);
    onAchatSelectionSubmitRef.current = undefined;
  }, []);

  if (!vente?.collaborateur) {
    return null;
  }

  return (
    <div className="carte_Visite_Conseiller">
      <FlexRow
        className="container_Conseiller-detail-bien"
        width="initial"
        gap="0.5rem"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <h3 className="fs-lg fw-Bold">Le conseiller</h3>
        <img src={vente.collaborateur.avatar} alt="conseiller" />
        <h4 className="prenom fs-xl fw-Light">{vente.collaborateur.prenom}</h4>
        <h4 className="nom fs-xl fw-Bold">{vente.collaborateur.nom}</h4>
      </FlexRow>
      <div className="buttons_Container">
        <Button
          to={`/conseiller/${vente.collaborateur.id}`}
          className="button standard conseiller"
          icon={<IconAnonyme className="img-w4xl img-h4xl" />}
        >
          <p>
            Voir le profil
          </p>
        </Button>
        <Button
          to={achat ? makeRoutePath('achat.vente.demande_renseignements', { achatId: achat.id, venteId: vente.id }) : undefined}
          onClick={() => handleAchatRequiredCheck((achat) => navigate(makeRoutePath('achat.vente.demande_renseignements', { achatId: achat.id, venteId: vente.id })))}
          className="button standard conseiller"
          icon={<img src={ICON_PLUS} alt="icon en savoir plus" />}
        >
          <p>
            Demander des renseignements
          </p>
        </Button>
        <Button
          to={makeRoutePath('reservation.visite')}
          onClick={() => handleAchatRequiredCheck(() => navigate('/reservation-visite'))}
          className="button standard conseiller"
          icon={<img src={ICON_AGENDA} alt="icon en savoir plus" />}
        >
          <p>
            Réserver une visite
          </p>
        </Button>
        <Container>
          <FlexRow
            direction="row"
            gap="4"
            grow="1"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Button
              className="button small-light -link-neutre"
              href="mailto:support@ideeri.fr"
            >
              <small>{formatMessage({ id: 'detail_immo.signalement.bien' })}</small>
            </Button>
          </FlexRow>
        </Container>
        <AchatLocSelectionModal open={achatSelectionOpen} onClose={handleAchatRequiredClose} onSubmit={handleAchatRequiredSubmit} />
        <AuthRequiredModal open={authRequiredOpen} onClose={() => setAuthRequiredOpen(false)} />
      </div>
    </div>
  );
}
