export const URL_LOGIN = '/connexion';
export const URL_CREATED_ACCOUNT = '/inscription';
export const URL_PROJET_LIST = '/projets';
export const URL_CREATE_PROJET = '/projets/nouveau';
export const URL_CREATE_ACHAT = '/achats/nouveau';
export const URL_CREATE_LOCATION = '/locations/nouvelle';
export const URL_MES_DOCUMENTS_EDIT = '/profil/documents/mes_documents_edit';
export const URL_MON_RDV = '/mon_rendez-vous';
export const URL_MES_RDV = '/mes_rendez-vous';
export const URL_PROJET_ACHAT = '/created-project/projet-achat/accueil';
export const URL_PROJET_ACHAT_ID = '/created-project/projet-achat/id';
export const URL_PROJET_ACHAT_ID_COLLABORATEUR_ID = '/created-project/projet-achat/id/collaborateur/id';
export const URL_DECOUVERTE_1 = '/created-project/projet-achat/decouverte-1';
export const URL_DECOUVERTE_2 = '/created-project/projet-achat/decouverte-2';
export const URL_DECOUVERTE_3 = '/created-project/projet-achat/decouverte-3';
export const URL_DECOUVERTE_4 = '/created-project/projet-achat/decouverte-4';
export const URL_PROJET_VENTE = '/created-project/projet-vente/accueil';
export const URL_BIEN_A_ESTIMER = '/created-project/projet-vente/bien-a-estimer';
export const URL_PROJET_VENTE_ID = '/created-project/projet-vente/:id';
export const URL_SEARCH = '/search';
export const URL_DETAIL_IMMO_ID = '/search/vente';
