import './filtre.scss';

import { useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import Button from '../../Component/Button/Button';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import HEADER_FILTER from '../../Component/Header_Filter/header_filter.jsx';
import OPTION_CHECKBOX_VALUE_CIRCLE from '../../Component/Selecteur/Option/Checkbox_Value_Circle/option_Checkbox_Value_Circle';
import OPTION_CHECKBOX_VALUE_STRING from '../../Component/Selecteur/Option/Checkbox_Value_String/option_Checkbox_Value_String';
import OPTION_MIN_MAX from '../../Component/Selecteur/Option/Min_Max/option_Min_Max.jsx';
import ICON_BUDGET from '../../img/icon_budget.svg';
import ICON_MAISON from '../../img/icon_chambre.svg';
import ICON_DPE from '../../img/icon_dpe_jaune.svg';
import ICON_SURFACE from '../../img/icon_surface_habitable_jaune.svg';
import ICON_TERRAIN from '../../img/icon_surface_terrain_jaune.svg';
import { BIEN_POUR_FILTRE } from '../../lib/Biens/bien.const';
import { useFilter } from '../../provider/FilterProvider';

export default function FILTER({ onClick }) {
  const { formatMessage } = useIntl();
  const {
    addFilters,
    distance: dist, bienTypes, budgetMin: BMin, budgetMax: BMax, surfaceHabitableMin: sfHMin, surfaceHabitableMax: sfHMax, surfaceTerrainMin: sfTMin, surfaceTerrainMax: sfTMax, dpeMin: dpe, modalSearch,
  } = useFilter();

  const [arrayTypeDeBien, setArrayTypeDeBien] = useState(bienTypes ?? []);
  const [budgetMin, setBudgetMin] = useState(BMin ?? '');
  const [budgetMax, setBudgetMax] = useState(BMax ?? '');
  const [arrayChambre, setNumberChambre] = useState([]);
  const [surfaceHabitableMin, setSurfaceHabitableMin] = useState(sfHMin ?? '');
  const [surfaceHabitableMax, setSurfaceHabitableMax] = useState(sfHMax ?? '');
  const [surfaceTerrainMin, setSurfaceTerrainMin] = useState(sfTMin ?? '');
  const [surfaceTerrainMax, setSurfaceTerrainMax] = useState(sfTMax ?? '');
  const [distance, setDistance] = useState(dist ?? '');
  const [arrayDpe, setArrayDpe] = useState([] ?? '');

  const addTypeDeBien = useCallback((e) => {
    const selectedValue = e.target.value;
    if (arrayTypeDeBien.includes(selectedValue)) {
      setArrayTypeDeBien(
        arrayTypeDeBien.filter((item) => item !== selectedValue),
      );
    } else {
      setArrayTypeDeBien([...arrayTypeDeBien, selectedValue]);
    }
  }, [arrayTypeDeBien]);
  const listeTypeDeBienFiltree = useMemo(() => {
    const selectedValueTypeDeBienConcat = [...new Set(arrayTypeDeBien.sort())];

    return selectedValueTypeDeBienConcat?.flatMap((item) => item.split(','));
  }, [arrayTypeDeBien]);

  const addBudgetMin = useCallback((e) => {
    const selectedValue = e.target.value;
    setBudgetMin(selectedValue);
  }, [setBudgetMin]);

  const addBudgetMax = useCallback((e) => {
    const selectedValue = e.target.value;
    setBudgetMax(selectedValue);
  }, [setBudgetMax]);

  const addChambre = useCallback((e) => {
    const selectedValue = e.target.value;
    if (arrayChambre.includes(selectedValue)) {
      setNumberChambre(
        arrayChambre.filter((item) => item !== selectedValue),
      );
    } else {
      setNumberChambre([...arrayChambre, selectedValue]);
    }
  }, [arrayChambre]);

  const onlyNumbers = arrayChambre.filter((element) => !Number.isNaN(Number(element)));
  const chambresMin = onlyNumbers.length > 0 ? Math.min(...onlyNumbers) : '';

  const addSurfaceHabitableMin = useCallback((e) => {
    const selectedValue = e.target.value;
    setSurfaceHabitableMin(selectedValue);
  }, [setSurfaceHabitableMin]);

  const addSurfaceHabitableMax = useCallback((e) => {
    const selectedValue = e.target.value;
    setSurfaceHabitableMax(selectedValue);
  }, [setSurfaceHabitableMax]);

  const addSurfaceTerrainMin = useCallback((e) => {
    const selectedValue = e.target.value;
    setSurfaceTerrainMin(selectedValue);
  }, [setSurfaceTerrainMin]);

  const addSurfaceTerrainMax = useCallback((e) => {
    const selectedValue = e.target.value;
    setSurfaceTerrainMax(selectedValue);
  }, [setSurfaceTerrainMax]);

  const addDistance = useCallback((e) => {
    const selectedValue = e.target.value;
    setDistance(selectedValue);
  }, [setDistance]);

  const addDpe = useCallback((e) => {
    const selectedValue = e.target.value;
    if (arrayDpe.includes(selectedValue)) {
      setArrayDpe(
        arrayDpe.filter((item) => item !== selectedValue),
      );
    } else {
      setArrayDpe([...arrayDpe, selectedValue]);
    }
  }, [arrayDpe]);

  const listeDpeFiltree = arrayDpe?.sort();
  const dpeMin = listeDpeFiltree.length > 0 ? listeDpeFiltree.slice(-1) : '';

  const queryClient = useQueryClient();

  const form = useForm({
    mode: 'onTouched',
  });

  const typeOptions = useMemo(() => BIEN_POUR_FILTRE.map((type) => ({
    label: formatMessage({ id: `model.bien.type.${type.label}` }),
    value: formatMessage({ id: `model.bien.type.${type.value}` }),
  })), [formatMessage]);

  const onSubmit = useCallback(async () => {
    onClick();

    addFilters({
      page: 1,
      bienTypes: listeTypeDeBienFiltree,
      budgetMin,
      budgetMax,
      chambresMin,
      surfaceHabitableMin,
      surfaceHabitableMax,
      surfaceTerrainMin,
      surfaceTerrainMax,
      distance,
      dpeMin,
    });

    await queryClient.invalidateQueries({ queryKey: 'vente' });
  }, [onClick, addFilters, listeTypeDeBienFiltree, budgetMin, budgetMax, chambresMin, surfaceHabitableMin, surfaceHabitableMax, surfaceTerrainMin, surfaceTerrainMax, distance, dpeMin, queryClient]);

  return (
    <aside
      id="modal_aside"
      className="modal_aside"
      role="dialog"
      aria-labelledby="title_modal"
    >
      <HEADER_FILTER id="title_modal" onClick={onClick} />
      <Form className={`form_Filter${modalSearch}`} onSubmit={onSubmit} {...form}>
        <FlexRow
          direction="column"
          gap="4"
          grow="1"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <div className="filter_container">
            <OPTION_CHECKBOX_VALUE_STRING
              labelTitle={formatMessage({ id: 'search.filtre.labelTitle.type_de_bien' })}
              className="type_de_bien"
              name="bienTypes"
              icon={ICON_MAISON}
              options={typeOptions}
              onChange={addTypeDeBien}
            />
          </div>
          <hr />
          <div className="filter_container">
            <OPTION_MIN_MAX
              labelTitle={formatMessage({ id: 'search.filtre.labelTitle.distance' })}
              className="distance"
              valeur_Icon={formatMessage({ id: 'info.bien.km' })}
              valueMin={distance}
              onChangeMin={addDistance}
            />
          </div>
          <hr />
          <div className="filter_container">
            <OPTION_MIN_MAX
              labelTitle={formatMessage({ id: 'search.filtre.labelTitle.budget' })}
              className="budget"
              icon={ICON_BUDGET}
              valeur_Icon="€"
              valueMin={budgetMin}
              valueMax={budgetMax}
              onChangeMin={addBudgetMin}
              onChangeMax={addBudgetMax}
            />
          </div>
          <hr />
          <div className="filter_container">
            <div className="container_Info">
              <OPTION_CHECKBOX_VALUE_CIRCLE
                // labelTitle={formatMessage({ id: 'search.filtre.labelSubtitle.nombre_de_chambre' })}
                labelSubtitle={formatMessage({ id: 'search.filtre.labelSubtitle.nombre_de_chambre' })}
                className="chambre"
                name="chambresCount"
                value1="1"
                value2="2"
                value3="3"
                value4="4"
                value5="+"
                onChange={addChambre}
              />
            </div>
          </div>
          <hr />
          <div className="filter_container">
            <div className="container_Info">
              <OPTION_MIN_MAX
                labelTitle={formatMessage({ id: 'search.filtre.labelTitle.surface' })}
                labelSubtitle={formatMessage({ id: 'search.filtre.labelSubtitle.habitable' })}
                className="surfaceHabitable"
                icon={ICON_SURFACE}
                valeur_Icon={formatMessage({ id: 'info.bien.squareMetre' })}
                valueMin={surfaceHabitableMin}
                valueMax={surfaceHabitableMax}
                onChangeMin={addSurfaceHabitableMin}
                onChangeMax={addSurfaceHabitableMax}
              />
            </div>
            <div className="container_Info">
              <OPTION_MIN_MAX
                labelTitle={formatMessage({ id: 'search.filtre.labelTitle.surface' })}
                labelSubtitle={formatMessage({ id: 'search.filtre.labelSubtitle.terrain' })}
                className="surfaceTerrain"
                icon={ICON_TERRAIN}
                valeur_Icon={formatMessage({ id: 'info.bien.squareMetre' })}
                valueMin={surfaceTerrainMin}
                valueMax={surfaceTerrainMax}
                onChangeMin={addSurfaceTerrainMin}
                onChangeMax={addSurfaceTerrainMax}
              />
            </div>
          </div>
          <hr />
          <div className="filter_container">
            <div className="container_Info">
              <OPTION_CHECKBOX_VALUE_CIRCLE
                labelTitle={formatMessage({ id: 'search.filtre.labelTitle.dpe' })}
                className="dpe"
                name="dpeMin"
                icon={ICON_DPE}
                value1="A"
                value2="B"
                value3="C"
                value4="D"
                value5="E"
                value6="F"
                value7="G"
                onChange={addDpe}
              />
            </div>
          </div>
          <Button
            className="button submit yellow"
            type="submit"
            aria-label={formatMessage({ id: 'alt.button.valider' })}
          >
            <span>
              {formatMessage({ id: 'label.valider' })}
            </span>
          </Button>
        </FlexRow>
      </Form>
    </aside>
  );
}
